import { profileActions } from "../actions/actions";

const initialState: UsersState = {
	submitting: false,
	isCreateUserModalShown: false
};

export default function usersReducer(state = initialState, action) {
	switch (action.type) {
		case profileActions.GET_PROFILE:
			return Object.assign({}, state, { loading: true });
		case profileActions.SET_PROFILE:
			return Object.assign({}, state, { profile: action.data });
		case profileActions.SET_LOADING:
			return Object.assign({}, state, { loading: action.data });
		default:
			return state;
	}
}
