import React from "react";
import "./TopBar.sass";
import { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { SelectInput} from "../Form";
import { convertDateToString } from "../../utils/convertDate";
import {Calendar} from "primereact/calendar";

// Get Sunday of current week's date
function getSundayOfCurrentWeek() {
	const d = new Date();
	const day = d.getDay(),
		diff = d.getDate() - day;
	return new Date(d.setDate(diff));
}

// Get previous quarter's start date
function getPreviousQuarterStartDate() {
	const d = new Date();
	const month = d.getMonth();
	const quarter = Math.floor(month / 3);
	let year = d.getFullYear();
	let startMonth = quarter * 3 - 3;
	if (startMonth < 0) {
		year = year - 1;
		startMonth = startMonth + 12;
	}
	const startDate = new Date(year, startMonth, 1);
	return startDate;
}

// Get previous quarter's end date
function getPreviousQuarterEndDate() {
	const d = new Date();
	const month = d.getMonth();
	const quarter = Math.floor(month / 3);
	let year = d.getFullYear();
	let endMonth = quarter * 3 - 1;
	if (endMonth < 0) {
		year = year - 1;
		endMonth = endMonth + 12;
	}
	const endDate = new Date(year, endMonth + 1, 0);
	return endDate;
}

// Get current quarter's start date
function getCurrentQuarterStartDate() {
	const d = new Date();
	const month = d.getMonth();
	const quarter = Math.floor(month / 3);
	const year = d.getFullYear();
	const startMonth = quarter * 3;
	const startDate = new Date(year, startMonth, 1);
	return startDate;
}

// Get 30 days ago's date
function get30DaysAgo() {
	const d = new Date();
	const year = d.getFullYear();
	const month = d.getMonth();
	const day = d.getDate();
	const thirtyDaysAgo = new Date(year, month, day - 29);
	return thirtyDaysAgo;
}

// Get first day of the month
function getFirstDayOfMonth() {
	const d = new Date();
	const year = d.getFullYear();
	const month = d.getMonth();
	const firstDayOfMonth = new Date(year, month, 1);
	return firstDayOfMonth;
}

// Get first day of year
function getFirstDayOfYear() {
	const d = new Date();
	const year = d.getFullYear();
	const firstDayOfYear = new Date(year, 0, 1);
	return firstDayOfYear;
}

// Get day of one year go
function getOneYearAgo() {
	const d = new Date();
	const year = d.getFullYear();
	const month = d.getMonth();
	const day = d.getDate();
	const oneYearAgo = new Date(year - 1, month, day);
	return oneYearAgo;
}

// API expects date in format of YYYY-MM-DD, but when passing that format to ReactDatePicker the day is off by one
const formatDate = (date: string) => {
	const splitDate = date.split("-");
	return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Topbar({ setStartDate, setEndDate, startDate, endDate, minDate, maxDate, setDateRange, disabled }: TopbarProps) {
	const [preset, setPreset] = useState<string>("MTD");

	useEffect(() => {
		const currentDate = new Date();
		if (preset === "WTD") {
			if (setDateRange) {
				setDateRange({
					startDate: convertDateToString(getSundayOfCurrentWeek()),
					endDate: convertDateToString(currentDate)
				});
			} else {
				setStartDate(convertDateToString(getSundayOfCurrentWeek()));
				setEndDate ? (convertDateToString(currentDate)) : null;
			}
		} else if (preset === "MTD") {
			if (setDateRange) {
				setDateRange({
					startDate: convertDateToString(getFirstDayOfMonth()),
					endDate: convertDateToString(currentDate)
				});
			} else {
				setStartDate(convertDateToString(getFirstDayOfMonth()));
				setEndDate ? (convertDateToString(currentDate)) : null;
			}
		} else if (preset === "30") {
			if (setDateRange) {
				setDateRange({
					startDate: convertDateToString(get30DaysAgo()),
					endDate: convertDateToString(currentDate)
				});
			} else {
				setStartDate(convertDateToString(get30DaysAgo()));
				setEndDate ? (convertDateToString(currentDate)) : null;
			}
		} else if (preset === "QTD") {
			if (setDateRange) {
				setDateRange({
					startDate: convertDateToString(getCurrentQuarterStartDate()),
					endDate: convertDateToString(currentDate)
				});
			} else {
				setStartDate(convertDateToString(getCurrentQuarterStartDate()));
				setEndDate ? (convertDateToString(currentDate)) : null;
			}
		} else if (preset === "PQ") {
			if (setDateRange) {
				setDateRange({
					startDate: convertDateToString(getPreviousQuarterStartDate()),
					endDate: convertDateToString(getPreviousQuarterEndDate())
				});
			} else {
				setStartDate(convertDateToString(getPreviousQuarterStartDate()));
				setEndDate ? (convertDateToString(getPreviousQuarterEndDate())) : null;
			}
		} else if (preset === "YTD") {
			if (setDateRange) {
				setDateRange({
					startDate: convertDateToString(getFirstDayOfYear()),
					endDate: convertDateToString(currentDate)
				});
			} else {
				setStartDate(convertDateToString(getFirstDayOfYear()));
				setEndDate ? (convertDateToString(currentDate)) : null;
			}
		} else if (preset === "1_Year") {
			if (setDateRange) {
				setDateRange({
					startDate: convertDateToString(getOneYearAgo()),
					endDate: convertDateToString(currentDate)
				});
			} else {
				setStartDate(convertDateToString(getOneYearAgo()));
				setEndDate ? (convertDateToString(currentDate)) : null;
			}
		}
	}, [preset]);

	const handlePresetChange = (e: eventType) => {
		setPreset(e.target.value);
	};

	const handleEvent = (e,fn) => {
		if (e.originalEvent.type === "click") {
			fn(extractDateStringFromEvent(e));
		}
		if (e.originalEvent.type === "input") {
			try {
				const value = extractDateStringFromEvent(e);
				fn(value);

			} catch (error) {
				if (!(error instanceof TypeError)) {
					throw error;
				}
			}
		}
	};

	const extractDateStringFromEvent = (date) => {
		return date.value.toISOString().substring(0,10);
	};


	return (
		<div className="container">
			<Row>
				<Col xs={12} md={4}>
					<SelectInput
						field={{
							controlId: "leaderboards-select",
							label: "Date Range Type",
							labelClasses: "leaderboards-range-preset-label",
							onChange: handlePresetChange,
							value: preset,
							disabled,
							dropdownData: [
								{
									key: "WTD",
									value: "WTD",
									label: "Week to date"
								},
								{
									key: "MTD",
									value: "MTD",
									label: "Month to date"
								},
								{
									key: "30",
									value: "30",
									label: "Last 30 days"
								},
								{
									key: "QTD",
									value: "QTD",
									label: "Quarter to date"
								},
								{
									key: "PQ",
									value: "PQ",
									label: "Previous Quarter"
								},
								{
									key: "YTD",
									value: "YTD",
									label: "Year to date"
								},
								{
									key: "1_Year",
									value: "1_Year",
									label: "1 Year"
								},
								{
									key: "custom",
									value: "custom",
									label: "Custom"
								}
							]
						}}
					/>
				</Col>
				<Col xs={12} md={4}>
					<label style={{color:"white"}}>Start Date</label>
					<Calendar
						inputId="start-date"
						value={new Date(formatDate(startDate))}
						onChange={(e) => handleEvent(e,setStartDate)}
						dateFormat="mm/dd/yy"
						disabled={preset !== "custom"}
						showIcon
						key="topbar-start-date"
					/>
				</Col>
				<Col xs={12} md={4}>
					<label style={{color: "white"}}>End Date</label>
					<Calendar
						inputId="end-date"
						value={new Date(formatDate(endDate ? endDate : ""))}
						onChange={(e) => handleEvent(e,setEndDate)}
						dateFormat="mm/dd/yy"
						disabled={preset !== "custom"}
						showIcon
						key="topbar-end-date"
					/>
				</Col>
			</Row>
		</div>
	);
}

export default Topbar;
