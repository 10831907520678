import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useNavigate } from "react-router-dom";
import CSS from "csstype";
import "bootstrap-icons/font/bootstrap-icons.css";
import rootReducer from "./redux/reducers/root";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import loggerMiddleware from "./redux/middleware/logger";
import { composeWithDevTools } from "redux-devtools-extension";
import api from "./redux/middleware/api";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

const middleware = applyMiddleware(loggerMiddleware, thunkMiddleware, ...api);

const store = createStore(rootReducer, composeWithDevTools(middleware));

function Auth0ProviderWithRedirectCallback({ children, ...props }: Auth0ProviderOptions) {
	const navigate = useNavigate();
	const onRedirectCallback = (appState) => {
		navigate((appState && appState.returnTo) || window.location.pathname);
	};
	return (
		<Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
			{children}
		</Auth0Provider>
	);
}

if (!process.env.REACT_APP_AUTH0_DOMAIN || !process.env.REACT_APP_AUTH0_CLIENT_ID || !process.env.REACT_APP_AUTH0_AUDIENCE) {
	const style: CSS.Properties = {
		"color": "white",
		"textAlign": "center"
	};

	root.render(
		<div>
			<h1>ERROR</h1>
			<br></br>
			<p style={style}>If you are seeing this please contact development@infinitysolarusa.com</p>
		</div>
	);
} else {
	root.render(
		<BrowserRouter>
			<Auth0ProviderWithRedirectCallback
				domain={process.env.REACT_APP_AUTH0_DOMAIN}
				clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
				cacheLocation="localstorage"
				useRefreshTokens={true}
				authorizationParams={{
					redirect_uri: window.location.origin,
					audience: process.env.REACT_APP_AUTH0_AUDIENCE
				}}
			>
				<React.StrictMode>
					<Provider store={store}>
						<App />
					</Provider>
				</React.StrictMode>
			</Auth0ProviderWithRedirectCallback>
		</BrowserRouter>
	);
}


serviceWorkerRegistration.register();

reportWebVitals();
