import { commissionCalculationActions } from "../actions/actions";

const initialState: CommissionCalculationState = {
	commissionCalculationData: [],
	isCommissionEditModalShown: false,
	currentCommissionCalculationCustomer: undefined,
	isSubmittingCommissionCalculation: false
};

export default function commissionCalculationReducer(state = initialState, action: CommissionCalculationAction) {
	switch (action.type) {
		case commissionCalculationActions.SET_COMMISSION_CALCULATION_DATA:
			return Object.assign({}, state, { commissionCalculationData: action.data });
		case commissionCalculationActions.SET_IS_COMMISSION_EDIT_MODAL_SHOWN:
			return Object.assign({}, state, { isCommissionEditModalShown: action.data });
		case commissionCalculationActions.SET_CURRENT_COMMISSION_CALCULATION_CUSTOMER:
			return Object.assign({}, state, { currentCommissionCalculationCustomer: action.data });
		case commissionCalculationActions.SUBMIT_COMMISSION_EDIT:
			return Object.assign({}, state, { isSubmittingCommissionCalculation: true });
		case commissionCalculationActions.SET_IS_SUBMITTING_COMMISSION_CALCULATION:
			return Object.assign({}, state, { isSubmittingCommissionCalculation: action.data });
		default:
			return state;
	}
}
