import React from "react";
import Container from "react-bootstrap/Container";
import { Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Modal from "../Modal/Modal";
import Table from "../Table/Table";
import CustomerDetails from "../CustomerDetails/CustomerDetails";
import "./CustomerList.sass";
import { useDispatch, useSelector } from "react-redux";
import {
	setIsCustomerDetailModalShown,
	setCurrentCustomer
} from "../../redux/actions/customerPipeline";
import Board from "../Board/Board";
import CustomerCard from "../CustomerCard/CustomerCard";

const customerTableColumns = [
	{
		Header: "First Name",
		accessor: "first_name"
	},
	{
		Header: "Last Name",
		accessor: "last_name"
	},
	{
		Header: "Status",
		accessor: "status_name"
	},
	{
		Header: "Setter",
		accessor: "setter"
	},
	{
		Header: "Closer",
		accessor: "closer"
	},
	{
		Header: "Total Revenue",
		accessor: "total_combined_revenue"
	},
	{
		Header: "Days In Status",
		accessor: "days_in_status"
	}
];

const CustomerList = ({ customerList, source }: { customerList: Customer[], source: string }) => {
	const loadingCustomers = useSelector(({ customerPipeline }: RootState) => customerPipeline.loadingCustomers);
	const currentCustomer = useSelector(({ customerPipeline }: RootState) => customerPipeline.currentCustomer);
	const isCustomerDetailModalShown = useSelector(({ customerPipeline }: RootState) => customerPipeline.isCustomerDetailModalShown);
	const viewType = useSelector(({ customerPipeline }: RootState) => customerPipeline.viewType);
	const dispatch = useDispatch();

	const showCustomerModal = (customer: Customer) => {
		dispatch(setCurrentCustomer(customer));
		dispatch(setIsCustomerDetailModalShown(true));
	};

	const boardColumns = [
		{
			label: "Pre Approval",
			key: "pre_approval"
		},
		{
			label: "Post Approval",
			key: "post_approval"
		},
		{
			label: "Installed",
			key: "installed"
		},
		{
			label: "Completed",
			key: "completed"
		},
		{
			label: "Cancelled",
			key: "cancelled"
		},
	];

	type BoardData = {
		pre_approval: Customer[];
		post_approval: Customer[];
		installed: Customer[];
		completed: Customer[];
		cancelled: Customer[];
	}

	const boardData = customerList?.reduce((acc, curr: Customer) => {
		if (curr?.customer_stage && curr.customer_stage.indexOf("Pre Approval") > -1) {
			if (!acc.pre_approval) {
				acc.pre_approval = [];
			}
			acc.pre_approval.push(curr);
		} else if (curr?.customer_stage && curr.customer_stage.indexOf("Post Approval") > -1 && curr.customer_install_status === "Waiting") {
			if (!acc.post_approval) {
				acc.post_approval = [];
			}
			acc.post_approval.push(curr);
		} else if (curr?.customer_stage && curr.customer_stage.indexOf("Post Approval") > -1 && curr.customer_install_status === "Installed") {
			if (!acc.installed) {
				acc.installed = [];
			}
			acc.installed.push(curr);
		} else if (curr?.status_name && curr.status_name.indexOf("Completed") > -1) {
			if (!acc.completed) {
				acc.completed = [];
			}
			acc.completed.push(curr);
		} else if (curr?.customer_stage && curr.customer_stage.indexOf("Cancelled") > -1) {
			if (!acc.cancelled) {
				acc.cancelled = [];
			}
			acc.cancelled.push(curr);
		}
		return acc;
	}, {} as BoardData);

	return (<>
		<Modal
			isShown={isCustomerDetailModalShown}
			hideModal={() => {
				dispatch(setIsCustomerDetailModalShown(false));
			}}
			body={currentCustomer ? <CustomerDetails customer={currentCustomer} source={source} /> : <></>}
			heading={<h5 className="mb-0 text-center">Customer Details</h5>}
			addlProps={{ size: "lg" }}
		/>
		<Container>
			{loadingCustomers ? <div className="customer-loading-spinner">
				<Spinner animation="border" variant="info" />
			</div> :
				customerList?.length ?
					<div>
						{viewType === "list" ? <Table
							data={customerList}
							columns={customerTableColumns}
							responsive
							tableClasses="customer-table"
							trProps={{ onClick: rowData => showCustomerModal(rowData as Customer), className: "customer-table-row" }}
						/> : <Board list={boardData as BoardData} columns={boardColumns} component={CustomerCard} onClick={(customer: Customer) => showCustomerModal(customer)} iterateKey="jobnimbus_id" />}
					</div> : <Card>
						<Card.Body>There are no Customers associated with this Sales Rep</Card.Body>
					</Card>
			}
		</Container>
	</>);
};

export default CustomerList;
