import React from "react";
import { useEffect, useState } from "react";
import Table from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import {
	setForm,
	setOriginalHomeowner,
	setSolarRoofAccordion
} from "../../redux/actions/installAgreement";
import { useNavigate } from "react-router-dom";
import {
	getAuroraDesign,
} from "../../redux/actions/auroraProjects";
import { setIsCustomerDetailModalShown } from "../../redux/actions/customerPipeline";
import { setIsCustomerListModalShown } from "../../redux/actions/reports";
import { setIsProjectsDetailModalShown } from "../../redux/actions/auroraProjects";

const tableColumns = [
	{
		Header: "Financing",
		accessor: "financing_name"
	},
	{
		Header: "Design",
		accessor: "design_id"
	},
	{
		Header: "Design Name",
		accessor: "design_name"
	},
	{
		Header: "Date Created",
		accessor: "creation_date"
	}
];

const AuroraProjects = ({ auroraProjects }: { auroraProjects: AuroraDesigns[] }) => {
	const [data, setData] = useState<Array<AuroraDesigns>>();
	const currentDesign = useSelector(({ auroraProjects }: RootState) => auroraProjects.currentDesign);
	const customer = useSelector(({ customerPipeline }: RootState) => customerPipeline.currentCustomer);
	const [customer_id, setCustomer_id] = useState<string>();
	const [customer_name, setCustomer_name] = useState<string>();

	useEffect(() => {
		if (customer) {
			if (customer.customer_id != null) {
				setCustomer_id(customer.customer_id);
			}
			if (customer.first_name != null && customer.last_name != null) {
				setCustomer_name(customer.first_name + " " + customer.last_name);
			}
		}
	}, [customer]);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	function setAdderFormData(arg: { [key: string]: object }) {
		const accordionSolarRoof: Array<string> = [];
		for (const k in arg) {
			switch (k) {
				case "solaradder":
					accordionSolarRoof.push("0");
					break;
				case "roofadder":
					accordionSolarRoof.push("1");
					break;
			}
		}
		dispatch(setSolarRoofAccordion(accordionSolarRoof));
	}

	useEffect(() => {
		if (auroraProjects) {
			setData(auroraProjects);
		}
	}, [auroraProjects]);

	useEffect(() => {
		const mapStateAbbreviation = {
			AZ: "Arizona",
			TX: "Texas",
			WA: "Washington",
			OR: "Oregon",
			ID: "Idaho",
			NM: "New Mexico",
			NC: "North Carolina",
		};
		if (currentDesign) {
			const formData = JSON.parse(JSON.stringify(currentDesign));
			if ("adders" in formData) {
				if ("promotions" in formData["adders"]) {
					for (const k in formData["adders"]["promotions"]) {
						switch (k) {
							case "Perfect Timing Promo":
								// formData["seasonal_promotion"] = true;
								break;
							case "Hur-Ray for the Holidays":
								// formData["gtr_promotion"] = true;
								break;
							case "Other":
								// formData["other_checkbox"] = true;
								break;
						}
					}
				}
				if ("solaradder" in formData["adders"] || "roofadder" in formData["adders"]) {
					setAdderFormData(formData["adders"]);
				}
			}
			if ("state" in formData) {
				formData["state"] = mapStateAbbreviation[formData["state"]];
			}
			if (customer_id) {
				formData["customer_id"] = customer_id;
			}
			dispatch(setIsProjectsDetailModalShown(false));
			dispatch(setIsCustomerDetailModalShown(false));
			dispatch(setIsCustomerListModalShown(false));
			delete formData["adders"];
			dispatch(setForm(formData));
			if (customer_name) {
				dispatch(setOriginalHomeowner(customer_name));
			}
			navigate("/agreement");
		}
	});

	const onCreateInstallAgreementAurora = (design: AuroraDesigns) => {
		dispatch(getAuroraDesign(design));
	};

	return <div>
		<div className="customer-detail-modal-header-container">
			<h3>Project Proposals</h3>
		</div>
		{data?.length ?
			<Table
				columns={tableColumns}
				data={data}
				trProps={{ onClick: rowData => onCreateInstallAgreementAurora(rowData as AuroraDesigns), className: "customer-table-row" }}
			/> : <></>}
	</div>;
};


export default AuroraProjects;
