import apiCall from "../../../utils/api";
import { mismatchedRevenueActions } from "../../actions/actions";
import { setData, setLoading } from "../../actions/mismatchedRevenue";
import { Middleware } from "redux";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";

const getData: Middleware<object, RootState> = store => next => action => {
	const { dispatch } = store;
	if (action.type === mismatchedRevenueActions.GET_DATA) {
		const { loading } = store.getState().mismatchedRevenue;
		if (!loading) {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/csm-data-validator/customer-sheet-payment-vs-revenue/all`,
				method: "GET"
			}).then(res => {
				dispatch(setData(res.customers));
			}).catch(e => {
				console.log(e);
				apiErrorToast();
				dispatch(setLoading(false));
			});
		}
	}
	const result = next(action);
	return result;
};

export default [getData];
