import React, {useEffect, useState} from "react";
import { Button, Col, Row } from "react-bootstrap";
import CommissionCalculationEdit from "./CommissionCalculationEdit";

const CommissionCalculationModal = ({ customer }: { customer: CommissionCalculation }) => {
	const [editing, setEditing] = useState(false);
	const DetailItem = ({ field, value }: { field: string, value: string | boolean | number | null }) => <p style={{ marginBottom: 0 }}><b>{field}:</b> {value}</p>;

	const handleChange = () => {
		setEditing(!editing);
	};

	useEffect(() => {
		customer["total_job_revenue_plus_incentives"] = Number(customer.job_total_revenue) + Number(customer.incentives_offered);
	}, [customer]);

	return(
		<>
			{editing ?
				<CommissionCalculationEdit /> :
				<div>
					<div className="customer-detail-modal-header-container">
						<h3>{`${customer.customer_name}`}</h3>
					</div>
					<Row>
						<Col md={6} sm={12}>
							<DetailItem field="Customer Name" value={customer.customer_name} />
							<DetailItem field="Closer" value={customer.closer} />
							<DetailItem field="Setter" value={customer.setter} />
							<DetailItem field="Sales Month" value={String(customer.date_created).split(" ")[0]} />
							<DetailItem field="Job Total Reveune" value={customer.job_total_revenue} />
							<DetailItem field="Incentives Offered" value={customer.incentives_offered} />
							<DetailItem field="Total Job Revenue Plus Incentives" value={Number(customer.job_total_revenue) + Number(customer.incentives_offered)} />
							<DetailItem field="Job KW" value={customer.job_kw} />
							<DetailItem field="Price Per Watt" value={customer.sales_price_per_watt} />
							<DetailItem field="Market" value={customer.market} />
							<DetailItem field="Market Redline" value={customer.market_redline} />
							<DetailItem field="Market Total Commissions" value={customer.market_total_commission} />
							<DetailItem field="Roof" value={customer.roof} />
							{/* <DetailItem field="GTR" value={customer.GTR} /> */}
							<DetailItem field="Incentives Deductions" value={customer.incentive_deductions} />
							<DetailItem field="Total Adders Plus Incentives" value={customer.total_adders_plus_incentives} />
						</Col>
						<Col md={6} sm={12}>
							<DetailItem field="Net Commission" value={customer.net_commission} />
							<DetailItem field="Self Gen Closer Commission Redline" value={customer.closer_redline} />
							<DetailItem field="Self Gen Closer Commission Amount" value={customer.closer_amount} />
							<DetailItem field="Self Gen Setter Commission Paid" value={customer.closer_pay ? customer.closer_pay.closer_paid : ""} />
							<DetailItem field="Self Gen Closer Pay Date" value={customer.closer_pay ? customer.closer_pay.closer_pay_date : ""} />
							<DetailItem field="Setter Commission Rate" value={customer.setter_commission_rate} />
							<DetailItem field="Setter Commission Amount" value={customer.setter_commission_amount} />
							<DetailItem field="Setter Commission Paid" value={customer.setter_pay ? customer.setter_pay.setter_commission_paid : ""} />
							<DetailItem field="Setter Commission Pay Date" value={customer.setter_pay ? customer.setter_pay.setter_pay_date : ""} />
							<DetailItem field="Team Lead Name" value={customer.team_lead} />
							<DetailItem field="Team Lead Commission Rate" value={customer.team_lead_commission_rate} />
							<DetailItem field="Team Lead Commission Amount" value={customer.team_lead_commission_amount} />
							<DetailItem field="Team Lead Commission Paid" value={customer.team_lead_pay ? customer.team_lead_pay.team_lead_commission_paid : ""} />
							<DetailItem field="Team Lead Pay Date" value={customer.team_lead_pay ? customer.team_lead_pay.team_lead_pay_date : ""} />
							<DetailItem field="Setter Coach Name" value={customer.setter_coach} />
							<DetailItem field="Setter Coach Commission Rate" value={customer.setter_coach_commission_rate} />
							<DetailItem field="Setter Coach Commission Amount" value={customer.setter_coach_commission_amount} />
							<DetailItem field="Setter Coach Commission Paid" value={customer.setter_coach_pay ? customer.setter_coach_pay.setter_coach_commission_paid : ""} />
							<DetailItem field="Setter Coach Pay Date" value={customer.setter_coach_pay ? customer.setter_coach_pay.setter_coach_pay_date : ""} />
							<DetailItem field="Branch Manager Name" value={customer.branch_manager} />
							<DetailItem field="Branch Manager Commission Rate" value={customer.branch_manager_commission_rate} />
							<DetailItem field="Branch Manager Commission Amount" value={customer.branch_manager_commission_amount} />
							<DetailItem field="Branch Manager Commission Paid" value={customer.branch_manager_pay ? customer.branch_manager_pay.branch_manager_commission_paid : ""} />
							<DetailItem field="Branch Manager Pay Date" value={customer.branch_manager_pay ? customer.branch_manager_pay.branch_manager_pay_date : ""} />
							<DetailItem field="Market Manager Name" value={customer.market_manager} />
							<DetailItem field="Market Manager Commission Rate" value={customer.market_manager_commission_rate} />
							<DetailItem field="Market Manager Commission Amount" value={customer.market_manager_commission_amount} />
							<DetailItem field="Market Manager Commission Paid" value={customer.market_manager_pay ? customer.market_manager_pay.market_manager_commission_paid : ""} />
							<DetailItem field="Market Manager Pay Date" value={customer.market_manager_pay ? customer.market_manager_pay.market_manager_pay_date : ""} />
							<DetailItem field="Reserve Fund Percent" value={customer.reserve_fund_percent} />
							<DetailItem field="Reserve Fund Contribution" value={customer.reserve_fund_contribution} />
							<DetailItem field="Total Commission" value={customer.total_commission} />
							<DetailItem field="Miguel ASM" value={customer.miguel_asm} />
						</Col>
					</Row>
					<Row>
						{
							customer.solar_adders || customer.roof_adders ?
								<>
									<h3>Adders</h3>
									{
										customer.solar_adders ?
											<Col md={6} sm={12}>
												<h4>Solar</h4>
												{Object.keys(customer.solar_adders).map((key: string) => {
													return <DetailItem
														key={key}
														field={key}
														value={customer.solar_adders ? customer.solar_adders[key].default_cost : ""}
													/>;
												})}
											</Col> : <></>
									}
									{
										customer.roof_adders ?
											<Col md={6} sm={12}>
												<h4>Roof</h4>
												{Object.keys(customer.roof_adders).map((key: string) => {
													return <DetailItem
														key={key}
														field={key}
														value={customer.roof_adders ? customer.roof_adders[key].default_cost : ""}
													/>;
												})}
											</Col> : <></>
									}
								</> : <></>}
					</Row>
					<Button onClick={handleChange} className="ms-2" title="Edit customer">
						Edit
					</Button>
				</div>
			}
		</>
	);
};

export default CommissionCalculationModal;

