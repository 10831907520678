import React, { useEffect, useRef } from "react";
import filterList from "../../../utils/filterList";
import TextInput from "../Text/TextInput";
import ListGroup from "react-bootstrap/ListGroup";
import "./AutoComplete.sass";

function AutoComplete<T>({ field }: { field: AutoCompleteProps<T> }) {
	const [currentInput, setCurrentInput] = React.useState<string>("");
	const [filteredList, setFilteredList] = React.useState<Array<T>>([]);
	
	function useOutsideAlerter(ref) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setFilteredList([]);
				}
			}
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);

	useEffect(() => {
		if (currentInput === "") {
			setFilteredList([]);
			return;
		}
		const filteredList = field.list && field.list.length ? filterList(field.list, currentInput, field.filterKeys) : [];
		setFilteredList(filteredList);
		return () => { setFilteredList([]); };
	}, [currentInput]);

	const input = <TextInput
		field={{
			controlId: field.controlId,
			label: field.label,
			value: field.value ? field.value : currentInput,
			onChange: (e: React.ChangeEvent<HTMLInputElement>) => { if (field.onChange) field.onChange(e); setCurrentInput(e.target.value); },
			validation: field.validation,
			disabled: field.disabled,
			placeholder: field.placeholder
		}}
	/>;

	const list = <ListGroup>
		{filteredList && filteredList.length ? filteredList.map((item: T) =>
			<ListGroup.Item
				key={item[field.filterKeys[0]]}
				onClick={(e) => {
					e.preventDefault();
					if (field.onItemClick) field.onItemClick(item);
					if (field.onChange) field.onChange({ target: { value: field.valueTransform ? field.valueTransform(item) : item[field.filterKeys[0]] }} as React.ChangeEvent<HTMLInputElement>);
					setFilteredList([]);
				}}
			>
				{item[field.filterKeys[0]]}
			</ListGroup.Item>
		) : <></>}
	</ListGroup>;

	return <div className={`autocomplete-container ${field.autocompleteContainerClasses ? field.autocompleteContainerClasses : ""}`} ref={wrapperRef}>
		{input}
		{list}
	</div>;
}

export default AutoComplete;
