import { auroraProjectsActions } from "./actions";

export const setIsProjectsDetailModalShown = (data: boolean) => ({
	type: auroraProjectsActions.SET_IS_AURORA_PROJECT_DETAIL_MODAL_SHOWN,
	data
});

export const getAuroraDesigns = (data: string) => ({
	type: auroraProjectsActions.GET_DESIGNS,
	data
});

export const setAuroraDesigns = (data: AuroraDesigns[]) => ({
	type: auroraProjectsActions.SET_DESIGNS,
	data
});

export const setAuroraDesignsLoading = (data: boolean) => ({
	type: auroraProjectsActions.SET_DESIGNS_LOADING,
	data
});

export const getAuroraDesign = (data: AuroraDesigns) => ({
	type: auroraProjectsActions.GET_CURRENT_DESIGN,
	data
});

export const setAuroraDesign = (data: object) => ({
	type: auroraProjectsActions.SET_CURRENT_DESIGN,
	data
});

export const setAuroraDesignLoading = (data: boolean) => ({
	type: auroraProjectsActions.SET_DESIGNS_LOADING,
	data
});