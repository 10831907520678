import apiCall from "../../../utils/api";
import { dataValidationActions } from "../../actions/actions";
import { setData, setLoading, getData as getDataAction } from "../../actions/dataValidation";
import { Middleware } from "redux";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";

const getData: Middleware<object, RootState> = store => next => action => {
	const dispatch = store.dispatch;
	if (action.type === dataValidationActions.GET_DATA) {
		const { loading } = store.getState().dataValidation;
		if (!loading) {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/csm-data-validator/${action.data}`,
			}).then(res => {
				if (res.customers) dispatch(setData(res.customers));
				if (res.result) dispatch(setData(res.result));
			}).catch(e => {
				console.error(e);
				apiErrorToast();
			}).finally(() => {
				dispatch(setLoading(false));
			});
		}
	}
	if (action.type === dataValidationActions.SET_TYPE) {
		dispatch(getDataAction(action.data));
	}
	const result = next(action);
	return result;
};

export default [getData];
