import React from "react";
import Form from "react-bootstrap/Form";
import "./TextInput.sass";
import { InputGroup } from "react-bootstrap";

const numberInputOnWheelPreventChange = (e: React.WheelEvent<HTMLInputElement>) => {
	const { target } = e;
	// Prevent the input value change
	(target as HTMLButtonElement).blur();

	// Prevent the page/container scrolling
	e.stopPropagation();

	// Refocus immediately, on the next tick (after the current function is done)
	setTimeout(() => {
		(target as HTMLButtonElement).focus();
	}, 0);
};

type PrefixControl = ({ children, prefix }: {children: React.ReactNode, prefix: string | number}) => JSX.Element;
	
const PrefixControl: PrefixControl = ({ children, prefix }) => 
	<InputGroup className="mb-3">
		{children}
		<InputGroup.Text id="basic-addon2">{prefix}</InputGroup.Text>
	</InputGroup>;

type HintText = ({ children }: { children: React.ReactNode }) => JSX.Element;

const HintText = ({ children }: { children: React.ReactNode }) =>
	<Form.Text className="text-muted">
		{children}
	</Form.Text>;

const controlKeys: string[] = [
	"onChange",
	"controlClasses",
	"placeholder",
	"type",
	"disabled",
	"value",
	"readOnly",
	"name",
	"onBlur",
	"step",
	"inputMode",
	"pattern"
];

const filterControlField = (obj: {[key:string]:string}) => {
	const newObj = {};
	Object.keys(obj).forEach(key => {
		if (controlKeys.includes(key)) {
			newObj[key] = obj[key];
		}
	});
	return newObj;
};

const TextInput = ({ field }: { field: TextInputProps }) => {
	const {
		controlId,
		validation,
		prefix,
		hint
	} = field;

	const control = <Form.Control
		onWheel={numberInputOnWheelPreventChange}
		{...filterControlField(field)}
	/>;

	return <Form.Group
		controlId={field.controlId}
		className={`text-input${field.groupClasses ? ` ${field.groupClasses}` : ""}`}
		id={`${controlId}_group`}
	>
		<Form.Label>
			{field.label}
		</Form.Label>
		{ prefix ?
			<PrefixControl prefix={prefix}>
				{control}
			</PrefixControl>
			:
			control
		}
		{ hint ? <HintText>{hint}</HintText>
			: "" }
		{ validation && Object.keys(validation).length && controlId && validation[controlId] ? <p className="field-validation-error">
			{validation[controlId] as string}
		</p> : "" }
	</Form.Group>;
};

export default TextInput;
