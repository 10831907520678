const logger = store => next => action => {
	if (process.env.REACT_APP_LOG_LEVEL === "debug") {
		console.group(action.type);
		console.info("dispatching", action);
		
		console.log("next state", store.getState());
		console.groupEnd();
	}
	const result = next(action);
	return result;
};

export default logger;
