import { toast } from "react-toastify";

const apiErrorMessage = "An error occurred while submitting your information. Please try again later.";

export const apiErrorToast = (responseMessage?: string) =>
	((message?: string) => {
		if (responseMessage) {
			toast.error(responseMessage, {
				position: "top-right",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				toastId: "modal-Designs-Error",
				onClick: () => window.close(),
				autoClose: false
			});
		}
		else {
			toast.error(
				message ? responseMessage ? responseMessage : message : apiErrorMessage,
				{
					position: "top-right",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					toastId: "ia-Submission-Error",
					onClick: () => window.close(),
					autoClose: false
				});
		}
	})();
