import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserDetail from "../Components/UserDetail/UserDetail";
import { getProfile } from "../redux/actions/profileActions";
import { themedLoadingCircles } from "../Components/LoadingCircles/ThreeCircles";
import { Container } from "react-bootstrap";
import "./styles/Profile.sass";

const Profile = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getProfile());
	}, []);

	const loading = useSelector(({ profile }: RootState) => profile.loading);
	const profile = useSelector(({ profile }: RootState) => profile.profile);

	return <Container className="profile-container">
		{ !loading ?
			<UserDetail userDetails={profile} submittingUserDetail={false} readOnly={true} />
			:
			themedLoadingCircles
		}
	</Container>;
};

export default Profile;
