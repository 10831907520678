import TextInput from "./Text/TextInput";
import TextArea from "./Text/TextAreaInput";
import SelectInput from "./Select/SelectInput";
import CheckboxInput from "./Checkbox/CheckboxInput";
import Adder from "./Adder/Adder";
import DatePicker from "./DatePicker/DatePicker";

const enum fieldTypes {
	text = "text",
	number = "number",
	dropdown = "dropdown",
	checkbox = "checkbox",
	custom = "custom"
}

export { TextInput, TextArea, SelectInput, CheckboxInput, Adder, DatePicker, fieldTypes };