import { redlinePageActions } from "./actions";

export const setRedlineData = (data: User[]) => ({
	type: redlinePageActions.SET_REDLINE_DATA,
	data
});

export const getRedlineData = (data: FilterData) => ({
	type: redlinePageActions.GET_REDLINE_DATA,
	data
});
