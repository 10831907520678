import { installAgreementActions } from "../../actions/actions";
import { Middleware } from "redux";
import { validateForm, createFormElements, submitDryRun } from "../../../Components/InstallAgreement/submitForm";
import { setLoadingPricing, setPricing, setValidationErrors } from "../../actions/installAgreement";
import apiCall from "../../../utils/api";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";


const installAgreementMiddleware: Middleware<object, RootState> = store => next => async action => {
	const { type, data } = action;
	const state = store.getState();
	const dispatch = store.dispatch;

	switch (type) {
		case installAgreementActions.SET_FORM_VALUE: {
			const { form, solaradder, roofadder } = state.installAgreement;
			const { currentPricing: pricing_ppw } = state.iaPriceAdjuster;
			const formData = createFormElements({ ...form, ...data }, solaradder, roofadder);
			const validationErrors = await validateForm(formData, pricing_ppw);
			if (!Object.values(validationErrors).length) {
				dispatch(setValidationErrors({}));
				submitDryRun(formData, store.dispatch);
			}
			break;
		}
		case installAgreementActions.SET_ADDER_VALUE: {
			const { type: adderType, data: adderData } = data;
			const { form, solaradder, roofadder } = state.installAgreement;
			const { currentPricing: pricing_ppw } = state.iaPriceAdjuster;
			let solaradderWithNewData = solaradder;
			let roofadderWithNewData = roofadder;
			if (adderType === "solaradder") {
				solaradderWithNewData = { ...solaradder, ...adderData };
			} else if (adderType === "roofadder") {
				roofadderWithNewData = { ...roofadder, ...adderData };
			}
			const formData = createFormElements(form, solaradderWithNewData, roofadderWithNewData);
			const validationErrors = await validateForm(formData, pricing_ppw);
			if (!Object.values(validationErrors).length) {
				submitDryRun(formData, dispatch);
			}
			break;
		}
		case installAgreementActions.GET_PRICING: {
			const company = action.data["company"];
			const startDate = action.data["startDate"];
			// const endDate = action.data["endDate"];
			let market = "";
			if (action.data["market"] != "" && action.data["market"] != null) {
				market = `&market=${action.data["market"]}`;
			}
			let start_date = "";
			if (startDate != "" && startDate != null) {
				const chosenDate = new Date(startDate);
				const yearUTC = chosenDate.getUTCFullYear();
				const monthUTC = chosenDate.getUTCMonth() + 1;
				const dayUTC = chosenDate.getUTCDate();
				const hourUTC = chosenDate.getUTCHours();
				const minuteUTC = chosenDate.getUTCMinutes();
				const secondUTC = chosenDate.getUTCSeconds();
				const utcArray = [monthUTC, dayUTC, hourUTC, minuteUTC, secondUTC];
				const utcArrayStr: string[] = [];
				let temp = yearUTC.toString();
				utcArray.forEach((item, index) => {
					if (item < 10) {
						utcArrayStr[index] = `0${item}`;
					}
					else {
						utcArrayStr[index] = item.toString();
					}
					// UTC format is YYYY-MM-DDTHH:mm:ssZ
					if (index < 2) {
						temp = `${temp}-${utcArrayStr[index]}`;
					}
					else if (index == 2) {
						temp = `${temp}T${utcArrayStr[index]}`;
					}
					else {
						temp = `${temp}:${utcArrayStr[index]}`;
					}
				});
				temp = `${temp}Z`;
				start_date = `&date=${temp}`;
			}
			// if (endDate != "" && endDate != null) {
			// 	end_date = `&end_date=${endDate}`;
			// }
			if (company != "" && company != null) {
				apiCall({
					url: `${process.env.REACT_APP_API_SERVICE}/ia-price-adjuster/company-prices?company=${company}` + market + start_date
				}).then(res => {
					dispatch(setPricing(res));
				}).catch(err => {
					if (err.response.status != 500) {
						if (start_date != "") {
							apiErrorToast(err.response.data);
						}
					}
					else {
						apiErrorToast();
					}
					dispatch(setPricing([]));
				}).finally(() => {
					dispatch(setLoadingPricing(false));
				});
			}
		}
	}
	const result = next(action);
	return result;
};

export default [installAgreementMiddleware];
