import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Leaderboards from "./pages/Leaderboards";
import CustomerPipeline from "./pages/CustomerPipeline";
import InstallAgreement from "./pages/InstallAgreement";
import HomePage from "./pages/HomePage";
import WhenAuthorized from "./Components/WhenAuthorized";
import Users from "./pages/Users";
import DataValidation from "./pages/DataValidation";
import { UserPermissions } from "./enums/UserPermissions";
import PerformanceAuditor from "./pages/PerformanceAuditor";
import MismatchedRevenue from "./pages/MismatchedRevenue";
import Reports from "./pages/Reports";
import Profile from "./pages/Profile";
import Prices from "./pages/Prices";
import MarketPipeline from "./pages/MarketPipeline";
import ResourcePage from "./pages/ResourcePage";
import checkIfAuthorized from "./utils/permissions";
import CompanyPipeline from "./pages/CompanyPipeline";
import Redline from "./pages/Redline";
import CommissionCalculation from "./pages/CommissionCalculation";
import CommissionSalesRep from "./pages/CommissionSalesRep";

const AppRoutes = () => {
	return <Routes>
		<Route path='*' element={<HomePage />} />
		<Route
			path='leaderboard'
			element={
				<WhenAuthorized permission={UserPermissions.leaderboard} notAuthorized={<Navigate to="/" />}>
					<Leaderboards />
				</WhenAuthorized>
			}
		/>
		<Route
			path='commission'
			element={
				<WhenAuthorized permission={UserPermissions.commission} notAuthorized={<Navigate to="/" />}>
					<CommissionSalesRep />
				</WhenAuthorized>
			}
		/>
		<Route
			path='users'
			element={
				<WhenAuthorized permission={UserPermissions.user} notAuthorized={<Navigate to="/" />}>
					<Users />
				</WhenAuthorized>
			}
		/>
		<Route
			path='customer'
			element={
				<WhenAuthorized permission={UserPermissions.customer} notAuthorized={<Navigate to="/" />}>
					<CustomerPipeline />
				</WhenAuthorized>
			}
		/>
		<Route path='agreement' element={(checkIfAuthorized(UserPermissions.agreement) || checkIfAuthorized(UserPermissions.external_agreement)) ? <InstallAgreement /> : <Navigate to="/" />}>
		</Route>
		<Route
			path='validator'
			element={
				<WhenAuthorized permission={UserPermissions.validator} notAuthorized={<Navigate to="/" />}>
					<DataValidation />
				</WhenAuthorized>
			}
		/>
		<Route
			path='performance-auditor'
			element={
				<WhenAuthorized permission={UserPermissions.performanceAuditor} notAuthorized={<Navigate to="/" />}>
					<PerformanceAuditor />
				</WhenAuthorized>
			}
		/>
		<Route
			path='mismatched-data'
			element={
				<WhenAuthorized permission={UserPermissions.admin} notAuthorized={<Navigate to="/" />}>
					<MismatchedRevenue />
				</WhenAuthorized>
			}
		/>
		<Route
			path='reports'
			element={
				<WhenAuthorized permission={UserPermissions.reports} notAuthorized={<Navigate to="/" />}>
					<Reports />
				</WhenAuthorized>
			}
		/>
		<Route
			path='pricing'
			element={
				<WhenAuthorized permission={UserPermissions.view_pricing_page} notAuthorized={<Navigate to="/" />}>
					<Prices />
				</WhenAuthorized>
			}
		/>
		<Route
			path='market'
			element={
				<WhenAuthorized permission={UserPermissions.market} notAuthorized={<Navigate to="/" />}>
					<MarketPipeline />
				</WhenAuthorized>
			}
		/>
		<Route
			path='company-pipeline'
			element={
				<WhenAuthorized permission={UserPermissions.company} notAuthorized={<Navigate to="/" />}>
					<CompanyPipeline />
				</WhenAuthorized>
			}
		/>
		<Route
			path='redline'
			element={
				<WhenAuthorized permission={UserPermissions.redlineInsert} notAuthorized={<Navigate to="/" />}>
					<Redline />
				</WhenAuthorized>
			}
		/>
		<Route
			path='profile'
			element={
				<Profile />
			}
		/>
		<Route
			path='resource'
			element={
				<ResourcePage />
			}
		/>
		<Route
			path='commission-calculation'
			element={
				<CommissionCalculation />
			}
		/>
	</Routes>;
};

export default AppRoutes;
