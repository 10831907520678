import React from "react";

function PanelReportbi() {
	return (
		<div className="powerbi-container" >
			<div>
				<iframe width="100%"
					height="850"
					src="https://app.powerbi.com/reportEmbed?reportId=cd4eb8f5-2ca6-479e-a0b8-84c22cd3f5cf&autoAuth=true&ctid=4fb4efe8-a20f-4608-b1fa-4fe4ffc5c0a6"
					title="POWER BI REPORT">
				</iframe>
			</div>
		</div>
	);
}

export default PanelReportbi;
