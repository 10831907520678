import React from "react";
import Form from "react-bootstrap/Form";
import "./TextInput.sass";
import { InputGroup } from "react-bootstrap";

const numberInputOnWheelPreventChange = (e: React.WheelEvent<HTMLInputElement>) => {
	const { target } = e;
	// Prevent the input value change
	(target as HTMLButtonElement).blur();

	// Prevent the page/container scrolling
	e.stopPropagation();

	// Refocus immediately, on the next tick (after the current function is done)
	setTimeout(() => {
		(target as HTMLButtonElement).focus();
	}, 0);
};
	
const PrefixControl = ({ children, prefix }: { children: React.ReactNode, prefix: string|number }) => 
	<InputGroup className="mb-3">
		{children}
		<InputGroup.Text id="basic-addon2">{prefix}</InputGroup.Text>
	</InputGroup>;

const HintText = ({ children }: { children: React.ReactNode }) =>
	<Form.Text className="text-muted">
		{children}
	</Form.Text>;

const TextArea = ({ field }: { field: TextAreaProps }) => {
	const {
		controlClasses,
		placeholder,
		type,
		disabled,
		value,
		readOnly,
		rows,
		name
	} = field;

	const control = <Form.Control
		onWheel={numberInputOnWheelPreventChange}
		{...(field.onChange ? { onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => field.onChange ? field.onChange(e) : "" } : {})}
		{...(controlClasses ? { className: controlClasses } : {} )}
		{...(placeholder ? { placeholder } : {} )}
		{...(type ? { type } : {} )}
		{...(disabled ? { disabled } : {} )}
		{...(value !== null && value !== undefined ? { value } : {} )}
		{...(readOnly ? { readOnly } : {} )}
		{...(rows ? { rows } : {})}
		{...(name ? { name } : {})}
		as="textarea"
	/>;

	return <Form.Group controlId={field.controlId} className={`text-input${field.groupClasses ? ` ${field.groupClasses}` : ""}`}>
		<Form.Label>{field.label}</Form.Label>
		{ field.prefix ? <PrefixControl prefix={field.prefix}>{control}</PrefixControl> : control }
		{ field.hint ? <HintText>{field.hint}</HintText> : "" }
		{ field.validation && Object.keys(field.validation).length && field.controlId && field.validation[field.controlId] ? <p className="field-validation-error">
			{field.validation[field.controlId]}
		</p> : "" }
	</Form.Group>;
};

export default TextArea;
