import React, { useEffect, useState } from "react";
import Table from "../Components/Table/Table";
import "./styles/Redline.sass";
import Modal from "../Components/Modal/Modal";
import SetRedline from "../Components/SetRedline/SetRedline";
import { getUserDetails } from "../redux/actions/users";
import { useDispatch, useSelector } from "react-redux";
import { getRedlineData } from "../redux/actions/redlinePageActions";
import UserFilter from "../Components/UserFilter/UserFilter";

const Redline = () => {
	const selectedUser = useSelector(({ users }: RootState) => users.selectedUser);
	const redlineData = useSelector(({ redlinePage }: RootState) => redlinePage.redlineData);
	const [isRedlineModalShown, setIsRedlineModalShown] = useState<boolean>(false);
	const dispatch = useDispatch();

	const tableHeaders = [
		{
			Header: "Name",
			accessor: "name"
		},
		{
			Header: "Start Date",
			accessor: "start_date"
		},
		{
			Header: "End Date",
			accessor: "end_date"
		},
		{
			Header: "Redline",
			accessor: "redline"
		},
		{
			Header: "Default PPW",
			accessor: "default_base_price_per_watt"
		},
		{
			Header: "Delagation Redline",
			accessor: "delagation_redline"
		}
	];

	useEffect(() => {
		const data: FilterData = {
			date: "",
			company: "",
			market: ""
		};
		dispatch(getRedlineData(data));
	}, []);

	const openRedlineModal = (userData) => {
		dispatch(getUserDetails(userData.email));
		setIsRedlineModalShown(true);
		console.log(isRedlineModalShown);
	};

	return <>
		<Modal
			isShown={isRedlineModalShown}
			hideModal={() => {
				setIsRedlineModalShown(false);
			}}
			body={
				<SetRedline user={selectedUser} />
			}
			heading="Set Redline"
			addlProps={{ size: "lg" }}
		/>
		<h1>Redline</h1>
		{redlineData ?
			<div className="mainLayout">
				<UserFilter />
				<div className="redline-table">
					<Table
						columns={tableHeaders}
						data={redlineData}
						trProps={{ onClick: rowData => openRedlineModal(rowData) }}
					/>
				</div>
			</div> :
			<></>
		}
	</>;
};

export default Redline;
