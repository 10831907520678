import React from "react";
import { Card } from "react-bootstrap";
import "./Scorecard.sass";

const formatKey = (key: string) => {
	const withSpaces = key.replace(/_/g, " ");
	return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);
};

const Scorecard = ({ user }) => {
	const DetailCard = ({ label, value }) => {
		return (
			<Card className="scorecard-detail-card">
				<div className="scorecard-detail-card-label">{formatKey(label)}</div>
				<div className="scorecard-detail-card-value">{value}</div>
			</Card>
		);
	};
	const cards = Object.keys(user.detail).map(key => {
		return <DetailCard key={key} label={key} value={user.detail[key].display_value} />;
	});
	return <div className="scorecard-details-container">
		<h3>{user.name}</h3>
		{cards}
	</div>;
};

export default Scorecard;
