import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSalesMarketReportQuarter, setSalesMarketValues, setSalesMarketValuesModalShown } from "../../redux/actions/reports";
import { SelectInput } from "../Form";
import Table from "../Table/Table";
import { themedLoadingCircles } from "../LoadingCircles/ThreeCircles";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Bar } from "react-chartjs-2";
import Toggle from "../Toggle/Toggle";
import Modal from "../Modal/Modal";

const monthsInQuarters = [
	[0, 1, 2],
	[3, 4, 5],
	[6, 7, 8],
	[9, 10, 11]
];

const minYear = 2022;
const buildYearDropdownData = () => {
	const date = new Date();
	const year = date.getFullYear();
	const years = [] as SelectOption[];
	for (let i = minYear; i <= year; i++) {
		years.push({ key: i.toString(), value: i.toString(), label: i.toString() });
	}
	return years;
};

const QuarterSelect = ({ value, onChange, showQuarter, className = "" }) => {
	const quarterDropdownData = monthsInQuarters.map((q, i) => {
		return {
			key: i.toString(),
			value: (i + 1).toString(),
			label: (i + 1).toString()
		};
	});

	return <>
		<div className={`quarter-select-container ${className}`}>
			{showQuarter ? <SelectInput
				field={{
					controlId: "quarter",
					label: "Quarter",
					value: value.quarter,
					onChange: e => onChange({ ...value, quarter: e.target.value }),
					dropdownData: quarterDropdownData
				}}
			/> : <></>}
			<SelectInput
				field={{
					controlId: "year",
					label: "Year",
					value: value.year,
					onChange: e => onChange({ ...value, year: e.target.value }),
					dropdownData: buildYearDropdownData()
				}}
			/>
		</div>
	</>;
};

const getDefaultQuarter = () => {
	const date = new Date();
	const month = date.getMonth();
	const year = date.getFullYear();
	const quarter = monthsInQuarters.findIndex(q => q.includes(month));
	return { quarter: quarter.toString(), year: year.toString() };
};

const adaptData = (data) => {
	return Object.keys(data).map(k =>
		Object.assign({}, { market: k }, ...Object.keys(data[k]).map(tk => ({ [tk]: data[k][tk].display })), ...Object.keys(data[k]["setters"]).map(sk => ({ [`setters_${sk}`]: data[k]["setters"][sk].display })))
	);
};

const SalesMarketModalTableColumns = [
	{ accessor: "customer_name", Header: "Customer Name" },
	{ accessor: "closer_name", Header: "Closer Name" },
	{ accessor: "setter_name", Header: "Setter Name" },
	{ accessor: "Total Combined Revenue", Header: "Total Combined Revenue" },
	{ accessor: "Update", Header: "Update" },
	{ accessor: "Market", Header: "Market" }
];

const SalesMarketModalRepTableColumns = [
	{ accessor: "name", Header: "Name" },
	{ accessor: "email", Header: "Email" },
	{ accessor: "role", Header: "Role" },
	{ accessor: "market", Header: "Market" },
	{ accessor: "start_date", Header: "Start Date" },
	{ accessor: "end_date", Header: "End Date" },
];

const SalesMarketValuesTable = ({ data }) => {
	let columns = [] as HeaderData[];
	if (data.length && Object.keys(data[0]).includes("customer_name")) {
		columns = SalesMarketModalTableColumns;
	} else {
		columns = SalesMarketModalRepTableColumns;
	}
	return <>
		<Table data={data} columns={columns} />
	</>;
};

const SalesMarketReport = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setSalesMarketReportQuarter(getDefaultQuarter()));
	}, []);

	const salesMarketReportQuarter = useSelector(({ reports }: RootState) => reports.salesMarketReportQuarter) || getDefaultQuarter();
	const salesMarketReportData = useSelector(({ reports }: RootState) => reports.salesMarketReportData) || {};
	const loadingSalesMarketReport = useSelector(({ reports }: RootState) => reports.loadingSalesMarketReport);
	const isSalesMarketValuesModalShown = useSelector(({ reports }: RootState) => reports.isSalesMarketValuesModalShown);
	const salesMarketValues = useSelector(({ reports }: RootState) => reports.salesMarketValues);
	const [activeTab, setActiveTab] = React.useState("Quarter");
	const [timeframeToggle, setTimeframeToggle] = React.useState("Quarter");

	const reportColumns = [
		{ accessor: "market", Header: "Market" },
		{ accessor: "average_revenue_per_deal", Header: "Baseline Average Revenue" },
		{
			accessor: "setters_delta_29",
			Header: "Total Setters (1-29 Days)",
			Cell: (cellData) => {
				return <span
					className="sales-market-modal-table-cell"
					onClick={(e) => {
						e.stopPropagation();
						const settersType = cellData.column.id;
						const settersKey = settersType.substring(settersType.indexOf("_") + 1);

						const settersData = salesMarketReportData[activeTab][cellData.row.original.market]["setters"][settersKey].list;

						if (settersData.length) {
							dispatch(setSalesMarketValues(settersData));
						}
					}}
				>
					{cellData.value}
				</span >;
			}
		},
		{
			accessor: "setters_delta_59",
			Header: "Total Setters (30-59 Days)",
			Cell: (cellData) => {
				return <span
					className="sales-market-modal-table-cell"
					onClick={(e) => {
						e.stopPropagation();
						const settersType = cellData.column.id;
						const settersKey = settersType.substring(settersType.indexOf("_") + 1);

						const settersData = salesMarketReportData[activeTab][cellData.row.original.market]["setters"][settersKey].list;

						if (settersData.length) {
							dispatch(setSalesMarketValues(settersData));
						}
					}}
				>
					{cellData.value}
				</span >;
			}
		},
		{
			accessor: "setters_delta_89",
			Header: "Total Setters (60-89 Days)",
			Cell: (cellData) => {
				return <span
					className="sales-market-modal-table-cell"
					onClick={(e) => {
						e.stopPropagation();
						const settersType = cellData.column.id;
						const settersKey = settersType.substring(settersType.indexOf("_") + 1);

						const settersData = salesMarketReportData[activeTab][cellData.row.original.market]["setters"][settersKey].list;

						if (settersData.length) {
							dispatch(setSalesMarketValues(settersData));
						}
					}}
				>
					{cellData.value}
				</span >;
			}
		},
		{
			accessor: "setters_delta_90",
			Header: "Total Setters (90+ Days)",
			Cell: (cellData) => {
				return <span
					className="sales-market-modal-table-cell"
					onClick={(e) => {
						e.stopPropagation();
						const settersType = cellData.column.id;
						const settersKey = settersType.substring(settersType.indexOf("_") + 1);

						const settersData = salesMarketReportData[activeTab][cellData.row.original.market]["setters"][settersKey].list;

						if (settersData.length) {
							dispatch(setSalesMarketValues(settersData));
						}
					}}
				>
					{cellData.value}
				</span >;
			}
		},
		{
			accessor: "selfgen",
			Header: "Total Self-Gen",
			Cell: (cellData) => {
				return <span
					className="sales-market-modal-table-cell"
					onClick={() => {
						const repData = salesMarketReportData[activeTab][cellData.row.original.market]["selfgen"].list;

						if (repData.length) {
							dispatch(setSalesMarketValues(repData));
						}
					}}
				>
					{cellData.value}
				</span>;
			}
		},
		{
			accessor: "closers",
			Header: "Total Closers",
			Cell: (cellData) => {
				return <span
					className="sales-market-modal-table-cell"
					onClick={() => {
						console.log(activeTab);
						console.log(cellData.row.original.market);
						const repData = salesMarketReportData[activeTab][cellData.row.original.market]["closers"].list;

						if (repData.length) {
							dispatch(setSalesMarketValues(repData));
						}
					}}
				>
					{cellData.value}
				</span>;
			}
		},
		{ accessor: "total", Header: "Total Agents" },
		{ accessor: "quota", Header: "Quota" },
		{ accessor: "stretch_goal", Header: "Stretch Goal" },
		{
			accessor: "current_revenue",
			Header: "Current Status",
			Cell: (cellData) => {
				return <span
					className="sales-market-modal-table-cell"
					onClick={() => {
						const repData = salesMarketReportData[activeTab][cellData.row.original.market].values;

						if (repData.length) {
							dispatch(setSalesMarketValues(repData));
						}
					}}
				>
					{cellData.value}
				</span>;
			}
		},
		{ accessor: "perc_target_achieved", Header: "Percentage of Target Achieved" },
		{ accessor: "deals_needed", Header: "Deals Needed" },
		{ accessor: "per_rep_average_needed", Header: "Per Rep Average needed to hit goal between setters and self gen" },
		{ accessor: "setter_selfgen_quota", Header: "Setter / Self-Gen Quota" },
	];


	const getGraphData = () => {
		const graphDataKeys = ["total", "quota", "current_revenue"];
		const graphColors = {
			total: "rgb(133, 227, 90)",
			quota: "rgb(255, 99, 132)",
			current_revenue: "rgb(54, 162, 235)"
		};

		const datasets = graphDataKeys.map((gk) => {
			return {
				label: gk,
				// Map through the data and get the value for each market only if the data exists, determined by the .value attribute
				data: salesMarketReportData[activeTab] ? Object.keys(salesMarketReportData[activeTab]).map(mk => {
					const marketData = salesMarketReportData[activeTab][mk];
					const marketDataValue = marketData[gk];
					// Check if the value exists, if it does, return the value, if not, return 0
					if (!marketDataValue) {
						return 0;
					}
					return marketDataValue.value;
				}) : [],
				backgroundColor: graphColors[gk],
				yAxisID: gk === "total" ? "y1" : "y2",
				grouped: true
			};
		});

		return {
			labels: Object.keys(Object.keys(salesMarketReportData).length > 0 ? salesMarketReportData[Object.keys(salesMarketReportData)[0]] : {}),
			datasets: datasets,
		};
	};

	const toggleTimeframe = (timeframe) => {
		setTimeframeToggle(timeframe === 1 ? "Year" : "Quarter");
		dispatch(setSalesMarketReportQuarter(timeframe === 0 ? { quarter: "1", year: salesMarketReportQuarter.year } : { quarter: "0", year: salesMarketReportQuarter.year }));
	};

	const toggle = <div className="market-report-toggle-container">
		<Toggle options={["Quarter", "Year"]} onChange={toggleTimeframe} />
	</div>;

	useEffect(() => {
		if (Object.keys(salesMarketReportData).length > 0) {
			setActiveTab(timeframeToggle === "Year" ?
				Object.keys(salesMarketReportData)[0]
				: "Quarter");
		}
	}, [salesMarketReportData]);

	return <>
		<Modal
			isShown={isSalesMarketValuesModalShown}
			hideModal={() => {
				dispatch(setSalesMarketValuesModalShown(false));
			}}
			body={salesMarketValues && salesMarketValues.length > 0 ? <SalesMarketValuesTable data={salesMarketValues} /> : <></>}
			heading=""
			addlProps={{ size: "lg" }}
		/>
		{toggle}
		<QuarterSelect
			onChange={(d: { quarter: string, year: string }) => dispatch(setSalesMarketReportQuarter(d))}
			value={salesMarketReportQuarter}
			showQuarter={timeframeToggle === "Quarter"}
			className={`${timeframeToggle === "Year" ? " year-active" : ""}`}
		/>
		{loadingSalesMarketReport ?
			themedLoadingCircles :
			<></>
		}
		{!loadingSalesMarketReport && Object.keys(salesMarketReportData).length === 0 ?
			<h4 className="text-white text-center market-report-no-date-text">No data found for this quarter</h4> :
			<></>
		}
		{!loadingSalesMarketReport && Object.keys(salesMarketReportData).length > 0 ?
			<>
				<Tabs
					defaultActiveKey="Quarter"
					onSelect={(k) => { if (k) setActiveTab(k); }}
					activeKey={activeTab}
				>
					{Object.keys(salesMarketReportData).map(timeframe =>
						<Tab title={timeframe} key={timeframe} eventKey={timeframe}>
							<Table
								columns={reportColumns} data={adaptData(salesMarketReportData[timeframe])}
								responsive
							/>
						</Tab>
					)}
				</Tabs>
				<div className="bar-container">
					<Bar
						data={getGraphData()}
						options={{
							maintainAspectRatio: false,
						}}
					/>
				</div>
			</> :
			<></>
		}
	</>;
};

export default SalesMarketReport;
