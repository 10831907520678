import { leaderboardActions } from "./actions";

export const setDateRange = (data: DateRange) => ({
	type: leaderboardActions.SET_DATE_RANGE,
	data,
});

export const getData = (data: DateRange) => ({
	type: leaderboardActions.GET_DATA,
	data
});

export const setScrollData = (data: { [key:string]: PersonReport[] }) => ({
	type: leaderboardActions.SET_SCROLL_DATA,
	data,
});

export const setSetterData = (data: PersonReport[]) => ({
	type: leaderboardActions.SET_SETTER_DATA,
	data,
});

export const setCloserData = (data: PersonReport[]) => ({
	type: leaderboardActions.SET_CLOSER_DATA,
	data,
});

export const setSelfgenData = (data: PersonReport[]) => ({
	type: leaderboardActions.SET_SELFGEN_DATA,
	data,
});

export const setLoading = (data: boolean) => ({
	type: leaderboardActions.SET_LOADING,
	data,
});

export const setTop5Text = (data: object) => ({
	type: leaderboardActions.SET_TOP5_TEXT,
	data,
});

export const setSetterColumns = (data: HeaderData[]) => ({
	type: leaderboardActions.SET_SETTER_COLUMNS,
	data,
});

export const setCloserColumns = (data: HeaderData[]) => ({
	type: leaderboardActions.SET_CLOSER_COLUMNS,
	data,
});

export const setSelfgenColumns = (data: HeaderData[]) => ({
	type: leaderboardActions.SET_SELFGEN_COLUMNS,
	data,
});

export const setViewType = (data: string) => ({
	type: leaderboardActions.SET_VIEW_TYPE,
	data,
});

export const setRepScorecardModalShown = (data: boolean) => ({
	type: leaderboardActions.SET_REP_SCORECARD_MODAL_SHOWN,
	data,
});

export const setRepScorecardData = (data: PersonReport) => ({
	type: leaderboardActions.SET_REP_SCORECARD_DATA,
	data,
});
