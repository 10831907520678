import { commissionCalculationActions } from "../../actions/actions";
import { Middleware } from "redux";
import { setCommissionCalculationData, setIsCommissionEditModalShown, setIsSubmittingCommissionCalculation } from "../../actions/commissionCalculation";
import apiCall from "../../../utils/api";

const commissionCalculationMiddleware: Middleware<object, RootState> = store => next => action => {
	const dispatch = store.dispatch;
	switch (action.type) {
		case commissionCalculationActions.GET_COMMISSION_CALCULATION_DATA: {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/sales-commission-report/commission-calculation?start_date=01-01-2024`,
				method: "GET"
			}).then(res => {
				dispatch(setCommissionCalculationData(res));
			}).catch((e) => {
				console.error(e);
			});
			break;
		}
		case commissionCalculationActions.SUBMIT_COMMISSION_EDIT: {
			const data = action.data;
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/sales-commission-report/commission-calculation/edit`,
				method: "POST",
				body: data
			}).then(() => {
				dispatch(setIsSubmittingCommissionCalculation(true));
			}).catch((e) => {
				console.error(e);
			});
			dispatch(setIsSubmittingCommissionCalculation(false));
			dispatch(setIsCommissionEditModalShown(false));
			break;
		}
		default:
			break;
	}
	const result = next(action);
	return result;
};

export default [commissionCalculationMiddleware];
