import React, { } from "react";
import { useTable, useSortBy } from "react-table";
import "./Table.sass";
import { createAutoColumns } from "../../utils/table";

const ResponsiveTableWrapper = ({ children, responsive }: { children: JSX.Element, responsive: boolean }) =>
	responsive ?
		<div className="table-responsive">
			{children}
		</div>
		:
		children;

const stickyTableHeader = () => {
	const elements = Array.from(document.getElementsByClassName("fixed-thead") as HTMLCollectionOf<HTMLElement>);
	if (elements) {
		elements.forEach(element => {
			if (element) {
				const parentElement = element.parentElement;
				if (parentElement) {
					window.addEventListener("scroll", debounce(() => {
						const coordinates = parentElement.getBoundingClientRect();
						if (coordinates.y < 0) {
							element.style.transform = "translate3d(0, " + (-coordinates.y) + "px, 0)";
						} else {
							element.style.transform = "translate3d(0,0,0)";
						}
					}));
				}
			}
		});
	}
};

function debounce(func: () => void, timeout = 5) {
	let timer: NodeJS.Timeout;
	return () => {
		clearTimeout(timer);
		timer = setTimeout(() => { func(); }, timeout);
	};
}

const EditableCell = ({
	value: initialValue,
	row: { index },
	column: { id },
	updateTableData = null, // This is a custom function that we supplied to our table instance
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) => {
	// We need to keep and update the state of the cell normally
	const [value, setValue] = React.useState(initialValue);

	const onChange = e => {
		setValue(e.target.value);
	};

	// We'll only update the external data when the input is blurred
	const onBlur = () => {
		if (updateTableData !== null) updateTableData(index, id, value);
	};

	// If the initialValue is changed external, sync it up with our state
	React.useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	return <input value={value} onChange={onChange} onBlur={onBlur} />;
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
	Cell: EditableCell,
};
/**
 * React component that displays the table with the given data
 *
 * Props
 * @param {array} data The array of objects with all the data that goes in the table
 * @param {array} columns The Array with the names of the cloumn headers
 * @returns JSX Code for the table
 */
function Table<T extends { [key: string]: string | number | boolean | null | undefined | { [key: string]: string } }>({
	data,
	columns,
	tableClasses,
	trProps = {},
	responsive = false,
	stickyHeader = false,
	hiddenColumns = [],
	tdOnclick,
	tdClasses = "",
	updateTableData = null
}: TableProps<T>) {

	const tableColumns = React.useMemo(() => columns ? columns : createAutoColumns(data), [data]) || [];

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns: tableColumns, data, initialState: {
			hiddenColumns: hiddenColumns
		},
		...(updateTableData ? { defaultColumn } : {}),
		updateTableData
	}, useSortBy);

	//Keys for rows
	let headerGroupId = 0;
	let columnId = 0;
	let rowId = 0;
	let cellId = 0;

	if (stickyHeader) stickyTableHeader();

	return (
		<ResponsiveTableWrapper responsive={responsive}>
			<table {...getTableProps()} className={`table table-bordered ${tableClasses}`} {...responsive ? { responsive } : {}}>
				<thead className={`${stickyHeader ? "fixed-thead" : ""}`}>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()} key={headerGroupId++}>
							{headerGroup.headers.map(column => {
								return <th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									key={columnId++}
									className="table-header bg-white sticky-top border-bottom"
									scope="col"
								>
									{column.render("Header")}
									<span>
										{column.isSorted
											? column.isSortedDesc
												? "⬇️"
												: "⬆️"
											: "  "}
									</span>
								</th>;
							})}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()} className="table-group-divider">
					{rows.map(row => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}
								key={rowId++}
								onClick={() => {
									if (trProps.onClick) {
										const onClicked = trProps.onClick as (val: { [key: string]: string | number | boolean | null | undefined | { [key: string]: string }; }) => void;
										onClicked(row.original);
									}
								}}
								{...(trProps.className ? { className: trProps.className as string } : {})}
							>
								{row.cells.map(cell => {
									return (
										<td
											{...cell.getCellProps()}
											key={cellId++}
											{...(tdOnclick ? { onClick: () => tdOnclick(cell) } : {})}
											className={tdClasses}
										>
											{cell.render("Cell")}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</ResponsiveTableWrapper>
	);
}

export default Table;
