const tableColumns = {
	"solarBasePriceState": [
		{
			Header: "State",
			accessor: "solar_base_price_state_name"
		},
		{
			Header: "Power Company",
			accessor: "solar_base_price_state_subcompany"
		},
		{
			Header: "Price",
			accessor: "solar_base_price_state_price"
		}
	],

	"promotions": [
		{
			Header: "Promotion",
			accessor: "promotion_name"
		},
		{
			Header: "Price",
			accessor: "promotion_price"
		}
	],

	"roofPrice": [
		{
			Header: "Roof Type",
			accessor: "roof_price_name"
		},
		{
			Header: "Price",
			accessor: "roof_price_value"
		},
		{
			Header: "Max",
			accessor: "roof_price_max"
		}
	],

	"roofTax": [
		{
			Header: "State",
			accessor: "roof_tax_name"
		},
		{
			Header: "Price",
			accessor: "roof_tax_price"
		}
	],
	"marketAdjustment": [
		{
			Header: "Roof Type",
			accessor: "market_adjustment_name"
		},
		{
			Header: "Price",
			accessor: "market_adjustment_price"
		}
	],

	"solarAdder": [
		{
			Header: "Solar Adder",
			accessor: "solar_adder_name"
		},
		{
			Header: "Price",
			accessor: "solar_adder_price"
		},
		{
			Header: "Per",
			accessor: "solar_adder_per"
		},
		{
			Header: "Max",
			accessor: "solar_adder_max"
		},
		{
			Header: "kW Scaled",
			accessor: "solar_adder_kw_scaled"
		}
	],

	"roofAdder": [
		{
			Header: "Roof Adder",
			accessor: "roof_adder_name"
		},
		{
			Header: "Price",
			accessor: "roof_adder_price"
		},
		{
			Header: "Per",
			accessor: "roof_adder_per"
		},
		{
			Header: "Max",
			accessor: "roof_adder_max"
		},
		{
			Header: "kW Scaled",
			accessor: "roof_adder_kw_scaled"
		}
	],

	"panelFee": [
		{
			Header: "Panel",
			accessor: "panel_fee_name"
		},
		{
			Header: "Small System",
			accessor: "panel_fee_system_size"
		},
		{
			Header: "Price",
			accessor: "panel_fee_price"
		}
	],

	"incentives": [
		{
			Header: "Incentive Company",
			accessor: "incentive_company"
		},
		{
			Header: "Incentive",
			accessor: "incentive_name"
		},
		{
			Header: "Price",
			accessor: "incentive_price"
		},
		{
			Header: "Incentive Max",
			accessor: "incentive_max"
		},
	],

	"loanOptions": [
		{
			Header: "Loan Option",
			accessor: "loan_options_name"
		},
		{
			Header: "Rate",
			accessor: "loan_options_rate"
		}
	],

	"company": [
		{
			Header: "Name",
			accessor: "company_name"
		}
	],

	//  pdf = [
	// 	{
	// 		Header: "PDF Name",
	// 		accessor: "pdf_name"
	// 	},
	// 	{
	// 		Header: "id",
	// 		accessor: "pdf_price"
	// 	}
	// ];

	"market": [
		{
			Header: "Market",
			accessor: "market_value"
		}
	],

	"notes": [
		{
			Header: "Notes",
			accessor: "notes_value"
		}
	],

	"startDate": [
		{
			Header: "Date",
			accessor: "start_date_value"
		}
	],

	"endDate": [
		{
			Header: "Date",
			accessor: "end_date_value"
		}
	]
};
export { tableColumns };