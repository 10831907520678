import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { CheckboxInput, TextInput, SelectInput } from "../Form";
import { fieldTypes } from "../Form";
import "./UserDetail.sass";
import WhenAuthorized from "../WhenAuthorized";
import { UserPermissions } from "../../enums/UserPermissions";
import Modal from "../Modal/Modal";
import SetRedline from "../SetRedline/SetRedline";
import { getAllCompanies } from "../../redux/actions/users";
import { useDispatch, useSelector } from "react-redux";
import { ListGroup } from "react-bootstrap";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import {Calendar} from "primereact/calendar";

const closeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
	<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
</svg>;

interface UserDetailProps {
	putUserDetail?: (user_email: string, update_query: string) => void;
	userDetails: User;
	submittingUserDetail: boolean;
	readOnly?: boolean;
}

interface UserDetail extends Omit<User, "status"> {
	status: string;
}

const defaultCurrentUser: UserDetail = {
	name: "",
	email: "",
	department: "",
	role: "",
	manager_email: "",
	start_date: "",
	end_date: "",
	onboarding_complete: "",
	commission_tier: "",
	market: "",
	adp_position_id: "",
	company: "",
	status: "false",
	has_access_to_companies: "",
};

// Filter out null items from object
const filterNull = (obj: { [key: string]: string | Array<string> | null | boolean }) => {
	const newObj: { [key: string]: string | Array<string> | null | boolean } = {};
	for (const key in obj) {
		if (obj[key] !== null) {
			newObj[key] = obj[key];
		}
	}
	return newObj;
};

const convertCurrentUserStatus = (currentUser: User) => {
	if (currentUser.status !== undefined) {
		return {
			status: currentUser.status ? "true" : "false"
		};
	}
	return {};
};

const createCompanyDropdown = (allCompanies: object) => {
	const companyDropDown: Array<SelectOption> = [];
	if (allCompanies["companies"]) {
		allCompanies["companies"].forEach((element, i) => {
			companyDropDown.push({ value: element, label: element, key: i });
		});
	}
	return companyDropDown;
};

const UserDetail = ({ putUserDetail, userDetails, submittingUserDetail, readOnly }: UserDetailProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { status, ...rest } = userDetails;
	const [currentUserData, setCurrentUserData] = useState<UserDetail>({ ...defaultCurrentUser, ...filterNull(rest), ...convertCurrentUserStatus(userDetails) });
	const [isRedlineModalShown, setIsRedlineModalShown] = useState<boolean>(false);
	const [dropDownCompanies, setDropDownCompanies] = useState<Array<SelectOption>>([]);
	const [accessCompanies, setAccessCompanies] = useState<Array<string>>([]);
	const allCompanies = useSelector(({ users }: RootState) => users.allCompanies);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAllCompanies(""));
		setAccessCompanies(userDetails.has_access_to_companies as Array<string>);
	}, []);

	useEffect(() => {
		setAccessCompanies(userDetails.has_access_to_companies as Array<string>);
	}, [userDetails]);

	useEffect(() => {
		if (allCompanies) {
			setDropDownCompanies(createCompanyDropdown(allCompanies));
		}
	}, [allCompanies]);

	useEffect(() => {
		const updatedUserDetails = { ...defaultCurrentUser, ...filterNull(rest), ...convertCurrentUserStatus(userDetails) };
		setCurrentUserData(updatedUserDetails as UserDetail);
	}, [userDetails]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setCurrentUserData({ ...currentUserData, [event.target.name]: event.target.value });
	};

	const handleCompaniesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (accessCompanies?.length) {
			if (accessCompanies.includes(event.target.value)) {
				return;
			}
			setAccessCompanies([...accessCompanies, event.target.value]);
		} else {
			setAccessCompanies([event.target.value]);
		}
		setCurrentUserData({ ...currentUserData, [event.target.name]: [...accessCompanies, event.target.value] });
	};

	const handleCompaniesRemove = (passedCompany: string) => {
		const newAccessCompanies = accessCompanies.filter((company) => company !== passedCompany);
		setAccessCompanies(newAccessCompanies);
		setCurrentUserData({ ...currentUserData, has_access_to_companies: newAccessCompanies });
	};

	const submitUserDetail = () => {
		const {
			email,
			role,
			department,
			manager_email,
			start_date,
			end_date,
			onboarding_complete,
			market,
			commission_tier,
			adp_position_id,
			company,
			status,
			has_access_to_companies,
		} = currentUserData;

		const dataToSubmit = {
			user_email: email,
			title: role,
			department,
			manager_email,
			start_date,
			end_date,
			onboarding_complete: !onboarding_complete ? "False" : "True",
			market,
			commission_tier,
			adp_position_id,
			company,
			status: status === "true" ? true : false,
			has_access_to_companies,
		};

		const queryString = Object.keys(dataToSubmit).map(key => `${key}=${dataToSubmit[key]}`).join("&");

		if (putUserDetail) putUserDetail(email as string, queryString);
	};

	const handleDateChange = (value, type) => {
		// If type is start_date or end_date and the value is 1970-01-01, set it to null
		if (type === "start_date" || type === "end_date") {
			if (value === "1970-01-01") {
				value = null;
			}
		}
		setCurrentUserData({ ...currentUserData, [type === "start_date" ? "start_date" : "end_date"]: value.value.toISOString().substring(0,10)});
	};

	const openRedlineModal = () => {
		setIsRedlineModalShown(true);
	};

	function parseDateCorrectly(date) {
		if (date === undefined || date === null) {
			return null;
		}
		const split = date.split("-");
		return`${split[1]}-${split[2]}-${split[0]}`;
	}

	return <>
		<Modal
			isShown={isRedlineModalShown}
			hideModal={() => {
				setIsRedlineModalShown(false);
			}}
			body={
				<SetRedline user={userDetails} />
			}
			heading="Set Redline"
			addlProps={{ size: "lg" }}
		/>
		<Card style={{ marginBottom: "20px" }}>
			<Card.Body>
				<h3 className="text-center">{currentUserData.name}</h3>
				<Form onSubmit={submitUserDetail}>
					<TextInput
						field={{
							label: "Full Name",
							disabled: true,
							controlId: "userName",
							type: fieldTypes.text,
							readOnly: true,
							value: currentUserData.name,
							groupClasses: "mb-3",
							name: "user_name"
						}}
					/>
					<TextInput
						field={{
							label: "Email address",
							controlId: "userEmail",
							type: fieldTypes.text,
							value: currentUserData.email,
							hint: "first.last@infinitysolarusa.com",
							groupClasses: "mb-3",
							name: "email",
							readOnly: true,
							disabled: true
						}}
					/>
					<TextInput
						field={{
							label: "Manager's Email address",
							controlId: "userManagerEmail",
							type: fieldTypes.text,
							value: currentUserData.manager_email,
							hint: "first.last@infinitysolarusa.com",
							groupClasses: "mb-3",
							name: "manager_email",
							onChange: handleChange,
							disabled: readOnly || submittingUserDetail
						}}
					/>
					<hr />
					<Row>
						<Col>
							<TextInput
								field={{
									label: "Department",
									controlId: "userDepartment",
									type: fieldTypes.text,
									value: currentUserData.department,
									name: "department",
									placeholder: "Sales",
									groupClasses: "mb-3",
									onChange: handleChange,
									disabled: readOnly || submittingUserDetail
								}}
							/>
						</Col>
						<Col>
							<TextInput
								field={{
									label: "Market",
									controlId: "userMarket",
									type: fieldTypes.text,
									value: currentUserData.market,
									name: "market",
									placeholder: "PDX",
									groupClasses: "mb-3",
									onChange: handleChange,
									disabled: readOnly || submittingUserDetail
								}}
							/>
						</Col>
					</Row>

					<Row>
						<Col>
							<TextInput
								field={{
									label: "Role / Title",
									controlId: "userRole",
									type: fieldTypes.text,
									value: currentUserData.role,
									name: "role",
									placeholder: "SSA1",
									groupClasses: "mb-3",
									onChange: handleChange,
									disabled: readOnly || submittingUserDetail
								}}
							/>
						</Col>
						<Col>
							<TextInput
								field={{
									label: "Commission Tier",
									controlId: "userCommissionTier",
									type: fieldTypes.text,
									value: currentUserData.commission_tier,
									name: "commission_tier",
									placeholder: "SSA1",
									groupClasses: "mb-3",
									onChange: handleChange,
									disabled: readOnly || submittingUserDetail
								}}
							/>
						</Col>
					</Row>

					<Row>
						<Col style={{display: "flex", flexDirection: "column"}}>
							<label style={{paddingBottom:"0.5rem"}}>Start Date</label>
							<Calendar
								value={new Date(String(parseDateCorrectly(currentUserData.start_date)))}
								onChange={(date) => handleDateChange(date, "start_date")}
								showIcon
								dateFormat="mm/dd/yy"

							/>
						</Col>

						<Col style={{display: "flex", flexDirection: "column"}}>
							<label  style={{paddingBottom:"0.5rem"}}>End Date</label>
							<Calendar
								value={new Date(String(parseDateCorrectly(currentUserData.end_date)))}
								onChange={(date) => handleDateChange(date, "end_date")}
								showIcon
								dateFormat="mm/dd/yy"
							/>
						</Col>

					</Row>
					<Row className="formRowBuffer onboarding-adpposition-container">
						<Col>
							<TextInput
								field={{
									label: "Company",
									controlId: "userCompany",
									type: fieldTypes.text,
									value: currentUserData.company,
									name: "company",
									placeholder: "",
									onChange: handleChange,
									disabled: readOnly || submittingUserDetail
								}}
							/>
						</Col>
						<WhenAuthorized permission={UserPermissions.adpPositionId}>
							<Col>
								<TextInput
									field={{
										label: "ADP Position ID",
										controlId: "adpPositionId",
										type: fieldTypes.text,
										value: currentUserData.adp_position_id,
										name: "adp_position_id",
										placeholder: "",
										groupClasses: "mb-3",
										onChange: handleChange,
										disabled: readOnly || submittingUserDetail
									}}
								/>
							</Col>
						</WhenAuthorized>
					</Row>
					<Row className="formRowBuffer onboarding-adpposition-container">
						<Col>
							<SelectInput
								field={{
									controlId: "has_access_to_companies",
									label: "Company Access",
									name: "has_access_to_companies",
									dropdownData: dropDownCompanies,
									type: fieldTypes.dropdown,
									onChange: handleCompaniesChange,
									value: "",
								}}
							/>
							<Row className="formRowBuffer onboarding-adpposition-container">
								<Col>
									<>
										{(accessCompanies as Array<string>)?.map((company, i) => (
											<ListGroup key={i} horizontal="sm" className="my-2">
												<ListGroupItem variant="light" action disabled>{company}</ListGroupItem>
												<ListGroupItem variant="light" onClick={() => handleCompaniesRemove(company)}>{closeIcon}</ListGroupItem>
											</ListGroup>
										))}
									</>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="formRowBuffer onboarding-adpposition-container">
						<Col>
							<SelectInput
								field={{
									controlId: "status",
									label: "Status",
									name: "status",
									dropdownData: [
										{ value: "true", label: "Active", key: "active" },
										{ value: "false", label: "Inactive", key: "inactive" },
									],
									type: fieldTypes.dropdown,
									disabled: readOnly || submittingUserDetail || currentUserData.company === "Infinity Solar USA",
									onChange: handleChange,
									value: currentUserData.status,
								}}
							/>
						</Col>
						<Col>
							<CheckboxInput
								field={{
									controlId: "userOnboardingComplete",
									label: "Onboarding Complete",
									checked: !!currentUserData.onboarding_complete,
									onChange: handleChange,
									containerClasses: "my-3",
									disabled: readOnly || submittingUserDetail
								}}
							/>
						</Col>
					</Row>
					<Row className="formRowBuffer onboarding-adpposition-container">
						<Col>
							<WhenAuthorized permission={UserPermissions.admin}>
								<Button
									variant="secondary"
									type="button"
									className="button"
									onClick={openRedlineModal}
									disabled={readOnly || submittingUserDetail}
								>
									Set Redline
								</Button>
							</WhenAuthorized>
						</Col>
						<Col>
							{readOnly ? null :
								<div className="user-detail-submit-button-container">
									<Button
										variant="primary"
										type="button"
										className="button"
										onClick={submitUserDetail}
										disabled={readOnly || submittingUserDetail}
									>
										Submit
									</Button>
								</div>
							}
						</Col>
					</Row>
				</Form>
			</Card.Body>
		</Card>
	</>;
};

export default UserDetail;
