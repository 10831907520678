import { commissionLedgerActions } from "./actions";

export const getCommissionData = (data: string) => ({
	type: commissionLedgerActions.GET_COMMISSION_DATA,
	data
});

export const setCommissionData = (data: Commission[]) => ({
	type: commissionLedgerActions.SET_COMMISSION_DATA,
	data
});

export const setCurrentRep = (data: string) => ({
	type: commissionLedgerActions.SET_CURRENT_REP,
	data
});

export const setLoadingCommissionData = (data: boolean) => ({
	type: commissionLedgerActions.SET_LOADING_COMMISSION_DATA,
	data
});

export const setCustomerActivity = (data: CommissionNote) => ({
	type: commissionLedgerActions.SET_CUSTOMER_ACTIVITY,
	data
});

export const getCustomerActviity = (data: object) => ({
	type: commissionLedgerActions.GET_CUSTOMER_ACTIVITY,
	data
});

export const setLoadingCustomerActivity = (data: boolean) => ({
	type: commissionLedgerActions.SET_LOADING_CUSTOMER_ACTIVITY,
	data
});

export const setModalShown = (data: boolean) => ({
	type: commissionLedgerActions.SET_MODAL_SHOWN,
	data
});

export const setNote = (data: string) => ({
	type: commissionLedgerActions.SET_NOTE,
	data
});

export const setSubmittingNote = (data: boolean) => ({
	type: commissionLedgerActions.SET_SUBMITTING_NOTE,
	data
});

export const submitNote = (data: object) => ({
	type: commissionLedgerActions.SUBMIT_NOTE,
	data
});

export const setEditType = (data: object) => ({
	type: commissionLedgerActions.SET_EDIT_TYPE,
	data
});

export const submitNoteEdit = (data: object) => ({
	type: commissionLedgerActions.SUBMIT_NOTE_EDIT,
	data
});

export const setSubmittingNoteEdit = (data: boolean) => ({
	type: commissionLedgerActions.SET_SUBMITTING_NOTE_EDIT,
	data
});

export const deleteNote = (data: object) => ({
	type: commissionLedgerActions.DELETE_NOTE,
	data
});

export const setDeletingNote = (data: boolean) => ({
	type: commissionLedgerActions.SET_DELETING_NOTE,
	data
});

export const setCurrentCustomer = (data: string) => ({
	type: commissionLedgerActions.SET_CURRENT_CUSTOMER,
	data
});
