import React, { useState } from "react";
import { useTable, useSortBy } from "react-table";
import "./Table.sass";
import { createAutoColumns } from "../../utils/table";

const ResponsiveTableWrapper = ({ children, responsive }: { children: JSX.Element, responsive: boolean }) =>
	responsive ?
		<div style={{ overflowX: "auto" }}>
			{children}
		</div>
		:
		children;

function ExpandingTable<T extends { [key: string]: string|number|boolean|null|undefined }, D extends { [key: string]: string|number|boolean|null|undefined }>({
	data,
	columns,
	tableClasses,
	trProps = {},
	responsive = false,
	hiddenColumns = [],
	innerComponent: InnerComponent,
	retrieveInnerComponentData,
	expandColumn = "",
	addtlCol: AddtlCol
}: ExpandingTableProps<T, D>) {
	const tableColumns = React.useMemo(() => columns ? columns : createAutoColumns(data), [data]) || [];

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({ columns: tableColumns, data, initialState: {
		hiddenColumns: hiddenColumns
	} }, useSortBy);

	type tableRowState = { setRowData: (data: { [key:string]: string|number }[]) => void, setRowExpanded: (expanded: boolean) => void };

	const tableRowStates: tableRowState[] = [];

	//Keys for rows
	let headerGroupId = 0;
	let columnId = 0;
	let rowId = 0;
	let cellId = 0;

	const ExpandingTable = () => {
		return <table {...getTableProps()} className={`table table-bordered table-striped ${tableClasses}`} {...responsive ? { responsive } : {}}>
			<thead>
				{headerGroups.map(headerGroup => (
					<tr
						{...headerGroup.getHeaderGroupProps()}
						key={headerGroupId++}
						onClick={() => { tableRowStates.forEach(rowState => { rowState.setRowData([]); rowState.setRowExpanded(false); });}}
					>
						{headerGroup.headers.map(column => (
							<th
								{...column.getHeaderProps(column.getSortByToggleProps())}
								key={columnId++}
							>
								{column.render("Header")}
								<span>
									{column.isSorted
										? column.isSortedDesc
											? "⬇️"
											: "⬆️"
										: "  "}
								</span>
							</th>
						))}
						{ expandColumn === "add-column" ? 
							<th>
								<span>Expand</span>
							</th>
							:
							<></>
						}
					</tr>
				))}
			</thead>
			<tbody {...getTableBodyProps()} className="table-group-divider">
				{rows.map((row, index) => {
					prepareRow(row);
					const [rowExpanded, setRowExpanded] = useState<boolean>(false);
					const [rowData, setRowData] = useState<{ [key:string]: string|number|boolean|null|undefined }[]>([]);
					tableRowStates.push({ setRowData, setRowExpanded });

					const expandOnClick = () => {
						setRowExpanded(!rowExpanded);
						if (!rowExpanded) {
							retrieveInnerComponentData(row, setRowData);
						}
					};

					return (
						<>
							<tr
								{...row.getRowProps()}
								key={rowId++}
								onClick={() => {
									if (trProps.onClick) {
										const onClick = trProps.onClick as (val: { [key: string]: string|number|boolean|null|undefined; }) => void;
										onClick(row.original);
									}
									if (expandColumn === "") {
										expandOnClick();
									}
								}}
								className={`${index % 2 === 0 ? "table-row-even" : "table-row-odd"} ${trProps.className ? trProps.className : ""}`}
							>
								{row.cells.map(cell => {
									return (
										<td
											{...cell.getCellProps()}
											key={cellId++}
											{...(cell.column.id === expandColumn ? { onClick: () => { expandOnClick(); } } : {})}
										>
											{ cell.column.id === expandColumn ?
												<div>
													{cell.render("Cell")}
													<span>{rowExpanded ? "⬆️" : "⬇️"}</span>
												</div>
												:
												cell.render("Cell")
											}
										</td>
									);
								})}
								{ expandColumn === "add-column" ?
									<td
										onClick={() => {
											setRowExpanded(!rowExpanded);
											if (!rowExpanded) {
												retrieveInnerComponentData(row, setRowData);
											}
										}}
										className="td-expand-button"
									>
										<span>{rowExpanded ? "⬆️" : "⬇️"}</span>
									</td>
									:
									<></>
								}
								{
									AddtlCol ?
										<AddtlCol row={row} />
										:
										<></>
								}
							</tr>
							<tr className="table-accordion-row">
								<td colSpan={row.cells.length + 1}>
									<div
										className={`table-accordion${rowExpanded ? " table-accordion-expanded" : ""}`}
									>
										<InnerComponent data={rowData as D[]} />
									</div>
								</td>
							</tr>
						</>
					);
				})}
			</tbody>
		</table>;
	};

	return (
		<ResponsiveTableWrapper responsive={responsive}>
			<ExpandingTable />
		</ResponsiveTableWrapper>
	);
}

export default ExpandingTable;
