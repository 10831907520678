import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "./UserFilter.sass";
import { convertDateToString } from "../../utils/convertDate";
import { useDispatch, useSelector } from "react-redux";
import { getRedlineData } from "../../redux/actions/redlinePageActions";
import { getProfile } from "../../redux/actions/profileActions";
import {Calendar} from "primereact/calendar";

const UserFilter = () => {
	const profile_details = useSelector(({ profile }: RootState) => profile.profile);
	const [selectedDate, setSelectedDate] = useState<string>(convertDateToString(new Date()));
	const [companies, setCompanies] = useState<string[]>([]);
	const [filterData, setFilterData] = useState<FilterData>({
		date: selectedDate,
		company: "",
		market: ""
	});
	const dispatch = useDispatch();

	const handleDateChange = (date: string) => {
		setSelectedDate(date);
	};

	const handleChange = (e) => {
		setFilterData({ ...filterData, [e.target.name]: e.target.id });
		// I am reusing the object due to it not being updated in time
		dispatch(getRedlineData({ ...filterData, [e.target.name]: e.target.id }));
	};

	useEffect(() => {
		if (profile_details === undefined) {
			dispatch(getProfile());
		}
	}, []);

	useEffect(() => {
		const companyBuildingArray = profile_details?.has_access_to_companies;
		if (companyBuildingArray === undefined || companyBuildingArray === null) {
			setCompanies([]);
			return;
		}
		companyBuildingArray.push("All");
		companyBuildingArray.push(profile_details?.company);
		setCompanies(companyBuildingArray);
	}, [profile_details]);


	const createDateFilterRadioButtons = () => {
		if (companies.length === 1) {
			return;
		}
		const companyFilter = companies.map((company: string) => {
			if (company !== "" && company !== " ") {
				return <Form.Check
					type="radio"
					label={company}
					name="company"
					id={company}
					key={company}
				></Form.Check>;
			}
		});
		return <>
			<h3>Company</h3>
			{companyFilter}
		</>;
	};

	const createMarketFilterRadioButtons = () => {
		const markets = ["All", "Albuquerque", "Austin", "Bend", "Boise",
			"Dallas", "Inside Sales", "Medford", "PDX", "PDX 2", "Phoenix", "Salem", "SeaTac", "Seattle", "Spokane", "Marysville"];
		return markets.map((market: string) => {
			return <Form.Check
				type="radio"
				label={market}
				name="market"
				id={market}
				key={market}
			></Form.Check>;
		}
		);
	};

	const parseDateCorrectly = (date) => {
		if (date === undefined || date === null) {
			return null;
		}
		const split = date.split("-");
		return`${split[1]}-${split[2]}-${split[0]}`;
	};

	const extractDateStringFromEvent = (date) => date.value.toISOString().substring(0,10);

	return (
		<div className="main-filter">
			<div >
				<h2 className="filter-title">Filter by:</h2>
				<Form className="filter-input" onChange={(e) => handleChange(e)}>
					<h3>Date</h3>
					<Calendar
						value = {new Date(String(parseDateCorrectly(selectedDate)))}
						onChange={(date) => handleDateChange(extractDateStringFromEvent(date))}
						dateFormat="mm/dd/yy"
						showIcon
					/>
					{createDateFilterRadioButtons()}
					<h3>Market</h3>
					{createMarketFilterRadioButtons()}
				</Form>
			</div>
		</div>
	);
};

export default UserFilter;
