import React from "react";

function SalesReport() {
	return (
		<div className="powerbi-container" >
			<div>
				<iframe width="100%"
					height="850"
					src="https://app.powerbi.com/reportEmbed?reportId=07ec8927-ab83-4e1b-9f95-b8ba5f049720&autoAuth=true&ctid=4fb4efe8-a20f-4608-b1fa-4fe4ffc5c0a6"
					title="POWER BI REPORT">
				</iframe>

			</div>
		</div>
	);
}

export default SalesReport;
