import { number, object, string } from "yup";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const iaSchema = object({
	homeowner: string().required("Homeowner is a required field"),
	phone: string().min(10).matches(phoneRegExp, "Please enter valid phone number").required("Please enter a phone number"),
	email: string().email("Please enter a valid email address").required("Please enter an email address"),
	cohomeowner_email: string().email("Please enter a valid email address"),
	street: string().required("Please enter a value for street"),
	city: string().required("Please enter a city"),
	state: string().required("Please select a state"),
	zip: string().min(5, "Please enter a valid zip code").max(5, "Please enter a valid zip code").matches(/^\d+$/, "Please enter a valid zip code").required("Please enter a zip code"),
	county: string().required("Please select a county after selecting a state"),
	utility_company: string().required("Please enter a utility company"),
	utility_account_number: string().required("Please enter your utility account number"),
	utility_meter_number: string().required("Please enter your utility meter number"),
	market: string().required("Please select a market"),
	setter: string().required("Please enter a setter"),
	payment_option: string().required("Please select a loan option"),
	ppw: number().positive("Please enter a positive value"),
	discount_percentage: number().test(
		"is-decimal",
		"Can be at most 2 decimal places",
		value => value === undefined || !!(value + "").match(/^[0-9]+(\.[0-9][0-9]?)?$/),
	).max(5, "Please enter a number between 0 and 5").positive("Please enter a number between 0 and 5"),
	discount_value: number().test(
		"is-decimal",
		"Can be at most 2 decimal places",
		value => value === undefined || !!(value + "").match(/^[0-9]+(\.[0-9][0-9]?)?$/),
	).positive("Please enter a number between 0 and 5"),
});

export const waSchema = object({
	business_phone: string().min(10).matches(phoneRegExp, "Please enter valid phone number").required("Please enter a phone number"),
	business_email: string().email("Please enter a valid email address").required("Please enter an email address"),
	business_address: string().required("Please enter a value for Business Address"),
	annualSavings: number().positive("Please enter a positive value"),
	firstYearProduction: number().positive("Please enter a positive value"),
	monthlyPayment: number().positive("Please enter a positive value"),
});

export const solarSchema = object({
	solar_size: number().required("Please enter a solar system size").positive("Please enter a positive value").test(
		"is-decimal",
		"Can be at most 3 decimal places",
		value => value === undefined || !!(value + "").match(/^[0-9]+(\.)?([0-9]{0,3}?)?$/),
	),
	panel_type: string().required("Please select a solar module"),
	inverter_type: string().required("Please select an inverter")
});

// If adder is checked, ensure there is an associated amount
const adderValTest = (val, ctx) => {
	if (ctx.parent[ctx.path] || ctx.originalValue === "") {
		return val !== undefined && val > 0;
	}
	return true;
};

const transformNaN = (value: number) => (isNaN(value) ? undefined : value);

export const solarAdderSchema = object({
	trenching_amount: number().transform(transformNaN).test("test1", "Please enter a valid value", adderValTest),
	car_charger_kit_amount: number().transform(transformNaN).test("test2", "Please enter a valid value", adderValTest),
	car_charger_port_amount: number().transform(transformNaN).test("test3", "Please enter a valid value", adderValTest),
	enphase_battery_amount: number().transform(transformNaN).test("test4", "Please enter a valid value", adderValTest)
});

export const roofSchema = object({
	roof_size: number().required("Please enter a roofing size").positive("Please enter a positive value").test(
		"is-decimal",
		"Can be at most 3 decimal places",
		value => value === undefined || !!(value + "").match(/^[0-9]+(\.[0-9]{0,3}?)?$/),
	),
	roof_type: string().required("Please select a roofing material"),
	roofing_color: string().required("Please select a roofing color")
});

export const roofAdderSchema = object({
	deco_ridge_amount: number().transform(transformNaN).test("test5", "Please enter a valid value", adderValTest),
	metal_valley_amount: number().transform(transformNaN).test("test6", "Please enter a valid value", adderValTest),
	plywood_amount: number().transform(transformNaN).test("test7", "Please enter a valid value", adderValTest),
	skylight_amount: number().transform(transformNaN).test("test8", "Please enter a valid value", adderValTest),
	solar_tube_installation_amount: number().transform(transformNaN).test("test9", "Please enter a valid value", adderValTest),
	venta_ridge_amount: number().transform(transformNaN).test("test0", "Please enter a valid value", adderValTest),
});
