import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { CheckboxInput, TextArea, TextInput } from "../Form";
import Form from "react-bootstrap/Form";
import { fieldTypes } from "../Form";
import "./CreateCustomer.sass";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import apiCall from "../../utils/api";
import { object, string } from "yup";
import { ValidationError } from "yup";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getReps } from "../../redux/actions/customerPipeline";
import { SelectInput } from "../Form";
import checkIfAuthorized from "../../utils/permissions";

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const createCustomerSchema = object({
	first_name: string().required("First name is required"),
	last_name: string().required("Last name is required"),
	zip: string().min(5, "Please enter a valid zip code").max(5, "Please enter a valid zip code").matches(/^\d+$/, "Please enter a valid zip code").required("Please enter a zip code"),
	city: string().required("City is required"),
	state: string().required("State is required"),
	address: string().required("Address is required"),
	email: string().email("Please enter a valid email address").required("Please enter an email address"),
	phone: string().min(10).matches(phoneRegExp, "Please enter valid phone number").required("Please enter a phone number"),
	utility_company: string().required("Utility company is required")
});

interface CustomerDetails {
	first_name?: string;
	last_name?: string;
	zip?: string;
	city?: string;
	state?: string;
	address?: string;
	closer?: string;
	setter?: string;
	selfgen?: string;
	closer_2?: string;
	setter_2?: string;
	selfgen_2?: string;
	email?: string;
	phone?: string;
	utility_company?: string;
	hoa?: boolean;
}

const showCreateCustomerToastError = (text?: string) => {
	toast.error(text ? text : "There was an error creating the customer", {
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "dark",
		toastId: "ia-Submission-Error",
		onClick: () => window.close(),
		autoClose: false
	});
};

const CreateCustomer = ({ customerCreated, customer = {} }) => {
	const [customerDetails, setCustomerDetails] = React.useState<CustomerDetails>(customer);
	const [validationErrors, setValidationErrors] = React.useState<ValidationErrors>({});
	const [submitting, setSubmitting] = React.useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getReps());
	}, []);

	const isCustomerUpdating = !!Object.keys(customer).length;

	const createNewCustomer = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setSubmitting(true);

		const currentValidationErrors: ValidationErrors = {};
		const customerDetailsCopy = { ...customerDetails };
		if (customerDetailsCopy.setter_2 === "") delete customerDetailsCopy.setter_2;
		if (customerDetailsCopy.closer_2 === "") delete customerDetailsCopy.closer_2;
		const customerData = { ...customerDetailsCopy, organization: localStorage.getItem("company") };

		await createCustomerSchema.validate(customerData, { abortEarly: false }).catch((e: ValidationError) => e.inner.forEach(e => {
			if (e.path) {
				currentValidationErrors[e.path] = e.message;
			}
		}));

		if (!customerData.selfgen) {
			if (!customerData.setter) {
				currentValidationErrors["setter"] = "Setter is required";
			}
		} else {
			if (customerData.setter === customerData.closer || !customerData.setter || !customerData.closer) {
				customerData.setter = customerData.selfgen;
				customerData.closer = customerData.selfgen;
			} else {
				customerData.selfgen = "";
			}
		}

		if (!Object.keys(currentValidationErrors).length) {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/customer-pipeline/customer`,
				method: isCustomerUpdating ? "PUT" : "POST",
				body: { ...customerData, hoa: customerDetails?.hoa ? "Yes" : "No" },
			})
				.then(res => {
					if (res === "Customer Created" || res === "Customer Updated") {
						toast.success(`Customer ${isCustomerUpdating ? "Updated" : "Created"}`);
						setCustomerDetails({});
						customerCreated();
					} else if (res?.response?.data === "Contact already exists") {
						showCreateCustomerToastError("Contact with provided name already exists");
					} else {
						showCreateCustomerToastError();
					}
					setSubmitting(false);
				})
				.catch(e => {
					console.error(e);
					if (e.response.data === "Contact already exists") {
						showCreateCustomerToastError("Contact with provided name already exists");
					} else {
						showCreateCustomerToastError();
					}
					setSubmitting(false);
				});
		} else {
			setValidationErrors(currentValidationErrors);
			setSubmitting(false);
		}
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		setCustomerDetails({ ...customerDetails, [e.target.name]: e.target.value });
	};

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCustomerDetails({ ...customerDetails, [e.target.name]: e.target.checked });
	};

	const setters = useSelector(({ general }: RootState) => general.settersList);
	const closers = useSelector(({ general }: RootState) => general.closersList);

	const checkCustomerEditAuthorized = (field: string) => {
		if (isCustomerUpdating) {
			return checkIfAuthorized(`portal/customer-pipeline/edit-customer/${field}`);
		}
		else {
			return checkIfAuthorized("portal/customer-pipeline/create-customer");
		}
	};

	return <Card style={{ marginBottom: "20px" }}>
		<Card.Body>
			<Form onSubmit={createNewCustomer}>
				<Row>
					<Col sm={12} md={6}>
						<TextInput
							field={{
								label: "First Name",
								controlId: "first_name",
								type: fieldTypes.text,
								value: customerDetails["first_name"],
								groupClasses: "mb-3",
								name: "first_name",
								onChange: handleChange,
								validation: validationErrors,
								disabled: submitting || !checkCustomerEditAuthorized("first_name")
							}}
						/>
					</Col>
					<Col sm={12} md={6}>
						<TextInput
							field={{
								label: "Last Name",
								controlId: "last_name",
								type: fieldTypes.text,
								value: customerDetails["last_name"],
								groupClasses: "mb-3",
								name: "last_name",
								onChange: handleChange,
								validation: validationErrors,
								disabled: submitting || !checkCustomerEditAuthorized("last_name")
							}}
						/>
					</Col>
				</Row>
				<Row className="d-flex align-items-center">
					<Col sm={12} md={6}>
						<TextInput
							field={{
								label: "Utility Company",
								controlId: "utility_company",
								type: fieldTypes.text,
								value: customerDetails["utility_company"],
								groupClasses: "mb-3",
								name: "utility_company",
								onChange: handleChange,
								validation: validationErrors,
								disabled: submitting || !checkCustomerEditAuthorized("utility_company")
							}}
						/>
					</Col>
					<Col sm={12} md={6}>
						<CheckboxInput
							field={{
								controlId: "HOA_checkbox",
								label: "HOA",
								type: fieldTypes.checkbox,
								checked: customerDetails["hoa"],
								name: "hoa",
								disabled: submitting || !checkCustomerEditAuthorized("hoa"),
								onChange: handleCheckboxChange,
								validation: validationErrors as { [key: string]: string }
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={12} md={6}>
						<TextInput
							field={{
								label: "Email",
								controlId: "email",
								type: fieldTypes.text,
								value: customerDetails["email"],
								groupClasses: "mb-3",
								name: "email",
								onChange: handleChange,
								validation: validationErrors,
								disabled: submitting || !checkCustomerEditAuthorized("email")
							}}
						/>
					</Col>
					<Col sm={12} md={6}>
						<TextInput
							field={{
								label: "Phone",
								controlId: "phone",
								type: fieldTypes.text,
								value: customerDetails["phone"],
								groupClasses: "mb-3",
								name: "phone",
								onChange: handleChange,
								validation: validationErrors,
								disabled: submitting || !checkCustomerEditAuthorized("phone")
							}}
						/>
					</Col>
				</Row>
				<TextArea
					field={{
						label: "Description",
						controlId: "description",
						type: fieldTypes.text,
						value: customerDetails["description"],
						groupClasses: "mb-3",
						name: "description",
						onChange: handleChange,
						validation: validationErrors as { [key: string]: string },
						disabled: submitting || !checkCustomerEditAuthorized("description")
					}}
				/>
				<TextInput
					field={{
						label: "Address",
						controlId: "address",
						type: fieldTypes.text,
						value: customerDetails["address"],
						groupClasses: "mb-3",
						name: "address",
						onChange: handleChange,
						validation: validationErrors,
						disabled: submitting || !checkCustomerEditAuthorized("address")
					}}
				/>
				<Row>
					<Col sm={12} md={4}>
						<TextInput
							field={{
								label: "City",
								controlId: "city",
								type: fieldTypes.text,
								value: customerDetails["city"],
								groupClasses: "mb-3",
								name: "city",
								onChange: handleChange,
								validation: validationErrors,
								disabled: submitting || !checkCustomerEditAuthorized("city")
							}}
						/>
					</Col>
					<Col sm={12} md={4}>
						<TextInput
							field={{
								label: "State",
								controlId: "state",
								type: fieldTypes.text,
								value: customerDetails["state"],
								groupClasses: "mb-3",
								name: "state",
								onChange: handleChange,
								validation: validationErrors,
								disabled: submitting || !checkCustomerEditAuthorized("state")
							}}
						/>
					</Col>
					<Col sm={12} md={4}>
						<TextInput
							field={{
								label: "Zip Code",
								controlId: "zip",
								type: fieldTypes.text,
								value: customerDetails["zip"],
								groupClasses: "mb-3",
								name: "zip",
								onChange: handleChange,
								validation: validationErrors,
								disabled: submitting || !checkCustomerEditAuthorized("zip")
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={12} md={6}>
						{closers && closers.length ? <SelectInput
							field={{
								controlId: "closer",
								label: "Closer",
								dropdownData: closers.filter(rep => !!rep).map(rep => ({ key: rep, value: rep, label: rep })),
								value: customerDetails.selfgen && !customerDetails.closer ? customerDetails.selfgen : customerDetails.closer,
								onChange: handleChange,
								validation: validationErrors,
								disabled: submitting || !checkCustomerEditAuthorized("closer"),
								name: "closer"
							}}
						/> : <></>}
					</Col>
					<Col sm={12} md={6} className="mb-3">
						{setters && setters.length ? <SelectInput
							field={{
								controlId: "setter",
								label: "Setter",
								dropdownData: setters.filter(rep => !!rep).map(rep => ({ key: rep, value: rep, label: rep })),
								value: customerDetails.selfgen && !customerDetails.setter ? customerDetails.selfgen : customerDetails.setter,
								onChange: handleChange,
								validation: validationErrors,
								disabled: submitting || !checkCustomerEditAuthorized("setter"),
								name: "setter"
							}}
						/> : <></>}
					</Col>
				</Row>
				<Row>
					<Col sm={12} md={6}>
						{closers && closers.length ? <SelectInput
							field={{
								controlId: "closer_2",
								label: "Closer 2",
								dropdownData: closers.filter(rep => !!rep).map(rep => ({ key: rep, value: rep, label: rep })),
								value: customerDetails.selfgen_2 && !customerDetails.closer_2 ? customerDetails.selfgen_2 : customerDetails.closer_2,
								onChange: handleChange,
								validation: validationErrors,
								disabled: submitting || !checkCustomerEditAuthorized("closer_2"),
								name: "closer_2",
								hiddenDefault: false
							}}
						/> : <></>}
					</Col>
					<Col sm={12} md={6} className="mb-3">
						{setters && setters.length ? <SelectInput
							field={{
								controlId: "setter_2",
								label: "Setter 2",
								dropdownData: setters.filter(rep => !!rep).map(rep => ({ key: rep, value: rep, label: rep })),
								value: customerDetails.selfgen_2 && !customerDetails.setter_2 ? customerDetails.selfgen_2 : customerDetails.setter_2,
								onChange: handleChange,
								validation: validationErrors,
								disabled: submitting || !checkCustomerEditAuthorized("setter_2"),
								name: "setter_2",
								hiddenDefault: false
							}}
						/> : <></>}
					</Col>
				</Row>
				<Button variant="primary" type="submit" className="form-submit-button" disabled={submitting}>
					Submit
				</Button>
			</Form>
		</Card.Body>
	</Card>;
};

export default CreateCustomer;
