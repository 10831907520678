import React from "react";
import Form from "react-bootstrap/Form";
import WhenAuthorized from "../../WhenAuthorized";

export const createDropdown = (dataArr: Array<SelectOption>) =>
	dataArr.map((option: SelectOption) => {
		if (!option.value && !option.label) {
			return <></>;
		}

		if (!option.key) {
			option.key = option.value;
		}

		const optionElement = <option
			key={option.key}
			value={option.value}
		>
			{option.label}
		</option>;

		if (option.permission) {
			return <WhenAuthorized permission={option.permission} key={option.key}>
				{optionElement}
			</WhenAuthorized>;
		} else {
			return optionElement;
		}
	});

const SelectInput = ({ field }: { field: SelectInputProps }) => {
	const {
		containerClasses,
		hiddenDefault = true
	} = field;
	return <Form.Group controlId={field.controlId} className={`select-input${containerClasses ? ` ${containerClasses}` : ""}`} id={`${field.controlId}_group`}>
		{field.label ? <Form.Label className={field.labelClasses}>{field.label}</Form.Label> : <></>}
		<Form.Select
			{...(field.disabled ? { disabled: field.disabled } : {})}
			{...(field.value ? { value: field.value, key: field.value } : {})}
			{...(field.name ? { name: field.name } : {})}
			{...(field.onChange ? { onChange: e => field.onChange ? field.onChange(e) : {} } : {})}
			{...(field.onBlur ? { onBlur: e => field.onBlur ? field.onBlur(e) : {} } : {})}
		>
			<option
				{...(hiddenDefault ? { hidden: true } : {})}
				key="default_value"
				value=""
			>
				{field.defaultValue}
			</option>
			{field.dropdownData.length ? createDropdown(field.dropdownData) : <></>}
		</Form.Select>
		{field.validation && Object.keys(field.validation).length && field.validation[field.controlId] ? <p className="field-validation-error">
			{field.validation[field.controlId]}
		</p> : ""}
	</Form.Group>;
};

export default SelectInput;
