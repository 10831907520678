import React, { useEffect } from "react";
import UsersList from "../Components/UserList/UserList";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WhenAuthorized from "../Components/WhenAuthorized";
import { UserPermissions } from "../enums/UserPermissions";
import apiCall from "../utils/api";
import Permissions from "../Components/Permissions/Permissions";
import UserDetail from "../Components/UserDetail/UserDetail";
import { toast } from "react-toastify";
import "./styles/Users.sass";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, getUserDetails, setSubmitting, setCreateUserModalShown, setSubmittingUser } from "../redux/actions/users";
import Modal from "../Components/Modal/Modal";
import { Button } from "react-bootstrap";
import CreateUser from "../Components/CreateUser/CreateUser";
import Heading from "../Components/Heading/Heading";
import {getProfile} from "../redux/actions/profileActions";

const Users = () => {
	const submitting = useSelector(({ users }: RootState) => users.submitting);
	const users = useSelector(({ users }: RootState) => users.users);
	const selectedUser = useSelector(({ users }: RootState) => users.selectedUser);
	const isCreateUserModalShown = useSelector(({ users }: RootState) => users.isCreateUserModalShown);
	const profile = useSelector(({ profile }: RootState) => profile.profile);


	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getProfile());
	}, []);

	const submittingUser = useSelector(({ users }: RootState) => users.submittingUser);

	useEffect(() => {
		dispatch(getUsers());
	}, []);

	const changeUser = (user: User) => {
		dispatch(getUserDetails(user.email));
	};

	const putUserDetail = async (user_email: string, update_query: string) => {
		dispatch(setSubmitting(true));
		update_query = cleanUpdateUserData(update_query);
		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/portal-user-manager/users/${user_email}?${update_query}`,
			method: "PUT"
		}).then(result => {
			if (result) {
				dispatch(setSubmitting(false));
				toast.info("Changes submitted successfully", {
					position: "top-right",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					toastId: "ia-Submission-Error",
					onClick: () => window.close(),
					autoClose: 5000
				});
			} else {
				dispatch(setSubmitting(false));
				toast.error("There was an error submitting your changes", {
					position: "top-right",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					toastId: "ia-Submission-Error",
					onClick: () => window.close(),
					autoClose: false
				});
			}
			if (selectedUser) dispatch(getUserDetails(selectedUser.email));
		});
	};

	const cleanUpdateUserData = (queryString: string) => {
		const regexp = new RegExp("(?<=manager_email=)(.*?)(?=&|$)");
		const match = queryString.match(regexp);
		if (match?.[0]) {
			queryString = queryString.replace(match?.[0], match?.[0].toLowerCase());
		}
		return queryString;
	};

	const createUser = (data: Partial<User>) => {
		dispatch(setSubmittingUser(true));
		cleanCreateUserData(data);
		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/portal-user-manager/users`,
			method: "POST",
			body: data
		}).then(result => {
			if (result) {
				toast.info("User created successfully", {
					position: "top-right",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					toastId: "ia-Submission-Error",
					onClick: () => window.close(),
					autoClose: 5000
				});
				dispatch(setCreateUserModalShown(false));
			} else {
				toast.error("There was an error submitting your changes", {
					position: "top-right",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					toastId: "ia-Submission-Error",
					onClick: () => window.close(),
					autoClose: false
				});
			}
		}).catch(e => {
			console.log(e);
			if (e.response.data === "Not Implemented") {
				toast.error("Creating users for Infinity Solar USA is not supported yet", {
					position: "top-right",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					toastId: "ia-Submission-Error",
					onClick: () => window.close(),
					autoClose: false
				});
			}
		}).finally(() => {
			dispatch(setSubmittingUser(false));
		});
	};

	const cleanCreateUserData = (data: Partial<User>) => {
		if (data.email) {
			data.email = data.email.toLowerCase();
		}
		if (data.manager_email) {
			data.manager_email = data.manager_email.toLowerCase();
		}
		if (data.first_name && typeof data.first_name === "string") {
			data.first_name = data.first_name.trim();
			data.first_name = data.first_name.charAt(0).toUpperCase() + data.first_name.slice(1);
		}
		if (data.last_name && typeof data.last_name === "string") {
			data.last_name = data.last_name.trim();
			data.last_name = data.last_name.charAt(0).toUpperCase() + data.last_name.slice(1);
		}
	};

	return (
		<WhenAuthorized permission={UserPermissions.user}>
			<>
				<Modal
					isShown={isCreateUserModalShown}
					hideModal={() => {
						dispatch(setCreateUserModalShown(false));
					}}
					body={
						<CreateUser
							createUser={createUser}
							submittingUser={submittingUser}
							userDetails={profile}
						/>
					}
					heading="Create User"
					addlProps={{ size: "lg" }}
				/>
				<Container>
					<Heading>Users</Heading>
					<Row>
						<Col lg={4}>
							{users?.length ? <UsersList
								setSelectedUser={changeUser}
								userList={users}
							/> : <></>}
						</Col>
						<Col lg={8}>
							{selectedUser !== null ?
								<div>
									<Container className="userDetail UserDetailEdit">
										<WhenAuthorized permission={UserPermissions.createCustomer}>
											<div className="create-user-container">
												<Button variant="primary"
													onClick={() => {
														dispatch(setCreateUserModalShown(true));
													}}
												>
													Create User
												</Button>
											</div>
										</WhenAuthorized>
										{selectedUser ? <UserDetail
											putUserDetail={putUserDetail}
											userDetails={selectedUser}
											submittingUserDetail={submitting || false}
										/> : ""}
										{selectedUser ?
											<WhenAuthorized permission={UserPermissions.admin}>
												<Permissions
													selectedUser={selectedUser}
												/>
											</WhenAuthorized>
											: ""
										}
									</Container>
								</div> : <></>
							}
						</Col>
					</Row>
				</Container>
			</>
		</WhenAuthorized>
	);
};

export default Users;
