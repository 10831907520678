import React from "react";
import "./InfoBar.sass";

const InfoBar = () => {
	return <div>
		<div className="info-bar">
			<p>Update available! Please close all instances and relaunch to update.</p>
		</div>
	</div>;
};

export default InfoBar;

