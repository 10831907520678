import apiCall from "../../../utils/api";
import { customerPipelineActions } from "../../actions/actions";
import { setCustomerList, setFilteredCustomerList } from "../../actions/customerPipeline";
import { getCustomers as getCustomersAction } from "../../actions/customerPipeline";
import { Middleware } from "redux";
import { setSettersList, setClosersList } from "../../actions/general";
import { setLoadingCustomers } from "../../actions/customerPipeline";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";
import { toast } from "react-toastify";
import { encodeEmail } from "../../../utils/formatString";


const customerPipelineMiddleware: Middleware<object, RootState> = store => next => action => {
	const dispatch = store.dispatch;
	const customerPipelineState = store.getState().customerPipeline;

	switch (action.type) {
		case customerPipelineActions.GET_CUSTOMERS: {
			const { loadingCustomers } = customerPipelineState;

			if (!loadingCustomers) {
				const encoded_sales_rep = encodeEmail(action.data);
				apiCall({
					url: `${process.env.REACT_APP_API_SERVICE}/customer-pipeline/customers?sales_rep=${encoded_sales_rep}`,
					method: "GET"
				}).then(res => {
					dispatch(setCustomerList(res));
					dispatch(setFilteredCustomerList(res));
				}).catch((e) => {
					console.error(e);
					apiErrorToast();
					dispatch(setLoadingCustomers(false));
				});
			}
			break;
		}
		case customerPipelineActions.SET_CURRENT_REP: {
			dispatch(getCustomersAction(action.data));
			break;
		}
		case customerPipelineActions.GET_REPS: {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/permissions/user/list/closers`,
				method: "GET"
			}).then(res => {
				dispatch(setClosersList(res));
			}).catch((e) => {
				console.error(e);
				apiErrorToast();
				dispatch(setClosersList([]));
			});

			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/permissions/user/list/setters`,
				method: "GET"
			}).then(res => {
				dispatch(setSettersList(res));
			}).catch((e) => {
				console.error(e);
				apiErrorToast();
				dispatch(setSettersList([]));
			});
			break;
		}
		case customerPipelineActions.SET_EXPORT: {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/csm-data-validator/export-customers?company=${action.data}`,
				method: "GET"
			}).then(() => {
				toast.info("Report Submitted, please check your email", {
					position: "top-right",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					toastId: "ia-Submission-Error",
					onClick: () => window.close(),
					autoClose: 4000
				});
			}).catch((e) => {
				console.error(e);
				apiErrorToast();
			});
		}
	}
	const result = next(action);
	return result;
};

export default [customerPipelineMiddleware];
