import React, { useEffect } from "react";
import ExpandingTable from "../Components/Table/ExpandingTable";
import Table from "../Components/Table/Table";
import LabelTooltip from "../Components/LabelTooltip/LabelTooltip";
import "./styles/MismatchedRevenue.sass";
import { Spinner } from "react-bootstrap";
import { getData } from "../redux/actions/mismatchedRevenue";
import { useSelector, useDispatch } from "react-redux";
import Heading from "../Components/Heading/Heading";

const columns = [
	{
		Header: "Customer Name",
		accessor: "customer_name",
		Cell: ({ row, value }) => {
			const jn_id = row.allCells.find(cell => cell.column.id === "jobnimbus_id")?.value;
			if (jn_id && jn_id !== "N/A") {
				return <a href={`https://app.jobnimbus.com/contact/${jn_id}`} target="_blank" rel="noreferrer">{value}</a>;
			} else {
				return value;
			}
		}
	},
	{
		Header: "Customer Stage",
		accessor: "customer_stage"
	},
	{
		Header: "Customer ID",
		accessor: "customer_id"
	},
	{
		Header: "JobNimbus ID",
		accessor: "jobnimbus_id"
	},
	{
		Header: "Issue",
		accessor: "issue",
		Cell: ({ value }) => <span>{value.length > 27 ? <LabelTooltip label={`${value.substring(0, 24)}...`} tooltipText={value} /> : value}</span>
	},
	{
		Header: "Total Incentives Received",
		accessor: "total_incentives_received",
	},
	{
		Header: "Total Incentives Revenue",
		accessor: "total_incentives_revenue",
	},
	{
		Header: "LMI Received",
		accessor: "lmi_received",
	},
	{
		Header: "ODOE Received",
		accessor: "odoe_received",
	},
	{
		Header: "ETO Received",
		accessor: "eto_received",
	},
	{
		Header: "SWR Received",
		accessor: "swr_received",
	},
	{
		Header: "Invoices Available",
		accessor: "invoices",
		Cell: ({ value }) => <span>{value?.length ? "Yes" : "No"}</span>
	}
];

const incentivesTableColumns = [
	{
		Header: "Internal Note",
		accessor: "internal_note",
	},
	{
		Header: "total",
		accessor: "total",
	},
	{
		Header: "total_paid",
		accessor: "total_paid",
	},
	{
		Header: "due",
		accessor: "due"
	}
];

const IncentivesTable = ({ data }) => {
	return data.length ? <Table data={data} columns={incentivesTableColumns} /> : <></>;
};

const MismatchedRevenue = () => {
	const loading = useSelector(({ mismatchedRevenue }: RootState) => mismatchedRevenue.loading);
	const data = useSelector(({ mismatchedRevenue }: RootState) => mismatchedRevenue.data);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getData());
	}, []);

	return <div>
		<Heading>Mismatched Data</Heading>
		{ loading ? <div className="mismatched-data-spinner-container">
			<Spinner variant="info" />
		</div>
			: data?.length ?
				<ExpandingTable
					data={data}
					columns={columns}
					hiddenColumns={["jobnimbus_id"]}
					innerComponent={IncentivesTable}
					retrieveInnerComponentData={(row, setRowData) => row.values.invoices?.length ? setRowData(row.values.invoices) : setRowData([]) }
					tableClasses="mismatched-data-table"
					trProps={{ className: "mismatched-data-table-row" }}
					expandColumn="add-column"
				/> : <h3 className="text-white text-center">No data to display</h3>
		}
	</div>;
};

export default MismatchedRevenue;
