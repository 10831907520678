import { mismatchedRevenueActions } from "../actions/actions";

const initialState: MismatchedRevenueState = {};

export default function mismatchedRevenueReducer(state = initialState, action: MismatchedRevenueAction) {
	switch (action.type) {
		case mismatchedRevenueActions.SET_DATA:
			return Object.assign({}, state, { data: action.data, loading: false });
		case mismatchedRevenueActions.GET_DATA:
			return Object.assign({}, state, { loading: true });
		case mismatchedRevenueActions.SET_LOADING:
			return Object.assign({}, state, { loading: action.data });
		default:
			return state;
	}
}
