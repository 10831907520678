import { dataValidationActions } from "./actions";

export const setType = (data: string) => ({
	type: dataValidationActions.SET_TYPE,
	data
});

export const setData = (data) => ({
	type: dataValidationActions.SET_DATA,
	data
});

export const getData = (data) => ({
	type: dataValidationActions.GET_DATA,
	data
});

export const setLoading = (data: boolean) => ({
	type: dataValidationActions.SET_LOADING,
	data
});

export const setCurrentCustomer = (data: Customer) => ({
	type: dataValidationActions.SET_CURRENT_CUSTOMER,
	data
});

export const setIsModalShown = (data: boolean) => ({
	type: dataValidationActions.SET_IS_MODAL_SHOWN,
	data
});
