import React, { useState, useEffect } from "react";
import { DatePicker, SelectInput, TextInput } from "../Form";
import { fieldTypes } from "../Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { object, string } from "yup";
import { ValidationError } from "yup";
import Button from "react-bootstrap/Button";
import apiCall from "../../utils/api";
import { apiErrorToast } from "../Toasts/ApiErrorToast";
import { toast } from "react-toastify";
import Table from "../Table/Table";
import "./SetRedline.sass";
import { encodeEmail } from "../../utils/formatString";

const setRedlineSchema = object({
	redline: string().required("Redline is required"),
	default_base_price_per_watt: string().required("Default Base Price Per Watt is required"),
});

const redlineColumns = [
	{
		Header: "Start Date",
		accessor: "start_date",
	},
	{

		Header: "End Date",
		accessor: "end_date",
	},
	{
		Header: "Redline",
		accessor: "redline",
	},
	{
		Header: "Default Base Price Per Watt",
		accessor: "default_base_price_per_watt",
	},
	{
		Header: "Delegation Redline",
		accessor: "delegation_redline",
	}
];

const SetRedline = ({ user }: { user: User | undefined }) => {
	if (!user) {
		return <></>;
	}
	const defaultValues = {
		start_date: "",
		end_date: "",
		redline: user.redline ? user.redline : "",
		default_base_price_per_watt: user.default_base_price_per_watt ? user.default_base_price_per_watt : "",
		delegation_redline: user.delegation_redline ? user.delegation_redline : "",
	};

	const [currentRedlineData, setCurrentUserData] = useState<Redline>(defaultValues as Redline);
	const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
	const [submittingRedline, setSubmittingRedline] = useState(false);
	const [currentTimeframe, setCurrentTimeframe] = useState("current");
	const [redlineHistory, setRedlineHistory] = useState<Redline[]>([]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setCurrentUserData({ ...currentRedlineData, [event.target.name]: event.target.value });
	};

	const handleDateChange = (value, type) => {
		// If type is start_date or end_date and the value is 1970-01-01, set it to null
		if (type === "start_date" || type === "end_date") {
			if (value === "1970-01-01") {
				value = null;
			}
		}
		setCurrentUserData({ ...currentRedlineData, [type === "start_date" ? "start_date" : "end_date"]: value });
	};

	const getRedline = () => {
		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/ia-price-adjuster/redline?user_email=${encodeEmail(user.email)}&time_frame=${currentTimeframe}`,
			method: "GET"
		}).then((res) => {
			if (currentTimeframe === "current") {
				setRedlineHistory([res]);
			} else {
				setRedlineHistory(res);
			}
		}).catch((e) => {
			console.error(e);
			apiErrorToast(e.response.data);
		});
	};

	useEffect(() => {
		getRedline();
	}, [currentTimeframe]);

	const onSubmit = async () => {
		const {
			start_date,
			end_date,
			redline,
			default_base_price_per_watt,
			delegation_redline,
		} = currentRedlineData;

		const dataToSubmit = {
			...(start_date ? { start_date } : {}),
			...(end_date ? { end_date } : {}),
			redline: parseFloat(redline),
			default_base_price_per_watt: parseFloat(default_base_price_per_watt),
			...(delegation_redline ? { delegation_redline: parseFloat(delegation_redline) } : {}),
			user_email: user.email
		};

		const currentValidationErrors = {} as ValidationErrors;

		await setRedlineSchema.validate(dataToSubmit, { abortEarly: false }).catch((e: ValidationError) => e.inner.forEach(e => {
			if (e.path) {
				currentValidationErrors[e.path] = e.message;
			}
		}));

		if (!Object.keys(currentValidationErrors).length) {
			setSubmittingRedline(true);
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/ia-price-adjuster/redline`,
				method: "POST",
				body: dataToSubmit
			}).then(() => {
				toast.info("Redline set successfully", {
					position: "top-right",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					toastId: "ia-Submission-Error",
					onClick: () => window.close(),
					autoClose: false
				});
				setSubmittingRedline(false);
				getRedline();
				setCurrentUserData(defaultValues as Redline);
			}).catch((e) => {
				console.error(e);
				apiErrorToast(e.response.data);
			});
		}

		setValidationErrors(currentValidationErrors);
	};

	const timeframeOptions = [
		{ key: "current", value: "current", label: "Current" },
		{ key: "all", value: "all", label: "All" },
		{ key: "upcoming", value: "upcoming", label: "Upcoming" },
	];

	return (
		<>
			<Row className="mb-3">
				<Col>
					<SelectInput
						field={{
							label: "Timeframe",
							controlId: "timeframe",
							type: fieldTypes.text,
							value: currentTimeframe,
							name: "timeframe",
							onChange: (e) => { setCurrentTimeframe(e.target.value); },
							dropdownData: timeframeOptions
						}}
					/>
				</Col>
				<Col>
				</Col>
			</Row>
			<Row>
				<Table columns={redlineColumns} data={redlineHistory} />
			</Row>
			<Row className="mb-3">
				<Col>
					<DatePicker
						label="Start Date"
						controlId="start_date"
						value={currentRedlineData.start_date}
						setValue={date => handleDateChange(date, "start_date")}
						disabled={submittingRedline}
						validation={validationErrors}
					/>
				</Col>
				<Col>
					<DatePicker
						label="End Date"
						controlId="end_date"
						value={currentRedlineData.end_date}
						setValue={date => handleDateChange(date, "end_date")}
						disabled={submittingRedline}
						validation={validationErrors}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<TextInput
						field={{
							label: "Redline",
							controlId: "redline",
							type: fieldTypes.text,
							value: currentRedlineData.redline,
							groupClasses: "mb-3",
							name: "redline",
							onChange: handleChange,
							validation: validationErrors,
							disabled: submittingRedline
						}}
					/>
				</Col>
				<Col>
					<TextInput
						field={{
							label: "Default Base Price Per Watt",
							controlId: "default_base_price_per_watt",
							type: fieldTypes.text,
							value: currentRedlineData.default_base_price_per_watt,
							groupClasses: "mb-3",
							name: "default_base_price_per_watt",
							onChange: handleChange,
							validation: validationErrors,
							disabled: submittingRedline
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<TextInput
						field={{
							label: "Delegation Redline",
							controlId: "delegation_redline",
							type: fieldTypes.text,
							value: currentRedlineData.delegation_redline,
							groupClasses: "mb-3",
							name: "delegation_redline",
							onChange: handleChange,
							validation: validationErrors,
							disabled: submittingRedline
						}}
					/>
				</Col>
				<Col>
					<div className="redline-submit-button-container">
						<Button
							variant="primary"
							type="button"
							className="button"
							onClick={onSubmit}
						>
							Submit
						</Button>
					</div>
				</Col>
			</Row>
		</>
	);
};

export default SetRedline;
