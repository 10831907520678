import { usersActions } from "./actions";

export const getUsers = () => ({
	type: usersActions.GET_USERS
});

export const setUsers = (data: User[]) => ({
	type: usersActions.SET_USERS,
	data
});

export const setSubmitting = (data: boolean) => ({
	type: usersActions.SET_SUBMITTING,
	data
});

export const setSelectedUser = (data: User) => ({
	type: usersActions.SET_SELECTED_USER,
	data
});

export const getUserDetails = (data: string) => ({
	type: usersActions.GET_USER_DETAILS,
	data
});

export const setCreateUserModalShown = (data: boolean) => ({
	type: usersActions.SET_CREATE_USER_MODAL_SHOWN,
	data
});

export const setSubmittingUser = (data: boolean) => ({
	type: usersActions.SET_SUBMITTING_USER,
	data
});

export const setLoadingUsers = (data: boolean) => ({
	type: usersActions.SET_LOADING_USERS,
	data
});

export const setLoadingUserDetails = (data: boolean) => ({
	type: usersActions.SET_LOADING_USER_DETAILS,
	data
});

export const getAllCompanies = (data: string) => ({
	type: usersActions.GET_ALL_COMPANIES,
	data
});

export const setAllCompanies = (data: string) => ({
	type: usersActions.SET_ALL_COMPANIES,
	data
});
