import apiCall from "../../../utils/api";
import { Middleware } from "redux";
import { redlinePageActions } from "../../actions/actions";
import { setRedlineData } from "../../actions/redlinePageActions";

const redlinePageMiddleware: Middleware<object, RootState> = store => next => action => {
	const { dispatch } = store;
	if (action.type === redlinePageActions.GET_REDLINE_DATA) {
		const data = action.data;
		let queryString = "";
		Object.keys(data).forEach(key => {
			console.log(key);
			if (data[key] && data[key] !== "all") {
				queryString += `&${key}=${data[key]}`;
			}
		});

		if (queryString) {
			queryString = `?${queryString.slice(1)}`;
		}

		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/ia-price-adjuster/redline/all${queryString}`,
			method: "GET"
		}).then(res =>
			dispatch(setRedlineData(res))
		).catch((e) => {
			console.error(e);
		});
	}
	return next(action);
};

export default [redlinePageMiddleware];
