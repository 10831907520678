import { installAgreementActions } from "./actions";

export const setFormValue = (data: object) => ({
	type: installAgreementActions.SET_FORM_VALUE,
	data
});

export const removeFormValue = (data: string) => ({
	type: installAgreementActions.REMOVE_FORM_VALUE,
	data
});

export const setIsComplete = (data: boolean) => ({
	type: installAgreementActions.SET_IS_COMPLETE,
	data
});

export const setForm = (data: { [key: string]: string | number | boolean | null | undefined }) => ({
	type: installAgreementActions.SET_FORM,
	data
});

export const setMainAccordionTabsOpen = (data: { [key: string]: boolean }) => ({
	type: installAgreementActions.SET_MAIN_ACCORDION_TABS_OPEN,
	data
});

export const setValidationErrors = (data: { [key: string]: string | { [key: string]: string } }) => ({
	type: installAgreementActions.SET_VALIDATION_ERRORS,
	data
});

export const setMainAccordionTabs = (data: string[]) => ({
	type: installAgreementActions.SET_MAIN_ACCORDION_TABS,
	data
});

export const setSolarRoofAccordion = (data: string[]) => ({
	type: installAgreementActions.SET_SOLAR_ROOF_ACCORDION,
	data
});

export const setShowDraftSubmission = (data: boolean) => ({
	type: installAgreementActions.SET_SHOW_DRAFT_SUBMISSION,
	data
});

export const setDraftData = (data: { [key: string]: string | number | boolean | null | undefined }) => ({
	type: installAgreementActions.SET_DRAFT_DATA,
	data
});

export const setSubmitting = (data: boolean) => ({
	type: installAgreementActions.SET_SUBMITTING,
	data
});

export const setGeneratePreview = (data: boolean) => ({
	type: installAgreementActions.SET_GENERATE_PREVIEW,
	data
});

export const setAdderValue = (data: { type: string, data: object }) => ({
	type: installAgreementActions.SET_ADDER_VALUE,
	data
});


export const setSolarAdder = (data: object) => ({
	type: installAgreementActions.SET_SOLAR_ADDERS,
	data
});

export const setRoofAdder = (data: object) => ({
	type: installAgreementActions.SET_ROOF_ADDERS,
	data
});

export const clearAdders = (data: string) => ({
	type: installAgreementActions.CLEAR_ADDERS,
	data
});
export const setFormCompany = (data: string) => ({
	type: installAgreementActions.SET_FORM_COMPANY,
	data
});

export const setCompletedSubmission = (data: boolean) => ({
	type: installAgreementActions.SET_COMPLETED_SUBMISSION,
	data
});

export const getPricing = (data: { company: string, market?: string | null, startDate?: string | null, endDate?: string | null }) => ({
	type: installAgreementActions.GET_PRICING,
	data
});

export const setPricing = (data: IaPricing | []) => ({
	type: installAgreementActions.SET_PRICING,
	data
});

export const setLoadingPricing = (data: boolean) => ({
	type: installAgreementActions.SET_LOADING_PRICING,
	data
});

export const setShowPricingModal = (data: boolean) => ({
	type: installAgreementActions.SET_SHOW_PRICING_MODAL,
	data
});

export const setAdderPricing = (data: boolean) => ({
	type: installAgreementActions.SET_ADDER_PRICING,
	data
});

export const setAdderPricingItem = (data: boolean) => ({
	type: installAgreementActions.SET_ADDER_PRICING_ITEM,
	data
});

export const setShowPricingModalEdit = (data: boolean) => ({
	type: installAgreementActions.SET_SHOW_PRICING_MODAL_EDIT,
	data
});

export const setOriginalHomeowner = (data: string) => ({
	type: installAgreementActions.SET_ORIGINAL_HOMEOWNER,
	data
});
