import { commissionSalesRepAction } from "./actions";

export const getCommissionData = (data: {startDate: string, endDate: string}) => ({
	type: commissionSalesRepAction.GET_COMMISSION_DATA,
	data
});

export const setCommissionData = (data: CommissionCalculation[]) => ({
	type: commissionSalesRepAction.SET_COMMISSION_DATA,
	data
});

export const setCurrentCommissionCustomer = (data: CommissionCalculation) => ({
	type: commissionSalesRepAction.SET_CURRENT_COMMISSION_CUSTOMER,
	data
});

export const setIsCommissionModalShown = (data: boolean) => ({
	type: commissionSalesRepAction.SET_IS_COMMISSION_MODAL_SHOWN,
	data
});

export const setCurrentRep = (data: string) => ({
	type: commissionSalesRepAction.SET_CURRENT_REP,
	data
});
