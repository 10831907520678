import React, { useEffect } from "react";
import Table from "../Components/Table/Table";
import Modal from "../Components/Modal/Modal";
import CommissionCalculationModal from "../Components/CommissionCalculation/CommissionCalculationModal";
import { getCommissionCalculationData, setIsCommissionEditModalShown, setCurrentCommissionCalculationCustomer } from "../redux/actions/commissionCalculation";
import { useDispatch, useSelector } from "react-redux";

const tableHeaders: HeaderData[] = [
	{
		"Header": "Customer Name",
		"accessor": "customer_name",
	},
	{
		"Header": "Market",
		"accessor": "market",
	},
	{
		"Header": "Closer",
		"accessor": "closer",
	},
	{
		"Header": "Setter",
		"accessor": "setter",
	},
	{
		"Header": "Sales Month",
		"accessor": "date_created",
	},
	{
		"Header": "Incentives Offered",
		"accessor": "incentives_offered",
	},
	{
		"Header": "Total Job Revenue Plus Incentives",
		"accessor": "job_total_revenue",
	},
	{
		"Header": "Job KW",
		"accessor": "job_kw",
	},
	{
		"Header": "Price Per Watt",
		"accessor": "sales_price_per_watt",
	}
];

const CommissionCalculation = () => {
	const currentCommissionCalculationCustomer = useSelector(({ commissionCalculation }: RootState) => commissionCalculation.currentCommissionCalculationCustomer);
	const isCommissionModalShown = useSelector(({ commissionCalculation }: RootState) => commissionCalculation.isCommissionEditModalShown);
	const commissionCalculationData = useSelector(({ commissionCalculation }: RootState) => commissionCalculation.commissionCalculationData);
	const dispatch = useDispatch();

	const handleOnClick = (customer: CommissionCalculation) => {
		dispatch(setCurrentCommissionCalculationCustomer(customer));
		dispatch(setIsCommissionEditModalShown(true));
		console.log(isCommissionModalShown);
	};

	useEffect(() => {
		dispatch(getCommissionCalculationData());
	}, []);

	return <>
		{commissionCalculationData?
			<>
				<Modal
					isShown={isCommissionModalShown}
					hideModal={() => {
						dispatch(setIsCommissionEditModalShown(false));
					}}
					body={currentCommissionCalculationCustomer? <CommissionCalculationModal customer={currentCommissionCalculationCustomer} />: <></>}
					heading="Commission Calculation"
					addlProps={{ size: "lg" }}
				/>
				<h1>Commission Calculation</h1>
				<div style={{ width: "75%", margin: "auto" }}>
					<Table data={commissionCalculationData} columns={tableHeaders}
						trProps={{ onClick: rowData => handleOnClick(rowData as CommissionCalculation)}}
					/>
				</div>
			</>
			:
			<></>
		}

	</>;
};

export default CommissionCalculation;
