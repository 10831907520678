import { Nullable } from "primereact/ts-helpers";
import { siteSurveySchedulerActions } from "./actions";

export const setAppointment = (data: Customer) => ({
	type: siteSurveySchedulerActions.SET_APPOINTMENT,
	data
});

export const setIsSiteSurveyModalShown = (data: boolean) => ({
	type: siteSurveySchedulerActions.SET_IS_SITE_SURVEY_MODAL_SHOWN,
	data
});

export const setMonth = (data: Nullable<Date>) => ({
	type: siteSurveySchedulerActions.SET_MONTH,
	data
});

export const setDays = (data: Date[]) => ({
	type: siteSurveySchedulerActions.SET_DAYS,
	data
});

export const setTimes = (data: TimeArray[]) => ({
	type: siteSurveySchedulerActions.SET_TIMES,
	data
});

export const setSelectedDay = (data: string | null) => ({
	type: siteSurveySchedulerActions.SET_SELECTED_DAY,
	data
});

export const getDays = (data: number | string) => ({
	type: siteSurveySchedulerActions.GET_DAYS,
	data
});

export const getTimes = (data: number | string) => ({
	type: siteSurveySchedulerActions.GET_TIMES,
	data
});
