import { generalActions } from "./actions";

export const getRepList = (data = {}) => ({
	type: generalActions.GET_REP_LIST,
	data
});

export const setRepList = (data: User[]) => ({
	type: generalActions.SET_REP_LIST,
	data
});

export const getSettersList = () => ({
	type: generalActions.GET_SETTERS_LIST
});

export const setSettersList = (data) => ({
	type: generalActions.SET_SETTERS_LIST,
	data
});

export const setClosersList = (data) => ({
	type: generalActions.SET_CLOSERS_LIST,
	data
});
