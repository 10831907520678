import { convertDateToString } from "../../utils/convertDate";
import { leaderboardActions } from "../actions/actions";

// Get first day of the month
function getFirstDayOfMonth() {
	const d = new Date();
	const year = d.getFullYear();
	const month = d.getMonth();
	const firstDayOfMonth = new Date(year, month, 1);
	return firstDayOfMonth;
}

const initialState: LeaderboardState = {
	dateRange: { startDate: convertDateToString(getFirstDayOfMonth()), endDate: convertDateToString(new Date()) },
	viewType: "detailed"
};

export default function leaderboardsReducer(state = initialState, action: LeaderboardAction) {
	switch (action.type) {
		case leaderboardActions.SET_DATE_RANGE:
			return Object.assign({}, state, { dateRange: action.data });
		case leaderboardActions.GET_DATA:
			return Object.assign({}, state, { loading: true });
		case leaderboardActions.SET_SCROLL_DATA:
			return Object.assign({}, state, { scrollData: action.data });
		case leaderboardActions.SET_SETTER_DATA:
			return Object.assign({}, state, { setterData: action.data });
		case leaderboardActions.SET_CLOSER_DATA:
			return Object.assign({}, state, { closerData: action.data });
		case leaderboardActions.SET_SELFGEN_DATA:
			return Object.assign({}, state, { selfGenData: action.data });
		case leaderboardActions.SET_LOADING:
			return Object.assign({}, state, { loading: action.data });
		case leaderboardActions.SET_TOP5_TEXT:
			return Object.assign({}, state, { top5Text: action.data });
		case leaderboardActions.SET_SETTER_COLUMNS:
			return Object.assign({}, state, { setterColumns: action.data });
		case leaderboardActions.SET_CLOSER_COLUMNS:
			return Object.assign({}, state, { closerColumns: action.data });
		case leaderboardActions.SET_SELFGEN_COLUMNS:
			return Object.assign({}, state, { selfgenColumns: action.data });
		case leaderboardActions.SET_VIEW_TYPE:
			return Object.assign({}, state, { viewType: action.data });
		case leaderboardActions.SET_REP_SCORECARD_MODAL_SHOWN:
			return Object.assign({}, state, { repScorecardModalShown: action.data });
		case leaderboardActions.SET_REP_SCORECARD_DATA:
			return Object.assign({}, state, { repScorecardData: action.data });
		default:
			return state;
	}
}
