import { profileActions } from "./actions";

export const setProfile = (data: User) => ({
	type: profileActions.SET_PROFILE,
	data
});

export const getProfile = () => ({
	type: profileActions.GET_PROFILE
});

export const setLoading = (data: boolean) => ({
	type: profileActions.SET_LOADING,
	data
});