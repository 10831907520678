import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import WhenAuthorized from "../Components/WhenAuthorized";
import { UserPermissions } from "../enums/UserPermissions";
import CreateCustomer from "../Components/CreateCustomer/CreateCustomer";
import Modal from "../Components/Modal/Modal";
import { TextInput } from "../Components/Form";
import Button from "react-bootstrap/Button";
import filterList from "../utils/filterList";
import "./styles/CustomerPipeline.sass";
import { useDispatch, useSelector } from "react-redux";
import {
	setFilteredCustomerList,
	setIsCreateCustomerModalShown,
	getCustomers,
	setViewType
} from "../redux/actions/marketPipeline";
import Heading from "../Components/Heading/Heading";
import CustomerList from "../Components/CustomerList/CustomerList";
import Toggle from "../Components/Toggle/Toggle";

const MarketPipeline = () => {
	const repList = useSelector(({ general }: RootState) => general.repList);
	const customerList = useSelector(({ marketPipeline }: RootState) => marketPipeline.customerList);
	const filteredCustomerList = useSelector(({ marketPipeline }: RootState) => marketPipeline.filteredCustomerList);
	const loadingCustomers = useSelector(({ marketPipeline }: RootState) => marketPipeline.loadingCustomers);
	const isCreateCustomerModalShown = useSelector(({ marketPipeline }: RootState) => marketPipeline.isCreateCustomerModalShown);
	const dispatch = useDispatch();

	const toggleOptions = [
		<svg key="1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
			<path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
		</svg>,
		<svg key="2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-view-stacked" viewBox="0 0 16 16">
			<path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z" />
		</svg>
	];

	const viewTypes = ["list", "grid"];

	const handleToggleType = (typeIndex: number) => {
		dispatch(setViewType(viewTypes[typeIndex]));
	};

	useEffect(() => {
		dispatch(getCustomers());
	}, []);

	const customerFilterKeys = ["name", "status_name", "email", "state"];
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (customerList) {
			dispatch(setFilteredCustomerList(
				filterList<Customer>(
					customerList.map(cus => { cus.name = `${cus.first_name ? `${cus.first_name} ` : ""}${cus.last_name ? cus.last_name : ""}`; return cus; }),
					e.target.value.toUpperCase(),
					customerFilterKeys
				)
			));
		}
	};

	return (
		<WhenAuthorized permission={UserPermissions.customer}>
			<>
				<Modal
					isShown={isCreateCustomerModalShown}
					hideModal={() => {
						dispatch(setIsCreateCustomerModalShown(false));
					}}
					body={<CreateCustomer customerCreated={() => dispatch(setIsCreateCustomerModalShown(false))} />}
					heading="Create JobNimbus Customer"
					addlProps={{ size: "lg" }}
				/>
				<Container>
					<Heading>Market Pipeline</Heading>
					<div className="customer-toggle-button-container container">
						<WhenAuthorized permission={UserPermissions.createCustomer}>
							<Button variant="primary"
								onClick={() => {
									dispatch(setIsCreateCustomerModalShown(true));
								}}
							>
								Create Customer
							</Button>
						</WhenAuthorized>
					</div>
					{customerList || repList ?
						<div className="customer-search-wrapper container">
							<div className="customer-search-container">
								<div className="customer-select-container">
									<TextInput
										field={{
											controlId: "customerFilterString",
											label: "Search for Customer",
											onChange: handleChange,
											placeholder: "Search for Customer"
										}}
									/>
								</div>
								<div className="customer-list-toggle-container">
									<Toggle options={toggleOptions} onChange={handleToggleType} />
								</div>
							</div>
						</div>
						:
						""
					}
					{loadingCustomers ? <div className="customer-loading-spinner">
						<Spinner animation="border" variant="info" />
					</div> :
						filteredCustomerList?.length ?
							<CustomerList customerList={filteredCustomerList} source="marketPipeline" />
							: <Card>
								<Card.Body>There are no Customers associated with this Market</Card.Body>
							</Card>
					}
				</Container>
			</>
		</WhenAuthorized>
	);
};

export default MarketPipeline;
