import apiCall from "../../../utils/api";
import { profileActions } from "../../actions/actions";
import { setLoading, setProfile } from "../../actions/profileActions";
import { Middleware } from "redux";

const getProfile: Middleware<object, RootState> = store => next => action => {
	const { dispatch } = store;
	const { loading } = store.getState().profile;

	if (action.type === profileActions.GET_PROFILE) {
		if (!loading) {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/portal-user-manager/user`,
				method: "GET"
			}).then(res =>
				dispatch(setProfile(res))
			).catch((e) => {
				console.error(e);
			}).finally(() => {
				dispatch(setLoading(false));
			});
		}
	}
	const result = next(action);
	return result;
};

export default [getProfile];
