import React, { useState } from "react";
import Tabletable from "../Table/Table";
import { Button } from "react-bootstrap";
import "./AddPrices.sass";
import { useDispatch } from "react-redux";
import validator from "./PricingConfigValidate";
import { addCompanyConfig } from "../../redux/actions/iaPriceAdjuster";
import { tableColumns } from "./data/pricingTableColumns";


function preFillDateData(dateValue: string, dateData: object, dateType: string) {
	if (dateValue === null || dateValue === undefined || dateValue === "") {
		Object.assign(dateData[0], { [`${dateType}_value`]: dateValue });
	}
	else {
		dateValue = dateValue.replace(" ", "T");
		dateValue += "Z";
		Object.assign(dateData[0], { [`${dateType}_value`]: dateValue });
	}
}


function preFillStateBasedData(stateBasedData: object, tableData: object, columnKey: string) {
	if (stateBasedData === null) return;
	Object.keys(stateBasedData).forEach((key, index) => {
		if (key?.toLowerCase() === "oregon" || key === "or") {
			Object.keys(stateBasedData[key]).forEach((subKey, subIndex) => {
				const rowIndexer = index + subIndex;
				Object.assign(tableData, { [rowIndexer]: {} });
				Object.assign(tableData[rowIndexer], { [`${columnKey}_name`]: key });
				Object.assign(tableData[rowIndexer], { [`${columnKey}_price`]: stateBasedData[key][subKey] });
				Object.assign(tableData[rowIndexer], { [`${columnKey}_subcompany`]: subKey });
			});
		}
		else {
			if (tableData[index] === undefined) {
				Object.assign(tableData, { [index]: {} });
			}
			Object.assign(tableData[index], { [`${columnKey}_name`]: key });
			Object.assign(tableData[index], { [`${columnKey}_price`]: stateBasedData[key] });
		}
	});
}


function preFillRoofPriceData(roofPrices: object, tableData: object, columnKey: string) {
	if (roofPrices === null) return;
	Object.keys(roofPrices).forEach((key, index) => {
		if (tableData[index] === undefined) {
			Object.assign(tableData, { [index]: {} });
		}
		if (typeof roofPrices[key] === "object") {
			Object.assign(tableData[index], { [`${columnKey}_name`]: key });
			Object.assign(tableData[index], { [`${columnKey}_value`]: roofPrices[key]["value"] });
			Object.assign(tableData[index], { [`${columnKey}_max`]: roofPrices[key]["max"] });
		}
		else {
			Object.assign(tableData[index], { [`${columnKey}_name`]: key });
			Object.assign(tableData[index], { [`${columnKey}_value`]: roofPrices[key] });
			Object.assign(tableData[index], { [`${columnKey}_max`]: null });
		}
	}
	);
}


function preFillPriceData(roofTax: object, tableData: object, columnKey: string) {
	if (roofTax === null) return;
	Object.keys(roofTax).forEach((key, index) => {
		if (tableData[index] === undefined) {
			Object.assign(tableData, { [index]: {} });
		}
		Object.assign(tableData[index], { [`${columnKey}_name`]: key });
		Object.assign(tableData[index], { [`${columnKey}_price`]: roofTax[key] });
	}
	);
}


function preFillLoanOptionsData(loanOptions: object, tableData: object, columnKey: string) {
	if (loanOptions === null) return;
	Object.keys(loanOptions).forEach((key, index) => {
		if (tableData[index] === undefined) {
			Object.assign(tableData, { [index]: {} });
		}
		Object.assign(tableData[index], { [`${columnKey}_name`]: key });
		Object.assign(tableData[index], { [`${columnKey}_rate`]: loanOptions[key] });
	}
	);
}


function preFillAdders(adders: object, tableData: object, columnKey: string) {
	if (adders === null) return;
	Object.keys(adders).forEach((key, index) => {
		if (tableData[index] === undefined) {
			Object.assign(tableData, { [index]: {} });
		}
		if (typeof adders[key] === "object") {
			Object.assign(tableData[index], { [`${columnKey}_name`]: key });
			Object.assign(tableData[index], { [`${columnKey}_price`]: adders[key]["base"] });
			Object.assign(tableData[index], { [`${columnKey}_per`]: adders[key]["per"] });
			Object.assign(tableData[index], { [`${columnKey}_max`]: adders[key]["max"] });
		}
		else {
			Object.assign(tableData[index], { [`${columnKey}_name`]: key });
			Object.assign(tableData[index], { [`${columnKey}_price`]: adders[key] });
			Object.assign(tableData[index], { [`${columnKey}_per`]: null });
			Object.assign(tableData[index], { [`${columnKey}_max`]: null });
		}
	}
	);
}


function preFillIncentivesData(incentives: object, tableData: object, columnKey: string) {
	if (incentives === null) return;
	let runningIndex = 0;
	Object.keys(incentives).forEach((key) => {
		Object.keys(incentives[key]).forEach((subKey) => {
			Object.assign(tableData, { [runningIndex]: {} });
			Object.assign(tableData[runningIndex], { [`${columnKey}_company`]: key });
			Object.assign(tableData[runningIndex], { [`${columnKey}_name`]: subKey });
			Object.assign(tableData[runningIndex], { [`${columnKey}_price`]: incentives[key][subKey]["base_price"] });
			Object.assign(tableData[runningIndex], { [`${columnKey}_max`]: incentives[key][subKey]["max"] });
			runningIndex++;
		});
	}
	);
}


function preFillPanelFeeData(panelFee: object, tableData: object, columnKey: string) {
	if (panelFee === null) return;
	Object.keys(panelFee).forEach((key, index) => {
		if (typeof panelFee[key] === "object") {
			let rowIndexer = index;
			Object.keys(panelFee[key]).forEach((subKey) => {
				while (tableData[rowIndexer] !== undefined) {
					rowIndexer++;
				}
				Object.assign(tableData, { [rowIndexer]: {} });
				Object.assign(tableData[rowIndexer], { [`${columnKey}_name`]: key });
				Object.assign(tableData[rowIndexer], { [`${columnKey}_price`]: panelFee[key][subKey] });
				Object.assign(tableData[rowIndexer], { [`${columnKey}_system_size`]: subKey });
			});
		}
		else {
			while (tableData[index] !== undefined) {
				index++;
			}
			if (tableData[index] === undefined) {
				Object.assign(tableData, { [index]: {} });
			}
			Object.assign(tableData[index], { [`${columnKey}_name`]: key });
			Object.assign(tableData[index], { [`${columnKey}_price`]: panelFee[key] });
		}
	});
	// find {} in tableData and remove it
	Object.keys(tableData).forEach((key) => {
		if (Object.keys(tableData[key]).length < 1) {
			delete tableData[key];
		}
	});
	return tableData;
}


function preFillTableBody(subTable: Array<object>, tableData: object) {
	subTable.forEach((subObj, index) => {
		Object.keys(subObj).forEach((key) => {
			if (tableData[key] === undefined || tableData[key] === null) {
				Object.assign(tableData, { [key]: {} });
			}
			Object.assign(tableData[key], { [`"${key}_${index}"`]: subObj[key] });
		});
	});
}


const DataTable = ({ pricing }: { pricing: IaPricing | undefined }) => {
	if (!pricing || Object.keys(pricing).length < 1) return <></>;
	const dispatch = useDispatch();
	const [tableData, setTableData] = useState({});
	const [solarBasePriceStateData, setsolarBasePriceState] = useState([{}]);
	const [promotionsData, setPromotions] = useState([{}]);
	const [roofPriceData, setRoofPrice] = useState([{}]);
	const [roofTaxData, setRoofTax] = useState([{}]);
	const [marketAdjustmentData, setMarketAdjustment] = useState([{}]);
	const [solarAdderData, setSolarAdder] = useState([{}]);
	const [roofAdderData, setRoofAdder] = useState([{}]);
	const [panelFeeData, setPanelFee] = useState([{}]);
	const [incentivesData, setIncentives] = useState([{}]);
	const [loanOptionsData, setLoanOptions] = useState([{}]);
	const [companyData,] = useState([{}]);
	// const [pdfData,] = useState([{}]);
	const [marketData,] = useState([{}]);
	const [notesData,] = useState([{}]);
	const [startDateData,] = useState([{}]);
	const [endDateData,] = useState([{}]);
	const updateTableData = (rowIndex, columnKey, cellValue) => {
		if (tableData[columnKey]) {
			Object.assign(tableData[columnKey], { [`"${columnKey}_${rowIndex}"`]: cellValue });
		} else {
			Object.assign(tableData, { [columnKey]: { [`"${columnKey}_${rowIndex}"`]: cellValue } });
		}
		setTableData(tableData);
	};
	const iteratePricing = (pricing: object) => {
		Object.keys(pricing).forEach(key => {
			switch (key) {
				case "solar_base_price_state":
					preFillStateBasedData(pricing[key], solarBasePriceStateData, "solar_base_price_state");
					preFillTableBody(solarBasePriceStateData, tableData);
					break;
				case "promotions":
					preFillPriceData(pricing[key], promotionsData, "promotion");
					preFillTableBody(promotionsData, tableData);
					break;
				case "roof_price":
					preFillRoofPriceData(pricing[key], roofPriceData, "roof_price");
					preFillTableBody(roofPriceData, tableData);
					break;
				case "roof_tax":
					preFillPriceData(pricing[key], roofTaxData, "roof_tax");
					preFillTableBody(roofTaxData, tableData);
					break;
				case "market_adjustment":
					preFillPriceData(pricing[key], marketAdjustmentData, "market_adjustment");
					preFillTableBody(marketAdjustmentData, tableData);
					break;
				case "solaradder":
					preFillAdders(pricing[key], solarAdderData, "solar_adder");
					preFillTableBody(solarAdderData, tableData);
					break;
				case "roofadder":
					preFillAdders(pricing[key], roofAdderData, "roof_adder");
					preFillTableBody(roofAdderData, tableData);
					break;
				case "panel_fee":
					preFillPanelFeeData(pricing[key], panelFeeData, "panel_fee");
					preFillTableBody(panelFeeData, tableData);
					break;
				case "incentives":
					preFillIncentivesData(pricing[key], incentivesData, "incentive");
					preFillTableBody(incentivesData, tableData);
					break;
				case "loan_options":
					preFillLoanOptionsData(pricing[key], loanOptionsData, "loan_options");
					preFillTableBody(loanOptionsData, tableData);
					break;
				case "company":
					Object.assign(companyData[0], { "company_name": pricing[key] });
					preFillTableBody(companyData, tableData);
					break;
				case "market":
					Object.assign(marketData[0], { "market_value": pricing[key] });
					preFillTableBody(marketData, tableData);
					break;
				case "start_date": {
					preFillDateData(pricing[key], startDateData, "start_date");
					preFillTableBody(startDateData, tableData);
					break;
				}
				case "end_date": {
					preFillDateData(pricing[key], endDateData, "end_date");
					preFillTableBody(endDateData, tableData);
					break;
				}
				default:
					break;
			}
		});
	};
	iteratePricing(pricing as PricingGroup);
	return <div>

		<>
			<h4>Solar Base Price State</h4>
			<div className="pricing-detail-modal-table">
				<Tabletable
					columns={tableColumns.solarBasePriceState}
					data={solarBasePriceStateData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setsolarBasePriceState([...solarBasePriceStateData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Promotions</h4>
			<div className="pricing-detail-modal-table">
				<Tabletable
					columns={tableColumns.promotions}
					data={promotionsData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setPromotions([...promotionsData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Roofing Price</h4>
			<div className="pricing-detail-modal-table">
				<Tabletable
					columns={tableColumns.roofPrice}
					data={roofPriceData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setRoofPrice([...roofPriceData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Roofing Tax per State</h4>
			<div className="pricing-detail-modal-table">
				<Tabletable
					columns={tableColumns.roofTax}
					data={roofTaxData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setRoofTax([...roofTaxData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Market Adjustment</h4>
			<div className="pricing-detail-modal-table">
				<Tabletable
					columns={tableColumns.marketAdjustment}
					data={marketAdjustmentData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setMarketAdjustment([...marketAdjustmentData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Solar Adders</h4>
			<div className="pricing-detail-modal-table">
				<Tabletable
					columns={tableColumns.solarAdder}
					data={solarAdderData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setSolarAdder([...solarAdderData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Roof Adders</h4>
			<div className="pricing-detail-modal-table">
				<Tabletable
					columns={tableColumns.roofAdder}
					data={roofAdderData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setRoofAdder([...roofAdderData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Panel Fees</h4>
			<div className="pricing-detail-modal-table">
				<Tabletable
					columns={tableColumns.panelFee}
					data={panelFeeData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setPanelFee([...panelFeeData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Incentives</h4>
			<div className="pricing-detail-modal-table">
				<Tabletable
					columns={tableColumns.incentives}
					data={incentivesData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setIncentives([...incentivesData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Loan Options</h4>
			<div className="pricing-detail-modal-table">
				<Tabletable
					columns={tableColumns.loanOptions}
					data={loanOptionsData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setLoanOptions([...loanOptionsData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>
		{/*
			<>
				<br></br>
				<h4>PDF Link</h4>
				<Tabletable
					columns={pdf}
					data={pdfData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</>  */}

		<>
			<br></br>
			<h4>Company</h4>
			<Tabletable
				columns={tableColumns.company}
				data={companyData}
				trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
				updateTableData={updateTableData}
			/>
		</>

		<>
			<br></br>
			<h4>Market</h4>
			<Tabletable
				columns={tableColumns.market}
				data={marketData}
				trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
				updateTableData={updateTableData}
			/>
		</>

		<>
			<h4>Notes</h4>
			<Tabletable
				columns={tableColumns.notes}
				data={notesData}
				trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
				updateTableData={updateTableData}
			/>
		</>

		<>
			<h4>Start Date UTC</h4>
			<Tabletable
				columns={tableColumns.startDate}
				data={startDateData}
				trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
				updateTableData={updateTableData}
			/>
		</>

		<>
			<h4>End Date UTC</h4>
			<Tabletable
				columns={tableColumns.endDate}
				data={endDateData}
				trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
				updateTableData={updateTableData}
			/>
		</>
		<Button onClick={() => {
			const res = validator(tableData);
			if (typeof res === "object") {
				dispatch(addCompanyConfig(res));
			}
		}} className="submit-pricing-button">Update Pricing</Button>
	</div>;
};

export default DataTable;
