import { closerAppointmentSchedulerActions } from "../actions/actions";

const initialState: CloserAppointmentSchedulerState = {
	isCloserAppointmentModalShown: false
};

export default function siteSurveySchedulerReducer(state = initialState, action: CloserAppointmentSchedulerActions) {
	switch (action.type) {
		case closerAppointmentSchedulerActions.SET_IS_CLOSER_APPOINTMENT_MODAL_SHOWN:
			return Object.assign({}, state, { isCloserAppointmentModalShown: action.data });
		default:
			return state;
	}
}
