import apiCall from "../../../utils/api";
import { generalActions } from "../../actions/actions";
import { setRepList, setSettersList } from "../../actions/general";
import { Middleware } from "redux";

const getRepList: Middleware<object, RootState> = store => next => action => {
	if (action.type === generalActions.GET_REP_LIST) {
		const { loadingRepList } = store.getState().general;
		if (!loadingRepList) {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/permissions/user/list?${action.data.source ? `source=${action.data.source}&` : ""}list_fields=name`,
				method: "GET"
			}).then(result => {
				store.dispatch(setRepList(result));
			}).catch((e) => {
				console.error(e);
			});
		}
	}
	const result = next(action);
	return result;
};

const getSettersList: Middleware<object, RootState> = store => next => action => {
	if (action.type === generalActions.GET_SETTERS_LIST) {
		const { loadingSettersList } = store.getState().general;
		if (!loadingSettersList) {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/permissions/user/list/setters`, method: "GET" 
			}).then(
				data => store.dispatch(setSettersList(data.filter((item: string) => !!item)))
			).catch((e) => {
				console.error(e);
			});
		}
	}
	const result = next(action);
	return result;
};


export default [getRepList, getSettersList];
