import React, { useRef } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const backgroundColors = {
	"Not Qualified": "rgba(255, 99, 132, 0.7)",
	"Interested": "rgba(54, 162, 235, 0.7)",
	"Not Interested": "rgba(255, 206, 86, 0.7)",
	"Not Home": "rgba(75, 192, 192, 0.7)",
	"Sold and Financed": "rgba(153, 102, 255, 0.7)",
	"default": "rgba(255, 159, 64, 0.7)",
};

const borderColors = {
	"Not Qualified": "rgba(255, 99, 132, 1)",
	"Interested": "rgba(54, 162, 235, 1)",
	"Not Interested": "rgba(255, 206, 86, 1)",
	"Not Home": "rgba(75, 192, 192, 1)",
	"Sold and Financed": "rgba(153, 102, 255, 1)",
	"default": "rgba(255, 159, 64, 1)",
};

const InteractionsPie = ({ data, onClick }) => {
	const cumulativeInteractionResults = data.reduce((acc, curr) => {
		const { type } = curr;
		if (!acc[type]) {
			acc[type] = 0;
		}
		acc[type] += 1;
		return acc;
	}, {});
	
	const pieRef = useRef<ChartJS<"pie", number[], unknown>>(null);

	const interactionTypeKeys = Object.keys(cumulativeInteractionResults);

	const pieData = {
		labels: interactionTypeKeys,
		datasets: [
			{
				data: interactionTypeKeys.map(key => cumulativeInteractionResults[key]),
				backgroundColor: interactionTypeKeys.map((key) => backgroundColors[key]),
				borderColor: interactionTypeKeys.map((key) => borderColors[key]),
				borderWidth: 1,
			}
		]
	};

	return <Pie data={pieData} onClick={e => onClick(e, pieRef)} ref={pieRef} />;
};

export default InteractionsPie;
