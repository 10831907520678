import apiCall from "../../../utils/api";
import { performanceAuditorActions } from "../../actions/actions";
import { setAcuityData, setCanvassData, setAdpData, setLoading } from "../../actions/performanceAuditor";
import { getData as getDataAction } from "../../actions/performanceAuditor";
import { Middleware } from "redux";
import { encodeEmail } from "../../../utils/formatString";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";

const getData: Middleware<object, RootState> = store => next => action => {
	const { dispatch } = store;
	if (action.type === performanceAuditorActions.GET_DATA) {
		if (!store.getState().performanceAuditor.loadingData) {
			dispatch(setLoading(true));

			const { startDate, endDate, currentRep } = action.data;

			if (startDate && endDate && currentRep) {
				const encodedEmail = encodeEmail(currentRep);
				Promise.all([
					apiCall({ url: `${process.env.REACT_APP_API_SERVICE}/performance-improvement-auditor/sales/canvass?sales_rep=${encodedEmail}&start_date=${startDate}&end_date=${endDate}`, method: "GET" }),
					apiCall({ url: `${process.env.REACT_APP_API_SERVICE}/performance-improvement-auditor/sales/acuity?sales_rep=${encodedEmail}&start_date=${startDate}&end_date=${endDate}`, method: "GET" }),
					apiCall({ url: `${process.env.REACT_APP_API_SERVICE}/performance-improvement-auditor/sales/adp?sales_rep=${encodedEmail}&start_date=${startDate}&end_date=${endDate}`, method: "GET" })
				]).then(res => {
					dispatch(setCanvassData(res[0]));
					dispatch(setAcuityData(res[1]));
					dispatch(setAdpData(res[2]));
				}).catch(e => {
					console.error(e);
					apiErrorToast();
				}).finally(() => {
					dispatch(setLoading(false));
				});
			}
		}
	}
	const result = next(action);
	return result;
};

const getDataOnChange: Middleware<object, RootState> = store => next => action => {
	const { dispatch } = store;
	const loading = store.getState().performanceAuditor.loadingData;
	if (!loading && (action.type === performanceAuditorActions.SET_DATE_RANGE || action.type === performanceAuditorActions.SET_CURRENT_REP)) {
		const performanceAuditorState = store.getState().performanceAuditor;

		const dateRange = action.type === performanceAuditorActions.SET_DATE_RANGE ? action.data : performanceAuditorState.dateRange;
		const { startDate, endDate } = dateRange;

		const currentRep = action.type === performanceAuditorActions.SET_CURRENT_REP ? action.data : performanceAuditorState.currentRep;
		dispatch(getDataAction({ startDate, endDate, currentRep }));
	}
	const result = next(action);
	return result;
};

export default [getData, getDataOnChange];
