import { reportsActions } from "../actions/actions";

const monthsInQuarters = [
	[0, 1, 2],
	[3, 4, 5],
	[6, 7, 8],
	[9, 10, 11]
];

const getDefaultQuarter = () => {
	const date = new Date();
	const month = date.getMonth();
	const year = date.getFullYear();
	const quarter = monthsInQuarters.findIndex(q => q.includes(month));
	return { quarter: quarter.toString(), year: year.toString() };
};

const initialState: ReportsState = {
	salesMarketReportQuarter: getDefaultQuarter()
};

export default function reportsReducer(state = initialState, action: CommissionLedgerAction) {
	switch (action.type) {
		case reportsActions.GET_CONSTRUCTION_FUNNEL_DATA:
			return Object.assign({}, state, { loadingConstructionFunnelReport: true });
		case reportsActions.SET_CONSTRUCTION_FUNNEL_DATA:
			return Object.assign({}, state, { loadingConstructionFunnelReport: false, constructionFunnelData: action.data });
		case reportsActions.SET_IS_CUSTOMER_LIST_MODAL_SHOWN:
			return Object.assign({}, state, { isCustomerListModalShown: action.data });
		case reportsActions.SET_CUSTOMER_LIST:
			return Object.assign({}, state, { customerList: action.data, isCustomerListModalShown: true, loadingCustomerList: false });
		case reportsActions.GET_CUSTOMER_LIST:
			return Object.assign({}, state, { loadingCustomerList: true });
		case reportsActions.SET_LOADING_CUSTOMER_LIST:
			return Object.assign({}, state, { loadingCustomerList: action.data });
		case reportsActions.SET_FUNNEL_REPORT_DEFINITION_LIST:
			return Object.assign({}, state, { funnelReportDefinitionList: action.data });
		case reportsActions.SET_SALES_MARKET_REPORT:
			return Object.assign({}, state, { salesMarketReportData: action.data, loadingSalesMarketReport: false });
		case reportsActions.SET_LOADING_SALES_MARKET_REPORT:
			return Object.assign({}, state, { loadingSalesMarketReport: action.data });
		case reportsActions.SET_SALES_MARKET_REPORT_QUARTER:
			return Object.assign({}, state, { salesMarketReportQuarter: action.data, loadingSalesMarketReport: true });
		case reportsActions.SET_SALES_MARKET_VALUES_MODAL_SHOWN:
			return Object.assign({}, state, { isSalesMarketValuesModalShown: action.data });
		case reportsActions.SET_SALES_MARKET_VALUES:
			return Object.assign({}, state, { salesMarketValues: action.data });
		case reportsActions.GET_CLOSED_OUT_SALES_REPS_DATA:
			return Object.assign({}, state, { loadingClosedOutSalesRepsReport: action.data });
		case reportsActions.SET_CLOSED_OUT_SALES_REPS_DATA:
			return Object.assign({}, state, { closedOutSalesRepsData: action.data });
		default:
			return state;
	}
}
