import { dataValidationActions } from "../actions/actions";

const initialState: DataValidationState = {
};

export default function dataValidationReducer(state = initialState, action: DataValidationAction) {
	switch (action.type) {
		case dataValidationActions.GET_DATA:
			return Object.assign({}, state, { loading: true });
		case dataValidationActions.SET_TYPE:
			return Object.assign({}, state, { type: action.data });
		case dataValidationActions.SET_DATA:
			return Object.assign({}, state, { data: action.data });
		case dataValidationActions.SET_LOADING:
			return Object.assign({}, state, { loading: action.data });
		case dataValidationActions.SET_CURRENT_CUSTOMER:
			return Object.assign({}, state, { currentCustomer: action.data });
		case dataValidationActions.SET_IS_MODAL_SHOWN:
			return Object.assign({}, state, { isModalShown: action.data });
		default:
			return state;
	}
}
