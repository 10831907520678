import apiCall from "../../../utils/api";
import { companyPipelineActions } from "../../actions/actions";
import { setCompanyCustomers, setFilteredCustomerList, setLoadingCustomers } from "../../actions/companyPipeline";
import { Middleware } from "redux";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";


const companyPipelineMiddleware: Middleware<object, RootState> = store => next => action => {
	const dispatch = store.dispatch;
	const companyPipelineState = store.getState().companyPipeline;

	switch (action.type) {
		case companyPipelineActions.GET_COMPANY_CUSTOMERS: {
			const { loadingCustomers } = companyPipelineState;
			const data = action.data;
			if (!loadingCustomers) {
				apiCall({
					url: `${process.env.REACT_APP_API_SERVICE}/customer-pipeline/company/customers/${data.currentCompany}?startDate=${data.dateRange.startDate}&endDate=${data.dateRange.endDate}`,
					method: "GET"
				}).then(res => {
					dispatch(setCompanyCustomers(res));
					dispatch(setFilteredCustomerList(res));
				}).catch((e) => {
					console.error(e);
					apiErrorToast();
					dispatch(setLoadingCustomers(false));
				});
			}
			break;
		}
	}
	const result = next(action);
	return result;
};

export default [companyPipelineMiddleware];