import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Card } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import WhenAuthorized from "../Components/WhenAuthorized";
import { UserPermissions } from "../enums/UserPermissions";
import Modal from "../Components/Modal/Modal";
import { DatePicker, SelectInput, TextInput } from "../Components/Form";
import Button from "react-bootstrap/Button";
import filterList from "../utils/filterList";
import "./styles/CustomerPipeline.sass";
import { useDispatch, useSelector } from "react-redux";
import ExportData from "../Components/ExportData/ExportData";
import { setIsExportPageShown } from "../redux/actions/customerPipeline";
import { getProfile } from "../redux/actions/profileActions";
import Heading from "../Components/Heading/Heading";
import CompanyCustomerList from "../Components/CompanyCustomerList/CompanyCustomerList";
import Toggle from "../Components/Toggle/Toggle";
import { setViewType } from "../redux/actions/customerPipeline";
import { getCompanyCustomers, setFilteredCustomerList, setDateRange } from "../redux/actions/companyPipeline";

const company_vals: Array<SelectOption> = [];

function clearArray<T>(array: Array<T>) {
	while (array.length > 0) {
		array.pop();
	}
}

const CompanyPipeline = () => {
	const customerList = useSelector(({ companyPipeline }: RootState) => companyPipeline.companyCustomers);
	const loadingCustomers = useSelector(({ companyPipeline }: RootState) => companyPipeline.loadingCustomers);
	const filteredCustomerList = useSelector(({ companyPipeline }: RootState) => companyPipeline.filteredCustomerList);
	const dateRange = useSelector(({ companyPipeline }: RootState) => companyPipeline.dateRange);
	const isExportPageShown = useSelector(({ customerPipeline }: RootState) => customerPipeline.isExportPageShown);
	const profile_details = useSelector(({ profile }: RootState) => profile.profile);
	const [currentCompany, setCurrentCompany] = useState<string>("");
	const dispatch = useDispatch();


	const toggleOptions = [
		<svg key="1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
			<path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
		</svg>,
		<svg key="2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-view-stacked" viewBox="0 0 16 16">
			<path d="M3 0h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3zm0 8h10a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1H3z" />
		</svg>
	];

	const viewTypes = ["list", "grid"];

	useEffect(() => {
		clearArray(company_vals);
		let company_exists = false;
		if (profile_details?.company) {
			for (let i = 0; i < company_vals.length; i++) {
				if (company_vals[i].value == profile_details.company) {
					company_exists = true;
				}
			}
			if (company_exists === false) {
				company_vals.push({ key: (company_vals.length + 100).toString(), value: profile_details.company, label: profile_details.company });
			}
			setCurrentCompany(profile_details.company);
		}
		if (profile_details?.has_access_to_companies) {
			profile_details?.has_access_to_companies?.map((company: string, index: number) => {
				if (company !== "") {
					if (!company_vals.includes({ key: index.toString(), value: company, label: company })) {
						company_vals.push({ key: index.toString(), value: company, label: company });
					}
					else {
						company_vals.push({ key: (index + 1).toString(), value: company, label: company });
					}
				}
			});
		}
	}, [profile_details]);

	const handleToggleType = (typeIndex: number) => {
		dispatch(setViewType(viewTypes[typeIndex]));
	};

	useEffect(() => {
		if (profile_details === undefined) {
			dispatch(getProfile());
		}
	}, [profile_details]);

	useEffect(() => {
		if (currentCompany) {
			dispatch(setFilteredCustomerList([]));
			dispatch(getCompanyCustomers({ currentCompany: currentCompany, dateRange: dateRange }));
		}
	}, [currentCompany, dateRange]);

	useEffect(() => {
		if (!currentCompany) {
			setCurrentCompany(profile_details?.company || "");
		}
	}, []);

	const handleDateChange = (value, type) => {
		// If type is start_date or end_date and the value is 1970-01-01, set it to null
		if (type === "start_date" || type === "end_date") {
			if (value === "1970-01-01") {
				value = null;
			}
		}
		if (type === "start_date" && dateRange) {
			dispatch(setDateRange({ startDate: value, endDate: dateRange.endDate }));
		}
		else if (type === "end_date" && dateRange) {
			dispatch(setDateRange({ startDate: dateRange.startDate, endDate: value }));
		}
	};

	const customerFilterKeys = ["name", "status_name", "email", "state"];
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (customerList) {
			dispatch(setFilteredCustomerList(
				filterList<Customer>(
					customerList.map(cus => { cus.name = `${cus.first_name ? `${cus.first_name} ` : ""}${cus.last_name ? cus.last_name : ""}`; return cus; }),
					e.target.value.toUpperCase(),
					customerFilterKeys
				)
			));
		}
	};

	return (
		<WhenAuthorized permission={UserPermissions.customer}>
			<>
				<Modal
					isShown={isExportPageShown}
					hideModal={() => {
						dispatch(setIsExportPageShown(false));
					}}
					body={<ExportData company_vals={company_vals} />}
					heading="Export Company Data"
					addlProps={{ size: "lg" }}
				/>
				<Container>
					<Heading>Company Pipeline</Heading>
					<div>
						<WhenAuthorized permission={UserPermissions.portal_export_caliber}>
							<div className="export-button-container">
								<Button variant="primary"
									onClick={() => {
										dispatch(setIsExportPageShown(true));
									}}
								>
									Export Company Data
								</Button>
							</div>
						</WhenAuthorized>
					</div>
					{customerList ?
						<div className="customer-search-wrapper container">
							<div className="customer-search-container">
								<div className="customer-select-container">
									{company_vals.length > 1 ? <SelectInput
										field={{
											controlId: "companySlect",
											label: "Select Company",
											dropdownData: (company_vals || []).map(company => ({ key: company.key, value: company.value, label: company.label })),
											value: currentCompany,
											onChange: (e: React.ChangeEvent<HTMLSelectElement>) => setCurrentCompany(e.target.value)
										}}
									/> : <></>}
									<TextInput
										field={{
											controlId: "customerFilterString",
											label: "Search for Customer",
											onChange: handleChange,
											placeholder: "Search for Customer"
										}}
									/>
									<div style={{ paddingTop: "5px", margin: "2px", display: "flex" }}>
										<DatePicker
											label="Start Date"
											labelClasses="text-white"
											controlId="start_date"
											value={dateRange?.startDate}
											setValue={date => handleDateChange(date, "start_date")}
											disabled={false}
										/>
									</div>
									<div style={{ paddingTop: "5px", margin: "2px", display: "flex" }}>
										<DatePicker
											label="End Date"
											labelClasses="text-white"
											controlId="start_date"
											value={dateRange?.endDate}
											setValue={date => handleDateChange(date, "end_date")}
											disabled={false}
										/>
									</div>
								</div>
								<div className="customer-list-toggle-container">
									<Toggle options={toggleOptions} onChange={handleToggleType} />
								</div>
							</div>
						</div>
						:
						""
					}
					{loadingCustomers ? <div className="customer-loading-spinner">
						<Spinner animation="border" variant="info" />
					</div> :
						filteredCustomerList?.length ?
							<CompanyCustomerList customerList={filteredCustomerList} />
							: <Card>
								<Card.Body>There are no Customers associated with this Company</Card.Body>
							</Card>
					}
				</Container>
			</>
		</WhenAuthorized >
	);
};

export default CompanyPipeline;
