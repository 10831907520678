import React from "react";
import Form from "react-bootstrap/Form";

const CheckboxInput = ({ field }: { field: CheckboxInputField }) => {
	return <Form.Group
		controlId={field.controlId}
		className={`checkbox-input${field.containerClasses ? ` ${field.containerClasses}` : ""}`}
	>
		<Form.Check
			type="checkbox"
			label={field.label}
			{...(field.disabled ? { disabled: field.disabled } : {})}
			{...(field.checked !== undefined ? { checked: field.checked } : {})}
			{...(field.onChange ? { onChange: e => field.onChange ? field.onChange(e) : {} } : {})}
			{...(field.name ? { name: field.name } : {})}
		/>
		{ field.validation && Object.keys(field.validation).length && field.controlId && field.validation[field.controlId] ? <p className="field-validation-error">
			{field.validation[field.controlId]}
		</p> : "" }
	</Form.Group>;
};

export default CheckboxInput;