import { performanceAuditorActions } from "../actions/actions";
import { getFirstDayOfMonth } from "../../utils/convertDate";
import { convertDateToString } from "../../utils/convertDate";

const initialState: PerformanceAuditorState = {
	currentRep: localStorage.getItem("email") || "",
	dateRange: {
		startDate: convertDateToString(getFirstDayOfMonth()),
		endDate: convertDateToString(new Date())
	}
};

export default function performanceAuditorReducer(state = initialState, action: PerformanceAuditorAction) {
	switch (action.type) {
		case performanceAuditorActions.GET_DATA:
			return Object.assign({}, state, { loadingData: true });
		case performanceAuditorActions.SET_ACUITY_DATA:
			return Object.assign({}, state, { acuityData: action.data });
		case performanceAuditorActions.SET_CANVASS_DATA:
			return Object.assign({}, state, { canvassData: action.data });
		case performanceAuditorActions.SET_ADP_DATA:
			return Object.assign({}, state, { adpData: action.data });    
		case performanceAuditorActions.SET_CURRENT_REP:
			return Object.assign({}, state, { currentRep: action.data });
		case performanceAuditorActions.SET_DATE_RANGE:
			return Object.assign({}, state, { dateRange: { startDate: (action.data as DateRange).startDate, endDate: (action.data as DateRange).endDate }});
		case performanceAuditorActions.SET_LOADING:
			return Object.assign({}, state, { loadingData: action.data });
		default:
			return state;
	}
}
