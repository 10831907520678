import { combineReducers } from "redux";

import commissionLedger from "./commissionLedger";
import general from "./general";
import installAgreement from "./installAgreement";
import customerPipeline from "./customerPipeline";
import dataValidation from "./dataValidation";
import mismatchedRevenue from "./mismatchedRevenue";
import performanceAuditor from "./performanceAuditor";
import users from "./users";
import leaderboards from "./leaderboards";
import login from "./login";
import iaPriceAdjuster from "./iaPriceAdjuster";
import profile from "./profile";
import reports from "./reports";
import auroraProjects from "./auroraProjects";
import marketPipeline from "./marketPipeline";
import siteSurveyScheduler from "./siteSurveyScheduler";
import closerAppointmentScheduler from "./closerAppointmentScheduler";
import companyPipeline from "./companyPipeline";
import redlinePage from "./redlinePage";
import commissionCalculation from "./commissionCalculation";
import commissionSalesRep from "./commissionSalesRep";

export default combineReducers({
	commissionLedger,
	general,
	installAgreement,
	customerPipeline,
	dataValidation,
	mismatchedRevenue,
	performanceAuditor,
	users,
	leaderboards,
	login,
	iaPriceAdjuster,
	profile,
	reports,
	auroraProjects,
	marketPipeline,
	siteSurveyScheduler,
	closerAppointmentScheduler,
	companyPipeline,
	redlinePage,
	commissionCalculation,
	commissionSalesRep
});
