import React from "react";

function PowerBi() {
	return (
		<div className="powerbi-container" >
			<div>
				<iframe width="100%"
					height="850"
					src="https://app.powerbi.com/reportEmbed?reportId=45222aef-2815-4d90-8c16-5a1dfd9c55c1&autoAuth=true&ctid=4fb4efe8-a20f-4608-b1fa-4fe4ffc5c0a6"
					title="POWER BI REPORT">
				</iframe>

			</div>
		</div>
	);
}

export default PowerBi;
