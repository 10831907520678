import React, { useEffect } from "react";
import "./styles/DataValidation.sass";
import { SelectInput } from "../Components/Form";
import apiCall from "../utils/api";
import Table from "../Components/Table/Table";
import ExpandingTable from "../Components/Table/ExpandingTable";
import { Container, Spinner } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import CustomerDetails from "../Components/CustomerDetails/CustomerDetails";
import Modal from "../Components/Modal/Modal";
import LabelTooltip from "../Components/LabelTooltip/LabelTooltip";
import { UserPermissions } from "../enums/UserPermissions";
import { useSelector, useDispatch } from "react-redux";
import { setType, setCurrentCustomer, setIsModalShown } from "../redux/actions/dataValidation";
import { dataValidationTypes } from "../enums/DataValidation";
import Heading from "../Components/Heading/Heading";

const sheetPaymentRevenueUrl = "customer-sheet-payment-vs-revenue/";

const dataValidationTypesArr = [
	{
		key: dataValidationTypes.missingfromjn,
		value: dataValidationTypes.missingfromjn,
		label: "Missing Customer ID in Jobnimbus",
		permission: UserPermissions.dataValidation
	},
	{
		key: dataValidationTypes.installed,
		value: dataValidationTypes.installed,
		label: "Installed",
		permission: UserPermissions.dataValidation
	},
	{
		key: dataValidationTypes.cancelled,
		value: dataValidationTypes.cancelled,
		label: "Cancelled",
		permission: UserPermissions.dataValidation
	},
	// {
	// 	key: dataValidationTypes.jnCancelled,
	// 	value: dataValidationTypes.jnCancelled,
	// 	label: "JobNimbus Cancelled"
	// },
	// {
	// 	key: dataValidationTypes.totalRevenueNoMatchesReceived,
	// 	value: dataValidationTypes.totalRevenueNoMatchesReceived,
	// 	label: "Total Combined Revenue does not match Total Received"
	// },
	// {
	// 	key: dataValidationTypes.solarRoofTotalMatchesRevenue,
	// 	value: dataValidationTypes.solarRoofTotalMatchesRevenue,
	// 	label: "DocuSign Solar or Roof Total matches Revenue"
	// },
	// {
	// 	key: dataValidationTypes.initialFinalReceived,
	// 	value: `${sheetPaymentRevenueUrl}${dataValidationTypes.initialFinalReceived}`,
	// 	label: "Revenue mismatch Initial & Final Amount Received",
	// 	permission: UserPermissions.initialFinalReceived
	// },
	// {
	// 	key: dataValidationTypes.incentivesReceived,
	// 	value: `${sheetPaymentRevenueUrl}${dataValidationTypes.incentivesReceived}`,
	// 	label: "Expected Incentives mismatch those Received",
	// 	permission: UserPermissions.incentivesReceived
	// },
	// {
	// 	key: dataValidationTypes.totalReceived,
	// 	value: `${sheetPaymentRevenueUrl}${dataValidationTypes.totalReceived}`,
	// 	label: "Incentives and Revenue mismatch Total Combined Revenue",
	// 	permission: UserPermissions.totalReceived
	// }
];


const mismatchedDataColumns = [
	{
		Header: "Customer Name",
		accessor: "customer_name",
		Cell: ({ row, value }) => {
			const jn_id = row.allCells.find(cell => cell.column.id === "jobnimbus_id")?.value;
			if (jn_id && jn_id !== "N/A") {
				return <a href={`https://app.jobnimbus.com/contact/${jn_id}`} target="_blank" rel="noreferrer">{value}</a>;
			} else {
				return value;
			}
		}
	},
	{
		Header: "Customer Stage",
		accessor: "customer_stage",
		Cell: ({ value }) => <span>{value?.length > 27 ? <LabelTooltip label={`${value.substring(0, 24)}...`} tooltipText={value} /> : value}</span>
	},
	{
		Header: "Customer ID",
		accessor: "customer_id"
	},
	{
		Header: "JobNimbus ID",
		accessor: "jobnimbus_id"
	},
	{
		Header: "Issue",
		accessor: "issue",
		Cell: ({ value }) => <span>{value?.length > 27 ? <LabelTooltip label={`${value.substring(0, 24)}...`} tooltipText={value} /> : value}</span>
	},
	{
		Header: "Total Incentives Received",
		accessor: "total_incentives_received",
	},
	{
		Header: "Total Incentives Revenue",
		accessor: "total_incentive_revenue",
	},
	{
		Header: "LMI Received",
		accessor: "lmi_received",
	},
	{
		Header: "ODOE Received",
		accessor: "odoe_received",
	},
	{
		Header: "ETO Received",
		accessor: "eto_received",
	},
	{
		Header: "SWR Received",
		accessor: "swr_received",
	},
	{
		Header: "Invoices Available",
		accessor: "invoices",
		Cell: ({ value }) => <span>{value?.length ? "Yes" : "No"}</span>
	}
];

const incentivesTableColumns = [
	{
		Header: "Internal Note",
		accessor: "internal_note",
	},
	{
		Header: "total",
		accessor: "total",
	},
	{
		Header: "total_paid",
		accessor: "total_paid",
	},
	{
		Header: "due",
		accessor: "due"
	}
];

const IncentivesTable = ({ data }) => {
	return data.length ? <Table data={data} columns={incentivesTableColumns} /> : <></>;
};

// Dymanic generation of columns based on the json data provided
const generateColumns = (data) => {
	// Create an empty array of type HeaderData
	const dynamicColumns: HeaderData[] = [];
	for (const key in data[0]) {
		const col = {
			Header: key,
			accessor: key
		};
		// Add the column to the array
		dynamicColumns.push(col);
	}
	return dynamicColumns;
};

const descriptions = {
	[dataValidationTypes.cancelled]: "Customers that have been cancelled in JobNimbus but not in Customer Sheet",
	[dataValidationTypes.installed]: "Customers that have been installed in JobNimbus but not in Customer Sheet",
	[dataValidationTypes.missingfromjn]: "Customers that are missing a Customer ID in JobNimbus",
	[dataValidationTypes.jnCancelled]: "Customers that have been cancelled in Customer Sheet but not in JobNimbus",
	[dataValidationTypes.totalRevenueNoMatchesReceived]: "Customers where Total Revenue does not match Total Received",
	[dataValidationTypes.solarRoofTotalMatchesRevenue]: "Customers where Solar or Roof Total in DocuSign matches Revenue (it shouldn't if not cash)"
};

const loadingSpinner = <div className="text-center m-5">
	<Spinner
		animation="border"
		variant="info"
		className="data-validation-spinner"
	/>
</div>;

const DataValidation = () => {
	const data = useSelector(({ dataValidation }: RootState) => dataValidation.data) || [];
	const loading = useSelector(({ dataValidation }: RootState) => dataValidation.loading);
	const type = useSelector(({ dataValidation }: RootState) => dataValidation.type);
	const currentCustomer = useSelector(({ dataValidation }: RootState) => dataValidation.currentCustomer);
	const isModalShown = useSelector(({ dataValidation }: RootState) => dataValidation.isModalShown);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setType(dataValidationTypes.missingfromjn));
	}, []);

	const rowClick = (row: { [key: string]: string; }) => {
		const { customer_id } = row;
		if (customer_id) {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/customer-pipeline/customers?id=${customer_id}`,
				method: "GET"
			}
			).then(result => {
				dispatch(setCurrentCustomer({ ...result, jobnimbus_id: row.jobnimbus_id } as Customer));
				dispatch(setIsModalShown(true));
			});
		}
	};

	return <>
		{currentCustomer ? <Modal
			isShown={isModalShown}
			hideModal={() => dispatch(setIsModalShown(false))}
			body={currentCustomer ? <CustomerDetails customer={currentCustomer} source="companyPipeline" /> : <></>}
			heading=""
			addlProps={{ size: "lg" }}
		/> : <></>}
		<Container className="data-validation-container">
			<Heading>Data Validation</Heading>
			<div className="mb-3 data-validaton-type-select-container">
				<SelectInput field={{
					controlId: "dataType",
					label: "Data Type",
					labelClasses: "text-white",
					value: type,
					onChange: e => dispatch(setType(e.target.value)),
					dropdownData: dataValidationTypesArr,
					disabled: loading
				}} />
			</div>
			{loading ? loadingSpinner : null}
			{data.length && !loading ? <div>
				{type && descriptions[type] ? <Alert variant={"info"} className="data-validation-description">{descriptions[type]}</Alert> : <></>}
				{type === `${sheetPaymentRevenueUrl}${dataValidationTypes.initialFinalReceived}`
					|| type === `${sheetPaymentRevenueUrl}${dataValidationTypes.incentivesReceived}`
					|| type === `${sheetPaymentRevenueUrl}${dataValidationTypes.totalReceived}` ?
					<ExpandingTable
						data={data}
						columns={mismatchedDataColumns}
						hiddenColumns={["jobnimbus_id"]}
						innerComponent={IncentivesTable}
						retrieveInnerComponentData={(row, setRowData) => row.values.invoices?.length ? setRowData(row.values.invoices) : setRowData([])}
						tableClasses="mismatched-data-table"
						trProps={{ className: "mismatched-data-table-row" }}
						expandColumn="add-column"
					/>
					:
					<Table
						// Columns equal to the headers or keys in data
						columns={generateColumns(data)}
						data={data}
						responsive
						trProps={{ onClick: rowClick, className: "data-validation-table-row" }}
					/>
				}
			</div> : null
			}
			{!data.length && !loading ? <Alert variant={"info"}>No data to display</Alert> : null}
		</Container>
	</>;
};

export default DataValidation;
