import { redlinePageActions } from "../actions/actions";

const initialState: RedlinePageState = {
	redlineData: [],
};

export default function redlinePageReducer(state = initialState, action: RedlinePageActions) {
	switch (action.type) {
		case redlinePageActions.GET_REDLINE_DATA:
			return Object.assign({}, state, { loadingData: true });
		case redlinePageActions.SET_REDLINE_DATA:
			return Object.assign({}, state, { redlineData: action.data });
		default:
			return state;
	}
}
