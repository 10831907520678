import React from "react";
import { ThreeCircles } from "react-loader-spinner";

const LoadingCircles = ({ wrapperClass }: { wrapperClass: string }) =>
	<ThreeCircles
		outerCircleColor="blue"
		middleCircleColor="black"
		innerCircleColor="grey"
		wrapperClass={wrapperClass}
	/>;

export const themedLoadingCircles = <div className="mt-5">
	<ThreeCircles
		outerCircleColor="blue"
		middleCircleColor="black"
		innerCircleColor="grey"
		wrapperClass="center"
	/>
</div>;

export default LoadingCircles;
