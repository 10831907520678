import React, { useState } from "react";
import "./Toggle.sass";

type ToggleProps = {
	options: JSX.Element[] | number[] | string[];
	onChange?: (i: number) => void;
};

type ActiveStyle = {
	width: string;
	transform: string;
}

const Toggle = ({ options, onChange }: ToggleProps) => {
	const [currentActive, setCurrentActive] = useState<number>(0);

	const activeStyle: ActiveStyle = {
		width: `${100 / options.length}%`,
		transform: currentActive === options.length - 1 ? `translateX(calc(${100 * currentActive}% + 1px))` : `translateX(${100 * currentActive}%)`
	};

	const handleClick = (index: number) => {
		setCurrentActive(index);
		if (onChange) onChange(index);
	};

	return <div className="toggle-container">
		<div className="toggle-active" style={activeStyle}>{options[currentActive]}</div>
		{options.map((option: JSX.Element | number | string, index: number) => (<div className="toggle-item" key={index} onClick={() => handleClick(index)}>{option}</div>))}
	</div>;
};

export default Toggle;
