import { commissionCalculationActions } from "./actions";

export const getCommissionCalculationData = () => ({
	type: commissionCalculationActions.GET_COMMISSION_CALCULATION_DATA
});

export const setCommissionCalculationData = (data: CommissionCalculation[]) => ({
	type: commissionCalculationActions.SET_COMMISSION_CALCULATION_DATA,
	data
});

export const setIsCommissionEditModalShown = (data: boolean) => ({
	type: commissionCalculationActions.SET_IS_COMMISSION_EDIT_MODAL_SHOWN,
	data
});

export const setCurrentCommissionCalculationCustomer = (data: CommissionCalculation) => ({
	type: commissionCalculationActions.SET_CURRENT_COMMISSION_CALCULATION_CUSTOMER,
	data
});

export const submitCommissionEdit = (data: CommissionCalculation) => ({
	type: commissionCalculationActions.SUBMIT_COMMISSION_EDIT,
	data
});

export const setIsSubmittingCommissionCalculation = (data: boolean) => ({
	type: commissionCalculationActions.SET_IS_SUBMITTING_COMMISSION_CALCULATION,
	data
});
