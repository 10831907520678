import React from "react";
import { Container } from "react-bootstrap";
import Heading from "../Components/Heading/Heading";
import "./styles/Resource.sass";
import WhenAuthorized from "../Components/WhenAuthorized";
import checkIfAuthorized from "../utils/permissions";
import { getSolarHandout } from "../redux/actions/resourcePage";
import { useDispatch } from "react-redux";


function ResourcePage() {
	const dispatch = useDispatch();
	const handleDownload = (file: string) => {
		if (file === "solar_handout") {
			dispatch(getSolarHandout());
		}
	};

	return (
		<Container>
			<Heading>Resources</Heading>
			{(checkIfAuthorized("portal/resources/sales/internal")) ? <></> :
				<Container className="resource-container">
					<h3 className="text">Submit an IT Ticket</h3>
					<p className="text">If you are experiencing difficulties or errors using the portal. Please submit a ticket to <a style={{ color: "#3C91E6" }}>portalsupport@infinitysolarusa.com</a> with all relevant information and screenshots if needed so we may best help you.</p>
				</Container>}
			<WhenAuthorized permission="portal/resources/sales/internal">
				<Container className="resource-container">
					<h3 className="text">Submit an IT Ticket</h3>
					<p className="text">Having issues with the portal or other technical problems? The best way to get help is to submit a ticket, the IT email is <a style={{ color: "#3C91E6" }}>it@infinitysolarusa.com</a></p>
				</Container>
			</WhenAuthorized>
			<WhenAuthorized permission="portal/resources/sales/internal">
				<Container className="resource-container" onClick={() => window.open("https://sites.google.com/infinitysolarusa.com/help-center/home")}>
					<h3 className="text">Resource Center</h3>
					<p className="text">Internal Document Resources</p>
				</Container>
			</WhenAuthorized>
			<WhenAuthorized permission="portal/resources/sales">
				<Container className="resource-container">
					<h3 className="text">Infinity Solar USA Finance</h3>
					<p className="text">Reach out to Finance here at Infinity Solar USA at (800) 991-0235</p>
				</Container>
			</WhenAuthorized>
			<WhenAuthorized permission="portal/resources/sales/internal">
				<Container className="resource-container" onClick={() => window.open("https://infinitysolar.as.me/")}>
					<h3 className="text">Acuity Scheduling Link</h3>
					<p className="text">Click here to schedule an appointment on Acuity</p>
				</Container>
			</WhenAuthorized>
			<WhenAuthorized permission="portal/resources/sales/internal">
				<Container className="resource-container" onClick={() => window.open("https://secure.acuityscheduling.com/appointments.php")}>
					<h3 className="text">Acuity</h3>
					<p className="text">The home page of Acuity</p>
				</Container>
			</WhenAuthorized>
			<WhenAuthorized permission="portal/resources/sales/internal">
				<Container className="resource-container" onClick={() => window.open("https://app.jobnimbus.com/default.aspx")}>
					<h3 className="text">JobNimbus</h3>
					<p className="text">Check on customers</p>
				</Container>
			</WhenAuthorized>
			<WhenAuthorized permission="portal/resources/sales">
				<Container className="resource-container" onClick={() => window.open("https://phx.gosolo.io/login")}>
					<h3 className="text">SOLO</h3>
					<p className="text">Create and view proposals</p>
				</Container>
			</WhenAuthorized>
			<WhenAuthorized permission="portal/resources/sales">
				<Container className="resource-container" onClick={() => window.open("https://partner.dividendfinance.com/login")}>
					<h3 className="text">Dividend Finance</h3>
					<p className="text">Click here to create and check on Dividend loans</p>
				</Container>
			</WhenAuthorized>
			<WhenAuthorized permission="portal/resources/sales">
				<Container className="resource-container" onClick={() => window.open("https://apps.svcfin.com/DealerPortal/LoanCenter.aspx")}>
					<h3 className="text">Service Finance</h3>
					<p className="text">Click here to create and check on Service Finance loans</p>
				</Container>
			</WhenAuthorized>
			<WhenAuthorized permission="portal/resources/sales/internal">
				<Container className="resource-container" onClick={() => window.open("https://na4.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=25df320d-437f-4495-9571-398d4f33e019&env=na4&acct=f8d52a89-fdfa-4a7a-9f9c-60101183cc65&v=2")}>
					<h3 className="text">DocuSign Powerform</h3>
					<p className="text">Click here to create a manual project agreement</p>
				</Container>
			</WhenAuthorized>
			<WhenAuthorized permission="portal/resources/sales/internal">
				<Container className="resource-container" onClick={() => window.open("https://app.docusign.com/home")}>
					<h3 className="text">DocuSign</h3>
					<p className="text">View and Sign Project Agreements</p>
				</Container>
			</WhenAuthorized>
			<WhenAuthorized permission="portal/resources/sales/internal">
				<Container className="resource-container" onClick={() => handleDownload("solar_handout")}>
					<h3 className="text">Solar Handout Download</h3>
					<p className="text">Click here to download the solar handout</p>
				</Container>
			</WhenAuthorized >
		</Container >
	);
}

export default ResourcePage;
