import { siteSurveySchedulerActions } from "../actions/actions";

const initialState: SiteSurveySchedulerState = {
	month: new Date(),
	days: [],
	times: [],
	selectedDay: null,
	isSiteSurveyModalShown: false
};

export default function siteSurveySchedulerReducer(state = initialState, action: SiteSurveySchedulerActions) {
	switch (action.type) {
		case siteSurveySchedulerActions.SET_MONTH:
			return Object.assign({}, state, { month: action.data });
		case siteSurveySchedulerActions.SET_DAYS:
			return Object.assign({}, state, { days: action.data });
		case siteSurveySchedulerActions.SET_TIMES:
			return Object.assign({}, state, { times: action.data });
		case siteSurveySchedulerActions.SET_SELECTED_DAY:
			return Object.assign({}, state, { selectedDay: action.data });
		case siteSurveySchedulerActions.SET_IS_SITE_SURVEY_MODAL_SHOWN:
			return Object.assign({}, state, { isSiteSurveyModalShown: action.data });
		default:
			return state;
	}
}
