import React, { useState } from "react";
import { Card, Form, Row, Col, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import { SelectInput, fieldTypes } from "../Form";
import { useDispatch } from "react-redux";
import { setExport } from "../../redux/actions/customerPipeline";

const closeIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
	<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
</svg>;

const ExportData = ({ company_vals }) => {
	const [accessCompanies, setAccessCompanies] = useState<Array<string>>([]);
	const dispatch = useDispatch();

	const handleCompaniesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (accessCompanies?.length) {
			if (accessCompanies.includes(event.target.value)) {
				return;
			}
			setAccessCompanies([...accessCompanies, event.target.value]);
		} else {
			setAccessCompanies([event.target.value]);
		}
	};

	const handleCompaniesRemove = (passedCompany: string) => {
		const newAccessCompanies = accessCompanies.filter((company) => company !== passedCompany);
		setAccessCompanies(newAccessCompanies);
	};

	const setExportQuery = (companies) => {
		let query = "";
		for (let i = 0; i < companies.length; i++) {
			if (i === 0) {
				query = `${companies[i]}`;
			}
			query.concat(`,${companies[i]}`);
		}
		return query;
	};

	return <Card style={{ marginBottom: "20px" }}>
		<Card.Body>
			<Form>
				<Row className="formRowBuffer onboarding-adpposition-container">
					<Col>
						<SelectInput
							field={{
								controlId: "has_access_to_companies",
								label: "Company Access",
								name: "has_access_to_companies",
								dropdownData: company_vals,
								type: fieldTypes.dropdown,
								onChange: handleCompaniesChange,
								value: "",
							}}
						/>
						<Row className="formRowBuffer onboarding-adpposition-container">
							<Col>
								<>
									{(accessCompanies as Array<string>)?.map((company, i) => (
										<ListGroup key={i} horizontal="sm" className="my-2">
											<ListGroupItem variant="light" action disabled>{company}</ListGroupItem>
											<ListGroupItem variant="light" onClick={() => handleCompaniesRemove(company)}>{closeIcon}</ListGroupItem>
										</ListGroup>
									))}
								</>
							</Col>
						</Row>
					</Col>
				</Row>
				<div className="export-button-container">
					<Button variant="primary"
						onClick={() => {
							dispatch(setExport(setExportQuery(accessCompanies)));
						}}
					>
						Export Company Data
					</Button>
				</div>
			</Form>
		</Card.Body>
	</Card>;
};

export default ExportData;
