import commissionLedgerApi from "./api/commissionLedger";
import customerPipelineApi from "./api/customerPipeline";
import dataValidationApi from "./api/dataValidation";
import mismatchedRevenueApi from "./api/mismatchedRevenue";
import performanceAuditor from "./api/performanceAuditor";
import users from "./api/users";
import leaderboards from "./api/leaderboards";
import generalApi from "./api/general";
import iaPriceAdjuster from "./api/iaPriceAdjuster";
import installAgreement from "./api/installAgreement";
import profile from "./api/profile";
import reports from "./api/reports";
import auroraProjects from "./api/auroraProjects";
import marketPipeline from "./api/marketPipeline";
import siteSurveyScheduler from "./api/siteSurveyScheduler";
import resourcePage from "./api/resourcePage";
import closerAppointmentScheduler from "./api/closerAppointmentScheduler";
import companyPipeline from "./api/companyPipeline";
import redlinePage from "./api/redlinePage";
import commissionCalculation from "./api/commissionCalculation";
import commissionSalesRep from "./api/commissionSalesRep";

export default [
	...generalApi,
	...commissionLedgerApi,
	...customerPipelineApi,
	...dataValidationApi,
	...mismatchedRevenueApi,
	...performanceAuditor,
	...users,
	...leaderboards,
	...iaPriceAdjuster,
	...installAgreement,
	...profile,
	...reports,
	...auroraProjects,
	...marketPipeline,
	...siteSurveyScheduler,
	...resourcePage,
	...closerAppointmentScheduler,
	...companyPipeline,
	...redlinePage,
	...commissionCalculation,
	...commissionSalesRep
];
