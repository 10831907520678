import { mismatchedRevenueActions } from "./actions";

export const getData = () => ({
	type: mismatchedRevenueActions.GET_DATA
});

export const setData = (data: MismatchedRevenueData[]) => ({
	type: mismatchedRevenueActions.SET_DATA,
	data
});

export const setLoading = (data: boolean) => ({
	type: mismatchedRevenueActions.SET_LOADING,
	data
});
