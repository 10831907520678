import axios from "axios";

const clearStorageAndRedirect = () => {
	localStorage.clear();
	window.location.href = "/";
};

type ApiCallParams = {
	url: string,
	method?: string,
	headers?: object,
	body?: object,
	// Adding an any here because the axios types are not working
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	responseType?: any
}

const apiCall = async ({
	url,
	method = "GET",
	headers = {},
	body = {},
	responseType = "json"
}: ApiCallParams) => {
	const token = localStorage.getItem("t");

	if (token) {
		return axios({
			method: method,
			url: url,
			data: body,
			responseType: responseType,
			headers: {
				Authorization: `Bearer: ${token}`,
				...headers
			}
		}).then(res => res.data || true
		).catch(e => {
			console.error(e);
			if (e.response?.status === 401) {
				console.error("apiCall received 401, redirecting to login");
				const refreshTokenExp = localStorage.getItem("refreshTokenExp");
				if (!refreshTokenExp || refreshTokenExp !== "true") {
					clearStorageAndRedirect();
				}
				window.location.href = "/";
			}
			throw e;
		});
	} else {
		console.error("apiCall attempted with no current auth token");
		clearStorageAndRedirect();
		return false;
	}
};

export default apiCall;
