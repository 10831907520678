import { companyPipelineActions } from "./actions";

export const getCompanyCustomers = (data: { currentCompany: string, dateRange: DateRange | undefined }) => ({
	type: companyPipelineActions.GET_COMPANY_CUSTOMERS,
	data
});

export const setLoadingCustomers = (data: boolean) => ({
	type: companyPipelineActions.SET_LOADING_CUSTOMERS,
	data
});

export const setCompanyCustomers = (data: Customer[]) => ({
	type: companyPipelineActions.SET_COMPANY_CUSTOMERS,
	data
});

export const setFilteredCustomerList = (data: Customer[]) => ({
	type: companyPipelineActions.SET_FILTERED_CUSTOMER_LIST,
	data
});

export const setDateRange = (data: DateRange) => ({
	type: companyPipelineActions.SET_DATE_RANGE,
	data,
});

export const setIsCustomerDetailModalShown = (data: boolean) => ({
	type: companyPipelineActions.SET_IS_CUSTOMER_DETAIL_MODAL_SHOWN,
	data
});

export const setCurrentCustomer = (data: Customer) => ({
	type: companyPipelineActions.SET_CURRENT_CUSTOMER,
	data
});
