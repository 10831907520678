import apiCall from "../../../utils/api";
import { siteSurveySchedulerActions } from "../../actions/actions";
import { Middleware } from "redux";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";
import { setDays, setTimes, setIsSiteSurveyModalShown, setMonth, setSelectedDay } from "../../actions/siteSurveyScheduler";
import { toast } from "react-toastify";

const siteSurveySchedulerMiddleware: Middleware<object, RootState> = store => next => action => {
	const dispatch = store.dispatch;
	const siteSurveySchedulerState = store.getState().siteSurveyScheduler;
	const profileState = store.getState().profile;
	switch (action.type) {
		case siteSurveySchedulerActions.GET_DAYS: {
			const { month } = siteSurveySchedulerState;
			const passedMonth = `${month.getFullYear()}-${month.getMonth() + 1}`;
			const appointmentTypeID = action.data;
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/acuity-scheduler/site-survey/date/${appointmentTypeID}?month=${passedMonth}`,
				method: "GET"
			}).then((res) => {
				dispatch(setDays(res));
			}).catch((res) => {
				apiErrorToast(res.message);
			});
			break;
		}
		case siteSurveySchedulerActions.GET_TIMES: {
			const { selectedDay } = siteSurveySchedulerState;
			const appointmentTypeID = action.data;
			const { month } = siteSurveySchedulerState;
			let timezone = "America/Los_Angeles";
			if (month.toString().includes("Central")) {
				timezone = "America/Chicago";
			}
			else if (month.toString().includes("Eastern")) {
				timezone = "America/New_York";
			}
			else if (month.toString().includes("Mountain")) {
				timezone = "America/Denver";
			}
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/acuity-scheduler/site-survey/times/${appointmentTypeID}?date=${selectedDay}&timezone=${timezone}`,
			}).then((res) => {
				dispatch(setTimes(res));
			}).catch((res) => {
				apiErrorToast(res.message);
			});
			break;
		}
		case siteSurveySchedulerActions.SET_APPOINTMENT: {
			const { name, manager_email } = profileState.profile;
			const customer = action.data;
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/acuity-scheduler/site-survey`,
				body: {
					"datetime": customer.appointmentTime,
					"appointmentTypeID": customer.appointmentTypeID,
					"firstName": customer.first_name,
					"lastName": customer.last_name,
					"email": customer.email,
					"phone": customer.phone,
					"smsOptIn": customer.sms,
					"fields": {
						"address": customer.address,
						"roof": customer.roof,
						"hoa": customer.hoa ? true : false,
						"sales_rep": name,
						"sales_rep_number": customer.salesRepNumber,
						"direct_manager": manager_email
					}
				},
				method: "POST"
			}).then(() => {
				toast.info("Appointment created successfully", {
					position: "top-right",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					toastId: "site-survery-scheduler-toast",
					onClick: () => window.close(),
					autoClose: 5000
				});
				dispatch(setMonth(new Date()));
				dispatch(setDays([]));
				dispatch(setTimes([]));
				dispatch(setSelectedDay(null));
				dispatch(setIsSiteSurveyModalShown(false));
			}).catch((res) => {
				apiErrorToast(res.response.data.message);
			});
			break;
		}
	}
	const result = next(action);
	return result;
};

export default [siteSurveySchedulerMiddleware];
