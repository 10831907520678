import React from "react";
import {Col, Row } from "react-bootstrap";
import WhenAuthorized from "../WhenAuthorized";
import { UserPermissions } from "../../enums/UserPermissions";

const CommissionSalesRepModal = ({ customer }: { customer: CommissionCalculation }) => {
	const DetailItem = ({ field, value }: { field: string, value: string | boolean | number | null }) => <p style={{ marginBottom: 0 }}><b>{field}:</b> {value}</p>;


	return(
		<div>
			<div className="customer-detail-modal-header-container">
				<h3>{`${customer.customer_name}`}</h3>
			</div>
			<Row>
				<Col md={6} sm={12}>
					<DetailItem field="System Size" value={customer.job_kw} />
					<DetailItem field="Panel Type" value={customer.panel_type} />
					<DetailItem field="Inverter Type" value={customer.inverter_type} />
					<DetailItem field="Loan" value={customer.loan_type} />
				</Col>
			</Row>
			<Row>
				{
					customer.solar_adders || customer.roof_adders ?
						<>
							<h3>Adders</h3>
							{
								customer.solar_adders ?
									<Col md={6} sm={12}>
										<h4>Solar</h4>
										{Object.keys(customer.solar_adders).map((key: string) => {
											return <DetailItem
												key={key}
												field={key}
												value={customer.solar_adders ? customer.solar_adders[key].default_cost : ""}
											/>;
										})}
									</Col> : <></>
							}
							{
								customer.roof_adders ?
									<Col md={6} sm={12}>
										<h4>Roof</h4>
										{Object.keys(customer.roof_adders).map((key: string) => {
											return <DetailItem
												key={key}
												field={key}
												value={customer.roof_adders ? customer.roof_adders[key].default_cost : ""}
											/>;
										})}
									</Col> : <></>
							}
						</> : <></>}
			</Row>
			<br />
			<Row>
				<Col md={6} sm={12}>
					<DetailItem field="Total Revenue (Dealer Fee)" value={customer.total_revenue} />
					<DetailItem field="Net Revenue (No Dealer Fee)" value={customer.job_total_revenue} />
					<DetailItem field="Net PPW (No Dealer Fee)" value={customer.price_per_watt} />
				</Col>
			</Row>
			<br />
			<WhenAuthorized
				permission={UserPermissions.managerCommission}
			>
				<div>
					<Row>
						<Col md={6} sm={12}>
							<DetailItem field="Owed to Infinity Solar USA" value={customer.owed_to_company} />
							<DetailItem field="Net PPW Infinity Solar USA" value={customer.market_redline} />
							<DetailItem field="Owed to Management" value={customer.owed_to_management} />
							<DetailItem field="Total Commissionable" value={customer.net_commission} />
						</Col>
					</Row>
					<br />
				</div>
			</WhenAuthorized>
			<Row>
				<Col md={6} sm={12}>
					{customer.branch_manager_commission_amount ? <DetailItem field="Branch Manager" value={customer.branch_manager_commission_amount} /> : <></>}
					{customer.branch_manager_pay ? <DetailItem field="Branch Manager Paid" value={customer.branch_manager_pay.branch_manager_commission_paid} /> : <></>}
					{customer.market_manager_commission_amount ? <DetailItem field="Market Manager" value={customer.market_manager_commission_amount} /> : <></>}
					{customer.market_manager_pay ? <DetailItem field="Market Manager Paid" value={customer.market_manager_pay.market_manager_commission_paid} /> : <></>}
					{customer.team_lead_commission_amount ? <DetailItem field="Team Lead" value={customer.team_lead_commission_amount} /> : <></>}
					{customer.team_lead_pay ? <DetailItem field="Team Lead Paid" value={customer.team_lead_pay.team_lead_commission_paid} /> : <></>}
					{customer.setter_coach_commission_amount ? <DetailItem field="Setter Coach" value={customer.setter_coach_commission_amount} /> : <></>}
					{customer.setter_coach_pay ? <DetailItem field="Setter Coach Paid" value={customer.setter_coach_pay.setter_coach_commission_paid} /> : <></>}
					{customer.closer_amount ? <DetailItem field="Owed to Closer" value={customer.closer_amount} /> : <></>}
					{customer.closer_pay ? <DetailItem field="Closer Paid" value={customer.closer_pay.closer_paid} /> : <></>}
					<DetailItem field="Owed to Setter" value={customer.setter_commission_amount} />
					<DetailItem field="Setter Paid" value={customer.setter_pay ? customer.setter_pay.setter_commission_paid: ""} />
					<DetailItem field="Total Incentives" value={customer.incentives_offered} />
				</Col>
			</Row>
		</div>
	);
};

export default CommissionSalesRepModal;
