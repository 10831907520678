import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClosedOutSalesRepsData } from "../../redux/actions/reports";
import Table from "../Table/Table";
import WhenAuthorized from "../WhenAuthorized";
import { UserPermissions } from "../../enums/UserPermissions";

const reportColumns = [
	{
		Header: "Sales Rep",
		accessor: "sales_rep"
	},
	// {
	// 	Header: "Customer ID",
	// 	accessor: "customer_id",
	// },
	{
		Header: "Customer Name",
		accessor: "customer_name",
	},
	{
		Header: "Customer Stage",
		accessor: "customer_stage",
	},
	// {
	// 	Header: "Closer",
	// 	accessor: "closer",
	// },
	// {
	// 	Header: "Setter",
	// 	accessor: "setter",
	// },
	{
		Header: "Rep Type",
		accessor: "rep_type",
	},
	{
		Header: "Units Sold for Month",
		accessor: "units_sold",
	},
	{
		Header: "Total Combined Revenue",
		accessor: "total_combined_revenue",
	},
	{
		Header: "Total Commission",
		accessor: "total_commission",
	},
	{
		Header: "Expected Commission %",
		accessor: "expected_commission_percentage",
		Cell: ({ value }: { value: string }) => `${(parseFloat(value)).toFixed(3)}%`
	},
	{
		Header: "Percentage Paid",
		accessor: "percentage_paid",
		Cell: ({ value }: { value: string }) => `${(parseFloat(value)).toFixed(3)}%`
	},
	{
		Header: "Percentage Difference",
		accessor: "percentage_difference",
		Cell: ({ value }: { value: string }) => `${(parseFloat(value)).toFixed(3)}%`
	},
	// {
	// 	Header: "Number of CX Financed Approved",
	// 	accessor: "number_of_cx_financed_approved",
	// },
	{
		Header: "Update",
		accessor: "update",
		// Format as a date
		Cell: ({ value }: { value: string }) => new Date(value).toLocaleDateString()
	}
];

const convertToTableData = (data: ClosedOutSalesRepsReportData[]) =>
	data.map((item) => {
		return {
			"sales_rep": item.sales_rep,
			"customer_id": item.customer_id,
			"customer_name": item.customer_name,
			"customer_stage": item.customer_stage,
			"closer": item.closer,
			"setter": item.setter,
			"rep_type": item.rep_type,
			"units_sold": item.units_sold,
			"total_combined_revenue": item.total_combined_revenue,
			"total_commission": item.total_commission,
			"expected_commission_percentage": item.expected_commission_percentage,
			"percentage_paid": item.percentage_paid,
			"percentage_difference": item.percentage_difference,
			"number_of_cx_financed_approved": item.number_of_cx_financed_approved,
			"update": item.update,
		};
	});

const ClosedOutSalesRepsReport = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getClosedOutSalesRepsData());
	}, []);

	const closedOutSalesRepData = useSelector(({ reports }: RootState) => reports.closedOutSalesRepsData) || [];

	const reportTable = closedOutSalesRepData.length ?
		<Table
			columns={reportColumns}
			data={convertToTableData(closedOutSalesRepData)}
		/> : <></>;

	// Generate a table that displays each sales rep and the customers for them, illustrating the total commission for each sales rep
	// and any outstanding due

	return <WhenAuthorized permission={UserPermissions.construction_funnel_report}>
		<>
			{reportTable}
		</>
	</WhenAuthorized>;
};

export default ClosedOutSalesRepsReport;
