import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Table from "../../Components/Table/Table";
import apiCall from "../../utils/api";
import Modal from "../../Components/Modal/Modal";
import WhenAuthorized from "../WhenAuthorized";
import { UserPermissions } from "../../enums/UserPermissions";
import { Navigate } from "react-router-dom";
import { apiErrorToast } from "../Toasts/ApiErrorToast";
import { encodeEmail } from "../../utils/formatString";

const stateCustomerTableColumns: HeaderData[] = [
	{
		Header: "Update",
		accessor: "update",
	},
	{
		Header: "Customer Name",
		accessor: "customer_name",
	},
	{
		Header: "Customer Install Status",
		accessor: "customer_install_status",
	},
	{
		Header: "JobNimbus Status",
		accessor: "JobNimbus - Status",
	},
	{
		Header: "Customer Stage",
		accessor: "customer_stage",
	},
	{
		Header: "Panel Type",
		accessor: "Panel Type"
	},
	{
		Header: "Panel #",
		accessor: "Panel #"
	},
];

const StateCustomerTable = (data: { data: StateCustomerPanelData[] }) =>
	data.data.length ? <Table
		data={data.data}
		columns={stateCustomerTableColumns}
	/> : <></>;

const PanelReport = () => {
	const [panelData, setPanelData] = useState<SolarPanelQuantity[]>([]);
	const [kwPanelData, setKwPanelData] = useState<SolarPanelKw[]>([]);
	const [isModalShown, setIsModalShown] = useState<boolean>(false);
	const [stateCustomerData, setStateCustomerData] = useState<StateCustomerPanelData[]>([]);
	const [currentSelectedState, setCurrentSelectedState] = useState<string>("");
	const [currentSelectedPanelType, setCurrentSelectedPanelType] = useState<string | null>("");

	const states: string[] = [];
	const panelTypes: string[] = [];

	type PanelTableData = { [key: string]: string | number }
	const panelTableData: PanelTableData[] = [];

	useEffect(() => {
		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/solar-panel-report/state/?group_by=panel`,
		})
			.then(res => {
				setPanelData(res.data);
			});

		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/solar-panel-report/state/?group_by=kw-panel`,
		})
			.then(res => {
				setKwPanelData(res.data);
			});
	}, []);

	const onCellClick = (cell) => {
		if (cell.column.parent === undefined) {
			// Replace + with %2B
			const cellValue = encodeEmail(cell.value);
			setCurrentSelectedPanelType(cellValue);
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/solar-panel-report/?panel_type=${cellValue}`,
			})
				.then(res => {
					setStateCustomerData(res.data);
					setIsModalShown(true);
				})
				.catch(e => {
					apiErrorToast();
					console.error(e);
				});
			return;
		}
		setCurrentSelectedPanelType(null);
		const state = cell.column.parent.id;
		setCurrentSelectedState(state);
		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/solar-panel-report/?state=${state}`,
		})
			.then(res => {
				setStateCustomerData(res.data);
				setIsModalShown(true);
			})
			.catch(e => console.error(e));
	};

	panelData.forEach(stateData => {
		if (!states.includes(stateData.State)) {
			states.push(stateData.State);
		}
	});

	const panelColumns: HeaderData[] = [
		{
			Header: "Panel Type",
			accessor: "panelType"
		}
	];

	states.forEach((state: string) => {
		if (!panelColumns.find(i => i.accessor === state) && !!state) {
			panelColumns.push({
				Header: state,
				accessor: state,
				disableSortBy: true,
				columns: [
					{
						Header: "Quantity",
						accessor: `${state}_count`
					},
					{
						Header: "Kilowatts",
						accessor: `${state}_kilowatts`
					}
				]
			});
		}
		if (!panelColumns.find(i => i.accessor === "Null") && state === null) {
			panelColumns.push({
				Header: "Null",
				accessor: "Null",
				disableSortBy: true,
				columns: [
					{
						Header: "Quantity",
						accessor: "null_count"
					},
					{
						Header: "Kilowatts",
						accessor: "null_kilowatts"
					}
				]
			});
		}
	});

	panelData.forEach((stateData: SolarPanelQuantity) => {
		if (!panelTypes.includes(stateData["Panel Type"])) {
			panelTypes.push(stateData["Panel Type"]);
		}
	});

	panelTypes.forEach((panelType: string) => {
		const panelTypeData: PanelTableData = { panelType };
		states.forEach(state => {
			const statePanelData: SolarPanelQuantity | undefined = panelData.find((i) => i["Panel Type"] === panelType && i.State === state);
			const stateKwData: SolarPanelKw | undefined = kwPanelData.find((i) => i["Panel Type"] === panelType && i.State === state);
			const stateData = { ...statePanelData, ...stateKwData };
			if (stateData["Total Panels"]) panelTypeData[`${state}_count`] = stateData["Total Panels"];
			if (stateData["Total KW"]) panelTypeData[`${state}_kilowatts`] = stateData["Total KW"];
		});
		panelTableData.push(panelTypeData);
	});

	return <WhenAuthorized permission={UserPermissions.solar_panel_report} notAuthorized={<Navigate to="/" />}>
		<Container>
			<h3 className="text-white text-center mb-3">Solar Panel Report</h3>
			<Modal
				isShown={isModalShown}
				hideModal={() => setIsModalShown(false)}
				body={stateCustomerData ? <StateCustomerTable data={stateCustomerData} /> : <></>}
				heading={<h4>{`Customer panel list for ${currentSelectedPanelType ? currentSelectedPanelType : currentSelectedState}`}</h4>}
				addlProps={{ size: "lg" }}
			/>
			<div className="solar-panel-table-container">
				<Table
					data={panelTableData}
					columns={[...new Set(panelColumns)] as HeaderData[]}
					tdOnclick={(cell) => onCellClick(cell)}
					tdClasses="solar-panel-table-td"
				/>
			</div>
		</Container>
	</WhenAuthorized>;
};

export default PanelReport;
