import React from "react";
import "./Board.sass";
import { Card } from "react-bootstrap";

type BoardProps<T> = {
	list: { [key:string]: T[] };
	columns: { key: string, label: string }[];
	component: React.FC<{ customer: T, onClick: (customer: T) => void }>;
	onClick: (customer: T) => void;
	iterateKey: string;
};

function Board<T>(props: BoardProps<T>) {
	const { list, columns, component: Component, onClick, iterateKey } = props;

	const content = columns.map(col => {
		if (list[col.key]?.length) {
			return <div className="board-column" key={col.label}>
				<div className="board-column-header">{col.label}</div>
				<div className="board-column-body">
					{list[col.key].map((customer: T) => {
						return <Card key={customer[iterateKey]} className="board-card">
							<Component customer={customer} onClick={onClick} />
						</Card>;
					})}
				</div>
			</div>;
		} else {
			return <div className="board-column" key={col.label}>
				<div className="board-column-header">{col.label}</div>
				<div className="board-column-body">
				</div>
			</div>;
		}
	});

	return <div className="board-wrapper">
		<div className="board-container">
			{content}
		</div>
	</div>;
}

export default Board;
