import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Adder } from "../Form";
import dropDownData from "./data/formSelects.json";
import Accordion from "react-bootstrap/Accordion";
import "./InstallAgreement.sass";
import { TextInput, SelectInput, CheckboxInput } from "../Form";
import LabelTooltip from "../LabelTooltip/LabelTooltip";
import DraftSubmission from "./DraftSubmission";
import LoadingCircles from "../LoadingCircles/ThreeCircles";
import RadioInputGroup from "../Form/Radio/RadioInputGroup";
import WhenAuthorized from "../WhenAuthorized";
import { UserPermissions } from "../../enums/UserPermissions";
import { useDispatch, useSelector } from "react-redux";
import {
	setForm,
	setFormValue,
	setMainAccordionTabs,
	setSolarRoofAccordion,
	setAdderValue,
	clearAdders,
	setValidationErrors,
	removeFormValue,
	setOriginalHomeowner,
	setRoofAdder,
	setSolarAdder,
} from "../../redux/actions/installAgreement";
import { getSettersList } from "../../redux/actions/general";
import AutoComplete from "../Form/Autocomplete/AutoComplete";
import { getCustomers } from "../../redux/actions/customerPipeline";
import { getSalesRepPricingInfo } from "../../redux/actions/iaPriceAdjuster";
import {
	submitForm, submitDryRun, createFormElements, validateForm
} from "./submitForm";
import Toggle from "../Toggle/Toggle";
import { getProfile } from "../../redux/actions/profileActions";
import apiCall from "../../utils/api";

// eslint-disable-next-line
const esiInfo = `An ESI ID number, sometimes also referred to as an ESID, is an "Electric Service Identifier." This 17- or 22-digit number found on your electric bill is a unique number, which is assigned to every meter connected to a home or business in the Lone Star State.`;

const enum fieldTypes {
	text = "text",
	number = "number",
	dropdown = "dropdown",
	checkbox = "checkbox",
	autocomplete = "autocomplete",
	custom = "custom"
}

function offset(el: HTMLElement) {
	const box = el.getBoundingClientRect();
	const docElem = document.documentElement;
	return {
		top: box.top + window.pageYOffset - docElem.clientTop,
		left: box.left + window.pageXOffset - docElem.clientLeft
	};
}

function InstallAgreementForm() {
	const mainAccordionTabs = useSelector(({ installAgreement }: RootState) => installAgreement.mainAccordionTabs) || [];
	const solarRoofAccordion = useSelector(({ installAgreement }: RootState) => installAgreement.solarRoofAccordion) || [];
	const validationErrors = useSelector(({ installAgreement }: RootState) => installAgreement.validationErrors) || {};
	const showDraftSubmission = useSelector(({ installAgreement }: RootState) => installAgreement.showDraftSubmission);
	const draftData = useSelector(({ installAgreement }: RootState) => installAgreement.draftData);
	const submitting = useSelector(({ installAgreement }: RootState) => installAgreement.submitting);
	const installAgreementState = useSelector(({ installAgreement }: RootState) => installAgreement);
	const form = useSelector(({ installAgreement }: RootState) => installAgreement.form);
	const setters = useSelector(({ general }: RootState) => general.settersList);
	const customers = useSelector(({ customerPipeline }: RootState) => customerPipeline.customerList);
	const solarAdderState = useSelector(({ installAgreement }: RootState) => installAgreement.solaradder);
	const roofAdderState = useSelector(({ installAgreement }: RootState) => installAgreement.roofadder);
	const pricing_ppw = useSelector(({ iaPriceAdjuster }: RootState) => iaPriceAdjuster?.currentPricing);
	const profile = useSelector(({ profile }: RootState) => profile.profile);
	const pricing = useSelector(({ installAgreement }: RootState) => installAgreement.pricing) || [];
	const companySelection = useSelector(({ installAgreement }: RootState) => installAgreement.company);
	const currentDesign = useSelector(({ auroraProjects }: RootState) => auroraProjects.currentDesign);

	const dispatch = useDispatch();

	const [autocompleteToggle, setAutocompleteToggle] = useState<boolean>(true);
	const [solarAdders, setSolarAdders] = useState<JSX.Element[]>([]);
	const [roofAdders, setRoofAdders] = useState<JSX.Element[]>([]);
	const [auroraSolarAdders, setAuroraSolarAdders] = useState<object>([]);
	const [auroraRoofAdders, setAuroraRoofAdders] = useState<object>([]);
	const [markets, setMarkets] = useState<SelectOption[]>([]);


	const currentMarketPricing = pricing.find(price => price.market === form?.market as string);
	useEffect(() => {
		const updatedSolarAdders = createAdders(dropDownData.solar_adder, "solaradder", submitting, validationErrors);
		if (updatedSolarAdders) {
			setSolarAdders(updatedSolarAdders);
			updateSolarAdders(updatedSolarAdders);
		}

		const updatedRoofAdders = createAdders(dropDownData.roof_adder, "roofadder", submitting, validationErrors);
		if (updatedRoofAdders) {
			setRoofAdders(updatedRoofAdders);
			updateRoofAdders(updatedRoofAdders);
		}
	}, [currentMarketPricing, solarAdderState, roofAdderState, companySelection]);

	useEffect(() => {
		isRoofData.roofing_color = {
			controlId: "roofing_color",
			label: "Roofing Color",
			defaultValue: "Roofing Color",
			dropdownData: createSelectData(form.roof_type && form.state ? dropDownData.roofingColors[form.state as string][form.roof_type as string] : []),
			type: fieldTypes.dropdown,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("roofing_color")
		};
	}, [form.roof_type, form.state]);

	useEffect(() => { createMarketDropdown(); }, [form.company]);

	useEffect(() => {
		dispatch(getProfile());
	}, []);

	useEffect(() => {
		if (Object.keys(auroraSolarAdders).length > 0) {
			Object.keys(auroraSolarAdders).forEach(key => {
				if (key === "undefined") {
					delete auroraSolarAdders[key];
				}
			});
			if (solarAdderState === undefined || Object.keys(solarAdderState).length === 0) {
				dispatch(setSolarAdder(auroraSolarAdders));
			}
			else {
				if (Object.keys(auroraSolarAdders).length === Object.keys(solarAdderState).length) {
					for (const key in auroraSolarAdders) {
						if (auroraSolarAdders[key].defaultPricePer != solarAdderState[key]["defaultPricePer"]) {
							dispatch(setSolarAdder(auroraSolarAdders));
							break;
						}
					}
				}
			}
		}
	}, [auroraSolarAdders]);
	useEffect(() => {
		if (Object.keys(auroraRoofAdders).length > 0) {
			Object.keys(auroraRoofAdders).forEach(key => {
				if (key === "undefined") {
					delete auroraRoofAdders[key];
				}
			});
			if (roofAdderState === undefined || Object.keys(roofAdderState).length === 0) {
				dispatch(setRoofAdder(auroraRoofAdders));
			}
			else {
				if (Object.keys(auroraRoofAdders).length === Object.keys(roofAdderState).length) {
					for (const key in auroraRoofAdders) {
						if (auroraRoofAdders[key].defaultPricePer != roofAdderState[key]["defaultPricePer"]) {
							dispatch(setRoofAdder(auroraRoofAdders));
							break;
						}
					}
				}
			}
		}
	}, [auroraRoofAdders]);

	const shouldTabOpen = (validationKeys: string[]) =>
		!!Object.keys(validationErrors).find(key => validationKeys.includes(key));

	useEffect(() => {
		if (profile?.market && markets.find(market => profile.market === market.key)) {
			dispatch(setFormValue({ ["market"]: profile?.market }));
		}
	}, [profile, markets]);

	// Open accordions with errors
	useEffect(() => {
		if (Object.keys(validationErrors).length) {
			const tabsToOpen: Array<string> = [...mainAccordionTabs];
			if (shouldTabOpen(["homeowner", "cohomeowner", "phone", "email", "cohomeowner_email"])) tabsToOpen.push("0");
			if (shouldTabOpen(["street", "city", "state", "zip", "county"])) tabsToOpen.push("1");
			if (shouldTabOpen(["utility_company", "utility_account_number", "utility_meter_number"])) tabsToOpen.push("2");
			if (shouldTabOpen(["business_phone", "business_email", "business_address", "annualSavings", "annualUsage"])) tabsToOpen.push("5");
			dispatch(setMainAccordionTabs([...new Set(tabsToOpen)]));

			const solarRoofTabsToOpen: Array<string> = [...solarRoofAccordion];
			if (shouldTabOpen(["solar_size", "panel_type", "inverter_type"])) solarRoofTabsToOpen.push("0");
			if (Object.keys(validationErrors).includes("solaradder") || Object.keys(validationErrors).includes("solar_checkbox")) solarRoofTabsToOpen.push("0");
			if (shouldTabOpen(["roof_size", "roof_type", "roofing_color"])) solarRoofTabsToOpen.push("1");
			if (Object.keys(validationErrors).includes("roofadder") || Object.keys(validationErrors).includes("roof_checkbox")) solarRoofTabsToOpen.push("1");

			dispatch(setSolarRoofAccordion([...new Set(solarRoofTabsToOpen)]));
		}
	}, [validationErrors]);

	useEffect(() => {
		// Set the PPW once it's obtained from the API
		dispatch(setFormValue({ ["ppw"]: pricing_ppw?.default_base_price_per_watt }));
		if (currentDesign && currentDesign.price_per_watt != undefined) {
			dispatch(setFormValue({ ["ppw"]: currentDesign.price_per_watt }));
		}
	}, [pricing_ppw]);

	// Scroll to first error
	useEffect(() => {
		if (Object.keys(validationErrors).length) {
			const errorPositions = Object.keys(validationErrors).map(key => {
				if (key === "solaradder" || key === "roofadder") {
					return Object.keys(validationErrors[key]).map(subkey => {
						const element = document.getElementById(`${subkey.substring(0, subkey.indexOf("_"))}_group`);
						if (element) {
							const topOffset = offset(element).top;
							if (topOffset > 0) return topOffset;
						}
					});
				} else {
					const element = document.getElementById(`${{ ...fieldData, ...isSolarFieldData, ...isRoofData }[key]?.controlId}_group` as string);
					if (element) {
						const topOffset = offset(element).top;
						if (topOffset > 0) return topOffset;
					}
				}
			});
			const minErrorPosition = Math.min(...errorPositions.flat().filter(pos => !!pos) as number[]);
			if (minErrorPosition > 0) window.scrollTo({ top: minErrorPosition, behavior: "smooth" });
		}
	}, [validationErrors]);

	// Get setters
	useEffect(() => {
		dispatch(getSettersList());
	}, []);

	// Get rep pricing
	useEffect(() => {
		const pricePerWattPermission = "portal/installation-agreement/price-per-watt";
		const permissions = localStorage.getItem("permissions");
		const permissionsParsed = permissions ? JSON.parse(permissions) : null;

		const authorized = !!permissionsParsed?.find((perm: UserPermission) => perm.resource_list.includes(pricePerWattPermission));

		if (authorized) dispatch(getSalesRepPricingInfo(localStorage.getItem("email") || ""));
	}, []);

	useEffect(() => {
		const currentUserName = localStorage.getItem("email");
		const customerPipelinePermission = "portal/customer-pipeline";
		const permissions = localStorage.getItem("permissions");
		const permissionsParsed = permissions ? JSON.parse(permissions) : null;

		const authorized = !!permissionsParsed?.find((perm: UserPermission) => perm.resource_list.includes(customerPipelinePermission));

		if (currentUserName && authorized) {
			dispatch(getCustomers(currentUserName));
		}
	}, []);

	const createInputState = (field: string, defaultValue?: string) => ({
		value: form[field] || defaultValue || "",
		onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
			dispatch(setFormValue({ [field]: e.target.value }));
		}
	});

	const clearIncentives = () => {
		dispatch(removeFormValue("eto_swr"));
		dispatch(removeFormValue("odoe_lmi"));
	};

	const createInputStateWithClearUtility = (field: string, defaultValue?: string) => {
		const { value, onChange } = createInputState(field, defaultValue);
		const clearUtility = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
			if (e.target.value === "Oregon") {
				(fieldData as TextInputProps).utility_company.onChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
			}
		};
		return {
			value,
			onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
				clearUtility(e);
				clearIncentives();
				onChange(e);
			}
		};
	};

	const createInputStateWithClearIncentives = (field: string, defaultValue?: string) => {
		const { value, onChange } = createInputState(field, defaultValue);
		return {
			value,
			onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
				clearIncentives();
				onChange(e);
			}
		};
	};


	const createCheckboxState = (field: string) => ({
		checked: form[field],
		onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.id === "solar_checkbox" && e.target.checked === false) {
				dispatch(removeFormValue("solar_size"));
				dispatch(removeFormValue("panel_type"));
				dispatch(removeFormValue("inverter_type"));
			}
			if (e.target.id === "roof_checkbox" && e.target.checked === false) {
				dispatch(removeFormValue("roof_size"));
				dispatch(removeFormValue("roof_type"));
				dispatch(removeFormValue("roofing_color"));
			}
			dispatch(setFormValue({ [field]: e.target.checked }));
		}
	});

	const createPromotionCheckboxState = (field: string) => {
		return {
			checked: form[field],
			onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
				if (e.target.checked) {
					dispatch(setFormValue({ [field]: e.target.checked, [`${field}_name`]: e.target.labels ? e.target.labels[0].innerHTML : "" }));
				} else {
					dispatch(removeFormValue(field));
					dispatch(removeFormValue(`${field}_name`));
				}
			}
		};
	};

	const createCheckboxStateWithClearDiscountPercent = (field: string) => {
		const { checked, onChange } = createCheckboxState(field);
		return {
			checked,
			onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
				(fieldData as TextInputProps).discount_percentage.onChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
				(fieldData as TextInputProps).discount_value.onChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
				onChange(e);
			}
		};
	};

	const createRadioStateWithClear = (field: string) => ({
		value: form[field],
		onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
			dispatch(setFormValue({ [field]: e.target.id }));
		},
		onClick: (e: React.ChangeEvent<HTMLInputElement>) => {
			if (e.target.checked && form[field] === e.target.id) {
				dispatch(setFormValue({ [field]: "" }));
			}
		}
	});

	const createRadioStateWithClearDiscount = (field: string) => {
		const { value, onChange, onClick } = createRadioStateWithClear(field);
		return {
			value,
			onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
				if (e.target.id === "value") {
					(fieldData as TextInputProps).discount_percentage.onChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
				} else if (e.target.id === "percentage") {
					(fieldData as TextInputProps).discount_value.onChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
				}
				onChange(e);
			},
			onClick: (e: React.ChangeEvent<HTMLInputElement>) => {
				(fieldData as TextInputProps).discount_percentage.onChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
				(fieldData as TextInputProps).discount_value.onChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
				onClick(e);
			}
		};
	};

	const createAdders = (adders: AdderOption[], type: string, disabled = false, validation: { [key: string]: string | number | { [key: string]: string } }) => {
		let company = localStorage.getItem("company") || "Infinity Solar USA";
		if (companySelection != "" && companySelection != undefined) {
			company = companySelection;
		}
		const adderPricing = currentMarketPricing ? currentMarketPricing[type] : {};
		const solarAdderPricing = pricing[0] ? pricing[0].solaradder : {};
		const roofAdderPricing = pricing[0] ? pricing[0].roofadder : {};
		const approvedCompanies = [
			"Infinity Solar USA", "Caliber", "Caliber Group - JC", "Caliber Group MC", "Caliber Group - KB Solar", "Caliber Group - SB",
			"Caliber Group - Summit", "Flex Power", "Loyal Energy", "Sustainable Solutions", "Solar Outlet LLC",
			"The Solar Team", "Hawksnest Security Inc.", "One Nation Energy", "Eave"
		];

		return adders.map((option: AdderOption) => {
			let defaultPricePer = adderPricing[option.value] ? adderPricing[option.value].base as number : "-";
			if (solarAdderPricing != undefined) {
				if (solarAdderPricing[option.value] != undefined) {
					defaultPricePer = solarAdderPricing[option.value].base as number;
				}
			}
			if (roofAdderPricing != undefined) {
				if (roofAdderPricing[option.value] != undefined) {
					defaultPricePer = roofAdderPricing[option.value].base as number;
				}
			}

			const defaultValue = {
				checked: false,
				amount: option.readOnly ? option.amount : "",
				readOnly: option.readOnly,
				multiplier: option.multiplier ? option.multiplier : 1,
				...(approvedCompanies.includes(company) ? { defaultPricePer: defaultPricePer } : {})
			};

			const addersState = installAgreementState[type];
			const value = addersState && addersState[option.value] || defaultValue;
			if (currentDesign != undefined) {
				if (currentDesign.adders != undefined) {
					const adders_aurora = JSON.stringify(currentDesign.adders);
					const addersAurora = JSON.parse(adders_aurora);
					if (type == "solaradder") {
						if (solarAdderState === undefined || Object.keys(solarAdderState).length === 0) {
							if (addersAurora.solaradder != undefined) {
								if (option.value in addersAurora.solaradder) {
									value.checked = JSON.parse(adders_aurora).solaradder[option.value].checked;
								}
							}
						}
					}
					else if (type == "roofadder") {
						if (roofAdderState === undefined || Object.keys(roofAdderState).length === 0) {
							if (addersAurora.roofadder != undefined) {
								if (option.value in addersAurora.roofadder) {
									value.checked = JSON.parse(adders_aurora).roofadder[option.value].checked;
								}
							}
						}
					}
				}
			}
			const setValue = (value) => {
				dispatch(setAdderValue({ type, data: { [option.value]: value } }));
			};

			const optionCompany = option.company || "";
			const defaultPricePerProp = approvedCompanies.includes(company) ? { defaultPricePer } : {};
			const adder = <Adder
				option={option}
				type={type}
				key={`${option.label}_${option.value}`}
				disabled={disabled}
				validation={validation[type] as { [key: string]: string }}
				value={value}
				setValue={setValue}
				id={`${option.value}`}
				{...defaultPricePerProp}
			/>;

			if (optionCompany === "" || optionCompany.indexOf(company) !== -1) {
				return adder;
			} else {
				return <></>;
			}
		});
	};

	const updateSolarAdders = data => {
		const solarAdders = {};
		for (const key in data) {
			let alternative = false;
			if (data[key].props) {
				const solarAdder = {
					[data[key].props.id]: {
						checked: data[key].props.value?.checked ? data[key].props.value.checked : false,
						amount: data[key].props.value?.amount ? data[key].props.value.amount : "",
						readOnly: data[key].props.value?.readOnly ? data[key].props.value.readOnly : false,
						multiplier: data[key].props.value?.multiplier ? data[key].props.value.multiplier : 1,
						defaultPricePer: data[key].props.defaultPricePer ? data[key].props.defaultPricePer : "-",
					},
				};
				if (currentDesign != undefined) {
					const solar_object = JSON.parse(JSON.stringify(currentDesign.adders))?.solaradder;
					if (solar_object != undefined) {
						const currentAdder = solar_object[data[key].props.id];
						if (currentAdder != undefined) {
							let amount = "";
							if (data[key].props.value?.amount === "") {
								amount = currentAdder.amount;
							}
							else {
								amount = data[key].props.value.amount;
							}
							const solarAdder = {
								[data[key].props.id]: {
									checked: data[key].props.value?.checked ? data[key].props.value.checked : false,
									amount: amount,
									readOnly: data[key].props.value?.readOnly ? data[key].props.value.readOnly : false,
									multiplier: data[key].props.value?.multiplier ? data[key].props.value.multiplier : 1,
									defaultPricePer: data[key].props.defaultPricePer ? data[key].props.defaultPricePer : "-",
								},
							};
							if (solarAdder[data[key].props.id].checked) {
								solarAdders[data[key].props.id] = solarAdder[data[key].props.id];
							}
							alternative = true;
						}
					}
				}
				if (!alternative && solarAdder[data[key].props.id].checked) {
					solarAdders[data[key].props.id] = solarAdder[data[key].props.id];
				}
			}
		}
		setAuroraSolarAdders(solarAdders);
	};

	const updateRoofAdders = data => {
		const roofAdders = {};
		for (const key in data) {
			let alternative = false;
			if (data[key].props) {
				const roofAdder = {
					[data[key].props.id]: {
						checked: data[key].props.value?.checked ? data[key].props.value.checked : false,
						amount: data[key].props.value?.amount ? data[key].props.value.amount : "",
						readOnly: data[key].props.value?.readOnly ? data[key].props.value.readOnly : false,
						multiplier: data[key].props.value?.multiplier ? data[key].props.value.multiplier : 1,
						defaultPricePer: data[key].props.defaultPricePer ? data[key].props.defaultPricePer : "-",
					},
				};
				if (currentDesign != undefined) {
					const roofObject = JSON.parse(JSON.stringify(currentDesign.adders))?.roofadder;
					if (roofObject != undefined) {
						const currentAdder = roofObject[data[key].props.id];
						if (currentAdder != undefined) {
							let amount = "";
							if (data[key].props.value?.amount === "") {
								amount = currentAdder.amount;
							}
							else {
								amount = data[key].props.value.amount;
							}
							const roofAdder = {
								[data[key].props.id]: {
									checked: data[key].props.value?.checked ? data[key].props.value.checked : false,
									amount: amount,
									readOnly: data[key].props.value?.readOnly ? data[key].props.value.readOnly : false,
									multiplier: data[key].props.value?.multiplier ? data[key].props.value.multiplier : 1,
									defaultPricePer: data[key].props.defaultPricePer ? data[key].props.defaultPricePer : "-",
								},
							};
							if (roofAdder[data[key].props.id].checked) {
								roofAdders[data[key].props.id] = roofAdder[data[key].props.id];
							}
							alternative = true;
						}
					}
				}
				if (!alternative && roofAdder[data[key].props.id].checked) {
					roofAdders[data[key].props.id] = roofAdder[data[key].props.id];
				}
			}
		}
		setAuroraRoofAdders(roofAdders);
	};

	const clearAdderState = type => {
		dispatch(clearAdders(type));
	};

	const clearAdderCheckbox = type => {
		if (type === "solar") {
			(fieldData as TextInputProps).solar_adder_checkbox.onChange({ target: { checked: false } });
		} else if (type === "roof") {
			(fieldData as TextInputProps).roof_adder_checkbox.onChange({ target: { checked: false } });
		}
	};

	const createAdderCheckboxState = (adderType, field) => {
		const { checked, onChange } = createCheckboxState(field);
		const onChangeWClearState = (e) => {
			if (!e.target.checked) {
				clearAdderState(adderType);
			}
			onChange(e);
		};
		return { checked, onChange: onChangeWClearState };
	};

	const createTypeCheckbox = (fieldType, field) => {
		const { checked, onChange } = createCheckboxState(field);
		const onChangeWClearState = (e) => {
			onChange(e);
			if (!e.target.checked) {
				clearAdderState(fieldType);
				clearAdderCheckbox(fieldType);
			}
		};
		return { checked, onChange: onChangeWClearState };
	};

	function createSelectData<T>(data: T[]) {
		return data ? data.map(i => ({ key: i as string, value: i as string, label: i as string })) : [];
	}

	const createSettersDropdownData = () => {
		const settersDropdownOptions = setters ? createSelectData(setters) : [];
		settersDropdownOptions.push({ key: "Other", value: "other", label: "Other" });
		return settersDropdownOptions;
	};

	function createMarketDropdown() {
		if (form.company === undefined || form.company === null){
			return;
		}

		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/ia-price-adjuster/markets?company=${form.company}`,
			method: "GET"
		}).then(res => {
			setMarkets(res.markets.map(i => ({ key: i, value: i, label: i })));
		}).catch(res => {
			console.error(res);
		});
	}

	const mapStateAbbreviation = {
		AZ: "Arizona",
		TX: "Texas",
		WA: "Washington",
		OR: "Oregon",
		ID: "Idaho",
		NM: "New Mexico",
	};

	const getCustomerDetails = (customer: Customer) => {
		const utilityCompany = customer.state && dropDownData.utilityCompany[customer.state] ? dropDownData.utilityCompany[customer.state] ? dropDownData.utilityCompany[customer.state] : customer.utility_company?.toUpperCase() === "PGE" ? "Portland General Electric" : null : customer.utility_company;
		const formValues = {
			...form,
			homeowner: customer.name,
			zip: customer.zip,
			street: customer.address,
			city: customer.city,
			state: customer.state ? mapStateAbbreviation[customer.state.toUpperCase()] : "",
			phone: customer.phone,
			email: customer.email,
			customer_id: customer.customer_id,
			...(utilityCompany ? { utilityCompany } : {})
		};
		if (typeof customer.name === "string") {
			dispatch(setOriginalHomeowner(customer.name));
		}
		dispatch(setForm(formValues));
	};

	const fieldData: { [key: string]: TextInputProps | SelectInputProps | RadioInputField | AutoCompleteProps<Customer> } = {
		homeowner: {
			label: "Homeowner",
			controlId: "homeowner",
			placeholder: "Homeowner",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			onItemClick: getCustomerDetails,
			filterKeys: ["name"],
			autocompleteContainerClasses: "homeowner-autocomplete",
			list: customers?.map(c => ({ ...c, name: `${c.first_name ? c.first_name : ""} ${c.last_name ? c.last_name : ""}` })) || [],
			...createInputState("homeowner")
		} as AutoCompleteProps<Customer>,
		cohomeowner: {
			label: "Co-Homeowner",
			controlId: "cohomeowner",
			placeholder: "Co-Homeowner",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("cohomeowner")
		} as TextInputProps,
		street: {
			label: "Street",
			controlId: "street",
			placeholder: "Street",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("street")
		} as TextInputProps,
		city: {
			controlId: "city",
			label: "City",
			placeholder: "City",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("city")
		} as TextInputProps,
		state: {
			controlId: "state",
			label: "State",
			defaultValue: "State",
			dropdownData: createSelectData(dropDownData.states),
			type: fieldTypes.dropdown,
			validation: validationErrors,
			disabled: submitting,
			...createInputStateWithClearUtility("state")
		} as SelectInputProps,
		zip: {
			controlId: "zip",
			label: "Zip code",
			placeholder: "Zip code",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("zip")
		} as TextInputProps,
		phone: {
			controlId: "phone",
			label: "Phone Number",
			textType: "tel",
			placeholder: "Phone Number",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("phone")
		} as TextInputProps,
		email: {
			controlId: "email",
			label: "Email",
			placeholder: "Email",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("email")
		} as TextInputProps,
		cohomeowner_email: {
			controlId: "cohomeowner_email",
			label: "Co-Homeowner Email",
			placeholder: "Email",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("cohomeowner_email")
		} as TextInputProps,
		HOA_checkbox: {
			controlId: "HOA_checkbox",
			label: "HOA",
			type: fieldTypes.checkbox,
			validation: validationErrors,
			disabled: submitting,
			...createCheckboxState("HOA_checkbox")
		} as CheckboxInputField,
		HOA_contact: {
			controlId: "HOA_contact",
			label: "HOA Contact",
			placeholder: "HOA Contact",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("HOA_contact")
		} as TextInputProps,
		program: {
			controlId: "program",
			label: "Program",
			defaultValue: "D2D",
			dropdownData: createSelectData(dropDownData.program),
			type: fieldTypes.dropdown,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("program")
		} as SelectInputProps,
		lead_program: {
			controlId: "lead_program",
			label: "Company Lead",
			defaultValue: "Outside Sales",
			dropdownData: createSelectData(dropDownData.lead_program),
			type: fieldTypes.dropdown,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("lead_program")
		} as SelectInputProps,
		utility_account_number: {
			controlId: "utility_account_number",
			label: "Utility Account Number",
			placeholder: "Utility Account Number",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("utility_account_number")
		} as TextInputProps,
		utility_meter_number: {
			controlId: "utility_meter_number",
			label: "Utility Meter Number",
			placeholder: "Utility Meter Number",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("utility_meter_number")
		} as TextInputProps,
		esi: {
			controlId: "esi",
			// eslint-disable-next-line
			label: <LabelTooltip label={"ESI ID \u2139"} tooltipText={esiInfo} />,
			placeholder: "ESI ID",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("esi")
		} as TextInputProps,
		setter: {
			controlId: "setter",
			label: "Setter",
			type: fieldTypes.dropdown,
			dropdownData: createSettersDropdownData(),
			validation: validationErrors,
			disabled: submitting,
			...createInputState("setter")
		} as SelectInputProps,
		market: {
			controlId: "market",
			label: "Market",
			defaultValue: "Market",
			dropdownData: markets,
			type: fieldTypes.dropdown,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("market")
		} as SelectInputProps,
		payment_option: {
			controlId: "payment_option",
			label: "Loan Options",
			defaultValue: "Loan Options",
			dropdownData: createSelectData(dropDownData.pricingOptions),
			type: fieldTypes.dropdown,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("payment_option")
		} as SelectInputProps,
		gift_card: {
			controlId: "gift_card",
			label: "Gift Card",
			placeholder: "Gift Card",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("gift_card")
		} as TextInputProps,
		seasonal_promotion: {
			controlId: "seasonal_promotion",
			label: dropDownData.seasonal_promotion,
			type: fieldTypes.checkbox,
			validation: validationErrors,
			disabled: submitting,
			...createPromotionCheckboxState("seasonal_promotion")
		} as CheckboxInputField,
		gtr_promotion: {
			controlId: "gtr_promotion",
			label: dropDownData.gtr_promotion,
			type: fieldTypes.checkbox,
			validation: validationErrors,
			disabled: submitting,
			...createPromotionCheckboxState("gtr_promotion")
		} as CheckboxInputField,
		solar_checkbox: {
			controlId: "solar_checkbox",
			label: "Solar",
			type: fieldTypes.checkbox,
			validation: validationErrors,
			disabled: submitting,
			...createTypeCheckbox("solar", "solar_checkbox")
		} as CheckboxInputField,
		roof_checkbox: {
			controlId: "roof_checkbox",
			label: "Roof",
			type: fieldTypes.checkbox,
			disabled: submitting,
			validation: validationErrors,
			...createTypeCheckbox("roof", "roof_checkbox")
		} as CheckboxInputField,
		discount_percentage: {
			controlId: "discount_percentage",
			label: "Discount %",
			placeholder: "Discount %",
			type: fieldTypes.text,
			inputMode: "decimal",
			prefix: "%",
			validation: validationErrors,
			disabled: submitting,
			...createInputState("discount_percentage")
		} as TextInputProps,
		discount_value: {
			controlId: "discount_value",
			label: "Discount Value",
			placeholder: "Discount Value",
			inputMode: "decimal",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			groupClasses: "checkbox-input",
			...createInputState("discount_value")
		} as TextInputProps,
		discount_type: {
			controlId: "discount_type",
			options: [
				{
					label: "Percentage",
					id: "percentage"
				},
				{
					label: "Value",
					id: "value"
				}
			],
			disabled: submitting,
			...createRadioStateWithClearDiscount("discount_type")
		} as RadioInputField,
		other_checkbox: {
			controlId: "other_checkbox",
			label: "Other",
			type: fieldTypes.checkbox,
			disabled: submitting,
			validation: validationErrors,
			...createCheckboxStateWithClearDiscountPercent("other_checkbox")
		} as CheckboxInputField,
		solar_adder_checkbox: {
			controlId: "solar_adder_checkbox",
			label: "Solar Adders",
			type: fieldTypes.checkbox,
			validation: validationErrors,
			disabled: submitting,
			...createAdderCheckboxState("solar", "solar_adder_checkbox")
		} as CheckboxInputField,
		roof_adder_checkbox: {
			controlId: "roof_adder_checkbox",
			label: "Roof Adders",
			type: fieldTypes.checkbox,
			validation: validationErrors,
			disabled: submitting,
			...createAdderCheckboxState("roof", "roof_adder_checkbox")
		} as CheckboxInputField,
		eto_swr: {
			controlId: "eto_swr",
			options: [
				{
					label: "ETO",
					id: "eto"
				},
				{
					label: "SWR",
					id: "swr"
				}
			],
			disabled: submitting,
			...createRadioStateWithClear("eto_swr")
		} as RadioInputField,
		odoe_lmi: {
			controlId: "odoe_lmi",
			options: [
				// {
				// 	label: "ODOE",
				// 	id: "odoe"
				// },
				// {
				// 	label: "LMI",
				// 	id: "lmi"
				// }
			],
			disabled: submitting,
			...createRadioStateWithClear("odoe_lmi")
		} as RadioInputField,
		cancellation_verbiage: {
			controlId: "cancellation_verbiage",
			label: "Document Verbiage",
			dropdownData: [
				{
					label: "Cancel after Site Survey",
					value: "site_survey",
					key: "site_survey"
				},
				{
					label: "Cancel after CAD",
					value: "cad_approved",
					key: "cad_approved"
				}
			],
			type: fieldTypes.dropdown,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("cancellation_verbiage")
		} as SelectInputProps,
		ppw: {
			controlId: "ppw",
			label: "Price Per Watt",
			placeholder: "0.00",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("ppw")
		} as TextInputProps,
		business_address: {
			controlId: "business_address",
			label: "Business Address",
			placeholder: "Business Address",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("business_address")
		} as TextInputProps,
		business_email: {
			controlId: "business_email",
			label: "Business Email",
			placeholder: "Business Email",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("business_email")
		} as TextInputProps,
		business_phone: {
			controlId: "business_phone",
			label: "Business Phone",
			placeholder: "Business Phone",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("business_phone")
		} as TextInputProps,
		annualSavings: {
			controlId: "annualSavings",
			label: "First Year Savings",
			placeholder: "First Year  Savings",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("annualSavings")
		} as TextInputProps,
		firstYearProduction: {
			controlId: "firstYearProduction",
			label: "Annual Usage",
			placeholder: "Annual Production",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("firstYearProduction")
		} as TextInputProps,
		monthlyPayment: {
			controlId: "monthly_payment",
			label: "Monthly Payment",
			placeholder: "Monthly Payment",
			type: fieldTypes.text,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("monthly_payment")
		} as TextInputProps,
		tax_preperation: {
			controlId: "tax_preperation",
			label: "Tax Preparation",
			validation: validationErrors,
			disabled: submitting,
			...createInputState("tax_preperation")
		} as CheckboxInputField,
	};

	fieldData.county = {
		controlId: "county",
		label: "County",
		defaultValue: "County",
		dropdownData: fieldData.state.value ? createSelectData(dropDownData.counties[fieldData.state.value as string]) : [],
		type: fieldTypes.dropdown,
		validation: validationErrors,
		disabled: submitting,
		...createInputState("county")
	} as SelectInputProps;

	fieldData.utility_company = {
		controlId: "utility_company",
		label: "Utility Company",
		placeholder: "Utility Company",
		defaultValue: "Utility Company",
		type: fieldTypes.custom,
		dropdownData: fieldData.state.value ? createSelectData(dropDownData.utilityCompany[fieldData.state.value as string]) : [],
		validation: validationErrors,
		disabled: submitting,
		...createInputStateWithClearIncentives("utility_company")
	} as SelectInputProps;

	fieldData.seasonal_promotion_name = {
		value: (fieldData.seasonal_promotion as CheckboxInputField).checked ? dropDownData.seasonal_promotion : undefined
	};

	fieldData.gtr_promotion_name = {
		value: (fieldData.gtr_promotion as CheckboxInputField).checked ? dropDownData.gtr_promotion : undefined
	};

	const isSolarFieldData = {
		solar_size: {
			controlId: "solar_size",
			label: "System Size",
			placeholder: "System Size",
			type: fieldTypes.text,
			validation: validationErrors,
			prefix: "kw",
			disabled: submitting,
			...createInputState("solar_size")
		},
		panel_type: {
			controlId: "panel_type",
			label: "Solar Modules",
			defaultValue: "Solar Modules",
			dropdownData: createSelectData(dropDownData.panels),
			type: fieldTypes.dropdown,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("panel_type")
		},
		eave_panel_type: {
			controlId: "panel_type",
			label: "Solar Modules",
			defaultValue: "Solar Modules",
			dropdownData: createSelectData(dropDownData.eave_panels),
			type: fieldTypes.dropdown,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("panel_type")
		},
		inverter_type: {
			type: fieldTypes.custom,
			controlId: "inverter_type",
			label: "Inverters",
			defaultValue: "Inverters",
			dropdownData: createSelectData(dropDownData.inverters),
			validation: validationErrors,
			disabled: submitting,
			...createInputState("inverter_type")
		},
		nm_inverter_type: {
			type: fieldTypes.custom,
			controlId: "nm_inverter_type",
			label: "Inverters",
			defaultValue: "Inverters",
			dropdownData: createSelectData(dropDownData.nm_inverters),
			validation: validationErrors,
			disabled: submitting,
			...createInputState("inverter_type")
		},
	};

	const isRoofData = {
		roof_size: {
			controlId: "roof_size",
			label: "Roofing Size",
			placeholder: "Roofing Size",
			type: fieldTypes.text,
			validation: validationErrors,
			prefix: "sqft",
			disabled: submitting,
			...createInputState("roof_size")
		},
		roof_type: {
			controlId: "roof_type",
			label: "Roofing Material",
			defaultValue: "Roofing Material",
			dropdownData: createSelectData(dropDownData.roofingMaterial),
			type: fieldTypes.dropdown,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("roof_type")
		},
		roofing_color: {
			controlId: "roofing_color",
			label: "Roofing Color",
			defaultValue: "Roofing Color",
			dropdownData: createSelectData(form.roof_type && form.state ? dropDownData.roofingColors[form.state as string][form.roof_type as string] : []),
			type: fieldTypes.dropdown,
			validation: validationErrors,
			disabled: submitting,
			...createInputState("roofing_color")
		}


	};


	const accordionItemOnClick = (eventKey, accordionTabsOpen, setAccordionTabsOpen) => {
		if (!accordionTabsOpen.includes(eventKey)) {
			dispatch(setAccordionTabsOpen([...accordionTabsOpen, eventKey]));
		} else {
			dispatch(setAccordionTabsOpen(accordionTabsOpen.filter(item => item !== eventKey)));
		}
	};

	const onFormSubmit = async () => {
		const formData = createFormElements(form, solarAdderState, roofAdderState);
		const validationErrors = await validateForm(formData, pricing_ppw);
		dispatch(setValidationErrors(validationErrors));
		if (!Object.values(validationErrors).length) {
			submitForm(formData, dispatch);
		}
	};

	const onPreviewSubmit = async (e) => {
		e.preventDefault();
		const formData = createFormElements(form, solarAdderState, roofAdderState);
		const validationErrors = await validateForm(formData, pricing_ppw);
		dispatch(setValidationErrors(validationErrors));
		if (!Object.values(validationErrors).length) {
			submitDryRun(formData, dispatch);
		}
	};

	const toggleOptions = [
		<div key="2">
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard2-check" viewBox="0 0 16 16">
				<path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z" />
				<path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z" />
				<path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3Z" />
			</svg>
		</div>,
		<div key="1">
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-input-cursor-text" viewBox="0 0 16 16">
				<path fillRule="evenodd" d="M5 2a.5.5 0 0 1 .5-.5c.862 0 1.573.287 2.06.566.174.099.321.198.44.286.119-.088.266-.187.44-.286A4.165 4.165 0 0 1 10.5 1.5a.5.5 0 0 1 0 1c-.638 0-1.177.213-1.564.434a3.49 3.49 0 0 0-.436.294V7.5H9a.5.5 0 0 1 0 1h-.5v4.272c.1.08.248.187.436.294.387.221.926.434 1.564.434a.5.5 0 0 1 0 1 4.165 4.165 0 0 1-2.06-.566A4.561 4.561 0 0 1 8 13.65a4.561 4.561 0 0 1-.44.285 4.165 4.165 0 0 1-2.06.566.5.5 0 0 1 0-1c.638 0 1.177-.213 1.564-.434.188-.107.335-.214.436-.294V8.5H7a.5.5 0 0 1 0-1h.5V3.228a3.49 3.49 0 0 0-.436-.294A3.166 3.166 0 0 0 5.5 2.5.5.5 0 0 1 5 2z" />
				<path d="M10 5h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4v1h4a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4v1zM6 5V4H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v-1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h4z" />
			</svg>
		</div>
	];

	const installAgreementHtml = (
		<Form onSubmit={onPreviewSubmit}>
			<div className="ia-autocomplete-toggle-container">
				<Toggle options={toggleOptions} onChange={i => setAutocompleteToggle(i === 1 ? false : true)} />
			</div>
			<Accordion className="main-accordion" activeKey={mainAccordionTabs}>
				<Accordion.Item eventKey="0">
					<Accordion.Header onClick={() => accordionItemOnClick("0", mainAccordionTabs, setMainAccordionTabs)}>
						Contact
					</Accordion.Header>
					<Accordion.Body>
						<div className="input-container">
							{autocompleteToggle ? <AutoComplete field={fieldData.homeowner as AutoCompleteProps<Customer>} />
								:
								<TextInput field={fieldData.homeowner as TextInputProps} />
							}
							<TextInput field={fieldData.cohomeowner as TextInputProps} />
						</div>
						<div className="input-container">
							<TextInput field={fieldData.phone as TextInputProps} />
							<TextInput field={fieldData.email as TextInputProps} />
							<TextInput field={fieldData.cohomeowner_email as TextInputProps} />
						</div>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="1">
					<Accordion.Header onClick={() => accordionItemOnClick("1", mainAccordionTabs, setMainAccordionTabs)}>
						Address
					</Accordion.Header>
					<Accordion.Body>
						<div className="input-container">
							<TextInput field={fieldData.street as TextInputProps} />
							<TextInput field={fieldData.city as TextInputProps} />
						</div>
						<div className="input-container">
							<SelectInput field={fieldData.state as SelectInputProps} />
							<TextInput field={fieldData.zip as TextInputProps} />
							<SelectInput field={fieldData.county as SelectInputProps} />
						</div>
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="2">
					<Accordion.Header onClick={() => accordionItemOnClick("2", mainAccordionTabs, setMainAccordionTabs)}>
						Utility
					</Accordion.Header>
					<Accordion.Body>
						<div className="input-container">
							{(fieldData.state.value && fieldData.state.value as string in dropDownData.utilityCompany) ?
								<SelectInput field={fieldData.utility_company as SelectInputProps} />
								:
								<TextInput field={fieldData.utility_company as TextInputProps} />
							}
							<TextInput field={fieldData.utility_account_number as TextInputProps} />
							<TextInput field={fieldData.utility_meter_number as TextInputProps} />
						</div>
						{fieldData.state.value === "Texas" ? <TextInput field={fieldData.esi as TextInputProps} /> : ""}
					</Accordion.Body>
				</Accordion.Item>
				<WhenAuthorized permission={UserPermissions.promotions_input}>
					<Accordion.Item eventKey="3">
						<Accordion.Header onClick={() => accordionItemOnClick("3", mainAccordionTabs, setMainAccordionTabs)}>
							Promotions
						</Accordion.Header>
						<Accordion.Body>
							<div className="promotions-container">
								<CheckboxInput field={fieldData.seasonal_promotion as CheckboxInputField} />
								{/* <CheckboxInput field={fieldData.gtr_promotion as CheckboxInputField} />
								<CheckboxInput field={fieldData.other_checkbox as CheckboxInputField} />
								{(fieldData.other_checkbox as CheckboxInputField).checked ?
										<div className="discount-container">
											<RadioInputGroup field={fieldData.discount_type as RadioInputField} />
											{fieldData.discount_type.value === "percentage" ?
												<TextInput field={fieldData.discount_percentage as TextInputProps} />
												: <></>
											}
											{fieldData.discount_type.value === "value" ?
												<TextInput field={fieldData.discount_value as TextInputProps} />
												: <></>
											}
										</div>
										:
										<></>
									} */}
							</div>
						</Accordion.Body>
					</Accordion.Item>
				</WhenAuthorized>
				<WhenAuthorized permission={UserPermissions.ppw_input}>
					<Accordion.Item eventKey="4">
						<Accordion.Header onClick={() => accordionItemOnClick("4", mainAccordionTabs, setMainAccordionTabs)}>
							Pricing
						</Accordion.Header>
						<Accordion.Body>
							<div className="pricing-container">
								<TextInput field={fieldData.ppw as TextInputProps} />
								<CheckboxInput field={fieldData.tax_preperation as CheckboxInputField} />
							</div>
						</Accordion.Body>
					</Accordion.Item>
				</WhenAuthorized>
				{fieldData.state.value === "Washington" ?
					<Accordion.Item eventKey="5">
						<Accordion.Header onClick={() => accordionItemOnClick("5", mainAccordionTabs, setMainAccordionTabs)}>
						Sales Rep Information (WA)
						</Accordion.Header>
						<Accordion.Body>
							<div className="pricing-container">
								<TextInput field={fieldData.business_address as TextInputProps} />
								<TextInput field={fieldData.business_email as TextInputProps} />
								<TextInput field={fieldData.business_phone as TextInputProps} />
								<TextInput field={fieldData.annualSavings as TextInputProps} />
								<TextInput field={fieldData.firstYearProduction as TextInputProps} />
								<TextInput field={fieldData.monthlyPayment as TextInputProps} />
							</div>
						</Accordion.Body>
					</Accordion.Item>
					:<></>
				}
			</Accordion>
			<div className="input-container HOA-container">
				<div className="text-input hoa-cancellation-container">
					<CheckboxInput field={fieldData.HOA_checkbox as CheckboxInputField} />
					{/* {(fieldData as TextInputProps).HOA_checkbox.checked ? <TextInput field={fieldData.HOA_contact as TextInputProps} /> : ""} */}
				</div>
				<div className="input-container">
					<SelectInput field={fieldData.lead_program as SelectInputProps} />
				</div>
				{fieldData.market.value === "Dallas" || fieldData.market.value === "PDX 2" || fieldData.market.value === "Albuquerque" || fieldData.market.value === "Phoenix" || fieldData.market.value === "New Mexico" ? <SelectInput field={fieldData.program as SelectInputProps} /> : ""}
				<WhenAuthorized permission={UserPermissions.after_cad_approved}>
					<SelectInput field={fieldData.cancellation_verbiage as SelectInputProps} />
				</WhenAuthorized>
			</div>
			<div className="input-container">
				<SelectInput field={fieldData.setter as SelectInputProps} />
				<SelectInput field={fieldData.market as SelectInputProps} />
			</div>
			<div className="input-container">
				<TextInput field={fieldData.gift_card as TextInputProps} />
				<SelectInput field={fieldData.payment_option as SelectInputProps} />
			</div>
			<Accordion className="solar-roof-accordion" activeKey={solarRoofAccordion}>
				<Accordion.Item eventKey="0">
					<Accordion.Header onClick={() => accordionItemOnClick("0", solarRoofAccordion, setSolarRoofAccordion)}>Solar</Accordion.Header>
					<Accordion.Body>
						<CheckboxInput field={fieldData.solar_checkbox as CheckboxInputField} />
						{
							(fieldData as TextInputProps).solar_checkbox.checked ? <>
								<div className="solar-group input-container">
									<TextInput field={isSolarFieldData.solar_size as TextInputProps} />
									{companySelection === "Eave" ? <SelectInput field={isSolarFieldData.eave_panel_type as SelectInputProps} /> : < SelectInput field={isSolarFieldData.panel_type as SelectInputProps} />}
									< div className="select-input solar-invert-container">
										{form.state === "New Mexico" ? <SelectInput field={isSolarFieldData.nm_inverter_type as SelectInputProps} /> : <SelectInput field={isSolarFieldData.inverter_type as SelectInputProps} />}
										{
											fieldData.state.value === "Oregon" ?
												<>
													<RadioInputGroup field={fieldData.eto_swr as RadioInputField} />
													<RadioInputGroup field={fieldData.odoe_lmi as RadioInputField} />
												</> :
												<></>
										}
									</div>
								</div>
								<CheckboxInput field={fieldData.solar_adder_checkbox as CheckboxInputField} />
								{(fieldData as TextInputProps).solar_adder_checkbox.checked ? solarAdders : ""}
							</> :
								<></>
						}
					</Accordion.Body>
				</Accordion.Item>
				<Accordion.Item eventKey="1">
					<Accordion.Header onClick={() => accordionItemOnClick("1", solarRoofAccordion, setSolarRoofAccordion)}>Roof</Accordion.Header>
					<Accordion.Body>
						<CheckboxInput field={fieldData.roof_checkbox as CheckboxInputField} />
						{(fieldData as TextInputProps).roof_checkbox.checked ?
							<>
								<div className="input-container">
									<TextInput field={isRoofData.roof_size as TextInputProps} />
									<SelectInput field={isRoofData.roof_type as SelectInputProps} />
									<SelectInput field={isRoofData.roofing_color as SelectInputProps} />
								</div>
								<CheckboxInput field={fieldData.roof_adder_checkbox as CheckboxInputField} />
								{(fieldData as TextInputProps).roof_adder_checkbox.checked ? roofAdders : ""}
							</>
							: <></>}
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
			{showDraftSubmission && draftData ? <DraftSubmission data={draftData} /> : ""}
			<div className="IA-form-buttons-wrapper">
				<div className="IA-form-buttons">
					<Button variant="primary" onClick={() => onFormSubmit()} className="form-submit-button" disabled={submitting}>
						Submit
					</Button>
					<Button variant="secondary" type="submit" onClick={() => onPreviewSubmit(event)} className="form-dry-submit-button" disabled={submitting}>
						Preview
					</Button>
					{submitting ? <LoadingCircles wrapperClass="submitting-IA-loading-circles" /> : ""}
				</div>
			</div>
		</Form >
	);

	return (
		<div className="installAgreementForm">
			{installAgreementHtml}
		</div>
	);
}

export default InstallAgreementForm;
