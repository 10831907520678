export const commissionLedgerActions = {
	GET_COMMISSION_DATA: "commissionLedger/GET_COMMISSION_DATA",
	SET_COMMISSION_DATA: "commissionLedger/SET_COMMISSION_DATA",
	SET_CURRENT_REP: "commissionLedger/SET_CURRENT_REP",
	SET_LOADING_COMMISSION_DATA: "commissionLedger/LOADING_COMMISSION_DATA",
	SET_CUSTOMER_ACTIVITY: "commissionLedger/SET_MODAL_CUSTOMER",
	SET_MODAL_SHOWN: "commissionLedger/SET_MODAL_SHOWN",
	SET_LOADING_CUSTOMER_ACTIVITY: "commissionLedger/SET_LOADING_CUSTOMER_DATA",
	GET_CUSTOMER_ACTIVITY: "commissionLedger/GET_CUSTOMER_DATA",
	SET_NOTE: "commissionLedger/SET_NOTE",
	SET_SUBMITTING_NOTE: "commissionLedger/SET_SUBMITTING_NOTE",
	SUBMIT_NOTE: "commissionLedger/SUBMIT_NOTE",
	SET_EDIT_TYPE: "commissionLedger/SET_EDIT_TYPE",
	SUBMIT_NOTE_EDIT: "commissionLedger/SUBMIT_NOTE_EDIT",
	SET_SUBMITTING_NOTE_EDIT: "commissionLedger/SET_SUBMITTING_NOTE_EDIT",
	DELETE_NOTE: "commissionLedger/DELETE_NOTE",
	SET_DELETING_NOTE: "commissionLedger/SET_DELETING_NOTE",
	SET_CURRENT_CUSTOMER: "commissionLedger/SET_CURRENT_CUSTOMER",
};

export const installAgreementActions = {
	SET_FORM_VALUE: "installAgreement/SET_FORM_VALUE",
	SET_FORM: "installAgreement/SET_FORM",
	SET_IS_COMPLETE: "installAgreement/SET_IS_COMPLETE",
	SET_MAIN_ACCORDION_TABS_OPEN: "installAgreement/SET_MAIN_ACCORDION_TABS_OPEN",
	SET_VALIDATION_ERRORS: "installAgreement/SET_VALIDATION_ERRORS",
	SET_MAIN_ACCORDION_TABS: "installAgreement/SET_MAIN_ACCORDION_TABS",
	SET_SOLAR_ROOF_ACCORDION: "installAgreement/SET_SOLAR_ROOF_ACCORDION",
	SET_SHOW_DRAFT_SUBMISSION: "installAgreement/SET_SHOW_DRAFT_SUBMISSION",
	SET_DRAFT_DATA: "installAgreement/SET_DRAFT_DATA",
	SET_SUBMITTING: "installAgreement/SET_SUBMITTING",
	SET_GENERATE_PREVIEW: "installAgreement/SET_GENERATE_PREVIEW",
	SET_ADDER_VALUE: "installAgreement/SET_ADDER_VALUE",
	SET_SOLAR_ADDERS: "installAgreement/SET_SOLAR_ADDERS",
	SET_ROOF_ADDERS: "installAgreement/SET_ROOF_ADDERS",
	CLEAR_ADDERS: "installAgreement/CLEAR_ADDERS",
	SET_COMPLETED_SUBMISSION: "installAgreement/SET_COMPLETED_SUBMISSION",
	REMOVE_FORM_VALUE: "installAgreement/REMOVE_FORM_VALUE",
	GET_PRICING: "installAgreement/GET_PRICING",
	SET_PRICING: "installAgreement/SET_PRICING",
	SET_LOADING_PRICING: "installAgreement/SET_LOADING_PRICING",
	SET_SHOW_PRICING_MODAL: "installAgreement/SET_SHOW_PRICING_MODAL",
	SET_ADDER_PRICING: "installAgreement/SET_ADDER_PRICING",
	SET_ADDER_PRICING_ITEM: "installAgreement/SET_ADDER_PRICING_ITEM",
	SET_SHOW_PRICING_MODAL_EDIT: "installAgreement/SET_SHOW_PRICING_MODAL_EDIT",
	SET_FORM_COMPANY: "installAgreement/SET_FORM_COMPANY",
	SET_ORIGINAL_HOMEOWNER: "installAgreement/SET_ORIGINAL_HOMEOWNER",
};

export const generalActions = {
	GET_REP_LIST: "general/GET_REP_LIST",
	SET_REP_LIST: "general/SET_REP_LIST",
	GET_SETTERS_LIST: "general/GET_SETTERS_LIST",
	SET_SETTERS_LIST: "general/SET_SETTERS_LIST",
	SET_CLOSERS_LIST: "general/SET_CLOSERS_LIST",
};

export const customerPipelineActions = {
	SET_CURRENT_REP: "customerPipeline/SET_CURRENT_REP",
	GET_CUSTOMERS: "customerPipeline/GET_CUSTOMERS",
	SET_LOADING_CUSTOMERS: "customerPipeline/SET_LOADING_CUSTOMERS",
	SET_CUSTOMER_LIST: "customerPipeline/SET_CUSTOMER_LIST",
	SET_FILTERED_CUSTOMER_LIST: "customerPipeline/SET_FILTERED_CUSTOMER_LIST",
	SET_IS_CUSTOMER_DETAIL_MODAL_SHOWN: "customerPipeline/SET_IS_CUSTOMER_DETAIL_MODAL_SHOWN",
	SET_IS_CREATE_CUSTOMER_MODAL_SHOWN: "customerPipeline/SET_IS_CREATE_CUSTOMER_MODAL_SHOWN",
	SET_IS_EXPORT_PAGE_SHOWN: "customerPipeline/SET_IS_EXPORT_PAGE_SHOWN",
	SET_CURRENT_CUSTOMER: "customerPipeline/SET_CURRENT_CUSTOMER",
	GET_REPS: "customerPipeline/GET_REPS",
	SET_VIEW_TYPE: "customerPipeline/SET_VIEW_TYPE",
	SET_EXPORT: "customerPipeline/SET_EXPORT",
};

export const marketPipelineActions = {
	SET_CURRENT_REP: "marketPipeline/SET_CURRENT_REP",
	GET_CUSTOMERS: "marketPipeline/GET_CUSTOMERS",
	SET_LOADING_CUSTOMERS: "marketPipeline/SET_LOADING_CUSTOMERS",
	SET_CUSTOMER_LIST: "marketPipeline/SET_CUSTOMER_LIST",
	SET_FILTERED_CUSTOMER_LIST: "marketPipeline/SET_FILTERED_CUSTOMER_LIST",
	SET_IS_CUSTOMER_DETAIL_MODAL_SHOWN: "marketPipeline/SET_IS_CUSTOMER_DETAIL_MODAL_SHOWN",
	SET_IS_CREATE_CUSTOMER_MODAL_SHOWN: "marketPipeline/SET_IS_CREATE_CUSTOMER_MODAL_SHOWN",
	SET_CURRENT_CUSTOMER: "marketPipeline/SET_CURRENT_CUSTOMER",
	GET_REPS: "marketPipeline/GET_REPS",
	SET_VIEW_TYPE: "marketPipeline/SET_VIEW_TYPE",
};

export const dataValidationActions = {
	SET_TYPE: "dataValidation/SET_TYPE",
	SET_DATA: "dataValidation/SET_DATA",
	SET_LOADING: "dataValidation/SET_LOADING",
	SET_CURRENT_CUSTOMER: "dataValidation/SET_CURRENT_CUSTOMER",
	SET_IS_MODAL_SHOWN: "dataValidation/SET_IS_MODAL_SHOWN",
	GET_DATA: "dataValidation/GET_DATA",
};

export const loginActions = {
	SET_LOGIN_ERROR: "login/SET_LOGIN_ERROR",
	GET_PERMISSIONS: "login/GET_PERMISSIONS",
};

export const mismatchedRevenueActions = {
	GET_DATA: "mismatchedRevenue/GET_DATA",
	SET_DATA: "mismatchedRevenue/SET_DATA",
	SET_LOADING: "mismatchedRevenue/SET_LOADING",
};

export const performanceAuditorActions = {
	GET_DATA: "performanceAuditor/GET_DATA",
	SET_ACUITY_DATA: "performanceAuditor/SET_ACUITY_DATA",
	SET_CANVASS_DATA: "performanceAuditor/SET_CANVASS_DATA",
	SET_ADP_DATA: "performanceAuditor/SET_ADP_DATA",
	SET_LOADING: "performanceAuditor/SET_LOADING",
	SET_CURRENT_REP: "performanceAuditor/SET_CURRENT_REP",
	SET_DATE_RANGE: "performanceAuditor/SET_DATE_RANGE",
};

export const usersActions = {
	GET_USERS: "users/GET_USERS",
	SET_USERS: "users/SET_USERS",
	SET_SELECTED_USER: "users/SET_SELECTED_USER",
	GET_USER_DETAILS: "users/GET_USER_DETAILS",
	SET_SUBMITTING: "users/SET_SUBMITTING",
	SET_CREATE_USER_MODAL_SHOWN: "users/SET_CREATE_USER_MODAL_SHOWN",
	SET_SUBMITTING_USER: "users/SET_SUBMITTING_USER",
	SET_LOADING_USERS: "users/SET_LOADING_USERS",
	SET_LOADING_USER_DETAILS: "users/SET_LOADING_USER_DETAILS",
	GET_ALL_COMPANIES: "users/GET_ALL_COMPANIES",
	SET_ALL_COMPANIES: "users/SET_ALL_COMPANIES",
};

export const leaderboardActions = {
	SET_DATE_RANGE: "leaderboard/SET_DATE_RANGE",
	SET_SCROLL_DATA: "leaderboard/SET_SCROLL_DATA",
	GET_DATA: "leaderboard/GET_DATA",
	SET_SETTER_DATA: "leaderboard/SET_SETTER_DATA",
	SET_CLOSER_DATA: "leaderboard/SET_CLOSER_DATA",
	SET_SELFGEN_DATA: "leaderboard/SET_SELFGEN_DATA",
	SET_LOADING: "leaderboard/SET_LOADING",
	SET_TOP5_TEXT: "leaderboard/SET_TOP5_TEXT",
	SET_SETTER_COLUMNS: "leaderboard/SET_SETTER_COLUMNS",
	SET_CLOSER_COLUMNS: "leaderboard/SET_CLOSER_COLUMNS",
	SET_SELFGEN_COLUMNS: "leaderboard/SET_SELFGEN_COLUMNS",
	SET_VIEW_TYPE: "leaderboard/SET_VIEW_TYPE",
	SET_REP_SCORECARD_MODAL_SHOWN: "leaderboard/SET_REP_SCORECARD_MODAL_SHOWN",
	SET_REP_SCORECARD_DATA: "leaderboard/SET_REP_SCORECARD_DATA",
};

export const iaPriceAdjusterActions = {
	GET_CURRENT_REP_DATA: "iaPriceAdjuster/GET_CURRENT_REP_DATA",
	SET_CURRENT_REP_DATA: "iaPriceAdjuster/SET_CURRENT_REP_DATA",
	SET_DATE_RANGE: "iaPriceAdjuster/SET_DATE_RANGE",
	SET_COMPANY: "iaPriceAdjuster/SET_COMPANY",
	GET_MARKETS: "iaPriceAdjuster/GET_MARKETS",
	SET_MARKETS: "iaPriceAdjuster/SET_MARKETS",
	SET_MARKET: "iaPriceAdjuster/SET_MARKET",
	ADD_COMPANY_CONFIG: "iaPriceAdjuster/ADD_COMPANY_CONFIG",
};

export const profileActions = {
	GET_PROFILE: "profile/GET_PROFILE",
	SET_PROFILE: "profile/SET_PROFILE",
	SET_LOADING: "profile/SET_LOADING",
};

export const reportsActions = {
	GET_CONSTRUCTION_FUNNEL_DATA: "reports/GET_CONSTRUCTION_FUNNEL_DATA",
	SET_CONSTRUCTION_FUNNEL_DATA: "reports/SET_CONSTRUCTION_FUNNEL_DATA",
	SET_IS_CUSTOMER_LIST_MODAL_SHOWN: "reports/SET_IS_CUSTOMER_LIST_MODAL_SHOWN",
	SET_CUSTOMER_LIST: "reports/SET_CUSTOMER_LIST",
	GET_CUSTOMER_LIST: "reports/GET_CUSTOMER_LIST",
	SET_LOADING_CUSTOMER_LIST: "reports/SET_LOADING_CUSTOMER_LIST",
	GET_FUNNEL_REPORT_DEFINITION_LIST: "reports/GET_DEFINITION_LIST",
	SET_FUNNEL_REPORT_DEFINITION_LIST: "reports/SET_DEFINITION_LIST",
	GET_SALES_MARKET_REPORT: "reports/GET_SALES_MARKET_REPORT",
	SET_SALES_MARKET_REPORT: "reports/SET_SALES_MARKET_REPORT",
	SET_LOADING_SALES_MARKET_REPORT: "reports/SET_LOADING_SALES_MARKET_REPORT",
	SET_SALES_MARKET_REPORT_QUARTER: "reports/SET_SALES_MARKET_REPORT_QUARTER",
	SET_SALES_MARKET_VALUES_MODAL_SHOWN: "reports/SET_SALES_MARKET_VALUES_MODAL_SHOWN",
	SET_SALES_MARKET_VALUES: "reports/SET_SALES_MARKET_VALUES",
	GET_CLOSED_OUT_SALES_REPS_DATA: "reports/GET_CLOSED_OUT_SALES_REPS_DATA",
	SET_CLOSED_OUT_SALES_REPS_DATA: "reports/SET_CLOSED_OUT_SALES_REPS_DATA"
};


export const auroraProjectsActions = {
	SET_IS_AURORA_PROJECT_DETAIL_MODAL_SHOWN: "auroraProjects/SET_IS_AURORA_PROJECT_DETAIL_MODAL_SHOWN",
	GET_DESIGNS: "auroraProjects/GET_DESIGNS",
	SET_DESIGNS: "auroraProjects/SET_DESIGNS",
	SET_DESIGNS_LOADING: "auroraProjects/SET_LOADING",
	GET_CURRENT_DESIGN: "auroraProjects/GET_CURRENT_DESIGN",
	SET_CURRENT_DESIGN: "auroraProjects/SET_CURRENT_DESIGN",
	SET_CURRENT_DESIGN_LOADING: "auroraProjects/SET_CURRENT_DESIGN_LOADING",
};

export const siteSurveySchedulerActions = {
	SET_APPOINTMENT: "siteSurveyScheduler/SET_APPOINTMENT",
	SET_DAYS: "siteSurveyScheduler/SET_DAYS",
	SET_IS_SITE_SURVEY_MODAL_SHOWN: "siteSurveyScheduler/SET_IS_SITE_SURVEY_MODAL_SHOWN",
	SET_MONTH: "siteSurveyScheduler/SET_MONTH",
	SET_SELECTED_DAY: "siteSurveyScheduler/SET_SELECTED_DAY",
	SET_TIMES: "siteSurveyScheduler/SET_TIMES",
	GET_DAYS: "siteSurveyScheduler/GET_DAYS",
	GET_TIMES: "siteSurveyScheduler/GET_TIMES",
};

export const resourcePageActions = {
	GET_SOLAR_HANDOUT: "resourcePage/GET_SOLAR_HANDOUT"
};

export const closerAppointmentSchedulerActions = {
	SET_IS_CLOSER_APPOINTMENT_MODAL_SHOWN: "closerAppointmentScheduler/SET_IS_CLOSER_APPOINTMENT_MODAL_SHOWN",
	SET_CLOSER_APPOINTMENT: "closerAppointmentScheduler/SET_CLOSER_APPOINTMENT"
};

export const companyPipelineActions = {
	GET_COMPANY_CUSTOMERS: "companyPipeline/GET_COMPANY_CUSTOMERS",
	SET_COMPANY_CUSTOMERS: "companyPipeline/SET_COMPANY_CUSTOMERS",
	SET_LOADING_CUSTOMERS: "companyPipeline/SET_LOADING_CUSTOMERS",
	SET_FILTERED_CUSTOMER_LIST: "companyPipeline/SET_FILTERED_CUSTOMER_LIST",
	SET_DATE_RANGE: "companyPipeline/SET_DATE_RANGE",
	SET_IS_CUSTOMER_DETAIL_MODAL_SHOWN: "companyPipeline/SET_IS_CUSTOMER_DETAIL_MODAL_SHOWN",
	SET_CURRENT_CUSTOMER: "companyPipeline/SET_CURRENT_CUSTOMER",
};

export const redlinePageActions = {
	GET_REDLINE_DATA: "redlinePage/GET_REDLINE_DATA",
	SET_REDLINE_DATA: "redlinePage/SET_REDLINE_DATA",
};

export const commissionCalculationActions = {
	GET_COMMISSION_CALCULATION_DATA: "commissionCalculation/GET_COMMISSION_DATA",
	SET_COMMISSION_CALCULATION_DATA: "commissionCalculation/SET_COMMISSION_DATA",
	SET_IS_COMMISSION_EDIT_MODAL_SHOWN: "commissionCalculation/SET_IS_COMMISSION_EDIT_MODAL_SHOWN",
	SET_CURRENT_COMMISSION_CALCULATION_CUSTOMER: "commissionCalculation/SET_CURRENT_COMMISSION_CALCULATION_CUSTOMER",
	SUBMIT_COMMISSION_EDIT: "commissionCalculation/SUBMIT_COMMISSION_EDIT",
	SET_IS_SUBMITTING_COMMISSION_CALCULATION: "commissionCalculation/SET_IS_SUBMITTING_COMMISSION_CALCULATION",
};

export const commissionSalesRepAction = {
	GET_COMMISSION_DATA: "commissionSalesRep/GET_COMMISSION_DATA",
	SET_COMMISSION_DATA: "commissionSalesRep/SET_COMMISSION_DATA",
	SET_CURRENT_COMMISSION_CUSTOMER: "commissionSalesRep/SET_CURRENT_COMMISSION_CUSTOMER",
	SET_IS_COMMISSION_MODAL_SHOWN: "commissionSalesRep/SET_IS_COMMISSION_MODAL_SHOWN",
	SET_CURRENT_REP: "commissionSalesRep/SET_CURRENT_REP"
};
