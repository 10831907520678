import React, { useEffect, useState } from "react";
import Table from "../Components/Table/Table";
import Modal from "../Components/Modal/Modal";import { useDispatch, useSelector } from "react-redux";
import CommissionSalesRepModal from "../Components/CommissionSalesRep/CommissionSalesRepModal";
import { getCommissionData, setCurrentCommissionCustomer, setCurrentRep, setIsCommissionModalShown } from "../redux/actions/commissionSalesRep";
import WhenAuthorized from "../Components/WhenAuthorized";
import { UserPermissions } from "../enums/UserPermissions";
import { getRepList } from "../redux/actions/general";
import SelectInput from "../Components/Form/Select/SelectInput";
import { Calendar } from "primereact/calendar";

const tableHeaders: HeaderData[] = [
	{
		"Header": "Sales Date",
		"accessor": "sales_month",
	},
	{
		"Header": "Customer Name",
		"accessor": "customer_name",
	},
	{
		"Header": "Closer",
		"accessor": "closer",
	},
	{
		"Header": "Setter",
		"accessor": "setter",
	},
	{
		"Header": "Job KW",
		"accessor": "job_kw",
	},
	{
		"Header": "Status",
		"accessor": "status",
	},
	{
		"Header": "Commission",
		"accessor": "user_commission",
	},
	{
		"Header": "Incentive Status",
		"accessor": "incentive_status",
	},
	{
		"Header": "Total Commission Paid",
		"accessor": "total_commission_paid",
	}
];

const CommissionSalesRep = () => {
	const repList = useSelector(({ general }: RootState) => general.repList);
	const currentRep = useSelector(({ commissionSalesRep }: RootState) => commissionSalesRep.currentRep);
	const currentCommissionCustomer = useSelector(({ commissionSalesRep }: RootState) => commissionSalesRep.currentCommissionSalesRepCustomer);
	const commissionData = useSelector(({ commissionSalesRep }: RootState) => commissionSalesRep.commissionSalesRepData);
	const isCommissionModalShown = useSelector(({ commissionSalesRep }: RootState) => commissionSalesRep.isCommissionModalShown);
	const [startDate, setStartDate] = useState<Date>(new Date(new Date().getFullYear(), 0, 1));
	const [endDate, setEndDate] = useState<Date>(new Date());

	const dispatch = useDispatch();

	const handleOnClick = (customer: CommissionCalculation) => {
		dispatch(setCurrentCommissionCustomer(customer));
		dispatch(setIsCommissionModalShown(true));
	};

	useEffect(() => {
		const commissionManagerPermissions = "portal/commission-manager";
		const permissions = localStorage.getItem("permissions");
		const permissionsParsed = permissions ? JSON.parse(permissions) : null;
		const authorized = !!permissionsParsed?.find((perm: UserPermission) => perm.resource_list.includes(commissionManagerPermissions));
		if (authorized) {
			dispatch(getRepList());
		}
		dispatch(setCurrentRep(localStorage.getItem("email") || ""));

	}, []);

	useEffect(() => {
		if (currentRep) {
			if (startDate && endDate) {
				dispatch(getCommissionData({ startDate: startDate.toISOString(), endDate: endDate.toISOString() }));
			}
		}
	}, [currentRep, startDate, endDate]);

	const handleDateChange = (e) => {
		setStartDate(e.value[0]);
		setEndDate(e.value[1]);
	};

	return <>
		<h1>Commissions</h1>
		<div style={{ display: "flex", alignItems: "center", marginLeft: "11.5%" }}>
			<WhenAuthorized
				permission={UserPermissions.managerCommission}
			>
				<>
					<label style={{ color: "white", padding: ".5rem" }}>Viewing Commission for</label>
					<div>
						<SelectInput
							field={{
								controlId: "repSelect",
								label: "",
								dropdownData: (repList || []).map(rep => ({ key: rep.email, value: rep.email, label: rep.name })).filter(rep => !!rep.label),
								value: currentRep,
								onChange: (e: React.ChangeEvent<HTMLSelectElement>) => dispatch(setCurrentRep(e.target.value))
							}}
						/>
					</div>
				</>
			</WhenAuthorized>
			<label style={{ color: "white", padding: ".5rem" }}>Date Range</label>
			<Calendar
				value={[startDate, endDate]}
				onChange={(e) => handleDateChange(e)}
				showIcon
				style={{ width: "25%", padding: ".5rem" }}
				placeholder="Date"
				selectionMode="range"
			/>
		</div>
		{commissionData?
			<>
				<Modal
					isShown={isCommissionModalShown}
					hideModal={() => {
						dispatch(setIsCommissionModalShown(false));
					}}
					body={currentCommissionCustomer? <CommissionSalesRepModal customer={currentCommissionCustomer} />: <></>}
					heading="Commission Calculation"
					addlProps={{ size: "lg" }}
				/>
				<div style={{ width: "75%", margin: "auto" }}>
					<p style={{color: "yellow"}}>This is just an estimate please view your paysheet for accurate commissions</p>
					<Table data={commissionData} columns={tableHeaders}
						trProps={{ onClick: rowData => handleOnClick(rowData as CommissionCalculation)}}
					/>
				</div>
			</>
			:
			<></>
		}

	</>;
};

export default CommissionSalesRep;
