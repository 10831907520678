export enum dataValidationTypes {
	cancelled = "customer-sheet-cancel",
	installed = "customer-sheet-install",
	missingfromjn = "missing-from-jn",
	jnCancelled = "jobnimbus-cancel",
	initialFinalReceived = "initial-and-final-amount-received",
	incentivesReceived = "total-incentives-received",
	totalReceived = "total-received",
	totalRevenueNoMatchesReceived = "/generic/customer-sheet/total-revenue-no-matches-received",
	solarRoofTotalMatchesRevenue = "/generic/customer-sheet/solar-roof-total-matches-revenue",
}
