import React, { ReactNode } from "react";
import ReactBSModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

interface ModalProps {
	hideModal?: () => void,
	isShown?: boolean,
	body?: string|ReactNode,
	heading?: string|ReactNode,
	addlProps?: { [key: string]: string }
}

const Modal = ({ hideModal, isShown, body, heading, addlProps = {} }: ModalProps) => {
	return (
		<>
			<ReactBSModal show={isShown} onHide={hideModal} {...addlProps}>
				<ReactBSModal.Header closeButton>
					<ReactBSModal.Title>{heading}</ReactBSModal.Title>
				</ReactBSModal.Header>
				<ReactBSModal.Body>{body}</ReactBSModal.Body>
				<ReactBSModal.Footer>
					<Button variant="secondary" onClick={hideModal}>
						Close
					</Button>
				</ReactBSModal.Footer>
			</ReactBSModal>
		</>
	);
};

export default Modal;