import apiCall from "../../../utils/api";
import { reportsActions } from "../../actions/actions";
import { getSalesMarketReport, setConstructionFunnelData, setFunnelReportDefinitionList, setLoadingCustomerList, setLoadingSalesMarketReport, setSalesMarketReport, setSalesMarketValuesModalShown, setClosedOutSalesRepsData } from "../../actions/reports";
import { Middleware } from "redux";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";
import { setCustomerList } from "../../actions/reports";

const reportsMiddleware: Middleware<object, RootState> = store => next => action => {
	const { dispatch } = store;
	if (action.type === reportsActions.GET_CONSTRUCTION_FUNNEL_DATA) {
		const { loadingConstructionFunnelReport } = store.getState().reports;
		if (!loadingConstructionFunnelReport) {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/construction-funnel-report/funnel-report`,
				method: "GET"
			}).then(res =>
				dispatch(setConstructionFunnelData(res))
			).catch((e) => {
				console.error(e);
				apiErrorToast();
			});
		}
	}
	if (action.type === reportsActions.GET_FUNNEL_REPORT_DEFINITION_LIST) {
		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/construction-funnel-report/funnel-report/definitions`,
			method: "GET"
		}).then(res =>
			dispatch(setFunnelReportDefinitionList(res))
		).catch((e) => {
			console.error(e);
		});
	}
	if (action.type === reportsActions.GET_CUSTOMER_LIST) {
		const { loadingCustomerList } = store.getState().reports;
		if (!loadingCustomerList) {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/customer-pipeline/customers?ids=${action.data.join(",")}`,
				method: "GET"
			}).then(res =>
				dispatch(setCustomerList(res))
			).catch((e) => {
				console.error(e);
				apiErrorToast();
				dispatch(setLoadingCustomerList(false));
			});
		}
	}
	if (action.type === reportsActions.SET_SALES_MARKET_REPORT_QUARTER) {
		const { loadingSalesMarketReport } = store.getState().reports;

		if (!loadingSalesMarketReport) {
			dispatch(getSalesMarketReport(action.data));
		}
	}
	if (action.type === reportsActions.GET_SALES_MARKET_REPORT) {
		const { loadingSalesMarketReport } = store.getState().reports;

		if (!loadingSalesMarketReport) {
			const { quarter, year } = action.data;

			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/sales-market-report/sales-report?year=${year}${quarter !== "0" ? `&quarter=${quarter}` : ""}`,
				method: "GET"
			}).then(res =>
				dispatch(setSalesMarketReport(res))
			).catch((e) => {
				console.error(e);
				apiErrorToast();
				dispatch(setLoadingSalesMarketReport(false));
			});
		}
	}
	if (action.type === reportsActions.SET_SALES_MARKET_VALUES) {
		dispatch(setSalesMarketValuesModalShown(true));
	}
	if (action.type === reportsActions.GET_CLOSED_OUT_SALES_REPS_DATA) {
		const { loadingClosedOutSalesRepsReport } = store.getState().reports;

		if (!loadingClosedOutSalesRepsReport) {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/sales-commission-report/reporting/closed-out-sales-reps?separated=true`,
				method: "GET"
			}).then(res => {
				dispatch(setClosedOutSalesRepsData(res));
			}
			).catch((e) => {
				console.error(e);
				apiErrorToast();
			});
		}
	}
	const result = next(action);
	return result;
};



export default [reportsMiddleware];
