import { commissionSalesRepAction } from "../actions/actions";

const initialState: CommissionSalesRepState = {
	commissionSalesRepData: [],
	currentCommissionSalesRepCustomer: undefined,
	isCommissionModalShown: false,
	currentRep: ""
};

export default function commissionSalesRepReducer(state = initialState, action: CommissionSalesRepAction) {
	switch (action.type) {
		case commissionSalesRepAction.SET_COMMISSION_DATA:
			return Object.assign({}, state, { commissionSalesRepData: action.data });
		case commissionSalesRepAction.SET_CURRENT_COMMISSION_CUSTOMER:
			return Object.assign({}, state, { currentCommissionSalesRepCustomer: action.data });
		case commissionSalesRepAction.SET_IS_COMMISSION_MODAL_SHOWN:
			return Object.assign({}, state, { isCommissionModalShown: action.data });
		case commissionSalesRepAction.SET_CURRENT_REP:
			return Object.assign({}, state, { currentRep: action.data });
		default:
			return state;
	}
}
