import apiCall from "../../../utils/api";
import { auroraProjectsActions } from "../../actions/actions";
import { setAuroraDesignsLoading, setAuroraDesignLoading } from "../../actions/auroraProjects";
import { setAuroraDesigns, setAuroraDesign } from "../../actions/auroraProjects";
import { Middleware } from "redux";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";

const auroraProjectsMiddleware: Middleware<object, RootState> = store => next => action => {
	const dispatch = store.dispatch;
	const auroraProjectsState = store.getState().auroraProjects;

	switch (action.type) {
		case auroraProjectsActions.GET_DESIGNS: {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/aurora-solar-projects/list_designs?jobnimbus_id=${action.data}`,
				method: "GET"
			}).then(res => {
				dispatch(setAuroraDesigns(res));
			}).catch((e) => {
				console.error(e);
				if (e.response.status === 404) {
					apiErrorToast(e.response.data);
					dispatch(setAuroraDesigns([]));
				}
				else {
					apiErrorToast();
					dispatch(setAuroraDesignsLoading(false));
				}
			});
			break;
		}
		case auroraProjectsActions.GET_CURRENT_DESIGN: {
			const { loadingDesign } = auroraProjectsState;
			if (!loadingDesign) {
				apiCall({
					url: `${process.env.REACT_APP_API_SERVICE}/aurora-solar-projects/create_agreement`,
					method: "POST",
					body: action.data
				}).then(res => {
					dispatch(setAuroraDesign(res));
				}).catch((e) => {
					console.error(e);
					apiErrorToast();
					dispatch(setAuroraDesignLoading(false));
				});
			}
			break;
		}
	}
	const result = next(action);
	return result;
};

export default [auroraProjectsMiddleware];
