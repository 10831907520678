import React, { useState, useRef } from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
	LineController
} from "chart.js";
import {
	getDatasetAtEvent,
	getElementAtEvent,
} from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import Modal from "../Modal/Modal";
import Table from "../Table/Table";
import InteractionsPie from "./InteractionsPie";
import "./Interactions.sass";
import Button from "react-bootstrap/Button";
import { getLocalDate, getLocalDateStr } from "../../utils/convertDate";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
	LineController
);

export const options = {
	plugins: {
		title: {
			display: true,
			text: "",
		},
	},
	responsive: true,
	scales: {
		x: {
			stacked: true,
		},
		y1: {
			stacked: true,
		},
		y2: {
			stacked: true,
		},
		y3: {
			stacked: true,
		},
	},
};

const groupAcuityDataByDate = (acuityData) => {
	const acuityGroupedData = {};
	console.log(acuityGroupedData);
	acuityData.forEach((data) => {
		const date = getLocalDateStr(data.date_time);
		// console.log(date, data.date_time);
		// console.log(groupedData);
		if (!acuityGroupedData[date]) {
			acuityGroupedData[date] = {
				numberOfAppointments: 0,
				interactions: [],
			};
		}
		acuityGroupedData[date].numberOfAppointments++;
		acuityGroupedData[date].interactions.push(data);

	});
	return acuityGroupedData;
};

const groupAdpDataByDate = (adpData) => {
	const groupedData = {};
	adpData.forEach((data) => {
		const date = getLocalDateStr(data.in_time);
		if (!groupedData[date]) {
			groupedData[date] = {
				hours: 0,
				timeEntries: [],
			};
		}
		groupedData[date].hours += data.hours;
		groupedData[date].timeEntries.push(data);
	});
	return groupedData;
};

const appointmentsColumns = [
	{
		Header: "Start Time",
		accessor: "start_time",
	},
	{
		Header: "Last Name",
		accessor: "last_name"
	},
	{
		Header: "Closer",
		accessor: "calendar_name"
	},
	{
		Header: "Setter",
		accessor: "home_qualifier_name"
	},
	{
		Header: "Type",
		accessor: "type"
	},
	{
		Header: "Cancelled",
		accessor: "cancelled",
		Cell: ({ value }) => value ? "Yes" : "No"
	},
	{
		Header: "Status",
		accessor: "label",
		Cell: ({ value }) => {
			if (value !== "None") {
				const data = JSON.parse(value)[0];return data ? <span style={{ color: data.color }}>{data.name}</span> : <></>;
			} else {
				return value;
			}
		}
	}
];

// interface GroupedAcuityData {
// 	numberOfAppointments: number;
// 	interactions: any[];
// }

interface ModalData {
	type: string;
	date: string;
	data: { [key:string]: string }[]|AcuityData[] | ADPData[];
}

const PerformanceAuditorChart = ({ canvassData, acuityData, adpData }) => {
	const [modalData, setModalData] = useState<ModalData>({} as ModalData);
	const [modalIsOpen, setIsOpen] = useState<boolean>(false);
	const [pieFilter, setPieFilter] = useState<string>("all");

	const thisCanvasData = { ...canvassData };
	const groupedAcuityData = groupAcuityDataByDate(acuityData);
	const groupedAdpData = groupAdpDataByDate(adpData);

	const combinedLabels = Object.keys({ ...canvassData, ...groupedAcuityData, ...groupedAdpData }).sort((a,b) => +new Date(a) - +new Date(b));
	combinedLabels.forEach(label => { if (!thisCanvasData[label]) thisCanvasData[label] = { hours: 0, interactions: [] };});
	combinedLabels.forEach(label => { if (!groupedAcuityData[label]) groupedAcuityData[label] = { numberOfAppointments: 0, interactions: [] };});
	combinedLabels.forEach(label => { if (!groupedAdpData[label]) groupedAdpData[label] = { numberOfEntries: 0, timeEntries: [] };});

	const sortedAcuityKeys = Object.keys(groupedAcuityData).sort((a,b) => +new Date(a) - +new Date(b));
	const sortedCanvasKeys = Object.keys(thisCanvasData).sort((a,b) => +new Date(a) - +new Date(b));
	const sortedAdpKeys = Object.keys(groupedAdpData).sort((a,b) => +new Date(a) - +new Date(b));

	const data = {
		labels: combinedLabels,
		datasets: [
			{
				label: "Appointments",
				data: sortedAcuityKeys.map(key => groupedAcuityData[key].numberOfAppointments),
				backgroundColor: "rgb(133, 227, 90)",
				yAxisID: "y1",
				stack: "stack3",
				type: "line" as "bar"
			},
			{
				label: "ADP Hours",
				data: sortedAdpKeys.map(key => groupedAdpData[key].hours),
				backgroundColor: "rgb(255, 99, 132)",
				yAxisID: "y3",
				stack: "stack4"
			},
			{
				label: "Canvass Est. Hours",
				data: sortedCanvasKeys.map(key => thisCanvasData[key].hours),
				backgroundColor: "rgb(173, 216, 230)",
				yAxisID: "y3",
				stack: "stack1"
			},
			{
				label: "Interactions",
				data: sortedCanvasKeys.map(key => thisCanvasData[key].interactions.length),
				backgroundColor: "rgb(33, 127, 190)",
				yAxisID: "y2",
				stack: "stack2"
			},
		],
	};

	const printDatasetAtEvent = (dataset) => {
		if (!dataset.length) return;

		const datasetIndex = dataset[0].datasetIndex;

		return data.datasets[datasetIndex].label;
	};
  
	const printElementAtEvent = (element) => {
		if (!element.length) return;

		const { index } = element[0];

		return data.labels[index];
	};

	const onClick = (event) => {
		const { current: chart } = chartRef;

		if (!chart) {
			return;
		}

		printDatasetAtEvent(getDatasetAtEvent(chart, event));
		printElementAtEvent(getElementAtEvent(chart, event));
		const type = printDatasetAtEvent(getElementAtEvent(chart, event));
		const date = printElementAtEvent(getElementAtEvent(chart, event)) || "";
		if (type === "Appointments" || type === "Interactions" || type === "ADP Hours") {
			let data = [];
			if (type === "Appointments") {
				data = groupedAcuityData[date]?.interactions;
			} else if (type === "Interactions") {
				data = thisCanvasData[date]?.interactions;
			} else if (type === "ADP Hours") {
				data = groupedAdpData[date]?.timeEntries;
			}
			if (data.length) {
				setModalData({ type, date, data });
				setIsOpen(true);
			}
		}
	};

	const onPieClick = (event, pieRef) => {
		const { current: chart } = pieRef;

		if (!chart) {
			return;
		}

		const { index } = getElementAtEvent(chart, event)[0];
		setPieFilter(chart.legend.legendItems[index].text);
	};

	const chartRef = useRef<ChartJS<"bar", number[], unknown>>(null);

	const InteractionsModalContent = () => {
		const filteredData = pieFilter !== "all" && modalData ? modalData.data.filter((interaction) => interaction.type === pieFilter) : modalData.data;

		return <div>
			<div className="interactions-pie-container mb-3">
				<div className="mb-5 interactions-pie">
					<InteractionsPie data={modalData.data} onClick={onPieClick} />
				</div>
				<div className="text-end mb-3">
					{ pieFilter !== "all" ? <Button onClick={() => setPieFilter("all")}>Clear Filter</Button> : <></> }
				</div>
				<div className="interactions-table">
					<Table
						data={filteredData}
						stickyHeader
					/>
				</div>
			</div>
		</div>;
	};

	const TimeCardEntriesModalContent = () => {
		const filteredData = pieFilter !== "all" && modalData ? modalData.data.filter((timeEntry) => timeEntry.out_punch_type === pieFilter) : modalData.data;

		return <div>
			<div className="interactions-pie-container mb-3">
				<div className="interactions-table">
					<Table
						data={filteredData}
						stickyHeader
					/>
				</div>
			</div>
		</div>;
	};

	const AppointmentsModalContent = () =>
		<Table
			data={(modalData.data as AcuityData[]).sort((a,b) => +getLocalDate(a.date_time) - +getLocalDate(b.date_time))}
			columns={appointmentsColumns}
		/>;
	let body;
	console.log(modalData.type);
	if (modalData.type === "ADP Hours") {
		body = <TimeCardEntriesModalContent />;
	} else if (modalData.type === "Appointments") {
		body = <AppointmentsModalContent />;
	} else {
		body = <InteractionsModalContent />;
	}

	let heading;
	if (modalData.type === "ADP Hours") {
		heading = <h4 className="mb-0">ADP Hours {modalData.date}</h4>;
	} else if (modalData.type === "Appointments") {
		heading = <h4 className="mb-0">Appointments for {modalData.date}</h4>;
	} else {
		heading = <h4 className="mb-0">Interactions for {modalData.date}</h4>;
	}

	return <>
		{ modalIsOpen ? <Modal
			isShown={modalIsOpen}
			hideModal={() => { setPieFilter("all"); setIsOpen(false); }}
			body={body}
			heading={heading}
			addlProps={{ size: "lg" }}
		/> : <></> }
		<div className="performance-auditor-bar-chart-container">
			<Bar
				options={options}
				data={data}
				onClick={onClick}
				ref={chartRef}
			/>
		</div>
	</>;
};

export default PerformanceAuditorChart;
