import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import { setMonth, getDays, getTimes, setSelectedDay } from "../../redux/actions/siteSurveyScheduler";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./CloserAppointmentScheduler.sass";
import Form from "react-bootstrap/Form";
import { Dropdown } from "react-bootstrap";
import { getProfile } from "../../redux/actions/profileActions";
import { setCloserAppointment } from "../../redux/actions/closerAppointmentScheduler";

const CloserAppointmentScheduler = ({ customer }: { customer: Customer }) => {
	const month = useSelector(({ siteSurveyScheduler }: RootState) => siteSurveyScheduler.month);
	const days = useSelector(({ siteSurveyScheduler }: RootState) => siteSurveyScheduler.days);
	const times = useSelector(({ siteSurveyScheduler }: RootState) => siteSurveyScheduler.times);
	const selectedDay = useSelector(({ siteSurveyScheduler }: RootState) => siteSurveyScheduler.selectedDay);
	const profile_details = useSelector(({ profile }: RootState) => profile.profile);
	const [selectedMarket, setSelectedMarket] = useState("");
	const [selectedIncentive, setSelectedIncentive] = useState("None");
	const [selectedExpectedLengthInHome, setSelectedExpectedLengthInHome] = useState("Selling home in 5 years or less");
	const [formData, setFormData] = useState({
		utilityBillAcquired: false,
		electicVehicle: false,
		roof: false,
		homeowners_attending: false,
		sms: false
	});
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getProfile());
		if (profile_details.market === undefined || profile_details.market === null) {
			setSelectedMarket("");
		} else {
			setSelectedMarket(markets[profile_details.market]);
		}

	}, []);

	//Each time we change the month we need to get the days for that month
	useEffect(() => {
		if (selectedMarket === "" || selectedMarket === undefined || selectedMarket === null) {
			console.error("Please select a market");
		} else {
			dispatch(getDays(selectedMarket));
		}
	}, [month, selectedMarket]);

	const onMonthSelect = (e: Nullable<Date>) => {
		dispatch(setMonth(e));
	};

	const handleDateClick = (date: string) => {
		dispatch(setSelectedDay(date));
		dispatch(getTimes(selectedMarket));
	};

	const handleTimeClick = (time: string) => {
		customer.appointmentTime = time;
		customer.appointmentTypeID = selectedMarket;
		customer.incentives = selectedIncentive;
		customer.expectedLengthInHome = selectedExpectedLengthInHome;
		dispatch(setCloserAppointment({ ...customer, ...formData }));
	};

	const markets: object = {
		"Albuquerque": 55100929,
		"Phoenix": 55100995,
		"Remote AZ": 55101014,
		"PDX": 55101031,
		"PDX2": 55101057,
		"Dallas": 55100884,
		"Tacoma": 55100750,
		"Seattle": 55100761,
		"Bend": 55100951,
		"Inside Sales": 55100975,
		"Houston": 56170458,
		"Salem (Shultz)": 59428113,
		"Salem (Hudson)": 60597039,
		"Austin": 60958558,
		"San Antonio": 60958126,
		"Vancouver": 60958004,
		"Marysville": 61835902
		// "Test": 54799810
	};

	const incentives: string[] = [
		"None",
		"Taxable income",
		"ETO incentive",
		"PGE Battery incentive",
		"ODOE Incentive"
	];

	const expectedLengthInHome: string[] = [
		"Selling home in 5 years or less",
		"Selling home in 5-10 years",
		"Selling home in 10 years +",
		"Forever home"
	];

	const handleFormChange = (e) => {
		if (e.target.value === "on") {
			setFormData({ ...formData, [e.target.id]: e.target.checked });
			return;
		}
		if (e.target.value === null || e.target.value === undefined) {
			return;
		}
		setFormData({ ...formData, [e.target.id]: e.target.value });
	};

	const formatTime = (time: string) => {
		const display = time.split("T")[1].split("-")[0];
		const timeParts = display.split(":");
		let standardTime = "";

		if (parseInt(timeParts[0]) > 12) {
			timeParts[0] = String(parseInt(timeParts[0]) - 12);
			standardTime = timeParts.join(":") + " PM";
		} else if (parseInt(timeParts[0]) === 12) {
			standardTime = timeParts.join(":") + " PM";
		} else {
			standardTime = timeParts.join(":") + " AM";
		}
		return standardTime;
	};

	return (<>
		<div className="question">
			<Form className="formBox" onChange={(e) => handleFormChange(e)}>
				<Form.Group controlId="utilityBillAcquired">
					<Form.Check type="checkbox" label="Utility Bill Acquired" />
				</Form.Group>
				<Form.Group controlId="utilityBillNotes">
					<Form.Label>If not acquired, why?</Form.Label>
					<Form.Control as="textarea" rows={1} placeholder="Notes" />
				</Form.Group>
				<Form.Group controlId="incentives">
					<Form.Label>Information for Incentives</Form.Label>
					<Dropdown className="d-inline mx-2" id="market">
						<Dropdown.Toggle variant="secondary" id="dropdown-autoclose-true">
							{selectedIncentive}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{incentives.map((incentive) => <Dropdown.Item key={incentive} onClick={() => setSelectedIncentive(incentive)}>{incentive}</Dropdown.Item>)}
						</Dropdown.Menu>
					</Dropdown>
				</Form.Group>
				<Form.Group controlId="electicVehicle">
					<Form.Check type="checkbox" label="Does the client currently own or intend to purchase an Electric Vehicle in the future?" />
				</Form.Group>
				<Form.Group controlId="roof">
					<Form.Check type="checkbox" label="Is the client interested in receiving a Roofing Estimate?" />
				</Form.Group>
				<Form.Group controlId="homeowners_attending">
					<Form.Check type="checkbox" label="Does the client understand and agree that all homeowners will be attending the appointment?" />
				</Form.Group>
				<Form.Group controlId="incentives-">
					<Form.Label>Expected length of time in home</Form.Label>
					<Dropdown className="mx-2" id="market">
						<Dropdown.Toggle variant="secondary" id="dropdown-autoclose-true">
							{selectedExpectedLengthInHome}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							{expectedLengthInHome.map((length) => <Dropdown.Item key={length} onClick={() => setSelectedExpectedLengthInHome(length)}>{length}</Dropdown.Item>)}
						</Dropdown.Menu>
					</Dropdown>
				</Form.Group>
				<Form.Group controlId="notes">
					<Form.Label>Notes</Form.Label>
					<Form.Control as="textarea" rows={4} placeholder="Notes" />
				</Form.Group>
				<Form.Check id="sms">
					<Form.Check.Input type="checkbox" />
					<Form.Check.Label>Does the customer want to opt-in to SMS messages from Acuity</Form.Check.Label>
				</Form.Check>
			</Form>
			<Dropdown className="d-inline mx-2" id="market">
				<Form.Label className="marketText">Market: </Form.Label>
				<Dropdown.Toggle variant="secondary" id="dropdown-autoclose-true">
					{Object.keys(markets)[Object.values(markets).indexOf(selectedMarket)]}
				</Dropdown.Toggle>

				<Dropdown.Menu>
					{Object.keys(markets).map((market) => <Dropdown.Item key={markets[market]} onClick={() => setSelectedMarket(markets[market])}>{market}</Dropdown.Item>)}
				</Dropdown.Menu>
			</Dropdown>
		</div>
		<div className="card flex justify-content-center">
			<Calendar
				value={month}
				onChange={(e) => onMonthSelect(e.value)}
				view="month"
				dateFormat="mm/yy"
				baseZIndex={10000000}
			/>
		</div >
		<div className="card flex justify-content-center allDates">
			{days ? days.map((day) => <div className="date" onClick={() => handleDateClick(day.date)} key={day.date}>{day.date}</div>) : <></>}
		</div>
		<div className="card flex justify-content-center allDates">
			<>
				{selectedDay ?
					<div>TIMES FOR {selectedDay}</div> :
					<></>
				}
			</>
			{times ? times.map((time) => <div className="date" onClick={() => handleTimeClick(time.time)} key={time.time}>{formatTime(time.time)}</div>) : <></>}
		</div>
	</>);
};

export default CloserAppointmentScheduler;

