import { marketPipelineActions } from "./actions";

export const setCurrentRep = (data: string) => ({
	type: marketPipelineActions.SET_CURRENT_REP,
	data
});

export const getCustomers = () => ({
	type: marketPipelineActions.GET_CUSTOMERS
});

export const setLoadingCustomers = (data: boolean) => ({
	type: marketPipelineActions.SET_LOADING_CUSTOMERS,
	data
});

export const setCustomerList = (data: Customer[]) => ({
	type: marketPipelineActions.SET_CUSTOMER_LIST,
	data
});

export const setFilteredCustomerList = (data: Customer[]) => ({
	type: marketPipelineActions.SET_FILTERED_CUSTOMER_LIST,
	data
});

export const setIsCustomerDetailModalShown = (data: boolean) => ({
	type: marketPipelineActions.SET_IS_CUSTOMER_DETAIL_MODAL_SHOWN,
	data
});

export const setIsCreateCustomerModalShown = (data: boolean) => ({
	type: marketPipelineActions.SET_IS_CREATE_CUSTOMER_MODAL_SHOWN,
	data
});

export const setCurrentCustomer = (data: Customer) => ({
	type: marketPipelineActions.SET_CURRENT_CUSTOMER,
	data
});

export const setViewType = (data: string) => ({
	type: marketPipelineActions.SET_VIEW_TYPE,
	data
});
