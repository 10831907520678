/* eslint-disable quotes */
import { commissionSalesRepAction } from "../../actions/actions";
import { Middleware } from "redux";
import { setCommissionData } from "../../actions/commissionSalesRep";
import apiCall from "../../../utils/api";

const commissionSalesRepMiddleware: Middleware<object, RootState> = store => next => action => {
	const dispatch = store.dispatch;
	switch (action.type) {
		case commissionSalesRepAction.GET_COMMISSION_DATA: {
			const data = action.data;
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/sales-commission-report/commission?start_date=${data.startDate}&end_date=${data.endDate}&rep_email=${store.getState().commissionSalesRep.currentRep}`,
				method: "GET"
			}).then(res => {
				dispatch(setCommissionData(res));
			}).catch((e) => {
				console.error(e);
			}
			);
			break;
		}
		default:
			break;
	}
	const result = next(action);
	return result;
};

export default [commissionSalesRepMiddleware];
