import { iaPriceAdjusterActions } from "../actions/actions";
import { convertDateToString } from "../../utils/convertDate";


function getDayOfMonth() {
	const d = new Date();
	const year = d.getFullYear();
	const month = d.getMonth();
	const day = d.getDate();
	const hour = d.getHours();
	const minute = d.getMinutes();
	const second = d.getSeconds();
	return new Date(Date.UTC(year, month, day, hour, minute, second));
}

const initialDate = convertDateToString(getDayOfMonth());

const initialState: iaPriceAdjusterState = {
	dateRange: { startDate: initialDate, endDate: initialDate },
	setCompany: "",
	setMarket: "",
	setMarkets: {},
};

export default function iaPriceAdjusterReducer(state = initialState, action: iaPriceAdjusterAction) {
	switch (action.type) {
		case iaPriceAdjusterActions.GET_MARKETS:
			return Object.assign({}, state, { loading: true });
		case iaPriceAdjusterActions.SET_CURRENT_REP_DATA:
			return Object.assign({}, state, { currentPricing: action.data });
		case iaPriceAdjusterActions.SET_DATE_RANGE
			: return Object.assign({}, state, { dateRange: action.data });
		case iaPriceAdjusterActions.SET_COMPANY:
			return Object.assign({}, state, { setCompany: action.data });
		case iaPriceAdjusterActions.SET_MARKET:
			return Object.assign({}, state, { setMarket: action.data });
		case iaPriceAdjusterActions.SET_MARKETS:
			return Object.assign({}, state, { setMarkets: action.data });
		case iaPriceAdjusterActions.ADD_COMPANY_CONFIG:
			return Object.assign({}, state, { companyConfig: action.data });
		default:
			return state;
	}
}