import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from "react-bootstrap/Accordion";
import CloseButton from "react-bootstrap/CloseButton";
import apiCall from "../../utils/api";
import "./Permissions.sass";
import { SelectInput } from "../Form";
import { encodeEmail } from "../../utils/formatString";

interface PermissionsProps {
	selectedUser: Partial<User>;
}

const defaultRoles = (permissions: Permission[]) => {
	const permissionNames = permissions && permissions.length ? permissions.map(perm => perm.role_name) : [];
	return permissionNames.reduce((acc, curr) => {
		if (!acc.includes(curr)) acc.push(curr);
		return acc;
	}, [] as string[]);
};

const Permissions = ({ selectedUser }: PermissionsProps) => {
	const [permissions, setPermissions] = useState<Permission[]>([] as Permission[]);
	const [roleList, setRoleList] = useState<Role[]>([] as Role[]);
	const [currentSelectedRole, setCurrentSelectedRole] = useState("");
	const [rolesAdded, setRolesAdded] = useState<Array<string>>(defaultRoles(permissions));
	let rowId = 0;

	const userEmail = selectedUser.email;

	const addRole = () => {
		if (!rolesAdded.includes(currentSelectedRole)) setRolesAdded([...rolesAdded, currentSelectedRole]);
	};

	const removeRole = role => {
		setRolesAdded(rolesAdded.filter(r => r !== role));
	};

	useEffect(() => {
		setRolesAdded(defaultRoles(permissions));
	}, [permissions]);

	useEffect(() => {
		if (roleList && roleList[0]) {
			setCurrentSelectedRole(roleList[0].name);
		} else {
			getAllRoles();
		}
	}, [roleList]);

	useEffect(() => {
		getUserPermissions(userEmail);
	}, [selectedUser]);

	const submitRoles = (rolesAdded: string[]) => {
		if (userEmail) {
			//setSubmittingRoles(true);
			apiCall({
				method: "PUT",
				url: `${process.env.REACT_APP_API_SERVICE}/portal-user-manager/users/${encodeEmail(userEmail)}?${`role_names={${rolesAdded.join(",")}}`}`,
				body: {
					user_email: userEmail,
					role_names: rolesAdded
				}
			})
				.then(() => {
					//setSubmittingRoles(false);
					getUserPermissions(userEmail);
				});
		}
	};

	const getUserPermissions = async (user_email: string | undefined) => {
		if (user_email === undefined) {
			return false;
		}
		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/permissions/user?user_email=${encodeEmail(user_email)}`,
			method: "GET"
		})
			.then(result => setPermissions(result))
			.catch(() => false);
	};

	const getAllRoles = () => {
		apiCall({ url: `${process.env.REACT_APP_API_SERVICE}/permissions/role` })
			.then(res => { setRoleList(res); });
	};

	return <Card className="userDetails-permissions-card">
		<Card.Body>
			<h3 className="text-center mb-4 mt-2">Permissions</h3>
			{permissions && permissions.length ? permissions?.map(permission => {
				return (
					<Accordion key={rowId++} className="permission-accordion">
						<Accordion.Item eventKey="0">
							<Accordion.Header>{permission.role_name}: {permission.permission_name}</Accordion.Header>
							<Accordion.Body>
								<ListGroup>
									{permission["resource_list"].map(resource => {
										return (
											<Row key={resource}>
												<Col>
													{permission["allow"] ? <ListGroup.Item>
														Allowed
													</ListGroup.Item>
														: <ListGroup.Item>Denied</ListGroup.Item>
													}
												</Col>
												<Col>
													<ListGroup.Item>{resource}</ListGroup.Item>
												</Col>
											</Row>

										);
									})}
								</ListGroup>
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				);
			})
				:
				<div className="no-permissions-message">This user does not have any permissions</div>
			}
			<hr />
			<div className="addRoles-container">
				<p className="text-center">Add Roles (This will overwrite existing roles)</p>
				<div className="roleContainer">
					{roleList.length ? <SelectInput field={{
						controlId: "roleSelect",
						onChange: (e) => setCurrentSelectedRole(e.target.value),
						dropdownData: roleList.map(r => ({ key: r.name, value: r.name, label: r.name })),
						value: currentSelectedRole,
					}} /> : <></>}
					<Button variant="primary" type="button" className="button add-permissions-button" onClick={addRole}>
						Add
					</Button>
				</div>
				<ListGroup className="permissions-list-group">
					{rolesAdded?.map(role =>
						<ListGroup.Item key={role}>
							<div className="role-content" data-testid="added-role" >
								{role}
								<CloseButton onClick={() => removeRole(role)} data-testid="remove-role-button" />
							</div>
						</ListGroup.Item>
					)}
				</ListGroup>
				<div className="submit-role-button-container">
					<Button variant="primary" type="button" className="button" onClick={() => submitRoles(rolesAdded)}>
						Submit
					</Button>
				</div>
			</div>
		</Card.Body>
	</Card>;
};

export default Permissions;