import React from "react";
import Form from "react-bootstrap/Form";

const RadioInputGroup = ({ field }: { field: RadioInputField }) => {
	const radioOptions = field.options.map((option: RadioInputOption) => {
		return <Form.Check
			key={option.id}
			inline
			label={option.label}
			id={option.id}
			type="radio"
			disabled={field.disabled}
			checked={field.value === option.id}
			aria-checked={field.value === option.id}
			onChange={field.onChange}
			onClick={field.onClick}
		/>;
	});

	return <Form.Group
		controlId={field.controlId}
		className={`radio-input${field.containerClasses ? ` ${field.containerClasses}` : ""}`}
	>
		{radioOptions}
		{ field.validation && Object.keys(field.validation).length && field.controlId && field.validation[field.controlId] ? <p className="field-validation-error">
			{field.validation[field.controlId]}
		</p> : "" }
	</Form.Group>;
};

export default RadioInputGroup;
