import { loginActions } from "../actions/actions";

const initialState: LoginState = {};

export default function loginReducer(state = initialState, action: LoginAction) {
	switch (action.type) {
		case loginActions.SET_LOGIN_ERROR:
			return Object.assign({}, state, { loginError: action.data });
		case loginActions.GET_PERMISSIONS:
			return Object.assign({}, state, { loadingPermissions: true });
		default:
			return state;
	}
}
