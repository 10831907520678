import React, { useRef, useState } from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";

const LabelTooltip = ({ label, tooltipText }: { label: string, tooltipText: string }) => {
	const target = useRef(null);
	const [show, setShow] = useState(false);

	return <>
		<div ref={target} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
			{label}
		</div>
		<Overlay target={target.current} show={show} placement="right">
			{(props) => (
				<Tooltip id="overlay-example" {...props}>
					{tooltipText}
				</Tooltip>
			)}
		</Overlay>
	</>;
};

export default LabelTooltip;