import { convertDateToString } from "../../utils/convertDate";
import { companyPipelineActions } from "../actions/actions";

const setUpDate = new Date();

const initialState: CompanyPipelineState = {
	companyCustomers: [],
	filteredCustomerList: [],
	loadingCustomers: false,
	dateRange: {
		startDate: convertDateToString(new Date(setUpDate.getFullYear(), setUpDate.getMonth(), 1)),
		endDate: convertDateToString(new Date())
	},
	isCustomerDetailModalShown: false,
	currentCustomer: undefined,
	viewType: "list"
};

export default function companyPipelineReducer(state = initialState, action: CompanyPipelineAction) {
	switch (action.type) {
		case companyPipelineActions.GET_COMPANY_CUSTOMERS:
			return Object.assign({}, state, { loadingCustomers: true });
		case companyPipelineActions.SET_COMPANY_CUSTOMERS:
			return Object.assign({}, state, { loadingCustomers: false, companyCustomers: action.data });
		case companyPipelineActions.SET_LOADING_CUSTOMERS:
			return Object.assign({}, state, { loadingCustomers: action.data });
		case companyPipelineActions.SET_FILTERED_CUSTOMER_LIST:
			return Object.assign({}, state, { filteredCustomerList: action.data });
		case companyPipelineActions.SET_DATE_RANGE:
			return Object.assign({}, state, { dateRange: action.data });
		case companyPipelineActions.SET_CURRENT_CUSTOMER:
			return Object.assign({}, state, { currentCustomer: action.data });
		case companyPipelineActions.SET_IS_CUSTOMER_DETAIL_MODAL_SHOWN:
			return Object.assign({}, state, { isCustomerDetailModalShown: action.data });
		default:
			return state;
	}
}
