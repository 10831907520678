import React from "react";
import "./DraftSubmission.sass";
import Table from "../Table/Table";

type TableData = {
	label: string,
	quantity: string,
	cost: string
}

const buildAdderTableData = (data: Partial<DraftResponseData>, typeKey: "roofadder" | "solaradder", types: string[], labels: { [key: string]: string }) => {
	const adders: { [key: string]: string | number | boolean } | undefined = data[typeKey];

	const tableData: TableData[] = [] as TableData[];

	types.forEach(t => {
		if (adders && adders[t] !== undefined) {
			tableData.push({ label: labels[t], quantity: adders[`${t}_amount`] as string, cost: adders[t] as string });
		}
	});

	return tableData;
};

const solarAdderTypes = [
	"aps_battery",
	"aps_battery_add_on",
	"car_charger_kit",
	"car_charger_port",
	"critter_guard",
	"flat_roof",
	"full_system_upgrade",
	"generator_plug",
	"ground_mount",
	"ground_mount_over_6kw",
	"main_breaker_kit",
	"main_panel_upgrade",
	"metal_roof",
	"multi_panel_array",
	"sense_monitoring",
	"sub_panel",
	"three_story_roof",
	"three_story_roof_w_boom",
	"travel_46_100",
	"travel_101_150",
	"travel_150_plus",
	"travel_250_plus",
	"trenching",
	"trenching_bedrock",
	"uninstall_reinstall",
	"enphase_battery",
	"steep_roof_solar",
	"concrete_tile_roof",
	"labor_charge",
	"main_panel_upgrade_-_400_amp",
	"main_panel_upgrade_-_200_amp",
	"uninstall_reinstall_infinity",
	"google_essentials",
	"google_premium"
];

const solarAdderLabels = {
	certainteed: "CertainTeed",
	aps_battery: "APS Battery",
	aps_battery_add_on: "APS Battery Add On",
	car_charger_kit: "Car Charger Kit",
	car_charger_port: "Car Charger Port (240V Outlet)",
	concrete_tile_roof: "Concrete/Tile Roof",
	critter_guard: "Critter Guard",
	flat_roof: "Flat Roof",
	full_system_upgrade: "Full System Upgrade",
	generator_plug: "Generator Switch",
	ground_mount: "Ground Mount",
	ground_mount_over_6kw: "Ground Mount (6 KW+)",
	main_panel_upgrade: "Main Panel Upgrade",
	metal_roof: "Metal Roof",
	multi_panel_array: "Multi Panel Array",
	sense_monitoring: "Sense Monitoring",
	sub_panel: "Sub Panel",
	three_story_roof: "Three Story Roof",
	three_story_roof_w_boom: "Three Story Roof w/ Boom",
	travel_46_100: "Travel 66/99",
	travel_101_150: "Travel 100/150",
	travel_150_plus: "Travel 150+",
	trenching: "Trenching",
	trenching_bedrock: "Trenching Bedrock",
	uninstall_reinstall: "Uninstall/Reinstall",
	enphase_battery: "Enphase Battery",
	steep_roof_solar: "Steep Roof 37*",
	labor_charge: "Labor Charge",
	uninstall_reinstall_infinity: "Uninstall/Reinstall Infinity",
	travel_250_plus: "Travel 250+",
	"main_panel_upgrade_-_200_amp": "Main Panel Upgrade",
	"main_panel_upgrade_-_400_amp": "Main Panel Upgrade - 400 Amp",
	google_essentials: "Google Essentials Package",
	google_premium: "Google Premium Package",
};

const roofAdderTypes = [
	"tear_off_one_layer",
	"tear_off_two_layer",
	"tear_off_three_layer",
	"pitch_7-12_9-12",
	"pitch_10-12_14-12",
	"pitch_15-12",
	"cedar_shake",
	"skylight",
	"solar_tube_installation",
	"venta_ridge",
	"metal_valley",
	"deco_ridge",
	"plywood_less_6",
	"plywood_more_6",
	"plywood",
	"ground_drop",
	"steep_roof",
	"tpo_roof",
	"pitch_8-12",
	"pitch_9-12",
	"tile_underlayment",
	"tile_roof_2nd_story"

];

const roofAdderLabels = {
	tear_off_one_layer: "Tear Off One Layer",
	tear_off_two_layer: "Tear Off Two Layer",
	tear_off_three_layer: "Tear Off Three Layer",
	"pitch_7-12_9-12": "Pitch 7-12/9-12",
	"pitch_10-12_14-12": "Pitch 10-12/14-12",
	"pitch_15-12": "Pitch 15-12",
	cedar_shake: "Cedar Shake",
	tpo_roof: "TPO Roof",
	ground_drop: "Tear Off Ground Drop",
	skylight: "Skylight",
	solar_tube_installation: "Solar Tube Installation",
	venta_ridge: "Ridge Vent",
	metal_valley: "Metal Valley",
	deco_ridge: "Deco Ridge",
	plywood_less_6: "Plywood",
	plywood_more_6: "Plywood",
	plywood: "Plywood",
	steep_roof: "Steep Roof 37*",
	"pitch_8-12": "Pitch 8-12",
	"pitch_9-12": "Pitch 9-12",
	tile_underlayment: "Tile Underlayment",
	tile_roof_2nd_story: "Tile Roof 2nd Story"
};

type SolarDraftData = {
	solar_size: string,
	panel_type: string,
	panel_amount: number,
	inverter_type: string,
	inverter_amount: number
}

const SolarTable = ({ data }: { data: SolarDraftData }) => <div>
	<table className="table system-size-table">
		<tbody>
			<tr>
				<td>System Size</td>
				<td>{data.solar_size}</td>
			</tr>
		</tbody>
	</table>
	<table className="table solar-table">
		<thead>
			<tr>
				<th>Item</th>
				<th>Description</th>
				<th>Quantity</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>Solar Modules</td>
				<td>{data.panel_type}</td>
				<td>{data.panel_amount}</td>
			</tr>
			<tr>
				<td>Inverters</td>
				<td>{data.inverter_type}</td>
				<td>{data.inverter_amount}</td>
			</tr>
		</tbody>
	</table>
</div>;

type RoofDraftData = {
	roof_size: number,
	roof_type: string,
	roofing_color: string
}

const RoofTable = ({ data }: { data: RoofDraftData }) => <table className="table roof-table">
	<tbody>
		<tr>
			<td>Roofing Size</td>
			<td>{data.roof_size}</td>
		</tr>
		<tr>
			<td>Roofing Material</td>
			<td>{data.roof_type}</td>
		</tr>
		<tr>
			<td>Roofing Color</td>
			<td>{data.roofing_color}</td>
		</tr>
	</tbody>
</table>;

const PricingTable = ({ data }: { data: Partial<DraftResponseData> }) => <table className="table pricing-table">
	<tbody>
		<tr>
			<td>System Cost</td>
			<td>{`Solar: ${data["Solar System Cost"]}`}</td>
			<td>{data["Roofing System Cost"] ? `Roofing: ${data["Roofing System Cost"]}` : ""}</td>
		</tr>
		{data.market_adjustment ? <tr>
			<td>Market Adjustment</td>
			<td colSpan={2}>{data.market_adjustment}</td>
		</tr> : ""}
		{data.total_discount ? <tr>
			<td>Discount</td>
			<td colSpan={2}>{data.total_discount}</td>
		</tr> : ""}
		{data.incentives && data.incentives.eto ? <tr>
			<td>Energy Trust Organization Credit</td>
			<td colSpan={2}>{data.incentives.eto}</td>
		</tr> : ""}
		{data.incentives && data.incentives.odoe ? <tr>
			<td>Oregon Department of Energy Credit</td>
			<td colSpan={2}>{data.incentives.odoe}</td>
		</tr> : ""}
		{data.incentives && data.incentives.lmi ? <tr>
			<td>Low to Moderate Income</td>
			<td colSpan={2}>{data.incentives.lmi}</td>
		</tr> : ""}
		{data.incentives && data.incentives.swr ? <tr>
			<td>Solar Within Reach</td>
			<td colSpan={2}>{data.incentives.swr}</td>
		</tr> : ""}
		{data.seasonal_promotion_name ? <tr>
			<td>{data.seasonal_promotion_name}</td>
			<td colSpan={2}>{data.seasonal_promotion}</td>
		</tr> : ""}
		{data.gtr_promotion_name ? <tr>
			<td>{data.gtr_promotion_name}</td>
			<td colSpan={2}>{data.gtr_promotion}</td>
		</tr> : ""}
		{data.roof_tax !== undefined ? <tr>
			<td>Roof Sales Tax</td>
			<td colSpan={2}>{data.roof_tax}</td>
		</tr> : ""}
		<tr>
			<td>Total Cost</td>
			<td>{`Solar: ${data["Solar Total"]}`}</td>
			<td>{data["Roofing Total Cost"] ? `Roofing: ${data["Roofing Total Cost"]}` : ""}</td>
		</tr>
		<tr>
			<td>Due at signing</td>
			<td colSpan={2}>{data.due_at_signing}</td>
		</tr>
		<tr>
			<td>Due at day of installation</td>
			<td colSpan={2}>{data.due_at_installation}</td>
		</tr>
	</tbody>
</table>;

const DraftSubmission = ({ data }: { data: DraftResponseData }) => {
	const adderColumns = React.useMemo(
		() => [
			{
				Header: "Add-On",
				accessor: "label"
			},
			{
				Header: "Quantity",
				accessor: "quantity"
			},
			{
				Header: "Cost",
				accessor: "cost"
			}
		],
		[]
	);

	return <div className="solar-adder-table-container">
		{data.solar_size ? <>
			<h2>Solar</h2>
			<SolarTable data={data} />
		</> : ""}
		{data.solaradder ? <>
			<h2>Solar Add-Ons</h2>
			<Table columns={adderColumns} data={buildAdderTableData(data, "solaradder", solarAdderTypes, solarAdderLabels)} tableClasses="solar-adder-table" />
		</> : ""}

		{data.roof_size ? <>
			<h2>Roofing</h2>
			<RoofTable data={data} />
		</> : ""}
		{data.roofadder ? <>
			<h2>Roof Add-Ons</h2>
			<Table columns={adderColumns} data={buildAdderTableData(data, "roofadder", roofAdderTypes, roofAdderLabels)} tableClasses="solar-adder-table" />
		</> : ""}
		<h2>Pricing</h2>
		<PricingTable data={data} />
	</div>;
};

export default DraftSubmission;
