import { performanceAuditorActions } from "./actions";

type PerformanceAuditorGetData = {
	startDate: string;
	endDate: string;
	currentRep: string;
}

export const getData = (data: PerformanceAuditorGetData) => ({
	type: performanceAuditorActions.GET_DATA,
	data
});

export const setAcuityData = (data: AcuityData[]) => ({
	type: performanceAuditorActions.SET_ACUITY_DATA,
	data
});

export const setCanvassData = (data: CanvasData[]) => ({
	type: performanceAuditorActions.SET_CANVASS_DATA,
	data
});

export const setAdpData = (data: ADPData[]) => ({
	type: performanceAuditorActions.SET_ADP_DATA,
	data
});

export const setLoading = (data: boolean) => ({
	type: performanceAuditorActions.SET_LOADING,
	data
});

export const setCurrentRep = (data: string) => ({
	type: performanceAuditorActions.SET_CURRENT_REP,
	data
});

export const setDateRange = (data: { startDate: string, endDate: string}) => ({
	type: performanceAuditorActions.SET_DATE_RANGE,
	data
});
