import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Nullable } from "primereact/ts-helpers";
import { setMonth, getDays, getTimes, setSelectedDay, setAppointment } from "../../redux/actions/siteSurveyScheduler";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "./SiteSurveyScheduler.sass";
import Form from "react-bootstrap/Form";
import { Dropdown } from "react-bootstrap";
import { getProfile } from "../../redux/actions/profileActions";

const SiteSurveyScheduler = ({ customer }: { customer: Customer }) => {
	const month = useSelector(({ siteSurveyScheduler }: RootState) => siteSurveyScheduler.month);
	const days = useSelector(({ siteSurveyScheduler }: RootState) => siteSurveyScheduler.days);
	const times = useSelector(({ siteSurveyScheduler }: RootState) => siteSurveyScheduler.times);
	const selectedDay = useSelector(({ siteSurveyScheduler }: RootState) => siteSurveyScheduler.selectedDay);
	const profile_details = useSelector(({ profile }: RootState) => profile.profile);
	const [sms, setSms] = useState(false);
	const [roof, setRoof] = useState(false);
	const [phone, setPhone] = useState("");
	const [selectedMarket, setSelectedMarket] = useState("");
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getProfile());
		setSelectedMarket(markets[profile_details.market]);
	}, []);

	//Each time we change the month we need to get the days for that month
	useEffect(() => {
		if (selectedMarket === "") {
			return;
		}
		dispatch(getDays(selectedMarket));
	}, [month, selectedMarket]);

	const onMonthSelect = (e: Nullable<Date>) => {
		dispatch(setMonth(e));
	};

	const handleDateClick = (date: string) => {
		dispatch(setSelectedDay(date));
		dispatch(getTimes(selectedMarket));
	};

	const handleTimeClick = (time: string) => {
		customer.sms = sms;
		customer.roof = roof;
		customer.salesRepNumber = phone;
		customer.appointmentTime = time;
		customer.appointmentTypeID = selectedMarket;
		dispatch(setAppointment(customer));
	};

	const handleFormChange = (e) => {
		if (e.target.id === "phone") {
			setPhone(e.target.value);
		} else if (e.target.id === "roof") {
			setRoof(e.target.checked);
		} else if (e.target.id === "sms") {
			setSms(e.target.checked);
		}
	};

	const markets: object = {
		"Houston": 54038407,
		"Albuquerque": 33590306,
		"Phoenix": 31738112,
		"Remote AZ": 52778873,
		"PDX": 20365920,
		"Dallas": 31738113,
		"Seattle": 20366729,
		"Bend": 20366847,
		"Salem": 60687957,
		"Medford": 60688156,
		"Tacoma": 60688002,
		"Austin": 60958856,
		"San Antonio": 60959033,
		"Vancover": 60959081,
		"Marysville": 62181247
		// "Dev": 54042467
	};

	const formatTime = (time: string) => {
		const display = time.split("T")[1].split("-")[0];
		const timeParts = display.split(":");
		let standardTime = "";

		if (parseInt(timeParts[0]) > 12) {
			timeParts[0] = String(parseInt(timeParts[0]) - 12);
			standardTime = timeParts.join(":") + " PM";
		} else if (parseInt(timeParts[0]) === 12) {
			standardTime = timeParts.join(":") + " PM";
		} else {
			standardTime = timeParts.join(":") + " AM";
		}
		return standardTime;
	};

	return (<>
		<div className="question">
			<Form onChange={(e) => handleFormChange(e)}>
				<Form.Label>Sales Rep Phone Number</Form.Label>
				<Form.Control id="phone" type="tel" placeholder="Enter Phone Number" />
				<Form.Check id="roof">
					<Form.Check.Input type="checkbox" />
					<Form.Check.Label>Does the customer need a new Roof</Form.Check.Label>
				</Form.Check>
				<Form.Check id="sms">
					<Form.Check.Input type="checkbox" />
					<Form.Check.Label>Does the customer want to opt-in to SMS messages from Acuity</Form.Check.Label>
				</Form.Check>
			</Form>
			<Dropdown className="d-inline mx-2" id="market">
				<Dropdown.Toggle variant="secondary" id="dropdown-autoclose-true">
					{Object.keys(markets)[Object.values(markets).indexOf(selectedMarket)]}
				</Dropdown.Toggle>

				<Dropdown.Menu>
					{Object.keys(markets).map((market) => <Dropdown.Item key={markets[market]} onClick={() => setSelectedMarket(markets[market])}>{market}</Dropdown.Item>)}
				</Dropdown.Menu>
			</Dropdown>
		</div>
		<div className="card flex justify-content-center">
			<Calendar
				value={month}
				onChange={(e) => onMonthSelect(e.value)}
				view="month"
				dateFormat="mm/yy"
				baseZIndex={10000000}
			/>
		</div >
		<div className="card flex justify-content-center allDates">
			{days ? days.map((day) => <div className="date" onClick={() => handleDateClick(day.date)} key={day.date}>{day.date}</div>) : <></>}
		</div>
		<div className="card flex justify-content-center allDates">
			<>
				{selectedDay ?
					<div>TIMES FOR {selectedDay}</div> :
					<></>
				}
			</>
			{times ? times.map((time) => <div className="date" onClick={() => handleTimeClick(time.time)} key={time.time}>{formatTime(time.time)}</div>) : <></>}
		</div>
	</>);
};

export default SiteSurveyScheduler;

