import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";
import apiCall from "../../../utils/api";
import { resourcePageActions } from "../../actions/actions";
import { Middleware } from "redux";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resourcePageMiddleware: Middleware<object, RootState> = store => next => action => {
	switch (action.type) {
		case resourcePageActions.GET_SOLAR_HANDOUT: {
			const link = document.createElement("a");
			link.target = "_blank";
			link.download = "solar-handout.pdf";
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/csm-data-validator/resource/solar-handout`,
				responseType: "blob"
			}).then((res) => {
				link.href = URL.createObjectURL(
					new Blob([res], { type: "application/pdf" })
				);
				link.click();
			}).catch((res) => {
				apiErrorToast(res.message);
			});
			break;
		}
	}
	const result = next(action);
	return result;
};

export default [resourcePageMiddleware];
