import apiCall from "../../../utils/api";
import { commissionLedgerActions } from "../../actions/actions";
import { setCommissionData, getCommissionData, setLoadingCommissionData, setCustomerActivity, setLoadingCustomerActivity, setSubmittingNote, getCustomerActviity, setNote, setDeletingNote, setSubmittingNoteEdit } from "../../actions/commissionLedger";
import { Middleware } from "redux";
import { encodeEmail } from "../../../utils/formatString";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";

const commissionLedgerMiddleware: Middleware<object, RootState> = store => next => action => {
	const { type, data } = action;
	const { dispatch } = store;
	const {
		loadingCommissionData,
		loadingCustomerActivity,
		submittingNote,
		submittingNoteEdit
	} = store.getState().commissionLedger;

	switch (type) {
		case commissionLedgerActions.GET_COMMISSION_DATA: {
			if (!loadingCommissionData) {
				apiCall({
					url: `${process.env.REACT_APP_API_SERVICE}/sales-commission-report/payroll?rep_email=${encodeEmail(data)}`,
					method: "GET"
				}).then(res => {
					dispatch(setCommissionData(res.data));
				}).catch((e) => {
					console.error(e);
					apiErrorToast();
					dispatch(setLoadingCommissionData(false));
				});
			}
			break;
		}
		case commissionLedgerActions.GET_CUSTOMER_ACTIVITY: {
			if (!loadingCustomerActivity) {
				apiCall({
					url: `${process.env.REACT_APP_API_SERVICE}/sales-commission-report/payroll/${data.customer_id}/activity?rep_email=${encodeEmail(data.rep_email)}`,
					method: "GET"
				}).then(res => {
					console.log(res);
					dispatch(setCustomerActivity(res.data));
				}).catch((e) => {
					console.error(e);
					apiErrorToast();
					dispatch(setLoadingCustomerActivity(false));
				});
			}
			break;
		}
		case commissionLedgerActions.SUBMIT_NOTE: {
			if (!submittingNote) {
				apiCall({
					url: `${process.env.REACT_APP_API_SERVICE}/sales-commission-report/payroll/${data.customer_id}/activity`,
					method: "POST",
					body: {
						content: data.content,
						activity_type: "text",
						rep_email: data.rep_email
					}
				}).then(res => {
					console.log(res);
					if (res !== "Activity created") apiErrorToast();
				}).catch((e) => {
					console.error(e);
					apiErrorToast();
				}).finally(() => {
					dispatch(setNote(""));
					dispatch(setSubmittingNote(false));
					dispatch(getCustomerActviity({
						customer_id: data.customer_id,
						rep_email: data.rep_email
					}));
				});
			}
			break;
		}
		case commissionLedgerActions.SUBMIT_NOTE_EDIT: {
			if (!submittingNoteEdit) {
				apiCall({
					url: `${process.env.REACT_APP_API_SERVICE}/sales-commission-report/payroll/${data.customer_id}/activity`,
					method: "PUT",
					body: {
						content: data.content,
						activity_type: "text",
						rep_email: data.rep_email,
						activity_id: data.id
					}
				}).then(res => {
					console.log(res);
					if (res !== "Activity updated") apiErrorToast();
				}).catch((e) => {
					console.error(e);
					apiErrorToast();
				}).finally(() => {
					dispatch(setNote(""));
					dispatch(setSubmittingNoteEdit(false));
					dispatch(getCustomerActviity({
						customer_id: data.customer_id,
						rep_email: data.rep_email
					}));
				});
			}
			break;
		}
		case commissionLedgerActions.DELETE_NOTE: {
			if (!submittingNoteEdit) {
				apiCall({
					url: `${process.env.REACT_APP_API_SERVICE}/sales-commission-report/payroll/${data.customer_id}/activity`,
					method: "DELETE",
					body: {
						activity_id: data.id,
						rep_email: data.rep_email
					}
				}).then(res => {
					console.log(res);
					if (res !== "Activity deleted") apiErrorToast();
				}).catch((e) => {
					console.error(e);
					apiErrorToast();
				}).finally(() => {
					dispatch(setDeletingNote(false));
					dispatch(getCustomerActviity({
						customer_id: data.customer_id,
						rep_email: data.rep_email
					}));
				});
			}
			break;
		}
		case commissionLedgerActions.SET_CURRENT_REP: {
			dispatch(getCommissionData(data));
			break;
		}
	}
	const result = next(action);
	return result;
};

export default [commissionLedgerMiddleware];
