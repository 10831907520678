import { auroraProjectsActions } from "../actions/actions";

const initialState: AuroraProjectsState = {
	designs: [],
	// design: "AuroraDesigns"
};

export default function auroraProjectsReducer(state = initialState, action: AuroraProjectsAction) {
	switch (action.type) {
		case auroraProjectsActions.SET_DESIGNS:
			return Object.assign({}, state, { designs: action.data, loadingDesigns: true });
		case auroraProjectsActions.GET_CURRENT_DESIGN:
			return Object.assign({}, state, { loadingDesign: true });
		case auroraProjectsActions.SET_CURRENT_DESIGN:
			return Object.assign({}, state, { currentDesign: action.data, loadingDesign: false });
		case auroraProjectsActions.SET_IS_AURORA_PROJECT_DETAIL_MODAL_SHOWN:
			return Object.assign({}, state, { isAuroraProjectDetailModalShown: action.data });
		default:
			return state;
	}
}
