import { marketPipelineActions } from "../actions/actions";

const initialState: MarketPipelineState = {
	currentRep: localStorage.getItem("email") || "",
	viewType: "list"
};

export default function marketPipelineReducer(state = initialState, action: MarketPipelineAction) {
	switch (action.type) {
		case marketPipelineActions.GET_CUSTOMERS:
			return Object.assign({}, state, { loadingCustomers: true });
		case marketPipelineActions.SET_LOADING_CUSTOMERS:
			return Object.assign({}, state, { loadingCustomers: action.data });
		case marketPipelineActions.SET_CUSTOMER_LIST:
			return Object.assign({}, state, { loadingCustomers: false, customerList: action.data });
		case marketPipelineActions.SET_FILTERED_CUSTOMER_LIST:
			return Object.assign({}, state, { filteredCustomerList: action.data });
		case marketPipelineActions.SET_IS_CUSTOMER_DETAIL_MODAL_SHOWN:
			return Object.assign({}, state, { isCustomerDetailModalShown: action.data });
		case marketPipelineActions.SET_IS_CREATE_CUSTOMER_MODAL_SHOWN:
			return Object.assign({}, state, { isCreateCustomerModalShown: action.data });
		case marketPipelineActions.SET_CURRENT_CUSTOMER:
			return Object.assign({}, state, { currentCustomer: action.data });
		case marketPipelineActions.SET_VIEW_TYPE:
			return Object.assign({}, state, { viewType: action.data });
		default:
			return state;
	}
}
