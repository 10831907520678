import React from "react";
import {
	iaSchema,
	solarSchema,
	roofSchema,
	waSchema,
	// solarAdderSchema,
	// roofAdderSchema
} from "./data/schema";
import {
	setDraftData,
	setForm,
	setShowDraftSubmission,
	setSubmitting,
} from "../../redux/actions/installAgreement";
import apiCall from "../../utils/api";
import { ValidationError } from "yup";
import { setValidationErrors } from "../../redux/actions/installAgreement";
import { toast } from "react-toastify";
import { setCompletedSubmission } from "../../redux/actions/installAgreement";
import { Nav } from "react-bootstrap";

const submissionErrorToastMsg = <div className="submission-error-msg-toast">
	There was an error submitting your Installation Agreement. Try again or click <Nav.Link href="https://infinitysolarusa.atlassian.net/servicedesk/customer/portal/2/group/9" target="_blank">here</Nav.Link> to report an issue
</div>;

const showIASubmissionErrorToast = (message?: string) => {
	toast.error(message ? message : submissionErrorToastMsg, {
		position: "top-right",
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "dark",
		toastId: "ia-Submission-Error",
		onClick: () => window.close(),
		autoClose: false
	});
};

const addRepDetails = formData => {
	formData["salesrep_name"] = localStorage.getItem("Name") || "";
	formData["salesrep_email"] = localStorage.getItem("email") || "";
	if (formData["ppw"] === "" || formData["ppw"] === "0.00") {
		delete formData["ppw"];
	}
	return formData;
};


function handle_error(e, dispatch) {
	if (e.response && e.response.data.includes("Discount is too large")) {
		showIASubmissionErrorToast("Discount is too large");
		dispatch(setValidationErrors({ discount_value: "Discount is too large" }));
	}
	else if (e.response && e.response.status != 500) {
		if (e.response.data.includes("Invalid adder price")) {
			const error_msg = e.response.data.split("<p>")[1].split("</p>")[0];
			showIASubmissionErrorToast(error_msg);
		}
		else if (e.response.data.includes("Unable to validate redline or price is not correct")) {
			const error_msg = e.response.data.replace("Unable to validate redline or price is not correct", "Price Per Watt is too low");
			showIASubmissionErrorToast(error_msg);
		}
	}
	else {
		showIASubmissionErrorToast();
	}
}

export const submitDryRun = (formData, dispatch) => {
	apiCall({
		url: `${process.env.REACT_APP_API_SERVICE}/solar-roof-pricing-calculations/draft`,
		method: "POST",
		body: addRepDetails(formData)
	}).then(res => {
		if (res) {
			dispatch(setDraftData(res));
			dispatch(setShowDraftSubmission(true));
		} else {
			showIASubmissionErrorToast();
		}
	}).catch((e) => {
		handle_error(e, dispatch);
	}).finally(() => {
		dispatch(setSubmitting(false));
	});
};


export const submitForm = (formData, dispatch) => {
	dispatch(setSubmitting(true));
	apiCall({
		url: `${process.env.REACT_APP_API_SERVICE}/solar-roof-pricing-calculations/`,
		method: "POST",
		body: addRepDetails(formData)
	}).then((res) => {
		if (res) {
			dispatch(setCompletedSubmission(true));
			dispatch(setForm({
				cancellation_verbiage: "site_survey",
				setter: "other"
			}));
		} else {
			showIASubmissionErrorToast();
		}
	}).catch((e) => {
		handle_error(e, dispatch);
	}).finally(() => {
		dispatch(setSubmitting(false));
	});
};

const MAX_PPW = 5;

export const validateForm = async (formData, pricing_ppw) => {
	const currentValidationErrors: ValidationErrors = {};

	await iaSchema.validate(formData, { abortEarly: false }).catch((e: ValidationError) => e.inner.forEach(e => {
		if (e.path) {
			currentValidationErrors[e.path] = e.message;
		}
	}));

	if (formData.roof_checkbox) {
		await roofSchema.validate(formData, { abortEarly: false }).catch((e: ValidationError) => e.inner.forEach(e => {
			if (e.path) {
				currentValidationErrors[e.path] = e.message;
			}
		}));
	}

	if (formData.solar_checkbox) {
		await solarSchema.validate(formData, { abortEarly: false }).catch((e: ValidationError) => e.inner.forEach(e => {
			if (e.path) {
				currentValidationErrors[e.path] = e.message;
			}
		}));
	}

	if (formData.state === "Washington") {
		await waSchema.validate(formData, { abortEarly: false }).catch((e: ValidationError) => e.inner.forEach(e => {
			if (e.path) {
				currentValidationErrors[e.path] = e.message;
			}
		}));
	}

	if (formData.solar_adder_checkbox) {
		if (formData.solaradder) {
			Object.keys(formData.solaradder).forEach(key => {
				if (!currentValidationErrors.solaradder) {
					currentValidationErrors.solaradder = {};
				}
				Object.keys(formData.solaradder[key]).forEach(subkey => {
					const parsedValue = parseFloat(formData.solaradder[key][subkey]);
					if (formData.solaradder[key][subkey] === "" || Number.isNaN(parsedValue)) {
						// currentValidationErrors.solaradder[subkey] = "Please enter a valid value";
						// currentValidationErrors.solaradder[subkey] = "";
					}
					if (subkey.includes("amount")) {
						const amount = parseFloat(formData.solaradder[key][subkey]);
						if (Number.isNaN(amount) || amount <= 0) {
							// currentValidationErrors.solaradder[subkey] = "Please enter a valid value";
							currentValidationErrors.solaradder[subkey] = "";
						}
					}
				});
			});
			if (currentValidationErrors.solaradder && Object.keys(currentValidationErrors.solaradder).length === 0) {
				delete currentValidationErrors.solaradder;
			}
		}
	}

	if (formData.roof_adder_checkbox) {
		if (formData.roofadder) {
			Object.keys(formData.roofadder).forEach(key => {
				if (!currentValidationErrors.roofadder) {
					currentValidationErrors.roofadder = {};
				}
				Object.keys(formData.roofadder[key]).forEach(subkey => {
					const parsedValue = parseFloat(formData.roofadder[key][subkey]);
					if (formData.roofadder[key][subkey] === "" || Number.isNaN(parsedValue)) {
						// currentValidationErrors.roofadder[subkey] = "Please enter a valid value";
						// currentValidationErrors.roofadder[subkey] = "";
					}
					if (subkey.includes("amount")) {
						const amount = parseFloat(formData.roofadder[key][subkey]);
						if (Number.isNaN(amount) || amount <= 0) {
							// currentValidationErrors.roofadder[subkey] = "Please enter a valid value";
							currentValidationErrors.roofadder[subkey] = "";
						}
					}
				});
			});
			if (currentValidationErrors.roofadder && Object.keys(currentValidationErrors.roofadder).length === 0) {
				delete currentValidationErrors.roofadder;
			}
		}
	}

	const ppw = formData.ppw;
	if (ppw) {
		if ((pricing_ppw && ppw < pricing_ppw.redline) || ppw > MAX_PPW)
			currentValidationErrors.ppw = "Invalid Price Per Watt";
	}

	if (!formData.roof_checkbox && !formData.solar_checkbox) {
		currentValidationErrors.solar_checkbox = "Please select either solar or roof";
		currentValidationErrors.roof_checkbox = "Please select either solar or roof";
	}

	if (formData.cohomeowner && !formData.cohomeowner_email && !currentValidationErrors.cohomeowner_email) {
		currentValidationErrors.cohomeowner_email = "Please enter co-homeowner email";
	}

	if (formData.cohomeowner_email && !formData.cohomeowner && !currentValidationErrors.cohomeowner_email) {
		currentValidationErrors.cohomeowner = "Please select co-homeowner";
	}
	return currentValidationErrors;
};

export const createFormElements = (formData, solarAdderState, roofAdderState) => {
	const allElements: InstallAgreementSubmitFormData = createFormValues(formData);

	if (allElements.solar_adder_checkbox) {
		allElements.solaradder = createAdderFormValues(solarAdderState, allElements);
	}
	if (allElements.roof_adder_checkbox) {
		allElements.roofadder = createAdderFormValues(roofAdderState, allElements);
	}
	return allElements;
};

const createFormValues = (data: InstallAgreementFormState) => {
	return Object.keys(data).reduce((accumulator, fieldKey) => {
		if (fieldKey === "eto_swr" || fieldKey === "odoe_lmi") {
			if (!accumulator.incentives) accumulator.incentives = {};
			if (data[fieldKey] !== "" && data[fieldKey] !== undefined) accumulator.incentives[data[fieldKey] as string] = true;
			return accumulator;
		}
		return data[fieldKey] ? { ...accumulator, [fieldKey]: data[fieldKey] as string } : accumulator;
	}, {} as InstallAgreementSubmitFormData);
};

const createAdderFormValues = (data: AdderValue, allData) => {
	const target = {} as { [key: string]: { [key: string]: boolean | string | number } };
	if (data && Object.keys(data).length) {
		Object.keys(data).forEach(dataKey => {
			if (data[dataKey].checked) {
				target[dataKey] = {};
				if (typeof data[dataKey].amount === "string") {
					if (allData[data[dataKey].amount]) {
						target[dataKey][`${dataKey}_amount`] = data[dataKey].multiplier ?
							parseFloat(allData[data[dataKey].amount]) * data[dataKey].multiplier
							:
							allData[data[dataKey].value.amount];
					} else {
						target[dataKey][`${dataKey}_amount`] = data[dataKey].amount + "";
					}
				} else {
					target[dataKey][`${dataKey}_amount`] = data[dataKey].amount + "";
				}
				if (data[dataKey] && data[dataKey].pricePer) {
					target[dataKey][`${dataKey}_price`] = data[dataKey].pricePer + "";
				} else if (data[dataKey] != undefined && data[dataKey].defaultPricePer != undefined) {
					target[dataKey][`${dataKey}_price`] = data[dataKey].defaultPricePer + "";
				}
			}
		});
	}
	return target;
};
