import React, { useState, useEffect } from "react";
import InstallAgreementForm from "../Components/InstallAgreement/InstallAgreementForm";
import Card from "react-bootstrap/Card";
import "./styles/InstallAgreement.sass";
import { setCompletedSubmission, setShowPricingModal, getPricing, setFormValue } from "../redux/actions/installAgreement";
import { SelectInput, fieldTypes } from "../Components/Form";
import { Button, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Heading from "../Components/Heading/Heading";
import Modal from "../Components/Modal/Modal";
import WhenAuthorized from "../Components/WhenAuthorized";
import { UserPermissions } from "../enums/UserPermissions";
import { getProfile } from "../redux/actions/profileActions";
import ViewPricing from "../Components/Prices/ViewPricing";
import { getAllCompanies } from "../redux/actions/users";
import { setFormCompany } from "../redux/actions/installAgreement";
import checkIfAuthorized from "../utils/permissions";
import { useNavigate } from "react-router-dom";


function InstallAgreementPage() {
	const completedSubmission = useSelector(({ installAgreement }: RootState) => installAgreement.completedSubmission);
	const pricing = useSelector(({ installAgreement }: RootState) => installAgreement.pricing);
	const showPricingModal = useSelector(({ installAgreement }: RootState) => installAgreement.showPricingModal);
	const profile_details = useSelector(({ profile }: RootState) => profile.profile);
	const companySelection = useSelector(({ installAgreement }: RootState) => installAgreement?.company);
	const allCompanies = useSelector(({ users }: RootState) => users.allCompanies);
	const installFormData = useSelector(({ installAgreement }: RootState) => installAgreement?.form);
	const original_homeowner = useSelector(({ installAgreement }: RootState) => installAgreement?.original_homeowner);
	const customer_id_present = installFormData?.customer_id ? true : false;
	const [accessCompanies, setAccessCompanies] = useState<Array<string>>([]);
	const [dropDownCompanies, setDropDownCompanies] = useState<Array<SelectOption>>([]);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (profile_details === undefined) {
			dispatch(getProfile());
		}
		if (allCompanies === undefined && checkIfAuthorized(UserPermissions.pa_company_finance)) {
			dispatch(getAllCompanies(""));
			setAccessCompanies(profile_details?.has_access_to_companies as Array<string>);
		}
	}, []);

	useEffect(() => {
		dispatch(setFormCompany(profile_details?.company));
		dispatch(setFormValue({ ["company"]: profile_details?.company }));
	}, [profile_details?.company]);

	useEffect(() => {
		if (allCompanies) {
			setDropDownCompanies(createCompanyDropdown(allCompanies));
		}
	}, [allCompanies]);

	useEffect(() => {
		const market = installFormData?.market;
		if (companySelection && typeof market === "string" && market != "") {
			dispatch(getPricing({ "company": companySelection, "market": market }));
		}
		else if (profile_details?.company && typeof market === "string" && market != "") {
			dispatch(getPricing({ "company": profile_details.company, "market": market }));

		}
	}, [companySelection, profile_details, installFormData?.market]);

	const handleCompaniesChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (accessCompanies?.length) {
			if (accessCompanies.includes(event.target.value)) {
				return;
			}
			setAccessCompanies([...accessCompanies, event.target.value]);
		} else {
			setAccessCompanies([event.target.value]);
		}
	};

	const createCompanyDropdown = (allCompanies: object) => {
		const companyDropDown: Array<SelectOption> = [];
		if (allCompanies["companies"]) {
			allCompanies["companies"].forEach((element, i) => {
				companyDropDown.push({ value: element, label: element, key: i });
			});
		}
		return companyDropDown;
	};

	const successCard = (
		<Card
			bg="success"
			key="success"
			text="white"
			className="center success-card"
			id="successCard"
			onClick={() => dispatch(setCompletedSubmission(false))}
		>
			<Card.Body>
				<Card.Title>Thank you for your submission.</Card.Title>
				<Card.Text>
					The project agreement was created. Please check your email for it.
					<br />
					<br />
					Click on this card to create a new one
				</Card.Text>
			</Card.Body>
		</Card >
	);

	const onPricingClick = () => {
		if (companySelection) {
			dispatch(getPricing({ "company": companySelection, "startDate": null, "endDate": null }));
			dispatch(setShowPricingModal(true));
		}
		else if (profile_details?.company) {
			dispatch(getPricing({ "company": profile_details.company, "startDate": null, "endDate": null }));
			dispatch(setShowPricingModal(true));
		}
	};

	const onCustomerIDPresent = () => {
		if (customer_id_present && original_homeowner != "" && typeof original_homeowner === "string") {
			return <>
				<p className="customer-id-present">
					Creating an agreement for customer {original_homeowner}.
				</p>
				<div className="customer-id-present-div">
					<Button className="refresh-button" onClick={() => { window.location.reload(); }}>
						Refresh for a New Customer
					</Button>
				</div>
			</>;
		}
	};

	const checkProjectAgreementForm = () => {
		if (localStorage.getItem("company") === "Infinity Solar USA") {
			return ProjectAgreementForm;
		}
		else if (localStorage.getItem("company") !== "Infinity Solar USA" && customer_id_present) {
			return ProjectAgreementForm;
		}
		else {
			navigate("/customer");
		}
	};

	const ProjectAgreementForm = (
		<>
			<Heading>Project Agreement</Heading>
			{(onCustomerIDPresent())}
			<div className="company-dropdown">
				<WhenAuthorized permission={UserPermissions.pa_company_finance}>
					<SelectInput
						field={{
							controlId: "has_access_to_companies",
							label: "Company Selection",
							name: "has_access_to_companies",
							dropdownData: dropDownCompanies,
							type: fieldTypes.dropdown,
							onChange: e => {
								dispatch(setFormCompany(e.target.value));
								dispatch(setFormValue({ ["company"]: e.target.value }));
								handleCompaniesChange(e);
							},
							value: companySelection ? companySelection : profile_details?.company,
						}}
					/>
				</WhenAuthorized>
			</div>
			{
				localStorage.getItem("company") === "Infinity Solar USA" && (
					<WhenAuthorized permission={UserPermissions.show_prices_button}>
						<>
							<Button onClick={onPricingClick} className="view-pricing-button">View Pricing</Button>
							<Modal
								isShown={showPricingModal}
								hideModal={() => {
									dispatch(setShowPricingModal(false));
								}}
								body={
									pricing ? <ViewPricing pricing={pricing} /> : <></>
								}
								heading="Pricing"
								addlProps={{ size: "lg" }}
							/>
						</>
					</WhenAuthorized>)
			}
			{
				completedSubmission ? successCard :
					<InstallAgreementForm />
			}
		</>);

	return (
		<Container className="install-agreement-container">
			{checkProjectAgreementForm()}
		</Container >
	);
}

export default InstallAgreementPage;
