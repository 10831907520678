export enum UserPermissions {
    leaderboard = "portal/leaderboard",
    commission = "portal/commission",
    customer = "portal/customer-pipeline",
    user = "portal/users",
    agreement = "portal/installation-agreement",
    external_agreement = "portal/installation-agreement-external",
    validator = "portal/validator",
    reports = "portal/reports",
    canvass = "portal/sales/canvass",
    acuity = "portal/sales/acuity",
    adpPositionId = "portal/users/adp_position_id",
    createCustomer = "portal/customer-pipeline/create-customer",
    performanceAuditor = "portal/performance-auditor",
    admin = "*",
    program = "portal/installation-agreement/program",
    after_cad_approved = "portal/installation-agreement/cancel-option/after-cad-approved",
    promo_approved = "portal/installation-agreement/promo-option",
    ppw_input = "portal/installation-agreement/price-per-watt",
    promotions_input = "portal/installation-agreement/promotions-input",
    initialFinalReceived = "portal/csm-data-validator/customer-sheet-payment-vs-revenue/initial-and-final-amount-received",
    incentivesReceived = "portal/csm-data-validator/customer-sheet-payment-vs-revenue/total-incentives-received",
    totalReceived = "portal/csm-data-validator/customer-sheet-payment-vs-revenue/total-received",
    solar_panel_report = "portal/reports/solar-panel-report",
    construction_funnel_report = "portal/construction-funnel-report",
    sales_market_report = "portal/sales-market-report",
    show_prices = "portal/show-prices",
    show_prices_button = "portal/show-prices-button",
    auroraSolarProject = "portal/aurora-solar-project",
    siteSurvey = "portal/site-survey",
    edit_customer = "portal/customer-pipeline/edit-customer",
    pricing = "portal/show-prices",
    market = "portal/market",
    pa_company_finance = "portal/company-finance",
    portal_export_caliber = "portal/export/caliber",
    edit_prices = "portal/edit-prices",
    view_pricing_page = "portal/pricing-page",
    closerAppointment = "portal/acuity/closer",
    company = "portal/company",
    redline = "portal/ia-price-adjuster/redline",
    redlineInsert = "portal/ia-price-adjuster/redline/insert",
    managerCommission = "portal/commission-manager",
    commissionCalculation = "portal/commission-calculation",
    powerbi = "portal/powerbi",
    infinityDSR = "portal/infinity-dsr",
    dataValidation = "portal/csm-data-validator/data-validation",
    none = "",
}
