import { commissionLedgerActions } from "../actions/actions";

const initialState: CommissionLedgerState = {
	currentRep: localStorage.getItem("email") || "",
	editType: "add"
};

export default function commissionLedgerReducer(state = initialState, action: CommissionLedgerAction) {
	switch (action.type) {
		case commissionLedgerActions.GET_COMMISSION_DATA:
			return Object.assign({}, state, { loadingCommissionData: true });
		case commissionLedgerActions.SET_COMMISSION_DATA:
			return Object.assign({}, state, { loadingCommissionData: false, commissionData: action.data });
		case commissionLedgerActions.SET_LOADING_COMMISSION_DATA:
			return Object.assign({}, state, { loadingCommissionData: action.data });
		case commissionLedgerActions.SET_CURRENT_REP:
			return Object.assign({}, state, { currentRep: action.data });
		case commissionLedgerActions.GET_CUSTOMER_ACTIVITY:
			return Object.assign({}, state, { loadingCustomerActivity: true, modalShown: true, selectedCustomer: (action.data as { customer_id: string }).customer_id });
		case commissionLedgerActions.SET_CUSTOMER_ACTIVITY:
			return Object.assign({}, state, { loadingCustomerActivity: false, customerActivity: action.data });
		case commissionLedgerActions.SET_LOADING_CUSTOMER_ACTIVITY:
			return Object.assign({}, state, { loadingCustomerActivity: action.data });
		case commissionLedgerActions.SET_MODAL_SHOWN:
			return Object.assign({}, state, { modalShown: action.data });
		case commissionLedgerActions.SET_NOTE:
			return Object.assign({}, state, { note: action.data });
		case commissionLedgerActions.SET_SUBMITTING_NOTE:
			return Object.assign({}, state, { submittingNote: action.data });
		case commissionLedgerActions.SUBMIT_NOTE:
			return Object.assign({}, state, { submittingNote: true });
		case commissionLedgerActions.SET_EDIT_TYPE:
			return Object.assign({}, state, { editType: (action.data as { type: string }).type, editId: (action.data as { id: string }).id });
		case commissionLedgerActions.SUBMIT_NOTE_EDIT:
			return Object.assign({}, state, { submittingNoteEdit: true });
		case commissionLedgerActions.SET_SUBMITTING_NOTE_EDIT:
			return Object.assign({}, state, { submittingNoteEdit: action.data });
		case commissionLedgerActions.DELETE_NOTE:
			return Object.assign({}, state, { deletingNote: true });
		case commissionLedgerActions.SET_DELETING_NOTE:
			return Object.assign({}, state, { deletingNote: action.data });
		case commissionLedgerActions.SET_CURRENT_CUSTOMER:
			return Object.assign({}, state, { selectedCustomer: action.data });
		default:
			return state;
	}
}
