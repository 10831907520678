import React from "react";
import "./CustomerCard.sass";

const CustomerCard = ({ customer, onClick }) => {
	return <div className="customer-card-wrapper" onClick={() => onClick(customer)}>
		<div className="customer-card-header">
			<div className="customer-card-header-left">
				<div className="customer-card-header-left-name">{`${customer.first_name} ${customer.last_name}`}</div>
			</div>
		</div>
		<div className="customer-card-body">
			<div className="customer-card-body-top">
				<div className="customer-card-detail-container">
					<div className="customer-card-detail-key">Status:</div>
					<div className="customer-card-detail-value">{customer.status_name}</div>
				</div>
				{/* <div className="customer-card-body-top-status"><b>Status:</b> Sales Lead</div> */}
			</div>
			<div className="customer-card-body-bottom">
				<div className="customer-card-detail-container">
					<div className="customer-card-detail-key">Revenue:</div>
					<div className="customer-card-detail-value">{customer.total_combined_revenue ? customer.total_combined_revenue : "$0"}</div>
				</div>
				<div className="customer-card-detail-container">
					<div className="customer-card-detail-key">Days in Status:</div>
					<div className="customer-card-detail-value">{customer.days_in_status}</div>
				</div>
			</div>
		</div>
	</div>;
};

export default CustomerCard;
