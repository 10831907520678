import React from "react";
import Form from "react-bootstrap/Form";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({
	value,
	setValue,
	label,
	disabled = false,
	labelClasses,
	placeholder,
	controlId,
	minDate,
	maxDate,
	validation
}: DatePickerProps) => 
	<Form.Group>
		{ label ?
			<Form.Label
				className={labelClasses}
				htmlFor={controlId}
			>
				{label}
			</Form.Label>
			: <></>
		}
		<ReactDatePicker
			selected={value ? new Date(value) : null}
			placeholderText={placeholder}
			{...(maxDate ? { maxDate: new Date(maxDate) } : {})}
			{...(minDate ? { minDate: new Date(minDate) } : {})}
			{...(setValue ? { onChange: (date: string) => setValue(new Date(date).toISOString().substring(0, 10)) } : {})}
			disabled={disabled}
			className="form-control"
			id={controlId}
		/>
		{ validation && Object.keys(validation).length && controlId && validation[controlId] ? <p className="field-validation-error">
			{validation[controlId] as string}
		</p> : "" }
	</Form.Group>;

export default DatePicker;
