import "./UserList.sass";
import React, { FunctionComponent, useState } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

interface UserList {
	userList: User[];
	setSelectedUser: (user: User) => void;
}

const UserList: FunctionComponent<UserList> = ({ userList, setSelectedUser }) => {
	const [filterString, setFilterString] = useState<string>("");
	const [filteredUserList, setFilteredUserList] = useState<User[] | undefined>();

	const handleChange = ((event: React.ChangeEvent<HTMLInputElement>) => {
		setFilterString(event.target.value);
		const filteredList = [] as Array<User>;
		for (const key in userList) {
			const user = userList[Number(key)];
			if (
				((user.name !== null && user.name.toUpperCase().includes(event.target.value.toUpperCase()))
					|| (user.email !== null && user.email.toUpperCase().includes(event.target.value.toUpperCase()))
					|| (user.department !== null && user.department.toUpperCase().includes(event.target.value.toUpperCase()))
					|| (user.role !== null && user.role.toUpperCase().includes(event.target.value.toUpperCase())))
				&& user.name !== null
			) {
				filteredList.push(user);
			}
		}
		setFilteredUserList(filteredList);
	});

	let userKey = 0;

	return (
		<Container className="UserList">
			<div className="userListDiv">
				<Form.Group className="mb-3" controlId="userEmail">
					<Form.Control
						type="text"
						placeholder="Search <name, email, department, role>"
						name="filter"
						defaultValue=""
						value={filterString}
						onChange={handleChange}
					/>
					<Form.Text className="text-white">
						Search for user
					</Form.Text>
				</Form.Group>
				{filteredUserList ?
					<div className="userListContainer">
						<ListGroup as="ul">
							{filteredUserList.map((user) => (
								<ListGroup.Item as="li" key={userKey++}>
									<Row>
										<Col sm={8}><p className="userEmail">{user.name}</p></Col>
										<Col sm={4} align="end">
											<Button className="float-right" variant="primary" onClick={() => setSelectedUser(user)}>Edit</Button>
										</Col>
									</Row>
									<Row>
										<Col sm>{user.email}</Col>
									</Row>
								</ListGroup.Item>
							))}
						</ListGroup>
					</div> :
					<></>
				}
			</div>
		</Container>
	);
};


export default UserList;

