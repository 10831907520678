import { reportsActions } from "./actions";

export const getConstructionFunnelData = () => ({
	type: reportsActions.GET_CONSTRUCTION_FUNNEL_DATA
});

export const setConstructionFunnelData = (data: ConstructionFunnelReportData[]) => ({
	type: reportsActions.SET_CONSTRUCTION_FUNNEL_DATA,
	data
});

export const setIsCustomerListModalShown = (data: boolean) => ({
	type: reportsActions.SET_IS_CUSTOMER_LIST_MODAL_SHOWN,
	data
});

export const setCustomerList = (data: Customer[]) => ({
	type: reportsActions.SET_CUSTOMER_LIST,
	data
});

export const getCustomerList = (data: string[]) => ({
	type: reportsActions.GET_CUSTOMER_LIST,
	data
});

export const setLoadingCustomerList = (data: boolean) => ({
	type: reportsActions.SET_LOADING_CUSTOMER_LIST,
	data
});

export const getFunnelReportDefinitionList = () => ({
	type: reportsActions.GET_FUNNEL_REPORT_DEFINITION_LIST
});

export const setFunnelReportDefinitionList = (data: FunnelReportDefinition[]) => ({
	type: reportsActions.SET_FUNNEL_REPORT_DEFINITION_LIST,
	data
});

export const getSalesMarketReport = (data: object) => ({
	type: reportsActions.GET_SALES_MARKET_REPORT,
	data
});

export const setSalesMarketReport = (data: SalesMarketReportData[]) => ({
	type: reportsActions.SET_SALES_MARKET_REPORT,
	data
});

export const setLoadingSalesMarketReport = (data: boolean) => ({
	type: reportsActions.SET_LOADING_SALES_MARKET_REPORT,
	data
});

export const setSalesMarketReportQuarter = (data: object) => ({
	type: reportsActions.SET_SALES_MARKET_REPORT_QUARTER,
	data
});

export const setSalesMarketValuesModalShown = (data: boolean) => ({
	type: reportsActions.SET_SALES_MARKET_VALUES_MODAL_SHOWN,
	data
});

export const setSalesMarketValues = (data: SalesMarketReportValue[]) => ({
	type: reportsActions.SET_SALES_MARKET_VALUES,
	data
});

export const getClosedOutSalesRepsData = () => ({
	type: reportsActions.GET_CLOSED_OUT_SALES_REPS_DATA
});

export const setClosedOutSalesRepsData = (data: ClosedOutSalesRepsReportData[]) => ({
	type: reportsActions.SET_CLOSED_OUT_SALES_REPS_DATA,
	data
});
