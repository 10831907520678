import { customerPipelineActions } from "./actions";

export const setCurrentRep = (data: string) => ({
	type: customerPipelineActions.SET_CURRENT_REP,
	data
});

export const getCustomers = (data: string) => ({
	type: customerPipelineActions.GET_CUSTOMERS,
	data
});

export const setLoadingCustomers = (data: boolean) => ({
	type: customerPipelineActions.SET_LOADING_CUSTOMERS,
	data
});

export const setCustomerList = (data: Customer[]) => ({
	type: customerPipelineActions.SET_CUSTOMER_LIST,
	data
});

export const setFilteredCustomerList = (data: Customer[]) => ({
	type: customerPipelineActions.SET_FILTERED_CUSTOMER_LIST,
	data
});

export const setIsCustomerDetailModalShown = (data: boolean) => ({
	type: customerPipelineActions.SET_IS_CUSTOMER_DETAIL_MODAL_SHOWN,
	data
});

export const setIsCreateCustomerModalShown = (data: boolean) => ({
	type: customerPipelineActions.SET_IS_CREATE_CUSTOMER_MODAL_SHOWN,
	data
});

export const setIsExportPageShown = (data: boolean) => ({
	type: customerPipelineActions.SET_IS_EXPORT_PAGE_SHOWN,
	data
});

export const setCurrentCustomer = (data: Customer) => ({
	type: customerPipelineActions.SET_CURRENT_CUSTOMER,
	data
});

export const getReps = () => ({
	type: customerPipelineActions.GET_REPS
});

export const setViewType = (data: string) => ({
	type: customerPipelineActions.SET_VIEW_TYPE,
	data
});

export const setExport = (data: string) => ({
	type: customerPipelineActions.SET_EXPORT,
	data
});