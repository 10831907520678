import React, { useState } from "react";
import Table from "../Table/Table";
import { Button } from "react-bootstrap";
import "./AddPrices.sass";
import { useDispatch } from "react-redux";
import { addCompanyConfig } from "../../redux/actions/iaPriceAdjuster";
import validator from "./PricingConfigValidate";
import { tableColumns } from "./data/pricingTableColumns";


const DataTable = () => {
	const dispatch = useDispatch();
	const [tableData, setTableData] = useState({});
	const [solarBasePriceStateData, setsolarBasePriceState] = useState([{}]);
	const [promotionsData, setPromotions] = useState([{}]);
	const [roofPriceData, setRoofPrice] = useState([{}]);
	const [roofTaxData, setRoofTax] = useState([{}]);
	const [marketAdjustmentData, setMarketAdjustment] = useState([{}]);
	const [solarAdderData, setSolarAdder] = useState([{}]);
	const [roofAdderData, setRoofAdder] = useState([{}]);
	const [panelFeeData, setPanelFee] = useState([{}]);
	const [incentivesData, setIncentives] = useState([{}]);
	const [loanOptionsData, setLoanOptions] = useState([{}]);
	const [companyData,] = useState([{}]);
	// const [pdfData,] = useState([{}]);
	const [marketData,] = useState([{}]);
	const [notesData,] = useState([{}]);
	const [startDateData,] = useState([{}]);
	const [endDateData,] = useState([{}]);

	const updateTableData = (rowIndex, columnKey, cellValue) => {
		if (tableData[columnKey]) {
			Object.assign(tableData[columnKey], { [`"${columnKey}_${rowIndex}"`]: cellValue });
		} else {
			Object.assign(tableData, { [columnKey]: { [`"${columnKey}_${rowIndex}"`]: cellValue } });
		}
		setTableData(tableData);
	};

	return <div>
		<>
			<h4>Solar Base Price State</h4>
			<div className="pricing-detail-modal-table">
				<Table
					columns={tableColumns.solarBasePriceState}
					data={solarBasePriceStateData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setsolarBasePriceState([...solarBasePriceStateData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Promotions</h4>
			<div className="pricing-detail-modal-table">
				<Table
					columns={tableColumns.promotions}
					data={promotionsData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setPromotions([...promotionsData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Roofing Price</h4>
			<div className="pricing-detail-modal-table">
				<Table
					columns={tableColumns.roofPrice}
					data={roofPriceData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setRoofPrice([...roofPriceData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Roofing Tax per State</h4>
			<div className="pricing-detail-modal-table">
				<Table
					columns={tableColumns.roofTax}
					data={roofTaxData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setRoofTax([...roofTaxData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Market Adjustment</h4>
			<div className="pricing-detail-modal-table">
				<Table
					columns={tableColumns.marketAdjustment}
					data={marketAdjustmentData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setMarketAdjustment([...marketAdjustmentData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Solar Adders</h4>
			<div className="pricing-detail-modal-table">
				<Table
					columns={tableColumns.solarAdder}
					data={solarAdderData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setSolarAdder([...solarAdderData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Roof Adders</h4>
			<div className="pricing-detail-modal-table">
				<Table
					columns={tableColumns.roofAdder}
					data={roofAdderData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setRoofAdder([...roofAdderData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Panel Fees</h4>
			<div className="pricing-detail-modal-table">
				<Table
					columns={tableColumns.panelFee}
					data={panelFeeData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setPanelFee([...panelFeeData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Incentives</h4>
			<div className="pricing-detail-modal-table">
				<Table
					columns={tableColumns.incentives}
					data={incentivesData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setIncentives([...incentivesData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>

		<>
			<br></br>
			<h4>Loan Options</h4>
			<div className="pricing-detail-modal-table">
				<Table
					columns={tableColumns.loanOptions}
					data={loanOptionsData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</div>
			<Button onClick={() => setLoanOptions([...loanOptionsData, {}])} className="add-pricing-row-button">Add Row</Button>
			<br></br>
		</>
		{/*
			<>
				<br></br>
				<h4>PDF Link</h4>
				<Table
					columns={tableColumns.pdf}
					data={pdfData}
					trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
					updateTableData={updateTableData}
				/>
			</> */}

		<>
			<br></br>
			<h4>Company</h4>
			<Table
				columns={tableColumns.company}
				data={companyData}
				trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
				updateTableData={updateTableData}
			/>
		</>

		<>
			<br></br>
			<h4>Market</h4>
			<Table
				columns={tableColumns.market}
				data={marketData}
				trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
				updateTableData={updateTableData}
			/>
		</>

		<>
			<h4>Notes</h4>
			<Table
				columns={tableColumns.notes}
				data={notesData}
				trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
				updateTableData={updateTableData}
			/>
		</>

		<>
			<h4>Start Date UTC</h4>
			<Table
				columns={tableColumns.startDate}
				data={startDateData}
				trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
				updateTableData={updateTableData}
			/>
		</>

		<>
			<h4>End Date UTC</h4>
			<Table
				columns={tableColumns.endDate}
				data={endDateData}
				trProps={{ onClick: () => console.log(), className: "customer-table-row" }}
				updateTableData={updateTableData}
			/>
		</>
		<Button onClick={() => {
			const res = validator(tableData);
			if (typeof res === "object") {
				dispatch(addCompanyConfig(res));
			}
		}} className="submit-pricing-button">Add Pricing</Button>

	</div>;
};

export default DataTable;
