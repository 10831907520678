import { customerPipelineActions } from "../actions/actions";

const initialState: CustomerPipelineState = {
	currentRep: localStorage.getItem("email") || "",
	viewType: "list"
};

export default function customerPipelineReducer(state = initialState, action: CustomerPipelineAction) {
	switch (action.type) {
		case customerPipelineActions.GET_CUSTOMERS:
			return Object.assign({}, state, { loadingCustomers: true });
		case customerPipelineActions.SET_LOADING_CUSTOMERS:
			return Object.assign({}, state, { loadingCustomers: action.data });
		case customerPipelineActions.GET_REPS:
			return Object.assign({}, state, { loadingReps: true });
		case customerPipelineActions.SET_CUSTOMER_LIST:
			return Object.assign({}, state, { loadingCustomers: false, customerList: action.data });
		case customerPipelineActions.SET_FILTERED_CUSTOMER_LIST:
			return Object.assign({}, state, { filteredCustomerList: action.data });
		case customerPipelineActions.SET_CURRENT_REP:
			return Object.assign({}, state, { currentRep: action.data });
		case customerPipelineActions.SET_IS_CUSTOMER_DETAIL_MODAL_SHOWN:
			return Object.assign({}, state, { isCustomerDetailModalShown: action.data });
		case customerPipelineActions.SET_IS_CREATE_CUSTOMER_MODAL_SHOWN:
			return Object.assign({}, state, { isCreateCustomerModalShown: action.data });
		case customerPipelineActions.SET_IS_EXPORT_PAGE_SHOWN:
			return Object.assign({}, state, { isExportPageShown: action.data });
		case customerPipelineActions.SET_CURRENT_CUSTOMER:
			return Object.assign({}, state, { currentCustomer: action.data });
		case customerPipelineActions.SET_VIEW_TYPE:
			return Object.assign({}, state, { viewType: action.data });
		default:
			return state;
	}
}
