import { usersActions } from "../actions/actions";

const initialState: UsersState = {
	submitting: false,
	isCreateUserModalShown: false
};

export default function usersReducer(state = initialState, action) {
	switch (action.type) {
		case usersActions.GET_USERS:
			return Object.assign({}, state, { loading: true });
		case usersActions.GET_USER_DETAILS:
			return Object.assign({}, state, { loadingUserDetails: true });
		case usersActions.GET_ALL_COMPANIES:
			return Object.assign({}, state, {});
		case usersActions.SET_LOADING_USER_DETAILS:
			return Object.assign({}, state, { loadingUserDetails: action.data });
		case usersActions.SET_USERS:
			return Object.assign({}, state, { loading: false, users: action.data });
		case usersActions.SET_SELECTED_USER:
			return Object.assign({}, state, { selectedUser: action.data, loadingUserDetails: false });
		case usersActions.SET_SUBMITTING:
			return Object.assign({}, state, { submitting: action.data });
		case usersActions.SET_CREATE_USER_MODAL_SHOWN:
			return Object.assign({}, state, { isCreateUserModalShown: action.data });
		case usersActions.SET_SUBMITTING_USER:
			return Object.assign({}, state, { submittingUser: action.data });
		case usersActions.SET_LOADING_USERS:
			return Object.assign({}, state, { loading: action.data });
		case usersActions.SET_ALL_COMPANIES:
			return Object.assign({}, state, { allCompanies: action.data });
		default:
			return state;
	}
}
