import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { CheckboxInput, DatePicker, TextInput, SelectInput } from "../Form";
import { fieldTypes } from "../Form";
import "./CreateUser.sass";
import WhenAuthorized from "../WhenAuthorized";
import { UserPermissions } from "../../enums/UserPermissions";
import { encodeEmail } from "../../utils/formatString";
import { object, string } from "yup";
import { ValidationError } from "yup";
import { getAllCompanies } from "../../redux/actions/users";
import { useDispatch,useSelector } from "react-redux";


type CreateUserData = {
	first_name: string;
	last_name: string;
	email: string;
	title: string;
	department: string;
	manager_email: string;
	start_date: string;
	end_date?: string;
	onboarding_complete?: string;
	market?: string;
	commission_tier?: string;
	company: string;
	status: boolean;
};

interface UserDetailProps {
	createUser?: (user: Partial<CreateUserData>) => void;
	submittingUser?: boolean;
	userDetails: User
}

const createUserSchema = object({
	first_name: string().required("First name is required"),
	last_name: string().required("Last name is required"),
	email: string().email("Please enter a valid email address").required("Please enter an email address"),
	department: string().required("Department is required"),
	title: string().required("Title is required"),
	manager_email: string().email("Please enter a valid email address").required("Manager Email is required"),
	start_date: string().required("Start Date is required"),
	market: string().required("Market is required"),
	company: string().required("Company is required"),
	status: string().required("Status is required"),
});

interface UserDetail extends Omit<User, "status"> {
	status: string;
}

const createCompanyDropdown = (allCompanies: object) => {
	const companyDropDown: Array<SelectOption> = [];
	if (allCompanies["companies"]) {
		allCompanies["companies"].forEach((element, i) => {
			companyDropDown.push({ value: element, label: element, key: i });
		});
	}
	return companyDropDown;
};

const CreateUser = ({ createUser, submittingUser,userDetails }: UserDetailProps) => {
	const [currentUserData, setCurrentUserData] = useState<UserDetail>({ status: "true" } as UserDetail);
	const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
	const [, setAccessCompanies] = useState<Array<string>>([]);
	const [dropDownCompanies, setDropDownCompanies] = useState<Array<SelectOption>>([]);

	const allCompanies = useSelector(({ users }: RootState) => users.allCompanies);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAllCompanies(""));
		setAccessCompanies(userDetails.has_access_to_companies as Array<string>);

	}, []);

	useEffect(() => {
		setAccessCompanies(userDetails.has_access_to_companies as Array<string>);
	}, [userDetails]);

	useEffect(() => {
		if (allCompanies) {
			setDropDownCompanies(createCompanyDropdown(allCompanies));
		}
	}, [allCompanies]);



	const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setCurrentUserData({ ...currentUserData, [event.target.name]: event.target.value });
	};

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		if (event.target.value.toLowerCase().includes("@infinitysolarusa.com")) {
			currentUserData.company = "Infinity Solar USA";
		}
		setCurrentUserData({ ...currentUserData, [event.target.name]: event.target.value });
	};

	const submitUser = async () => {
		if (currentUserData.company === "" && String(currentUserData.email).toLowerCase().includes("@infinitysolarusa.com")) {
			currentUserData.company = "Infinity Solar USA";
		}
		const currentValidationErrors = {} as ValidationErrors;
		await createUserSchema.validate(currentUserData, { abortEarly: false }).catch((e: ValidationError) => e.inner.forEach(e => {
			if (e.path) {
				currentValidationErrors[e.path] = e.message;
			}
		}));

		if (!Object.keys(currentValidationErrors).length && createUser) {
			const {
				first_name,
				last_name,
				email,
				title,
				department,
				manager_email,
				start_date,
				end_date,
				onboarding_complete,
				market,
				commission_tier,
				company,
				status
			} = currentUserData;

			const dataToSubmit: Partial<User> = {
				first_name,
				last_name,
				email: email ? encodeEmail(email as string) : undefined,
				title,
				department: department as string,
				manager_email: manager_email as string,
				start_date: start_date as string,
				end_date: end_date as string,
				onboarding_complete: !onboarding_complete ? "False" : "True",
				market: market as string,
				commission_tier: commission_tier as string,
				company,
				status: status === "true" ? true : false,
			};
			createUser(dataToSubmit);
		}

		setValidationErrors(currentValidationErrors);
	};

	const handleDateChange = (value, type) => {
		// If type is start_date or end_date and the value is 1970-01-01, set it to null
		if (type === "start_date" || type === "end_date") {
			if (value === "1970-01-01") {
				value = null;
			}
		}
		setCurrentUserData({ ...currentUserData, [type === "start_date" ? "start_date" : "end_date"]: value });
	};

	return <Card style={{ marginBottom: "20px" }}>
		<Card.Body>
			<Card.Title className="text-center">{currentUserData.name}</Card.Title>
			<Form onSubmit={submitUser}>
				<Row>
					<Col>
						<TextInput
							field={{
								label: "First Name",
								controlId: "first_name",
								type: fieldTypes.text,
								value: currentUserData.first_name,
								groupClasses: "mb-3",
								name: "first_name",
								onChange: handleChange,
								validation: validationErrors,
							}}
						/>
					</Col>
					<Col>
						<TextInput
							field={{
								label: "Last Name",
								controlId: "last_name",
								type: fieldTypes.text,
								value: currentUserData.last_name,
								groupClasses: "mb-3",
								name: "last_name",
								onChange: handleChange,
								validation: validationErrors,
							}}
						/>
					</Col>
				</Row>
				<Row>
					<Col>
						<TextInput
							field={{
								label: "Email address",
								controlId: "email",
								type: fieldTypes.text,
								value: currentUserData.email,
								groupClasses: "mb-3",
								name: "email",
								onChange: handleEmailChange,
								validation: validationErrors,
							}}
						/>
					</Col>
					<Col>
						<TextInput
							field={{
								label: "Manager's Email address",
								controlId: "manager_email",
								type: fieldTypes.text,
								value: currentUserData.manager_email,
								groupClasses: "mb-3",
								name: "manager_email",
								onChange: handleChange,
								disabled: submittingUser,
								validation: validationErrors,
							}}
						/>
					</Col>
				</Row>
				<hr />
				<Row>
					<Col>
						<TextInput
							field={{
								label: "Department",
								controlId: "department",
								type: fieldTypes.text,
								value: currentUserData.department,
								name: "department",
								placeholder: "Sales",
								groupClasses: "mb-3",
								onChange: handleChange,
								disabled: submittingUser,
								validation: validationErrors,
							}}
						/>
					</Col>
					<Col>
						<TextInput
							field={{
								label: "Market",
								controlId: "market",
								type: fieldTypes.text,
								value: currentUserData.market,
								name: "market",
								placeholder: "PDX",
								groupClasses: "mb-3",
								onChange: handleChange,
								disabled: submittingUser,
								validation: validationErrors,
							}}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<TextInput
							field={{
								label: "Role / Title",
								controlId: "title",
								type: fieldTypes.text,
								value: currentUserData.role,
								name: "title",
								placeholder: "SSA1",
								groupClasses: "mb-3",
								onChange: handleChange,
								disabled: submittingUser,
								validation: validationErrors,
							}}
						/>
					</Col>
					<Col>
						<TextInput
							field={{
								label: "Commission Tier",
								controlId: "userCommissionTier",
								type: fieldTypes.text,
								value: currentUserData.commission_tier,
								name: "commission_tier",
								placeholder: "SSA1",
								groupClasses: "mb-3",
								onChange: handleChange,
								disabled: submittingUser,
								validation: validationErrors,
							}}
						/>
					</Col>
				</Row>

				<Row>
					<Col>
						<DatePicker
							label="Start Date"
							controlId="start_date"
							value={currentUserData.start_date as string}
							setValue={date => handleDateChange(date, "start_date")}
							disabled={submittingUser}
							validation={validationErrors}
						/>
					</Col>
					<Col>
						<DatePicker
							label="End Date"
							controlId="end_date"
							value={currentUserData.end_date as string}
							setValue={date => handleDateChange(date, "end_date")}
							disabled={submittingUser}
							validation={validationErrors}
						/>
					</Col>
				</Row>
				<Row className="formRowBuffer onboarding-adpposition-container">
					<WhenAuthorized permission={UserPermissions.adpPositionId}>
						<Col>
							<SelectInput
								field={{
									label: "Company",
									controlId: "company",
									type: fieldTypes.text,
									value: String(currentUserData.company),
									dropdownData: dropDownCompanies,
									name: "company",
									onChange: handleChange,
									disabled: submittingUser,
									validation: validationErrors,
								}}
							/>
						</Col>
					</WhenAuthorized>
					<Col>
						{currentUserData.company !== "Infinity Solar USA" ? <SelectInput
							field={{
								controlId: "status",
								label: "Status",
								name: "status",
								dropdownData: [
									{ value: "true", label: "Active", key: "active" },
									{ value: "false", label: "Inactive", key: "inactive" },
								],
								type: fieldTypes.dropdown,
								disabled: submittingUser,
								onChange: handleChange,
								value: currentUserData.status,
								validation: validationErrors,
							}}
						/> : <></>}
					</Col>
				</Row>
				<Row className="formRowBuffer onboarding-adpposition-container">
					<Col>
						<CheckboxInput
							field={{
								controlId: "userOnboardingComplete",
								label: "Onboarding Complete",
								checked: !!currentUserData.onboarding_complete,
								onChange: handleChange,
								disabled: submittingUser,
							}}
						/>
					</Col>
					<Col>
					</Col>
				</Row>
				<div className="user-detail-submit-button-container">
					<Button
						variant="primary"
						type="button"
						className="button"
						onClick={submitUser}
						disabled={submittingUser}
					>
						Submit
					</Button>
				</div>
			</Form>
		</Card.Body>
	</Card>;
};

export default CreateUser;
