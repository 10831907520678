const checkIfAuthorized = (permission: string) => {
	let authorized = false;

	const permissions = localStorage.getItem("permissions");
	const permissionsParsed = permissions ? JSON.parse(permissions) : null;

	if (permissionsParsed) authorized = !!permissionsParsed.find((perm: UserPermission) => perm.resource_list.includes(permission) || perm.resource_list.includes("*"));

	return authorized;
};

export default checkIfAuthorized;
