export const createAutoColumns = (data) => {
	return Object.keys(data[0]).reduce((acc, curr) => {
		if (typeof data[0][curr] === "string" || typeof data[0][curr] === "number") {
			acc.push({
				Header: curr ? curr.split("_").map(word => word[0].toUpperCase() + word.substring(1)).join(" ") : "",
				accessor: curr
			});
		}
		return acc;
	}, [] as HeaderData[]);
};
