import { iaPriceAdjusterActions } from "./actions";


export const getSalesRepPricingInfo = (data: string) => ({
	type: iaPriceAdjusterActions.GET_CURRENT_REP_DATA,
	data
});

export const setSalesRepPricingInfo = (data: string) => ({
	type: iaPriceAdjusterActions.SET_CURRENT_REP_DATA,
	data
});

export const setDateRange = (data: DateRange) => ({
	type: iaPriceAdjusterActions.SET_DATE_RANGE,
	data,
});

export const setCompany = (data: string) => ({
	type: iaPriceAdjusterActions.SET_COMPANY,
	data,
});

export const getMarkets = (data: string) => ({
	type: iaPriceAdjusterActions.GET_MARKETS,
	data,
});

export const setMarkets = (data: string[]) => ({
	type: iaPriceAdjusterActions.SET_MARKETS,
	data,
});

export const setMarket = (data: string) => ({
	type: iaPriceAdjusterActions.SET_MARKET,
	data,
});

export const addCompanyConfig = (data: object) => ({
	type: iaPriceAdjusterActions.ADD_COMPANY_CONFIG,
	data,
});