import React from "react";
import { Table, Accordion } from "react-bootstrap";

const StateRateTable = ({ data = {}, label = "", eventKey }) => {
	return (
		<Accordion.Item eventKey={eventKey}>
			<Accordion.Header>
				<h5>{label.replaceAll("_", " ")}</h5>
			</Accordion.Header>
			<Accordion.Body>
				<Table striped bordered hover>
					<tbody>
						{Object.entries.length ? Object.entries(data).map(([state, rate]) => {
							return (
								<tr key={state}>
									{typeof rate === "object" ? (
										<>
											<td>{state.replaceAll("_", " ")}</td>
											<td>
												{Object.entries(rate as object).map(([company, rate]) => (
													<p key={company}>
														<>{company}: {rate}</>
													</p>
												))}
											</td>
										</>
									) : (
										<>
											<td>{state.replaceAll("_", " ")}</td>
											<td>{rate as string}</td>
										</>
									)}
								</tr>
							);
						}) : <></>}
					</tbody>
				</Table>
			</Accordion.Body>
		</Accordion.Item>
	);
};

const DisplayTable = ({ data = {}, label = "", eventKey }) => {
	return (
		<Accordion.Item eventKey={eventKey}>
			<Accordion.Header>
				<h5>{label}</h5>
			</Accordion.Header>
			<Accordion.Body>
				<Table striped bordered hover>
					<tbody>
						{Object.entries.length ? Object.entries(data).map(([key, value]) => {
							return (
								<tr key={key}>
									<>
										<td>{key}</td>
										<td>{value as string}</td>
									</>
								</tr>
							);
						}) : <></>}
					</tbody>
				</Table>
			</Accordion.Body>
		</Accordion.Item>
	);
};


const DataTable = ({ pricing }: { pricing: IaPricing[] | undefined }) => {
	if (!pricing || pricing.length < 1) return <></>;
	return (<Accordion>
		{pricing.map((price, index) => {
			const { incentives, loan_options, company, market_adjustment, panel_fee, promotions, roof_price, roof_tax, roofadder, solar_base_price_state, solaradder } = price;
			return <Accordion.Item eventKey={index.toString()} key={index}>
				<Accordion.Header>
					<h5>{company.toString()}</h5>
				</Accordion.Header>
				<Accordion.Body>
					<h4 className="mt-3">Pricings</h4>
					<Accordion>
						{market_adjustment ?
							<DisplayTable data={market_adjustment} key={index} label={"Market Adjustment"} eventKey={index} />
							: <></>
						}
					</Accordion>
				</Accordion.Body>
				<Accordion.Body>
					<Accordion>
						{
							panel_fee ?
								Object.keys(panel_fee).map((key, index) => (<>
									{panel_fee[key] && typeof panel_fee[key] === "string" || typeof panel_fee[key] === "number" ?
										<DisplayTable data={{ [key]: panel_fee[key] }} key={index} label={key} eventKey={index} /> :
										<StateRateTable data={panel_fee[key]} key={index} label={key} eventKey={index} />
									}
								</>
								))
								: <></>}
					</Accordion>
				</Accordion.Body>
				<Accordion.Body>
					<Accordion>
						{promotions ?
							<DisplayTable data={promotions} key={index} label={"Promotions"} eventKey={index} />
							: <></>
						}
					</Accordion>
				</Accordion.Body>
				<Accordion.Body>
					<Accordion>
						{roof_tax ?
							<DisplayTable data={roof_tax} key={index} label={"Roof Taxes"} eventKey={index} />
							: <></>
						}
					</Accordion>
				</Accordion.Body>
				<Accordion.Body>
					<h4 className="mt-3">Roof Price</h4>
					<Accordion>
						{roof_price ?
							Object.keys(roof_price).map((key, index) => (<>
								{roof_price[key] && typeof roof_price[key] === "string" || typeof roof_price[key] === "number" ?
									<DisplayTable data={{ [key]: roof_price[key] }} key={index} label={key} eventKey={index} /> :
									<StateRateTable data={roof_price[key]} key={index} label={key} eventKey={index} />
								}
							</>))
							: <></>
						}
					</Accordion>
				</Accordion.Body>
				<Accordion.Body>
					<h4 className="mt-3">Solar State Base Price</h4>
					<Accordion>
						{
							solar_base_price_state ?
								Object.keys(solar_base_price_state).map((key, index) => (<>
									{solar_base_price_state[key] && typeof solar_base_price_state[key] === "string" || typeof solar_base_price_state[key] === "number" ?
										<DisplayTable data={{ [key]: solar_base_price_state[key] }} key={index} label={key} eventKey={index} /> :
										<StateRateTable data={solar_base_price_state[key]} key={index} label={key} eventKey={index} />
									}
								</>
								))
								: <></>}
					</Accordion>
				</Accordion.Body>
				<Accordion.Body>
					<h4 className="mt-3">Incentives</h4>
					<Accordion>
						{incentives ?
							Object.keys(incentives).map((key, index) => (<>
								{incentives[key] ?
									<StateRateTable data={incentives[key]} key={index} label={key} eventKey={index} /> :
									typeof incentives[key] === "string" || typeof incentives[key] === "number" ?
										<Table striped bordered hover>
											<tbody>
												<tr>
													<th>{key}</th>
													{/* <th>{incentives[key] as number}</th> */}
												</tr>
											</tbody>
										</Table> :
										<></>
								}
							</>
							)) : <></>
						}
					</Accordion>
				</Accordion.Body>
				<Accordion.Body>
					<h4 className="mt-3">Adders</h4>
					<Accordion>
						<Accordion.Item eventKey="0">
							<Accordion.Header onClick={e => e.stopPropagation()}>
								<h5>Solar Adders</h5>
							</Accordion.Header>
							<Accordion.Body>
								{
									solaradder ?
										<Accordion>
											{
												Object.keys(solaradder).map((key, index) => (<>
													{solaradder[key] ?
														<StateRateTable data={solaradder[key]} key={index} label={key} eventKey={index} /> :
														typeof solaradder[key] === "string" || typeof solaradder[key] === "number" ?
															<Table striped bordered hover>
																<tbody>
																	<tr>
																		<th>{key}</th>
																		{/* <th>{solaradder[key] as number}</th> */}
																	</tr>
																</tbody>
															</Table> :
															<></>
													}
												</>
												))}
										</Accordion> : <></>}
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Accordion.Body>
				<Accordion.Body>
					<Accordion>
						<Accordion.Item eventKey="0">
							<Accordion.Header>
								<h5>Roof Adders</h5>
							</Accordion.Header>
							<Accordion.Body>
								{
									roofadder ?
										<Accordion>
											{
												Object.keys(roofadder).map((key, index) => (<>
													{roofadder[key] ?
														<StateRateTable data={roofadder[key]} key={index} label={key} eventKey={index} /> :
														typeof roofadder[key] === "string" || typeof roofadder[key] === "number" ?
															<Table striped bordered hover>
																<tbody>
																	<tr>
																		<th>{key}</th>
																		{/* <th>{roofadder[key] as number}</th> */}
																	</tr>
																</tbody>
															</Table> :
															<></>
													}
												</>
												))}
										</Accordion> : <></>}
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Accordion.Body>
				<Accordion.Body>
					<h4 className="mt-3">Loans</h4>
					<Accordion className="mt-3">
						<Accordion.Item eventKey="0">
							<Accordion.Header>
								<h5>Loan Options</h5>
							</Accordion.Header>
							<Accordion.Body>
								{loan_options ?
									<Table striped bordered hover>
										<tbody>
											<tr>
												<td>Loan</td>
												<td>Dealer Fee</td>
											</tr>
											{Object.keys(loan_options).map((key) => (
												<tr key={key}>
													<td>{key}</td>
													<td>{loan_options[key] as number}</td>
												</tr>
											))}
										</tbody>
									</Table>
									: <></>
								}
							</Accordion.Body>
						</Accordion.Item>
					</Accordion>
				</Accordion.Body>
			</Accordion.Item>;
		})}
	</Accordion>
	);
};

export default DataTable;
