import apiCall from "../../../utils/api";
import { marketPipelineActions } from "../../actions/actions";
import {
	setCustomerList,
	setFilteredCustomerList,
	setLoadingCustomers
} from "../../actions/marketPipeline";
import { Middleware } from "redux";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";

const marketPipelineMiddleware: Middleware<object, RootState> = store => next => action => {
	const dispatch = store.dispatch;
	const marketPipelineState = store.getState().marketPipeline;

	switch (action.type) {
		case marketPipelineActions.GET_CUSTOMERS: {
			const { loadingCustomers } = marketPipelineState;

			if (!loadingCustomers) {
				apiCall({
					url: `${process.env.REACT_APP_API_SERVICE}/customer-pipeline/customers?market=true`,
					method: "GET"
				}).then(res => {
					dispatch(setCustomerList(res));
					dispatch(setFilteredCustomerList(res));
				}).catch((e) => {
					console.error(e);
					apiErrorToast();
					dispatch(setLoadingCustomers(false));
				});
			}
			break;
		}
	}
	const result = next(action);
	return result;
};

export default [marketPipelineMiddleware];
