import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import TextInput from "../Text/TextInput";
import "./Adder.sass";

const numberInputOnWheelPreventChange = (e: React.WheelEvent<HTMLInputElement>) => {
	const { target } = e;
	// Prevent the input value change
	(target as HTMLButtonElement).blur();

	// Prevent the page/container scrolling
	e.stopPropagation();

	// Refocus immediately, on the next tick (after the current function is done)
	setTimeout(() => {
		(target as HTMLButtonElement).focus();
	}, 0);
};

const Adder = ({ option, type, validation, disabled, value, setValue, id, defaultPricePer }: Adder) => {
	const control = <Form.Control
		type="text"
		placeholder={option.label}
		onWheel={numberInputOnWheelPreventChange}
		disabled={disabled || !value.checked}
		value={value.amount}
		onChange={e => setValue({ ...value, amount: e.target.value })}
	/>;

	const controlWPrefix = <InputGroup className="mb-3">
		{control}
		<InputGroup.Text id="basic-addon2">{option.prefix}</InputGroup.Text>
	</InputGroup>;

	const formControl = <Form.Group controlId={`${type}_${option.value}_amount`} className={"checkbox"} id={id}>
		{option.prefix ? controlWPrefix : control}
		{validation && Object.keys(validation).length && validation[`${option.value}_amount`] ? <p className="field-validation-error">
			{validation[`${option.value}_amount`]}
		</p> : ""}
	</Form.Group>;

	const adderPrice = <TextInput
		field={{
			label: "Override Price",
			value: value.pricePer,
			onChange: e => setValue({ ...value, pricePer: e.target.value })
		}}
	/>;

	const adderDefaultPrice = <TextInput
		field={{
			label: "Default Price",
			disabled: true,
			value: defaultPricePer
		}}
	/>;

	return (<div className="adder-container">
		<div className="adder-main-container">
			<div className="adder-input-container">
				<Form.Check
					className={"checkbox"}
					id={`${type}_${option.value}`}
					type="checkbox"
					label={option.label}
					checked={value.checked}
					onChange={e => setValue({ ...value, checked: e.target.checked })}
					disabled={disabled}
				/>
				{option.readOnly ? <></> :
					formControl
				}
			</div>
			{defaultPricePer ? <div className="adder-price-container">
				{
					adderPrice
				}
				{
					adderDefaultPrice
				}
			</div> : <></>
			}
		</div>
	</div>);
};

export default Adder;
