import React, { useEffect } from "react";
import PerformanceBar from "../Components/PerformanceBar/PerformanceBar";
import Topbar from "../Components/TopBar/TopBar";
import "./styles/PerformanceAuditor.sass";
import { Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { setDateRange, setCurrentRep, getData } from "../redux/actions/performanceAuditor";
import { getRepList } from "../redux/actions/general";
import { SelectInput } from "../Components/Form";
import Heading from "../Components/Heading/Heading";

const PerformanceAuditor = () => {
	const currentRep = useSelector(({ performanceAuditor }: RootState) => performanceAuditor.currentRep);
	const startDate = useSelector(({ performanceAuditor }: RootState) => performanceAuditor.dateRange.startDate);
	const endDate = useSelector(({ performanceAuditor }: RootState) => performanceAuditor.dateRange.endDate);
	const canvassData = useSelector(({ performanceAuditor }: RootState) => performanceAuditor.canvassData);
	const acuityData = useSelector(({ performanceAuditor }: RootState) => performanceAuditor.acuityData);
	const adpData = useSelector(({ performanceAuditor }: RootState) => performanceAuditor.adpData);
	const repList = useSelector(({ general }: RootState) => general.repList);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getRepList({ source: "performanceAuditor" }));
	}, []);

	useEffect(() => {
		if (repList) {
			dispatch(setCurrentRep(localStorage.getItem("email") || ""));
		}
	}, [repList]);

	useEffect(() => {
		if (currentRep && startDate && endDate) {
			dispatch(getData({ startDate, endDate, currentRep }));
		}
	}, []);

	return <div>
		<Heading>Performance Auditor</Heading>
		<div className="performance-auditor-sales-rep-select-container">
			{ repList ? <SelectInput
				field={{
					controlId: "repSelect",
					label: "Show for Sales Agent",
					labelClasses: "text-white",
					dropdownData: repList.map(rep => ({ key: rep.email, value: rep.email, label: rep.name })).filter(rep => !!rep.label),
					value: currentRep,
					onChange: (e: React.ChangeEvent<HTMLSelectElement>) => dispatch(setCurrentRep(e.target.value)),
					containerClasses: "mb-3"
				}}
			/>
				: <></> }
		</div>
		<Topbar
			startDate={startDate}
			endDate={endDate}
			setStartDate={(d) => dispatch(setDateRange({ startDate: d, endDate }))}
			setEndDate={(d) => dispatch(setDateRange({ endDate: d, startDate }))}
			setDateRange={(range) => dispatch(setDateRange({ startDate: range.startDate, endDate: range.endDate }))}
		/>
		{ canvassData && Object.keys(canvassData).length || acuityData && acuityData.length || adpData && adpData.length ?
			<PerformanceBar canvassData={canvassData} acuityData={acuityData} adpData={adpData} />
			:
			<div className="no-performance-data-container">
				<Alert variant="info">
					There is no data for the specified parameters
				</Alert>
			</div>
		}
	</div>;
};

export default PerformanceAuditor;
