import { closerAppointmentSchedulerActions } from "./actions";


export const setIsCloserAppointmentModalShown = (data: boolean) => ({
	type: closerAppointmentSchedulerActions.SET_IS_CLOSER_APPOINTMENT_MODAL_SHOWN,
	data
});

export const setCloserAppointment = (data: Customer) => ({
	type: closerAppointmentSchedulerActions.SET_CLOSER_APPOINTMENT,
	data
});