import { installAgreementActions } from "../actions/actions";

const initialState: InstallationAgreementState = {
	form: {
		cancellation_verbiage: "site_survey",
		setter: "other",
		program: "D2D"
	},
	solaradder: {
	},
	roofadder: {
	},
	mainAccordionTabs: ["0"],
	company: "",
	original_homeowner: "",
};

export default function installAgreementReducer(state = initialState, action: InstallAgreementAction) {
	switch (action.type) {
		case installAgreementActions.SET_FORM_VALUE:
			return Object.assign({}, state, { form: { ...(state as InstallationAgreementState).form, ...(action.data as { [key: string]: string }) } });
		case installAgreementActions.REMOVE_FORM_VALUE: {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { [action.data as string]: removed, ...rest } = state.form;
			return Object.assign({}, state, { form: rest });
		}
		case installAgreementActions.SET_FORM:
			return Object.assign({}, state, { form: action.data });
		case installAgreementActions.SET_IS_COMPLETE:
			return Object.assign({}, state, { isComplete: action.data });
		case installAgreementActions.SET_MAIN_ACCORDION_TABS_OPEN:
			return Object.assign({}, state, { mainAccordionTabsOpen: action.data });
		case installAgreementActions.SET_VALIDATION_ERRORS:
			return Object.assign({}, state, { validationErrors: action.data });
		case installAgreementActions.SET_MAIN_ACCORDION_TABS:
			return Object.assign({}, state, { mainAccordionTabs: action.data });
		case installAgreementActions.SET_SOLAR_ROOF_ACCORDION:
			return Object.assign({}, state, { solarRoofAccordion: action.data });
		case installAgreementActions.SET_SHOW_DRAFT_SUBMISSION:
			return Object.assign({}, state, { showDraftSubmission: action.data });
		case installAgreementActions.SET_DRAFT_DATA:
			return Object.assign({}, state, { draftData: action.data });
		case installAgreementActions.SET_SUBMITTING:
			return Object.assign({}, state, { submitting: action.data });
		case installAgreementActions.SET_COMPLETED_SUBMISSION:
			return Object.assign({}, state, { completedSubmission: action.data });
		case installAgreementActions.SET_ADDER_VALUE:
			return Object.assign({}, state, { [(action.data as { [key: string]: string }).type as string]: { ...state[(action.data as { [key: string]: string }).type as string], ...(action.data as { [key: string]: { [key: string]: string } }).data as object } });
		case installAgreementActions.SET_SOLAR_ADDERS:
			return Object.assign({}, state, { solaradder: action.data });
		case installAgreementActions.SET_ROOF_ADDERS:
			return Object.assign({}, state, { roofadder: action.data });
		case installAgreementActions.GET_PRICING:
			return Object.assign({}, state, { loadingPricing: true });
		case installAgreementActions.SET_PRICING:
			return Object.assign({}, state, { pricing: action.data });
		case installAgreementActions.SET_LOADING_PRICING:
			return Object.assign({}, state, { loadingPricing: action.data });
		case installAgreementActions.SET_SHOW_PRICING_MODAL:
			return Object.assign({}, state, { showPricingModal: action.data });
		case installAgreementActions.SET_SHOW_PRICING_MODAL_EDIT:
			return Object.assign({}, state, { showPricingModalEdit: action.data });
		case installAgreementActions.SET_FORM_COMPANY:
			return Object.assign({}, state, { company: action.data });
		case installAgreementActions.CLEAR_ADDERS: {
			const stateWithoutAdders = {};
			const type = action.data;
			Object.keys(state)
				.filter(key => type === "solar" && key !== "solaradder" || type === "roof" && key !== "roofadder")
				.forEach(key => stateWithoutAdders[key] = state[key]);
			return Object.assign({}, stateWithoutAdders);
		}
		case installAgreementActions.SET_ORIGINAL_HOMEOWNER:
			return Object.assign({}, state, { original_homeowner: action.data });
		default:
			return state;
	}
}
