import apiCall from "../../../utils/api";
import { iaPriceAdjusterActions } from "../../actions/actions";
import { setSalesRepPricingInfo, setMarkets } from "../../actions/iaPriceAdjuster";
import { Middleware } from "redux";
import { encodeEmail } from "../../../utils/formatString";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";
import { toast } from "react-toastify";

const getPricingDetails: Middleware<object, RootState> = store => next => action => {
	const { dispatch } = store;
	if (action.type === iaPriceAdjusterActions.GET_CURRENT_REP_DATA) {
		const userEmail = action.data;
		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/ia-price-adjuster/redline?user_email=${encodeEmail(userEmail)}&time_frame=current`,
			method: "GET"
		}).then(res =>
			dispatch(setSalesRepPricingInfo(res))
		).catch((e) => {
			console.error(e);
		});
	}
	const result = next(action);
	return result;
};

const getMarkets: Middleware<object, RootState> = store => next => action => {
	const { dispatch } = store;
	if (action.type === iaPriceAdjusterActions.GET_MARKETS) {
		const company = action.data;
		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/ia-price-adjuster/markets?company=${company}`,
			method: "GET"
		}).then(res => {
			dispatch(setMarkets(res));
		}
		).catch((e) => {
			console.error(e);
			apiErrorToast();
		});
	}
	const result = next(action);
	return result;
};

const addCompanyConfig: Middleware<object, RootState> = () => next => action => {
	if (action.type === iaPriceAdjusterActions.ADD_COMPANY_CONFIG) {
		apiCall({
			url: `${process.env.REACT_APP_API_SERVICE}/ia-price-adjuster/company-prices`,
			method: "POST",
			body: action.data
		}).then(() => {
			toast.info("Submitted successfully", {
				position: "top-right",
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
				toastId: "ia-Submission-Error",
				onClick: () => window.close(),
				autoClose: false
			});
		}).catch((e) => {
			console.error(e);
			apiErrorToast();
		});
	}
	const result = next(action);
	return result;
};


export default [getPricingDetails, addCompanyConfig, getMarkets];