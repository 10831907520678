export const convertDateToString = (date: Date) => date.toISOString().split("T")[0];

export const getLocalDateStr = (date: string) => {
	const localDate = getLocalDate(date).split(",")[0].split("/");
	return localDate[2] +  "-" + String(localDate[0]).padStart(2, "0") + "-" + String(localDate[1]).padStart(2, "0");
};

export const getLocalDate = (date: string) => {
	return new Date(date).toLocaleString("en-US", { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
};

export function getFirstDayOfMonth() {
	const d = new Date();
	const year = d.getFullYear();
	const month = d.getMonth();
	const firstDayOfMonth = new Date(year, month, 1);
	return firstDayOfMonth;
}

// Subtract day from Date
export const subtractDay = (date: string) => {
	const newDate = new Date(date);
	newDate.setDate(newDate.getDate() - 1);
	return newDate.toISOString().split("T")[0];
};
