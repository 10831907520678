import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConstructionFunnelData, getCustomerList, getFunnelReportDefinitionList, setIsCustomerListModalShown } from "../../redux/actions/reports";
import Table from "../Table/Table";
import Modal from "../Modal/Modal";
import "./ConstructionFunnelReport.sass";
import CustomerList from "../CustomerList/CustomerList";
import WhenAuthorized from "../WhenAuthorized";
import { UserPermissions } from "../../enums/UserPermissions";
import ListGroup from "react-bootstrap/ListGroup";

type tableCellWithModal = {
	row: {
		original: {
			[key: string]: string[] | string;
		}
	},
	column: {
		id: string
	},
	value: string
}

const tableCellWithModal = ({ row, column, value }: tableCellWithModal) => {
	const dispatch = useDispatch();
	const tableCellOnClick = () => {
		if (row.original.Market !== "Total") {
			const customerIds = row.original[`${column.id}-customer_ids`];
			if ((customerIds as string[]).filter(id => !!id).length) dispatch(getCustomerList((customerIds as string[]).filter(id => !!id)));
		}
	};
	return <span className="funnel-report-td-modal" onClick={tableCellOnClick}>{value}</span>;
};

const funnelReportColumns = [
	{
		Header: "Market",
		accessor: "Market"
	},
	{
		Header: "Sales in Pre-CAD Approved Stages",
		accessor: "Sales in Pre-CAD Approved Stages",
		Cell: tableCellWithModal
	},
	{
		Header: "Sales in Customer Hold / Correction Stages",
		accessor: "Sales in Customer Hold / Correction Stages",
		Cell: tableCellWithModal
	},
	{
		Header: "Sales in Post-CAD Approved Stages",
		accessor: "Sales in Post-CAD Approved Stages",
		Cell: tableCellWithModal
	},
	{
		Header: "Sales Scheduled for Install",
		accessor: "Sales Scheduled for Install",
		Cell: tableCellWithModal
	},
	{
		Header: "Total Jobs",
		accessor: "Total Jobs"
	},
	{
		Header: "Construction Funnel in weeks",
		accessor: "Construction Funnel in weeks",
		Cell: ({ value }: { value: number }) => <span>{value.toFixed(2)}</span>
	},
	{
		Header: "Construction Average Pace per week for Installs",
		accessor: "Construction Average Pace per week for Installs"
	}
];

const reportDefinitionStatusList = (name: string, statusList: string[]) => {
	return <div>
		<h5 className="text-white report-definition-header">{name}</h5>
		<ListGroup className="report-definition-status-list">
			{statusList?.length ? statusList.map(status => <ListGroup.Item key={status}>{status}</ListGroup.Item>) : <></>}
		</ListGroup>
	</div>;
};

const reportDefinitions = (funnelReportDefinitionList: FunnelReportDefinition) => {
	return <div className="report-definitions-container">
		<h4 className="text-white mt-5 mb-4">List of statuses associated with each column definition</h4>
		<div className="report-definitions">
			{reportDefinitionStatusList("Sales in Pre-CAD Approved Stages", funnelReportDefinitionList["Sales in Pre-CAD Approved Stages"])}
			{reportDefinitionStatusList("Sales in Customer Hold / Correction Stages", funnelReportDefinitionList["Sales in Customer Hold / Correction Stages"])}
			{reportDefinitionStatusList("Sales in Post-CAD Approved Stages", funnelReportDefinitionList["Sales in Post-CAD Approved Stages"])}
			{reportDefinitionStatusList("Sales Scheduled for Install", funnelReportDefinitionList["Sales Scheduled for Install"])}
		</div>
	</div>;
};

const convertToTableData = (data: ConstructionFunnelReportData[]) =>
	data.filter(i => i.Market.value !== "Last Generated").map((item) => {
		return {
			Market: item.Market?.value,
			"Sales in Pre-CAD Approved Stages": item["Sales in Pre-CAD Approved Stages"]?.value,
			"Sales in Pre-CAD Approved Stages-customer_ids": item["Sales in Pre-CAD Approved Stages"]?.customer_ids,
			"Sales in Customer Hold / Correction Stages": item["Sales in Customer Hold / Correction Stages"]?.value,
			"Sales in Customer Hold / Correction Stages-customer_ids": item["Sales in Customer Hold / Correction Stages"]?.customer_ids,
			"Sales in Post-CAD Approved Stages": item["Sales in Post-CAD Approved Stages"]?.value,
			"Sales in Post-CAD Approved Stages-customer_ids": item["Sales in Post-CAD Approved Stages"]?.customer_ids,
			"Sales Scheduled for Install": item["Sales Scheduled for Install"]?.value,
			"Sales Scheduled for Install-customer_ids": item["Sales Scheduled for Install"]?.customer_ids,
			"Total Jobs": item["Total Jobs"]?.value,
			"Total Jobs-customer_ids": item["Total Jobs"]?.customer_ids,
			"Total Jobs in Construction Funnel": item["Total Jobs in COnstruction Funnel"]?.value,
			"Construction Funnel in weeks": item["Construction Funnel in weeks"]?.value,
			"Construction Average Pace per week for Installs": item["Construction Average Pace per week for Installs"]?.value,
		};
	});

const ConstructionFunnelReport = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getConstructionFunnelData());
		dispatch(getFunnelReportDefinitionList());
	}, []);

	const constructionFunnelData = useSelector(({ reports }: RootState) => reports.constructionFunnelData) || [];
	const customerList = useSelector(({ reports }: RootState) => reports.customerList) || [];
	const isCustomerListModalShown = useSelector(({ reports }: RootState) => reports.isCustomerListModalShown);
	const funnelReportDefinitionList = useSelector(({ reports }: RootState) => reports.funnelReportDefinitionList) || {};

	const funnelReportTable = constructionFunnelData.length ?
		<Table
			columns={funnelReportColumns}
			data={convertToTableData(constructionFunnelData)}
		/> : <></>;

	return <WhenAuthorized permission={UserPermissions.construction_funnel_report}>
		<>
			<Modal
				isShown={isCustomerListModalShown}
				hideModal={() => {
					dispatch(setIsCustomerListModalShown(false));
				}}
				body={customerList ? <CustomerList customerList={customerList} source="contrustionFunnelReport" /> : <></>}
				heading=""
				addlProps={{ size: "lg" }}
			/>
			{funnelReportTable}
			{Object.keys(funnelReportDefinitionList).length ? reportDefinitions(funnelReportDefinitionList) : <></>}
		</>
	</WhenAuthorized>;
};

export default ConstructionFunnelReport;
