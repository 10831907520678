import { generalActions } from "../actions/actions";

const initialState: GeneralState = {};

export default function generalReducer(state = initialState, action: GeneralAction) {
	switch (action.type) {
		case generalActions.GET_REP_LIST:
			return Object.assign({}, state, { loadingRepList: true });
		case generalActions.SET_REP_LIST:
			return Object.assign({}, state, { loadingRepList: false, repList: action.data });
		case generalActions.GET_SETTERS_LIST:
			return Object.assign({}, state, { loadingSettersList: true });
		case generalActions.SET_SETTERS_LIST:
			return Object.assign({}, state, { loadingSettersList: false, settersList: action.data });
		case generalActions.SET_CLOSERS_LIST:
			return Object.assign({}, state, { loadingClosersList: false, closersList: action.data });
		default:
			return state;
	}
}
