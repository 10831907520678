import apiCall from "../../../utils/api";
import { usersActions } from "../../actions/actions";
import { setUsers, setSelectedUser, setLoadingUsers, setAllCompanies } from "../../actions/users";
import { Middleware } from "redux";
import { encodeEmail } from "../../../utils/formatString";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";

const getUsers: Middleware<object, RootState> = store => next => action => {
	const { dispatch } = store;
	if (action.type === usersActions.GET_USERS) {
		const { loading } = store.getState().users;
		if (!loading) {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/portal-user-manager/users`,
				method: "GET"
			}).then(res =>
				dispatch(setUsers(res))
			).catch((e) => {
				console.error(e);
				apiErrorToast();
				dispatch(setLoadingUsers(false));
			});
		}
	}
	const result = next(action);
	return result;
};

const getUserDetails: Middleware<object, RootState> = store => next => action => {
	const { dispatch } = store;
	if (action.type === usersActions.GET_USER_DETAILS) {
		const { loadingUserDetails } = store.getState().users;
		if (!loadingUserDetails) {
			const userEmail = action.data;
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/portal-user-manager/users/${encodeEmail(userEmail)}`,
				method: "GET"
			}).then(res =>
				dispatch(setSelectedUser(res))
			).catch((e) => {
				console.error(e);
				apiErrorToast();
			});
		}
	}
	const result = next(action);
	return result;
};

const getAllCompanies: Middleware<object, RootState> = store => next => action => {
	const { dispatch } = store;
	if (action.type === usersActions.GET_ALL_COMPANIES) {
		const { loadingUserDetails } = store.getState().users;
		if (!loadingUserDetails) {
			const company = action.data;
			if (company != null && company != undefined) {
				apiCall({
					url: `${process.env.REACT_APP_API_SERVICE}/ia-price-adjuster/companies?search=${company}`,
					method: "GET"
				}).then(res => {
					dispatch(setAllCompanies(res));
				}
				).catch((e) => {
					console.error(e);
					apiErrorToast();
				});
			}
		}
	}
	const result = next(action);
	return result;
};



export default [getUsers, getUserDetails, getAllCompanies];
