import React, { useEffect, useState } from "react";
import "./scss/styles.scss";
import { useServiceWorker } from "./useServiceWorker";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Routes from "./Routes";
import "./index.css";
import Header from "./Components/Header/Header";
import InfoBar from "./Components/InfoBar/InfoBar";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import apiCall from "./utils/api";
import { useAuth0 } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";

const App = () => {
	const [isUpdate, setIsUpdate] = useState<boolean>(false);
	const [token, setToken] = useState<string | null>(null);
	const [rerender, setRerender] = useState<boolean>(false);

	const { waitingWorker, showReload, reloadPage } = useServiceWorker();

	const { user, getAccessTokenSilently } = useAuth0();
	const { logout } = useAuth0();

	const localToken = localStorage.getItem("t");
	const localPermissions = localStorage.getItem("permissions");
	const newTokenAdded = localStorage.getItem("n");

	useEffect(() => {
		if (!user && localToken && newTokenAdded !== "true") {
			localStorage.clear();
		}
		if (user) {
			getAccessTokenSilently().then(token => {
				// check if token has expired
				const decodedToken: { [key: string]: string | number } = jwt_decode(token);
				const currentTime = Date.now() / 1000;
				localStorage.setItem("exp", decodedToken.exp.toString());

				if (decodedToken.exp as number < currentTime) {
					console.log("Token has expired");
					localStorage.setItem("refreshTokenExp", "true");
					// Attempt to collect a refresh token
					getAccessTokenSilently({ cacheMode: "off" }).then(token => {
						localStorage.setItem("t", token);
						localStorage.setItem("n", "true");
						localStorage.setItem("exp", decodedToken.exp.toString());
						setToken(token);
					}).catch((error) => {
						console.log("Error getting refresh token", error);
						// Return to Auth0 login page
						logout({ logoutParams: { returnTo: window.location.origin } });
					});
					// Return to Auth0 login page
					logout({ logoutParams: { returnTo: window.location.origin } });
				}
				localStorage.setItem("t", token);
				localStorage.setItem("n", "true");
				setToken(token);
			}).catch((error) => {
				console.log("Error getting token", error);
				// Return to Auth0 login page
				logout({ logoutParams: { returnTo: window.location.origin } });
			});
		}
		if (user) {
			user.picture && localStorage.setItem("logo", user.picture);
			const name = user.given_name && user.family_name ? `${user.given_name} ${user.family_name}` : null;
			if (name) localStorage.setItem("Name", `${user.given_name} ${user.family_name}`);
			user.email && localStorage.setItem("email", user.email);
		}
	}, [user, getAccessTokenSilently, token]);

	useEffect(() => {
		if (token && !localPermissions) {
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/permissions/user`,
				method: "GET"
			}).then(result => {
				if (result.length) {
					if (result[0].company) { localStorage.setItem("company", result[0].company); }
					else { localStorage.setItem("company", ""); }
					const name = localStorage.getItem("Name");
					if (!name) {
						if (result[0].name) { localStorage.setItem("Name", result[0].name); }
						else { localStorage.setItem("Name", ""); }
					}
					localStorage.setItem("permissions", JSON.stringify(result));
					setRerender(!rerender);
				}
			});
		}
	}, [token]);

	useEffect(() => {
		if (showReload && waitingWorker) {
			setIsUpdate(true);
		}
	}, [waitingWorker, showReload, reloadPage]);

	const AuthenticatedApp = () =>
		<>
			<Header />
			{isUpdate ? <InfoBar /> : <></>}
			<Routes />
		</>;

	const ProtectedRoute = ({ component }) => {
		const Component = withAuthenticationRequired(component);
		return <Component />;
	};

	//After the user is logged in then the user can procced to the app
	return (
		<>
			<ToastContainer></ToastContainer>
			<div>
				{
					<div>
						<ProtectedRoute component={AuthenticatedApp} />
					</div>
				}
			</div >
		</>
	);
};

export default App;
