import apiCall from "../../../utils/api";
import { closerAppointmentSchedulerActions } from "../../actions/actions";
import { Middleware } from "redux";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";
import { toast } from "react-toastify";
import { setIsCloserAppointmentModalShown } from "../../actions/closerAppointmentScheduler";
import { setMonth, setDays, setTimes, setSelectedDay } from "../../actions/siteSurveyScheduler";

const closerAppointmentSchedulerMiddleware: Middleware<object, RootState> = store => next => action => {
	const dispatch = store.dispatch;
	const profileState = store.getState().profile;
	switch (action.type) {
		case closerAppointmentSchedulerActions.SET_CLOSER_APPOINTMENT: {
			const { name } = profileState.profile;
			const customer = action.data;
			apiCall({
				url: `${process.env.REACT_APP_API_SERVICE}/acuity-scheduler/closer`,
				body: {
					"datetime": customer.appointmentTime,
					"appointmentTypeID": customer.appointmentTypeID,
					"firstName": customer.first_name,
					"lastName": customer.last_name,
					"email": customer.email,
					"phone": customer.phone,
					"smsOptIn": customer.sms,
					"fields": {
						"address": customer.address,
						"state": customer.state,
						"zip": customer.zip,
						"utility_company": customer.utility_company,
						"utility_bill_acquired": customer.utilityBillAcquired,
						"utility_bill_acquired_notes": customer.utilityBillNotes,
						"setter": name,
						"hoa": customer.hoa,
						"Incentives": customer.incentives,
						"electric_vehicle": customer.electicVehicle,
						"homeowners_attending": customer.homeowners_attending,
						"roofing_estimate": customer.roof,
						"expected_length_of_time_in_home": customer.selectedExpectedLengthInHome,
						"customer_id": customer.customer_id,
						"notes": customer.notes
					}
				},
				method: "POST"
			}).then(() => {
				toast.info("Appointment created successfully", {
					position: "top-right",
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "dark",
					toastId: "site-survery-scheduler-toast",
					onClick: () => window.close(),
					autoClose: 5000
				});
				dispatch(setMonth(new Date()));
				dispatch(setDays([]));
				dispatch(setTimes([]));
				dispatch(setSelectedDay(null));
				dispatch(setIsCloserAppointmentModalShown(false));
			}).catch((res) => {
				apiErrorToast(res);
			});
			break;
		}
	}
	const result = next(action);
	return result;
};

export default [closerAppointmentSchedulerMiddleware];
