import dropDownData from "../InstallAgreement/data/formSelects.json";
import { apiErrorToast } from "../Toasts/ApiErrorToast";


const validator = (userData: object) => {
	function convert_string_capital_case(field_name: string) {
		return field_name?.trim()
			?.split(" ")
			?.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
			?.join(" ");
	}
	function validate_date(date, format, errors, dateType, bodyData) {
		if (date === undefined || date === null || date === "") {
			if (format === "End Date") return;
			errors[dateType] = `"Missing Required Field:\nExpected Format: ${format} \n\tex '2023-05-23T19:58:34Z', in 'YYYY-MM-DDTHH:MM:SSZ' format"`;
		}
		Object.keys(date).forEach((key) => {
			const date_value = date[key];
			if (format === "End Date") return;
			if (date_value === undefined || key === null || key === "") {
				if (dateType != "start_date") {
					errors[dateType] = `"Required:\nExpected Format: ${format} \n\tex '2023-05-23T19:58:34Z', in 'YYYY-MM-DDTHH:MM:SSZ' format"`;
				}
				else {
					bodyData[dateType] = null;
				}
			}
			else {
				if (date_value?.length < 20) errors[dateType] = `"Required Field Error:\nExpected Format: ${format} \n\tex '2023-05-23T19:58:34Z', in 'YYYY-MM-DDTHH:MM:SSZ' format"`;
				if (date_value?.length > 20) errors[dateType] = `"Required Field Error:\nExpected Format: ${format} \n\tex '2023-05-23T19:58:34Z', in 'YYYY-MM-DDTHH:MM:SSZ' format"`;
				if (isNaN(Date.parse(date_value))) errors[dateType] = `"Required Field Error:\nExpected Format: ${format} uses 'YYYY-MM-DDTHH:MM:SSZ' format, there might be a character in the date that is not a number."`;
				if (errors[dateType] === "") bodyData[dateType] = date_value;
			}
		});
	}

	function extractTextField(data: object, type: string, label: string, errors: { company: string; pdf: string; notes: string; }, bodyData: object) {
		Object.keys(data[`${type}_value`]).forEach((key) => {
			const textValue = data[`${type}_value`][key];
			if (textValue === undefined || textValue === null || textValue === "")
				errors[type] = `"Missing Field:\n\tExpected Field: '${label}'"`;
			if (errors[type] === "") bodyData[type] = textValue;
		}
		);
	}

	function validate(data) {
		const errors = {
			solar_base_price_state: "",
			promotions: "",
			roof_price: "",
			roof_tax: "",
			market_adjustment: "",
			solar_adder: "",
			roof_adder: "",
			panel_fee: "",
			incentives: "",
			loan_options: "",
			company: "",
			market: "",
			pdf: "",
			notes: "",
			start_date: "",
			end_date: ""
		};
		const bodyData: object = {};

		if (data.solar_base_price_state_name) {
			Object.keys(data.solar_base_price_state_name).forEach((key, index) => {
				if (!(data.solar_base_price_state_name[key] === undefined) && data.solar_base_price_state_name[key] != null && data.solar_base_price_state_name[key] != "") {
					let stateValue = data.solar_base_price_state_name[key]?.trim()
						?.toLowerCase();
					if (stateValue in dropDownData.mapStateAbbreviation) {
						stateValue = dropDownData.mapStateAbbreviation[stateValue];
					}
					stateValue = convert_string_capital_case(stateValue);
					let pricingValue = data.solar_base_price_state_price[`"solar_base_price_state_price_${index}"`];
					pricingValue = pricingValue?.toString().replace(/\s/g, "");
					let subCompanyValue = "";
					if (data.solar_base_price_state_subcompany !== undefined) {
						subCompanyValue = convert_string_capital_case(data.solar_base_price_state_subcompany[`"solar_base_price_state_subcompany_${index}"`]?.toLowerCase());
					}
					switch (stateValue) {
						case "":
							errors.solar_base_price_state = "Required:\nExpected Format: 'State' - 'Price' \n\tex 'WA - 1.50' or 'WA - 0.085, OR - CE - 0.2'";
							break;
						case null:
							errors.solar_base_price_state = "Required:\nExpected Format: 'State' - 'Price' \n\tex 'WA - 1.50' or 'WA - 0.085, OR - CE - 0.2'";
							break;
						case "or":
							if (subCompanyValue === undefined || subCompanyValue === null || subCompanyValue === "") {
								errors.solar_base_price_state = "Required:\nExpected SubCompany for Oregon:\n\tex 'OR - Central Electric - 0.4'";
							}
					}
					switch (pricingValue) {
						case "":
							errors.solar_base_price_state = "Required:\nExpected Format: 'State' - 'Price' \n\tex 'WA - 1.50' or 'WA - 0.085, OR - 0333'";
							break;
						case null:
							errors.solar_base_price_state = "Required:\nExpected Format: 'State' - 'Price' \n\tex 'WA - 1.50' or 'WA - 0.085, OR - 0444'";
							break;
					}
					if (stateValue && pricingValue && subCompanyValue) {
						if (bodyData["solar_base_price_state"]) {
							if (bodyData["solar_base_price_state"][stateValue]) {
								Object.assign(
									bodyData["solar_base_price_state"][stateValue],
									{ [subCompanyValue]: parseFloat(pricingValue) }
								);
							}
							else {
								Object.assign(bodyData["solar_base_price_state"], {
									[stateValue]: { [subCompanyValue]: parseFloat(pricingValue) }
								});
							}
						}
						else {
							Object.assign(bodyData, {
								"solar_base_price_state": {
									[stateValue]: { [subCompanyValue]: parseFloat(pricingValue) }
								}
							});
						}
					}
					else if (stateValue && pricingValue) {
						if (bodyData["solar_base_price_state"]) {
							Object.assign(bodyData["solar_base_price_state"], {
								[stateValue]: parseFloat(pricingValue),
							});
						}
						else {
							Object.assign(bodyData, {
								"solar_base_price_state": {
									[stateValue]: parseFloat(pricingValue),
								}
							});
						}
					}
				}
			});
		}


		if (!data.company_name) errors.company = "Missing Required Field:\nExpected Field: 'Company'\n\tex 'Aperture Science'";
		else {
			Object.keys(data.company_name).forEach((key) => {
				const companyValue = data.company_name[key];
				if (companyValue === undefined || companyValue === null || companyValue === "") errors.notes = "Missing Required Field:\nExpected Field: 'Company'\n\tex 'Aperture Science'";
				bodyData["company"] = companyValue;
			}
			);
		}


		if (!data.notes_value) errors.notes = "Missing Required Field:\nExpected Field: 'Notes'\n\tex 'Onboarding new group, effective 10-16-23.'";
		else {
			extractTextField(data, "notes", "Notes", errors, bodyData);
		}
		if (!data.market_value) errors.market = "Missing Required Field:\nExpected Field: 'Market'\n\tex 'Bend'";
		else {
			extractTextField(data, "market", "Market", errors, bodyData);
		}


		if (data.start_date_value) {
			validate_date(data.start_date_value, "Start Date", errors, "start_date", bodyData);
		}
		else {
			bodyData["start_date"] = null;
		}
		if (data.end_date_value) {
			validate_date(data.end_date_value, "End Date", errors, "end_date", bodyData);
		}

		if (data.promotion_name) {
			Object.keys(data.promotion_name).forEach((key, index) => {
				let promotionValue = data.promotion_name[key];
				if (promotionValue != undefined && promotionValue != null && promotionValue != "") {
					promotionValue = convert_string_capital_case(promotionValue?.toLowerCase());
					const priceKey = key.replace("name", "price");
					let promotionPrice = data["promotion_price"][priceKey];
					promotionPrice = promotionPrice?.toString().replace(/\s/g, "");

					if (promotionValue === undefined || promotionValue === null || promotionValue === "") errors.promotions = "Missing Field: Promotions 'Promotion'\n\tex 'Promotion Name' - '1'";
					if (promotionPrice === undefined || promotionPrice === null || promotionPrice === "") errors.promotions = "Missing Field: Promotions 'Price'\n\tex 'Promotion Name' - '2'";
					if (isNaN(parseFloat(promotionPrice))) errors.promotions = `"Invalid Field: Promotions row ${index + 1} 'Price' was not a number"`;
					if (bodyData["promotions"] && errors.promotions === "") {
						Object.assign(bodyData["promotions"], {
							[promotionValue]: parseFloat(promotionPrice),
						});
					}
					else {
						Object.assign(bodyData, {
							"promotions": {
								[promotionValue]: parseFloat(promotionPrice),
							}
						});
					}
				}
			});
		}
		if (data.roof_price_name) {
			Object.keys(data.roof_price_name).forEach((key, index) => {
				let roofMaterial = data.roof_price_name[key];
				if (roofMaterial != undefined && roofMaterial != null && roofMaterial != "") {
					roofMaterial = convert_string_capital_case(roofMaterial?.toLowerCase());
					const materialPriceKey = key.replace("name", "value");
					const materialMaxKey = key.replace("name", "max");
					let roofPrice = data["roof_price_value"][materialPriceKey];
					let roofMax = data["roof_price_max"][materialMaxKey];
					roofPrice = roofPrice?.toString().replace(/\s/g, "");
					roofMax = roofMax?.toString().replace(/\s/g, "");


					if (roofMaterial === undefined || roofMaterial === null || roofMaterial === "") errors.roof_price = "Missing Field: Roof Price 'Roof Type'\n\tex 'Owens Corning TruDefinition Duration Series Shingles' - '232.23' - '0'";
					if (roofPrice === undefined || roofPrice === null || roofPrice === "") errors.roof_price = "Missing Field: Roof Price 'Price'\n\tex 'Tile' - '343.00' -'0'";
					if (isNaN(parseFloat(roofPrice))) errors.roof_price = `"Invalid Field: Roof Price row ${index + 1} 'Price' was not a number"`;
					if (roofMax === undefined || roofMax === null || roofMax === "") errors.roof_price = "Missing Field: Roof Price 'Max'\n\tex 'Tile' - '343.00' - '0'";
					if (isNaN(parseFloat(roofMax))) errors.roof_price = `"Invalid Field: Roof Price row ${index + 1} 'Max' was not a number"`;
					const subPricing = { [roofMaterial]: { ["value"]: parseFloat(roofPrice), ["max"]: parseFloat(roofMax), } };

					if (bodyData["roof_price"] && errors.roof_price === "") {
						Object.assign(bodyData["roof_price"], subPricing);
					}
					else {
						Object.assign(bodyData, {
							"roof_price": subPricing,
						});
					}
				}
			});
		}
		if (data.roof_tax_name) {
			Object.keys(data.roof_tax_name).forEach((key, index) => {
				let stateNameTax = data.roof_tax_name[key];
				if (stateNameTax != undefined && stateNameTax != null && stateNameTax != "") {
					stateNameTax = stateNameTax?.toLowerCase()?.trim();
					if (stateNameTax in dropDownData.mapStateAbbreviation) {
						stateNameTax = dropDownData.mapStateAbbreviation[stateNameTax];
					}
					stateNameTax = convert_string_capital_case(stateNameTax?.toLowerCase());
					const materialPriceKey = key.replace("name", "price");
					let roofPrice = data["roof_tax_price"][materialPriceKey];
					roofPrice = roofPrice?.toString().replace(/\s/g, "");

					if (stateNameTax === undefined || stateNameTax === null || stateNameTax === "") errors.roof_tax = "Missing Field: Roof Tax per State 'Promotion'\n\tex 'OR' - '232.23'";
					if (roofPrice === undefined || roofPrice === null || roofPrice === "") errors.roof_tax = "Missing Field: Roof Tax per State 'Price'\n\tex 'WA' - '343.00'";
					if (isNaN(parseFloat(roofPrice))) errors.roof_tax = `"Invalid Field: Roof Tax per State row ${index + 1} 'Price' was not a number"`;
					if (bodyData["roof_tax"] && errors.roof_tax === "") {
						Object.assign(bodyData["roof_tax"], {
							[stateNameTax]: parseFloat(roofPrice),
						});
					}
					else {
						Object.assign(bodyData, {
							"roof_tax": {
								[stateNameTax]: parseFloat(roofPrice),
							}
						});
					}
				}
			});
		}
		if (data.market_adjustment_name) {
			Object.keys(data.market_adjustment_name).forEach((key, index) => {
				let marketAdjustment = data.market_adjustment_name[key];
				if (marketAdjustment != undefined && marketAdjustment != null && marketAdjustment != "") {
					marketAdjustment = convert_string_capital_case(marketAdjustment?.toLowerCase());
					const PriceKey = key.replace("name", "price");
					let marketPrice = data["market_adjustment_price"][PriceKey];
					marketPrice = marketPrice?.toString().replace(/\s/g, "");

					if (marketAdjustment === undefined || marketAdjustment === null || marketAdjustment === "") errors.market_adjustment = "Missing Field: Maket Adjustment 'Roof Type'\n\tex 'Owens Corning TruDefinition Duration Series Shingles' - '232.23'";
					if (marketPrice === undefined || marketPrice === null || marketPrice === "") errors.market_adjustment = "Missing Field: Maket Adjustment 'Price'\n\tex 'Tile' - '343.00'";
					if (isNaN(parseFloat(marketPrice))) errors.market_adjustment = `"Invalid Field: Market Adjustment row ${index + 1} 'Price' was not a number"`;
					if (bodyData["market_adjustment"] && errors.market_adjustment === "") {
						Object.assign(bodyData["market_adjustment"], {
							[marketAdjustment]: parseFloat(marketPrice),
						});
					}
					else {
						Object.assign(bodyData, {
							"market_adjustment": {
								[marketAdjustment]: parseFloat(marketPrice),
							}
						});
					}
				}
			});
		}
		if (data.solar_adder_name) {
			Object.keys(data.solar_adder_name).forEach((key, index) => {
				let solarAdder = data.solar_adder_name[key];
				if (solarAdder != undefined && solarAdder != null && solarAdder != "") {
					solarAdder = solarAdder?.trim()
						?.toLowerCase()
						?.replace(/\s/g, "_");
					const priceKey = key.replace("name", "price");
					const perKey = key.replace("name", "per");
					const maxKey = key.replace("name", "max");
					const scaledKey = key.replace("name", "kw_scaled");
					let adderPer = data["solar_adder_per"][perKey];
					let adderPrice = data["solar_adder_price"][priceKey];
					let adderMax = data["solar_adder_max"][maxKey];
					const adderScaled = data["solar_adder_kw_scaled"] ? [scaledKey] : undefined;
					adderPer = adderPer?.toString().replace(/\s/g, "");
					adderPrice = adderPrice?.toString().replace(/\s/g, "");
					adderMax = adderMax?.toString().replace(/\s/g, "");

					if (solarAdder === undefined || solarAdder === null || solarAdder === "") errors.solar_adder = "Missing Field: Solar Adders 'Solar Adder'\n\tex 'FSU' - '32'";
					if (adderPrice === undefined || adderPrice === null || adderPrice === "") errors.solar_adder = "Missing Field: Solar Adders 'Price'\n\tex 'MPU' - '250'";
					if (isNaN(parseFloat(adderPrice))) errors.solar_adder = `"Invalid Field: Solar Adders row ${index + 1} 'Price' is not a valid number"`;
					if (isNaN(parseFloat(adderMax))) errors.solar_adder = `"Invalid Field: Solar Adders row ${index + 1} 'Max' is not a valid number"`;


					if (adderPer != undefined && adderPer != null && adderPer != "") {
						if (isNaN(parseFloat(adderPer))) errors.solar_adder = `"Invalid Field: Solar Adders row ${index + 1} 'Per' is not a valid number"`;
						if (bodyData["solaradder"] && errors.solar_adder === "") {
							Object.assign(bodyData["solaradder"], {
								[solarAdder]: {
									"base": parseFloat(adderPrice),
									"per": parseFloat(adderPer),
									"max": parseFloat(adderMax),
									"kw_scaled": adderScaled ? true : false,
								},
							});
						}
						else {
							Object.assign(bodyData, {
								"solaradder": {
									[solarAdder]: {
										"base": parseFloat(adderPrice),
										"per": parseFloat(adderPer),
										"max": parseFloat(adderMax),
										"kw_scaled": adderScaled ? true : false,
									},
								}
							});
						}
					}
					else if (bodyData["solaradder"] && errors.solar_adder === "") {
						Object.assign(bodyData["solaradder"], {
							[solarAdder]: {
								"base": parseFloat(adderPrice),
								"max": parseFloat(adderMax),
								"kw_scaled": adderScaled ? true : false,
							},
						});
					}
					else {
						Object.assign(bodyData, {
							"solaradder": {
								[solarAdder]: {
									"base": parseFloat(adderPrice),
									"max": parseFloat(adderMax),
									"kw_scaled": adderScaled ? true : false,
								},
							}
						});
					}
				}
			});
		}
		if (data.roof_adder_name) {
			Object.keys(data.roof_adder_name).forEach((key, index) => {
				let roofAdder = data.roof_adder_name[key];
				if (roofAdder != undefined && roofAdder != null && roofAdder != "") {
					roofAdder = roofAdder?.trim()
						?.toLowerCase()
						?.replace(/\s/g, "_");
					const PriceKey = key.replace("name", "price");
					const perKey = key.replace("name", "per");
					const maxKey = key.replace("name", "max");
					const scaledKey = key.replace("name", "kw_scaled");
					let adderPrice = data["roof_adder_price"][PriceKey];
					let adderPer = data["roof_adder_per"][perKey];
					let adderMax = data["roof_adder_max"][maxKey];
					const adderScaled = data["roof_adder_kw_scaled"] ? [scaledKey] : undefined;
					adderPer = adderPer?.toString().replace(/\s/g, "");
					adderPrice = adderPrice?.toString().replace(/\s/g, "");
					adderMax = adderMax?.toString().replace(/\s/g, "");

					if (roofAdder === undefined || roofAdder === null || roofAdder === "") errors.roof_adder = "Missing Field: Roof Adders 'Roof Adder'\n\tex 'Tear Off One Layer' - '0'";
					if (adderPrice === undefined || adderPrice === null || adderPrice === "") errors.roof_adder = "Missing Field: Roof Adders 'Price'\n\tex 'Tear Off One Layer' - '0'";
					if (isNaN(parseFloat(adderPrice))) errors.roof_adder = `"Invalid Field: Roof Adders row ${index + 1} 'Price' is not a valid number"`;

					if (adderPer != undefined && adderPer != null && adderPer != "") {
						if (isNaN(parseFloat(adderPer))) errors.roof_adder = `"Invalid Field: Roof Adders row ${index + 1} 'Per' is not a valid number"`;
						if (bodyData["roofadder"] && errors.roof_adder === "") {
							Object.assign(bodyData["roofadder"], {
								[roofAdder]: {
									"base": parseFloat(adderPrice),
									"per": parseFloat(adderPer),
									"max": parseFloat(adderMax),
									"kw_scaled": adderScaled ? true : false,
								},
							});
						}
						else {
							Object.assign(bodyData, {
								"roofadder": {
									[roofAdder]: {
										"base": parseFloat(adderPrice),
										"per": parseFloat(adderPer),
										"max": parseFloat(adderMax),
										"kw_scaled": adderScaled ? true : false,
									},
								}
							});
						}
					}
					else if (bodyData["roofadder"] && errors.roof_adder === "") {
						Object.assign(bodyData["roofadder"], {
							[roofAdder]: {
								"base": parseFloat(adderPrice),
								"max": parseFloat(adderMax),
								"kw_scaled": adderScaled ? true : false,
							},
						});
					}
					else {
						Object.assign(bodyData, {
							"roofadder": {
								[roofAdder]: {
									"base": parseFloat(adderPrice),
									"max": parseFloat(adderMax),
									"kw_scaled": adderScaled ? true : false,
								},
							}
						});
					}
				}
			});
		}
		if (data.panel_fee_name) {
			Object.keys(data.panel_fee_name).forEach((key, index) => {
				let panelType = data.panel_fee_name[key];
				if (panelType != undefined && panelType != null && panelType != "") {
					panelType = panelType?.toLowerCase()
						.trim()
						.replace(/\s/g, "_");
					const PriceKey = key.replace("name", "price");
					const systemKey = key.replace("name", "system_size");
					let panelPrice = data["panel_fee_price"][PriceKey];
					let smallSystem = data["panel_fee_system_size"][systemKey];
					panelPrice = panelPrice?.toString().replace(/\s/g, "");
					smallSystem = smallSystem?.toString().replace(/\s/g, "");

					if (panelType === undefined || panelType === null || panelType === "") errors.panel_fee = "Missing Field: Panel Fee 'Panel'\n\tex 'Silfab' - '0.15'";
					if (panelPrice === undefined || panelPrice === null || panelPrice === "") errors.panel_fee = "Missing Field: Panel Fee 'Price'\n\tex 'ZnShine' - '0.1'";
					if (isNaN(parseFloat(panelPrice))) errors.panel_fee = `"Invalid Field: Panel Fee row ${index + 1} 'Price' is not a valid number"`;

					if (smallSystem != undefined && smallSystem != null && smallSystem != "") {
						const subSystem = {};
						Object.assign(subSystem, { [smallSystem]: parseFloat(panelPrice) });
						if (bodyData["panel_fee"] && bodyData["panel_fee"][panelType] && errors.panel_fee === "") {
							Object.assign(bodyData["panel_fee"][panelType], {
								[smallSystem]: parseFloat(panelPrice),
							});
						}
						else if (bodyData["panel_fee"] && errors.panel_fee === "") {
							Object.assign(bodyData["panel_fee"], {
								[panelType]: subSystem,
							});
						}
						else {
							Object.assign(bodyData, {
								"panel_fee": {
									[panelType]: subSystem,
								}
							});
						}
					}
					else {
						if (bodyData["panel_fee"] && errors.panel_fee === "") {
							Object.assign(bodyData["panel_fee"], {
								[panelType]: parseFloat(panelPrice),
							});
						}
						else {
							Object.assign(bodyData, {
								"panel_fee": {
									[panelType]: parseFloat(panelPrice),
								}
							});
						}
					}
				}
			});
		}
		if (data.incentive_company) {
			Object.keys(data.incentive_company).forEach((key, index) => {
				let incentiveCompany = data.incentive_company[key];
				if (incentiveCompany != undefined && incentiveCompany != null && incentiveCompany != "") {
					incentiveCompany = convert_string_capital_case(incentiveCompany?.toLowerCase());
					const nameKey = key.replace("company", "name");
					const PriceKey = key.replace("company", "price");
					const maxKey = key.replace("company", "max");

					let incentiveName = data["incentive_name"][nameKey];
					incentiveName = incentiveName?.toLowerCase()?.replace(/\s/g, "");
					let incentivePrice = data["incentive_price"][PriceKey];
					incentivePrice = incentivePrice?.toString().replace(/\s/g, "");
					let incentiveMax = data["incentive_max"][maxKey];
					incentiveMax = incentiveMax?.toString().replace(/\s/g, "");

					if (incentiveCompany === undefined || incentiveCompany === null || incentiveCompany === "") errors.incentives = "Missing Field: Incentives 'Roof Type'\n\tex '' - ''";
					if (incentiveName === undefined || incentiveName === null || incentiveName === "") errors.incentives = "Missing Field: Incentives 'Name'\n\tex '' - ''";
					if (incentivePrice === undefined || incentivePrice === null || incentivePrice === "") errors.incentives = "Missing Field: Incentives 'Price'\n\tex '' - ''";
					if (isNaN(parseFloat(incentivePrice))) errors.incentives = `"Invalid Field: Incentives row ${index + 1} 'Price' is not a valid number"`;

					let subData = {};
					if (parseFloat(incentiveMax) >= 0) {
						subData = {
							[incentiveName]: { "base_price": parseFloat(incentivePrice), "max": parseFloat(incentiveMax), "is_max": true },
						};
					}
					else {
						subData = {
							[incentiveName]: { "base_price": parseFloat(incentivePrice), "is_max": false },
						};
					}

					if (bodyData["incentives"] && errors.incentives === "") {
						if (bodyData["incentives"][incentiveCompany]) {
							Object.assign(bodyData["incentives"][incentiveCompany], {
								[incentiveName]: subData[incentiveName],
							});
						}
						else {
							Object.assign(bodyData["incentives"], {
								[incentiveCompany]: subData,
							});
						}
					}
					else {
						Object.assign(bodyData, {
							"incentives": {
								[incentiveCompany]: subData,
							}
						});
					}
				}
			});
		}
		if (data.loan_options_name) {
			Object.keys(data.loan_options_name).forEach((key, index) => {
				const loanOption = convert_string_capital_case(data.loan_options_name[key]?.toLowerCase());
				if (loanOption != undefined && loanOption != null && loanOption != "") {
					const rateKey = key.replace("name", "rate");
					let loanRate = data["loan_options_rate"][rateKey];
					loanRate = loanRate?.toString().replace(/\s/g, "");

					if (loanOption === undefined || loanOption === null || loanOption === "") errors.loan_options = "Missing Field: Loan Options 'Loan Option'\n\tex '' - ''";
					if (loanRate === undefined || loanRate === null || loanRate === "") errors.loan_options = "Missing Field: Loan Options 'Rate'\n\tex '' - ''";
					if (isNaN(parseFloat(loanRate))) errors.loan_options = `"Invalid Field: Loan Options row ${index + 1} 'Price' is not a valid number"`;
					if (bodyData["loan_options"] && errors.loan_options === "") {
						Object.assign(bodyData["loan_options"], {
							[loanOption]: parseFloat(loanRate),
						});
					}
					else {
						Object.assign(bodyData, {
							"loan_options": {
								[loanOption]: parseFloat(loanRate),
							}
						});
					}
				}
			});
		}
		// if (!data.pdf) errors.pdf = {"Required:\nExpected Format: 'PDF Name' \n\tex 'Infinity Solar USA '";}
		for (const key in errors) {
			if (errors[key].length > 0) {
				apiErrorToast(errors[key]);
				return "";
			}
		}
		return bodyData;
	}
	return validate(userData);
};

export default validator;
