import apiCall from "../../../utils/api";
import { leaderboardActions } from "../../actions/actions";
import { setTop5Text, setSetterData, setCloserData, setSelfgenData, setLoading, setSetterColumns, setCloserColumns, setSelfgenColumns } from "../../actions/leaderboards";
import { Middleware } from "redux";
import { apiErrorToast } from "../../../Components/Toasts/ApiErrorToast";

const formatTopFive = (data: PersonReport[]) => {
	if (data.length < 5) {
		return;
	}
	let topFive = "";
	for (let i = 0; i < 5; i++) {
		const personName = data[i]["name"];
		const personPowerScore = data[i]["detail"]["power_score"]["display_value"];
		topFive += ` ${i + 1}. ${personName} : Score ${personPowerScore} ⚡☀️⚡`;
	}
	return topFive;
};

const header_order = [
	"power_rank",
	"power_score",
	"name",
	"total_combined_revenue",
	"total_solar_sold_post_approval",
	"total_roofs_sold",
	"total_units_sold",
	"total_kw_sold",
	"total_square_footage",
	"total_interactions",
	"total_interaction_leads",
	"interaction_lead_ratio",
	"total_appointments_booked",
	"total_appointments_sat",
	"sit_rate",
	"total_appointments_closed",
	"closing_rate_on_sat_appointments",
	"total_appointments_cancelled",
	"total_cancelled",
	"sold_cancellation_rate"
];

// TODO: Should switch the formating of data over to the Lambda, for faster loading and caching
//   this is quite a lot for some machines to handle. Incurs an extra 200ms sometimes.
const header_mapping: { [key:string]: { [key:string]: string}} = {
	"total_accounts_sold": {
		"title": "Total Accounts Sold",
	},
	"total_solar_sold_post_approval": {
		"title": "Solar Sold",
	},
	"total_roofs_sold": {
		"title": "Roofs Sold",
	},
	"total_combined_revenue": {
		"title": "Combined Revenue",
	},
	"total_kw_sold": {
		"title": "KW Sold",
	},
	"total_cancelled": {
		"title": "Cancelled",
	},
	"total_units_sold": {
		"title": "Units Sold",
	},
	"total_square_footage": {
		"title": "SQ",
	},
	"total_interactions": {
		"title": "Interactions",
	},
	"total_interaction_leads": {
		"title": "Interaction Leads",
	},
	"total_appointments_booked": {
		"title": "Appts Booked",
	},
	"total_appointments_sat": {
		"title": "Appts Sat",
	},
	"total_appointments_closed": {
		"title": "Appts Closed"
	},
	"total_appointments_cancelled": {
		"title": "Appts Cancelled",
	},
	"sold_cancellation_rate": {
		"title": "Sold Cancellation %",
	},
	"sit_rate": {
		"title": "Sit %",
	},
	"interaction_lead_ratio": {
		"title": "Interaction/Lead Ratio",
	},
	"closing_rate_on_sat_appointments": {
		"title": "Closing % on Sat Appts",
	},
	"power_score": {
		"title": "Power Score",
	},
	"power_rank": {
		"title": "Power Rank",
	},
};

function setTableHeaders(data: string[]): Array<HeaderData> {
	data = data.sort((a,b) => {
		return (
			header_order.indexOf( a ) - header_order.indexOf( b )
		);
	});
	const headers: HeaderData[] = [];
	headers.push({
		Header: "Name",
		accessor: "name"
	});
	for (let i = 0; i < data.length; i++) {
		const value:string = data[i];
		headers.push({
			Header: header_mapping[value]["title"],
			accessor: "detail." + value,
			Cell: (row: { cell: { value: undefined|{ [key:string]: string } }}) => {
				if (row.cell.value == undefined) {
					return null;
				}
				return row.cell.value["display_value"];
			},
			sortType: (rowA, rowB, id) => {
				const rowAUndefined = rowA.values[id] === undefined || rowA.values[id].value === null;
				const rowBUndefined = rowB.values[id] === undefined || rowB.values[id].value === null;
				if (rowAUndefined && rowBUndefined) return 0;
				if (rowAUndefined) return -1;
				if (rowBUndefined) return 1;
				if (Number(rowA.values[id].value) > Number(rowB.values[id].value)) return 1;
				if (Number(rowB.values[id].value) > Number(rowA.values[id].value)) return -1;
				return 0;
			}
		});
	}
	return headers;
}

const getData: Middleware<object, RootState> = store => next => action => {
	const { dispatch } = store;
	if (action.type === leaderboardActions.GET_DATA) {
		const { loading } = store.getState().leaderboards;
		if (!loading) {
			const { startDate, endDate } = action.data;

			if (/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(startDate) && /^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/.test(endDate)) {
				Promise.all([
					apiCall({
						url: `${process.env.REACT_APP_API_SERVICE}/leaderboard/report?start_date=${`${startDate}T00:00:00`}&end_date=${`${endDate}T00:00:00`}&marquee=true`,
						method: "GET"
					}),
					apiCall({
						url: `${process.env.REACT_APP_API_SERVICE}/leaderboard/report?start_date=${`${startDate}T00:00:00`}&end_date=${`${endDate}T00:00:00`}`,
						method: "GET"
					})
				]).then(res => {
					const scrollData = res[0];
					const tableData = res[1];


					if (Object.keys(scrollData).length === 0) {
						return null;
					} else {
						if (scrollData && Object.keys(scrollData).length > 0) {
							const top5 = Object.keys(scrollData).reduce((acc, key) => { acc[key] = formatTopFive(scrollData[key]); return acc; }, {});
							dispatch(setTop5Text(top5));
						}
					}

					const dataKeys = Object.keys(tableData);

					if (dataKeys.includes("setters")) {
						dispatch(setSetterData(tableData["setters"]));
						if (tableData["setters"].length) {
							dispatch(setSetterColumns(setTableHeaders(Object.keys(tableData["setters"][0]["detail"]))));
						}
					} 
					if (dataKeys.includes("selfgen")) {
						dispatch(setSelfgenData(tableData["selfgen"]));
						if (tableData["selfgen"].length) {
							dispatch(setSelfgenColumns(setTableHeaders(Object.keys(tableData["selfgen"][0]["detail"]))));
						}
					} 
					if (dataKeys.includes("closers")) {
						dispatch(setCloserData(tableData["closers"]));
						if (tableData["closers"].length) {
							dispatch(setCloserColumns(setTableHeaders(Object.keys(tableData["closers"][0]["detail"]))));
						}
					}
				}).catch(e => {
					console.log(e);
					apiErrorToast();
				}).finally(() => {
					dispatch(setLoading(false));
				});
			}
		}
	}
	const result = next(action);
	return result;
};

export default [getData];
