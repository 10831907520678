import React from "react";
import {  Button, Col, Row } from "react-bootstrap";
import { TextInput, fieldTypes } from "../Form";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCommissionCalculationCustomer, submitCommissionEdit } from "../../redux/actions/commissionCalculation";

const CommissionCalculationEdit = () => {
	const currentCommissionCalculationCustomer = useSelector(({ commissionCalculation }: RootState) => commissionCalculation.currentCommissionCalculationCustomer);
	const isSubmittingCommissionCalculation = useSelector(({ commissionCalculation }: RootState) => commissionCalculation.isSubmittingCommissionCalculation);
	const dispatch = useDispatch();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.name && e.target.value && currentCommissionCalculationCustomer) {
			dispatch(setCurrentCommissionCalculationCustomer({ ...currentCommissionCalculationCustomer, [e.target.name]: e.target.value }));
		}
	};

	const handleSubmit = () => {
		console.log("Submitted");
		if (currentCommissionCalculationCustomer) {
			dispatch(submitCommissionEdit(currentCommissionCalculationCustomer));
		} else {
			console.log("No current customer");
		}
	};


	const leftColFields = [
		{
			label: "Customer Name",
			name: "customer_name"
		},
		{
			label: "Closer",
			name: "closer"
		},
		{
			label: "Setter",
			name: "setter"
		},
		{
			label: "Sales Month",
			name: "date_created"
		},
		{
			label: "Job Total Reveune",
			name: "job_total_revenue"
		},
		{
			label: "Incentives Offered",
			name: "incentives_offered"
		},
		{
			label: "Total Job Revenue Plus Incentives",
			name: "total_job_revenue_plus_incentives"
		},
		{
			label: "Job KW",
			name: "job_kw"
		},
		{
			label: "Price Per Watt",
			name: "sales_price_per_watt"
		},
		{
			label: "Market",
			name: "market"
		},
		{
			label: "Market Redline",
			name: "market_redline"
		},
		{
			label: "Market Total Commissions",
			name: "market_total_commissions"
		},
		{
			label: "Roof",
			name: "roof"
		},
		// {
		// 	label: "GTR",
		// 	name: "GTR"
		// },
		{
			label: "Incentives Deductions",
			name: "incentive_deductions"
		},
		{
			label: "Total Adders Plus Incentives",
			name: "total_adders_plus_incentives"
		}
	];

	const rightColFields = [
		{
			label: "Net Commission",
			name: "net_commission"
		},
		{
			label: "Self Gen Closer Commission Redline",
			name: "closer_redline"
		},
		{
			label: "Self Gen Closer Commission Amount",
			name: "closer_amount"
		},
		{
			label: "Self Gen Setter Commission Paid",
			name: "closer_paid"
		},
		{
			label: "Self Gen Closer Pay Date",
			name: "closer_pay_date"
		},
		{
			label: "Setter Commission Amount",
			name: "setter_commission_amount"
		},
		{
			label: "Setter Commission Rate",
			name: "setter_commission_rate"
		},
		{
			label: "Setter Commission Paid",
			name: "setter_commission_paid"
		},
		{
			label: "Setter Pay Date",
			name: "setter_pay_date"
		},
		{
			label: "Team Lead Name",
			name: "team_lead"
		},
		{
			label: "Team Lead Commission Rate",
			name: "team_lead_commission_rate"
		},
		{
			label: "Team Lead Commission Amount",
			name: "team_lead_commission_amount"
		},
		{
			label: "Team Lead Commission Paid",
			name: "team_lead_commission_paid"
		},
		{
			label: "Team Lead Pay Date",
			name: "team_lead_pay_date"
		},
		{
			label: "Setter Coach Name",
			name: "setter_coach"
		},
		{
			label: "Setter Coach Commission Rate",
			name: "setter_coach_commission_rate"
		},
		{
			label: "Setter Coach Commission Amount",
			name: "setter_coach_commission_amount"
		},
		{
			label: "Setter Coach Commission Paid",
			name: "setter_coach_commission_paid"
		},
		{
			label: "Setter Coach Pay Date",
			name: "setter_coach_pay_date"
		},
		{
			label: "Branch Manager Name",
			name: "branch_manager"
		},
		{
			label: "Branch Manager Commission Rate",
			name: "branch_manager_commission_rate"
		},
		{
			label: "Branch Manager Commission Amount",
			name: "branch_manager_commission_amount"
		},
		{
			label: "Branch Manager Commission Paid",
			name: "branch_manager_commission_paid"
		},
		{
			label: "Branch Manager Pay Date",
			name: "branch_manager_pay_date"
		},
		{
			label: "Market Manager Name",
			name: "market_manager"
		},
		{
			label: "Market Manager Commission Rate",
			name: "market_manager_commission_rate"
		},
		{
			label: "Market Manager Commission Amount",
			name: "market_manager_commission_amount"
		},
		{
			label: "Market Manager Commission Paid",
			name: "market_manager_commission_paid"
		},
		{
			label: "Market Manager Pay Date",
			name: "market_manager_pay_date"
		},
		{
			label: "Reserve Fund Percent",
			name: "reserve_fund_percent"
		},
		{
			label: "Reserve Fund Contribution",
			name: "reserve_fund_contribution"
		},
		{
			label: "Total Commission",
			name: "total_commission"
		},
		{
			label: "Miguel ASM",
			name: "miguel_asm"
		}
	];

	const paidFields = {
		"closer_paid": "closer_pay",
		"setter_commission_paid": "setter_pay",
		"team_lead_commission_paid": "team_lead_pay",
		"setter_coach_commission_paid": "setter_coach_pay",
		"branch_manager_commission_paid": "branch_manager_pay",
		"market_manager_commission_paid": "market_manager_pay",
		"closer_pay_date": "closer_pay",
		"setter_pay_date": "setter_pay",
		"team_lead_pay_date": "team_lead_pay",
		"setter_coach_pay_date": "setter_coach_pay",
		"branch_manager_pay_date": "branch_manager_pay",
		"market_manager_pay_date": "market_manager_pay"
	};


	return (
		<>
			{currentCommissionCalculationCustomer?
				<div>
					<div className="customer-detail-modal-header-container">
						<h3>{`${currentCommissionCalculationCustomer.customer_name}`}</h3>
					</div>
					<Row>
						<Col md={6} sm={12}>
							{leftColFields.map((field) =>
								<TextInput
									key={field.name}
									field={{
										label: field.label,
										controlId: field.name,
										type: fieldTypes.text,
										value: currentCommissionCalculationCustomer[field.name],
										groupClasses: "mb-3",
										name: field.name,
										onChange: handleChange,
										disabled: isSubmittingCommissionCalculation
									}}
								/>
							)}
						</Col>
						<Col md={6} sm={12}>
							{rightColFields.map((field) =>
								<TextInput
									key={field.name}
									field={{
										label: field.label,
										controlId: field.name,
										type: fieldTypes.text,
										value: Object.keys(paidFields).includes(field.name) && currentCommissionCalculationCustomer[paidFields[field.name]] !== null ? currentCommissionCalculationCustomer[paidFields[field.name]][field.name] : currentCommissionCalculationCustomer[field.name],
										groupClasses: "mb-3",
										name: field.name,
										onChange: handleChange,
										disabled: isSubmittingCommissionCalculation
									}}
								/>
							)}
						</Col>
					</Row>
					<Row>
						{
							currentCommissionCalculationCustomer.solar_adders || currentCommissionCalculationCustomer.roof_adders ?
								<>
									<h3>Adders</h3>
									{
										currentCommissionCalculationCustomer.solar_adders ?
											<Col md={6} sm={12}>
												<h4>Solar</h4>
												{Object.keys(currentCommissionCalculationCustomer.solar_adders).map((key: string) => {
													return <TextInput
														key={key}
														field={{
															label: key,
															controlId: key,
															type: fieldTypes.text,
															groupClasses: "mb-3",
															name: key,
															onChange: handleChange,
															value: currentCommissionCalculationCustomer.solar_adders ? currentCommissionCalculationCustomer.solar_adders[key].default_cost : "",
															disabled: isSubmittingCommissionCalculation
														}}
													/>;
												})}
											</Col>:<></>
									}
									{
										currentCommissionCalculationCustomer.roof_adders ?
											<Col md={6} sm={12}>
												<h4>Roof</h4>
												{Object.keys(currentCommissionCalculationCustomer.roof_adders).map((key: string) => {
													return <TextInput
														key={key}
														field={{
															label: key,
															controlId: key,
															type: fieldTypes.text,
															groupClasses: "mb-3",
															name: key,
															onChange: handleChange,
															value: currentCommissionCalculationCustomer.roof_adders ? currentCommissionCalculationCustomer.roof_adders[key].default_cost : "",
															disabled: isSubmittingCommissionCalculation
														}}
													/>;
												})}
											</Col>:<></>
									}
								</>:<></>}
					</Row>
					<Button onClick={handleSubmit} className="ms-2" title="Save">Save</Button>
				</div >:
				<></>
			}
		</>
	);
};

export default CommissionCalculationEdit;
