import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import Modal from "../Components/Modal/Modal";
import Container from "react-bootstrap/Container";
import Heading from "../Components/Heading/Heading";
import ViewPrices from "../Components/Prices/ViewPricing";
import AddPrices from "../Components/Prices/AddPrices";
import EditSelectedPricing from "../Components/Prices/EditSelectedPricing";
import WhenAuthorized from "../Components/WhenAuthorized";
import { toast } from "react-toastify";
import "./styles/Pricing.sass";
import {
	setCompany,
	getMarkets,
	setMarket,
	setDateRange,
} from "../redux/actions/iaPriceAdjuster";
import { getProfile } from "../redux/actions/profileActions";
import { useSelector, useDispatch } from "react-redux";
import { getPricing, setShowPricingModal, setShowPricingModalEdit } from "../redux/actions/installAgreement";
import { SelectInput } from "../Components/Form";
import { UserPermissions } from "../enums/UserPermissions";
import {Calendar} from "primereact/calendar";


const company_vals: Array<SelectOption> = [
];
const market_vals: Array<SelectOption> = [
];

function clearArray<T>(array: Array<T>) {
	while (array.length > 0) {
		array.pop();
	}
}

function setMarkets() {
	const markets = useSelector(({ iaPriceAdjuster }: RootState) => iaPriceAdjuster.setMarkets);
	clearArray(market_vals);
	if (markets !== undefined && Object.keys(markets).length > 0) {
		if (("markets" in markets)) {
			markets["markets"].map((market: string, index: number) => {
				if (!market_vals.includes({ key: index.toString(), value: market, label: market })) {
					market_vals.push({ key: index.toString(), value: market, label: market });
				}
				else {
					market_vals.push({ key: (index + 1).toString(), value: market, label: market });
				}
			});
		}
	}
	else if (typeof markets === "boolean") {
		toast.info("No markets for the selected company", {
			position: "top-right",
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "dark",
			toastId: "ia-Submission-Error",
			onClick: () => window.close(),
			autoClose: 5000
		});
	}
}

const Prices = () => {
	const profile_details = useSelector(({ profile }: RootState) => profile.profile);
	const pricing = useSelector(({ installAgreement }: RootState) => installAgreement.pricing);
	const dateRange = useSelector(({ iaPriceAdjuster }: RootState) => iaPriceAdjuster.dateRange);
	const company = useSelector(({ iaPriceAdjuster }: RootState) => iaPriceAdjuster.setCompany);
	const market = useSelector(({ iaPriceAdjuster }: RootState) => iaPriceAdjuster.setMarket);
	const markets = useSelector(({ iaPriceAdjuster }: RootState) => iaPriceAdjuster.setMarkets);
	const showPricingModal = useSelector(({ installAgreement }: RootState) => installAgreement.showPricingModal);
	const showPricingModalEdit = useSelector(({ installAgreement }: RootState) => installAgreement.showPricingModalEdit);
	const dispatch = useDispatch();

	useEffect(() => {
		clearArray(company_vals);
		clearArray(market_vals);
		dispatch(getProfile());
	}, []);
	setMarkets();

	useEffect(() => {
		clearArray(company_vals);
		let company_exists = false;
		if (profile_details?.company) {
			if (!company) { dispatch(setCompany(profile_details.company)); }
			for (let i = 0; i < company_vals.length; i++) {
				if (company_vals[i].value == profile_details.company) {
					company_exists = true;
				}
			}
			if (company_exists === false) {
				company_vals.push({ key: (company_vals.length + 100).toString(), value: profile_details.company, label: profile_details.company });
			}
			if (markets != undefined) {
				if (Object.keys(markets).length === 0 && markets.constructor === Object) {
					dispatch(getMarkets(profile_details.company));
				}
			}
		}
		if (profile_details?.has_access_to_companies) {
			profile_details?.has_access_to_companies?.map((company: string, index: number) => {
				if (!company_vals.includes({ key: index.toString(), value: company, label: company })) {
					company_vals.push({ key: index.toString(), value: company, label: company });
				}
				else {
					company_vals.push({ key: (index + 1).toString(), value: company, label: company });
				}
			});
		}
	}, [profile_details]);

	useEffect(() => {
		if ((company && market) && (company != "" && market != "")) {
			dispatch(getPricing({ "company": company, "market": market, "startDate": dateRange?.startDate, "endDate": dateRange?.endDate }));
		}
	}, [company, market, dateRange]);

	const handleDateChange = (value, type) => {
		// If type is start_date or end_date and the value is 1970-01-01, set it to null
		if (type === "start_date" || type === "end_date") {
			if (value === "1970-01-01") {
				value = null;
			}
		}
		dispatch(setDateRange({ startDate: value.value.toISOString().substring(0,10), endDate: value.value.toISOString().substring(0,10) }));
	};

	const onAddPricingClick = () => {
		dispatch(setShowPricingModal(true));
	};

	const onEditPricingClick = () => {
		if (company != undefined && market != undefined) {
			dispatch(setShowPricingModalEdit(true));
		}
	};

	const parseDateCorrectly = (date) => {
		if (date === undefined || date === null) {
			return null;
		}
		const split = date.split("-");
		return`${split[1]}-${split[2]}-${split[0]}`;
	};


	return <>
		<Modal
			isShown={showPricingModal ?? false}
			hideModal={() => {
				dispatch(setShowPricingModal(false));
			}}
			body={
				<AddPrices />
			}
			heading="Add Pricing Configuration"
			addlProps={{ size: "lg" }}
		/>
		<Modal
			isShown={showPricingModalEdit ?? false}
			hideModal={() => {
				dispatch(setShowPricingModalEdit(false));
			}}
			body={
				pricing ? <EditSelectedPricing pricing={pricing[0]} /> : <> </>
			}
			heading="Edit Pricing Configuration"
			addlProps={{ size: "lg" }}
		/>
		<Container>
			<WhenAuthorized permission={UserPermissions.admin}>
				<>
					<Button onClick={onAddPricingClick} className="add-pricing-button">Add Pricing</Button>
				</>
			</WhenAuthorized>
			{/* change permission to edit or historical edit depending on date range selected? */}
			<WhenAuthorized permission={UserPermissions.edit_prices}>
				<>
					<Button onClick={onEditPricingClick} className="add-pricing-button">Edit Pricing</Button>
				</>
			</WhenAuthorized>
			<div className="mb-3 pricing-company-select-container">
				<SelectInput field={{
					controlId: "companyChoice",
					label: "Selected Company",
					labelClasses: "text-white",
					value: company,
					onChange: e => {
						dispatch(setMarket(""));
						dispatch(setCompany(e.target.value));
						dispatch(getMarkets(e.target.value));
					},
					dropdownData: company_vals,
					disabled: false
				}} />
			</div>
			<div className="mb-3 pricing-market-select-container">
				<SelectInput field={{
					controlId: "marketChoice",
					label: "Selected Market",
					labelClasses: "text-white",
					value: market,
					onChange: e => {
						dispatch(setMarket(e.target.value));
					},
					dropdownData: market_vals,
					disabled: false
				}} />
			</div>
			<div className="mb-3 pricing-date-select-container" style={{}}>
				<label style={{color:"white",paddingBottom:"0.5rem", paddingRight:"0.5rem"}}>Date</label>
				<Calendar
					value = {new Date(String(parseDateCorrectly(dateRange?.startDate)))}
					onChange={(date) => handleDateChange(date, "start_date")}
					dateFormat="mm/dd/yy"
					showIcon
				/>
			</div>
			<Heading>Pricing</Heading>
			{pricing ? <ViewPrices pricing={pricing} /> : <></>}
		</Container>
	</>;
};


export default Prices;
